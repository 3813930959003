import React, { memo } from "react"

const EnglishIcon = (props) => (
  <svg height="28" viewBox="0 0 28 28" {...props}>
    <path d="M18.5 1.5H8.49992L3.21383 6.23121H23.8056L18.5 1.5Z" fill="#D31B24"/>
    <path d="M23.5 6H2.5L0.812012 11.0442L26.1515 11.0318L23.5 6Z" fill="white"/>
    <path d="M26.9982 11L1 11.0129L1.00182 16L27 15.9871L26.9982 11Z" fill="#D31B24"/>
    <path d="M27 16L1 16.0129L3 21L25 20.9871L27 16Z" fill="white"/>
    <path d="M12.8855 1.41797L6.5 3L0.8125 11.0382L12.8892 11.0336L12.8855 1.41797Z" fill="#6A48ED"/>
    <path d="M5.70966 4.72117L5.30659 4.94537L5.38305 4.46966L5.0564 4.13373L5.50706 4.06376L5.70924 3.63184L5.91023 4.06361L6.36096 4.13323L6.03456 4.46941L6.11293 4.94506L5.70966 4.72117Z" fill="white"/>
    <path d="M7.99011 4.72019L7.58703 4.94439L7.66503 4.46868L7.33838 4.13275L7.78905 4.06278L7.98969 3.63086L8.19222 4.06263L8.64293 4.13225L8.31654 4.46843L8.39337 4.94408L7.99011 4.72019Z" fill="white"/>
    <path d="M3.42695 6.66331L3.02386 6.8875L3.10033 6.41181L2.77368 6.07587L3.22435 6.00589L3.42653 5.57397L3.62752 6.00573L4.07823 6.07537L3.75337 6.41156L3.8302 6.88719L3.42695 6.66331Z" fill="white"/>
    <path d="M5.71063 6.66258L5.30755 6.88676L5.38402 6.41108L5.05737 6.07514L5.50803 6.00516L5.71021 5.57324L5.9112 6.005L6.36192 6.07464L6.03553 6.41083L6.11389 6.88645L5.71063 6.66258Z" fill="white"/>
    <path d="M7.99084 6.66209L7.58776 6.88628L7.66576 6.41059L7.33911 6.07465L7.78978 6.00467L7.99043 5.57275L8.19295 6.00451L8.64366 6.07415L8.31727 6.41034L8.3941 6.88597L7.99084 6.66209Z" fill="white"/>
    <path d="M3.42768 8.60107L3.0246 8.82527L3.10106 8.34956L2.77441 8.01363L3.22508 7.94364L3.42726 7.51172L3.62825 7.94349L4.07896 8.01313L3.7541 8.34931L3.83094 8.82496L3.42768 8.60107Z" fill="white"/>
    <path d="M5.71137 8.60034L5.3083 8.82454L5.38476 8.34883L5.05811 8.0129L5.50877 7.94291L5.71095 7.51099L5.91194 7.94276L6.36266 8.0124L6.03627 8.34858L6.11464 8.82423L5.71137 8.60034Z" fill="white"/>
    <path d="M7.99158 8.5996L7.58849 8.8238L7.66649 8.34809L7.33984 8.01216L7.79051 7.94217L7.99116 7.51025L8.19368 7.94201L8.64439 8.01166L8.318 8.34784L8.39483 8.82349L7.99158 8.5996Z" fill="white"/>
    <path d="M10.271 4.71946L9.86798 4.94366L9.94448 4.46795L9.61768 4.13202L10.0699 4.06205L10.2706 3.63013L10.4716 4.0619L10.9239 4.13152L10.5974 4.4677L10.6742 4.94335L10.271 4.71946Z" fill="white"/>
    <path d="M10.272 6.66087L9.86885 6.88505L9.94535 6.40937L9.61865 6.07343L10.0709 6.00345L10.2715 5.57153L10.4725 6.00329L10.9249 6.07293L10.5984 6.40912L10.6752 6.88475L10.272 6.66087Z" fill="white"/>
    <path d="M10.2727 8.59863L9.86969 8.82283L9.94619 8.34712L9.61938 8.01119L10.0716 7.9412L10.2723 7.50928L10.4733 7.94105L10.9256 8.01069L10.5991 8.34687L10.6759 8.82252L10.2727 8.59863Z" fill="white"/>
    <path d="M24.5 21H2.5L8.5 26L14.5 27L20.5 26L24.5 21Z" fill="#D31B24"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26802 6.268 0 14 0C21.732 0 28 6.26802 28 14C28 21.732 21.732 28 14 28C6.268 28 0 21.732 0 14ZM14 2C7.37257 2 2 7.37258 2 14C2 20.6274 7.37257 26 14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2Z" fill="white"/>
  </svg>
)

export default memo(EnglishIcon)
