import React, { memo } from "react"
import { CheckedIcon, Container, StyledInput, UncheckedIcon } from "./styles"

const Checkbox = ({ className, checked = false, children, ...rest }) => (
  <Container className={className}>
    <StyledInput {...rest} type="checkbox" checked={checked} />
    {checked ? <CheckedIcon /> : <UncheckedIcon />}
    <span>{children}</span>
  </Container>
)

export default memo(Checkbox)
