import React, { memo } from "react"

const ExclamationIcon = (props) => (
  <svg height="1em" viewBox="0 0 32.279 32.279" {...props}>
    <g>
      <path fill="currentColor" d="M16.14,0a16.14,16.14,0,1,0,16.14,16.14A16.131,16.131,0,0,0,16.14,0Zm0,29.757A13.618,13.618,0,1,1,29.757,16.14,13.61,13.61,0,0,1,16.14,29.757Z"/>
      <g transform="translate(14.879 8.125)">
        <path fill="currentColor" d="M237.261,128.877A1.261,1.261,0,0,0,236,130.138v8.12a1.261,1.261,0,1,0,2.522,0v-8.12A1.261,1.261,0,0,0,237.261,128.877Z" transform="translate(-236 -128.877)"/>
      </g>
      <g transform="translate(14.437 20.311)">
        <circle fill="currentColor" cx="1.702" cy="1.702" r="1.702"/>
      </g>
    </g>
  </svg>
)

export default memo(ExclamationIcon)
