import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a { fill: currentColor; }
`

const LogOutIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 18.75 20" {...props}>
    <path className="a" d="M17.571,14.428v-2.5h-6.25v-2.5h6.25v-2.5l3.75,3.75Zm-1.25-1.25v5h-6.25v3.75l-7.5-3.75V1.928h13.75v6.25h-1.25v-5h-10l5,2.5v11.25h5v-3.75Z" transform="translate(-2.571 -1.928)"/>
    <path className="a" d="M17.571,14.428v-2.5h-6.25v-2.5h6.25v-2.5l3.75,3.75Zm-1.25-1.25v5h-6.25v3.75l-7.5-3.75V1.928h13.75v6.25h-1.25v-5h-10l5,2.5v11.25h5v-3.75Z" transform="translate(-2.571 -1.928)"/>
  </StyledSvg>
)

export default memo(LogOutIcon)
