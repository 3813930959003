import React, { memo } from "react"

const SpanishIcon = (props) => (
  <svg height="29" viewBox="0 0 29 29" {...props}>
    <circle cx="14.5" cy="14.5" r="12.5" fill="#FF387D"/>
    <path d="M27 10H2V19H27V10Z" fill="#F7BD30"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.739258 14.4336C0.739258 6.70161 7.00727 0.433594 14.7393 0.433594C22.4713 0.433594 28.7393 6.70161 28.7393 14.4336C28.7393 22.1656 22.4713 28.4336 14.7393 28.4336C7.00728 28.4336 0.739258 22.1656 0.739258 14.4336ZM14.7393 2.43359C8.11184 2.43359 2.73926 7.80618 2.73926 14.4336C2.73926 21.061 8.11184 26.4336 14.7393 26.4336C21.3667 26.4336 26.7393 21.061 26.7393 14.4336C26.7393 7.80618 21.3667 2.43359 14.7393 2.43359Z" fill="white"/>
  </svg>
)

export default memo(SpanishIcon)