import styled from "styled-components"
import LoadingSpinner from "../../../legacyComponents/Desktop/Animations/LoadingSpinner"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { CARD_FLIP_DURATION } from "../Card/constants"

const blueColor = "#00AEE0"
const disabledTextColor = "#B7D3DB"

export const GradeButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
  padding: 0 44px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 39px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 17px;
    padding: 0;
    margin-bottom: 0;
    justify-content: space-around;

    &[data-disabled='true'] {
      display: none;
    }
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  flex: 1;
  max-width: 180px;

  :not(:last-child) {
    margin-right: 44px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    :not(:last-child) {
      margin-right: 39px;
    }
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  margin-bottom: 9px;
`

export const Button = styled.button`
  position: relative;
  width: 100%;
  height: 80px;
  font-size: 18px;
  border-radius: 12px;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  color: rgb(252, 252, 252);
  background: ${blueColor};

  &:hover {
    background: linear-gradient(240deg, #2CC7F4 0%, #00AEE0 100%) ${blueColor};
  }

  &[disabled] {
    color: ${disabledTextColor};
    background: rgba(170, 208, 219, 0.1);
    border: 2px solid rgba(128, 183, 199, 0.15);
  }

  &[data-pending='true'] {
    pointer-events: none;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 70px;
    font-size: 16px;
  }
`

export const ButtonShadow = styled.div`
  position: absolute;
  left: 7px;
  top: 7px;
  right: 7px;
  height: 56px;
  box-shadow: 0px 18px 30px #008BDB66;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  ${ButtonWrapper}:hover > & {
    opacity: 1;
  }

  ${ButtonWrapper}[data-disabled='true'] > & {
    display: none;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 46px;
  }
`

export const ShortcutTip = styled.div`
  display: flex;
  justify-content: center;
  color: rgb(150, 150, 150);
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  transition: opacity 300ms ease-in-out;

  &[data-disabled='true'] {
    opacity: 0;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
  }
`

export const DaysUntilReview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: ${blueColor};
  background-size: 40px;
  transition: opacity ${CARD_FLIP_DURATION}ms;
  opacity: 0;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  pointer-events: none;

  &[data-show='true'] {
    opacity: 1;
  }
`

export const ButtonSpinner = styled(LoadingSpinner)`
  stroke: white;
  margin-top: 4px;
`