import React from "react"
import {
  Container,
  IconContainer,
  InputRow
} from "./styles"
import { Select } from "formik-antd"
import SelectIcon from "../../../../assets/svgs/SelectIcon.svg"
import "antd/dist/antd.min.css"
import "./SelectField.scss"

const SelectField = ({ name, options, icon, placeholder = "Select option", ...restProps }) => {
  return (
    <Container>
      <InputRow>
        <IconContainer>{icon}</IconContainer>
        <Select
          allowClear
          bordered={false}
          suffixIcon={<img alt="" src={SelectIcon} />}
          {...restProps}
          name={name}
          placeholder={placeholder}
          showSearch
          filterOption={(input, option) => (!(option.props.children
            ?.toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) === -1 || undefined))}
        >
          {options.map(opt => (
            <Select.Option key={opt.code} value={opt.dial_code}>{`${opt.name} ${opt.dial_code}`}</Select.Option>
          ))}
        </Select>
      </InputRow>
    </Container>)
}

export default SelectField
