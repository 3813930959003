import React, { memo } from "react"
import { useMediaQuery } from "react-responsive"
import DesktopControls from "./DesktopControls"
import { MOBILE_MAX } from "../../../common/breakpoints"

const ControlsSidebar = ({
  show,
  autoplayEnabled,
  daysUntilReviewEnabled,
  showTranslationEnabled,
  onControlToggle,
  onClose
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })

  if (isMobile) {
    return null
  }

  return (
    <DesktopControls
      show={show}
      autoplayEnabled={autoplayEnabled}
      daysUntilReviewEnabled={daysUntilReviewEnabled}
      showTranslationEnabled={showTranslationEnabled}
      onControlToggle={onControlToggle}
      onClose={onClose}
    />
  )
}

export default memo(ControlsSidebar)