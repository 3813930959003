import React, { memo, useCallback, useEffect } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import EntrancePage from "../Common/EntrancePage"
import { ContentContainer, FormInnerContainer, PageContainer } from "../Common/styles"
import { RegisterFormContainer } from "./styles"
import VerifyPhoneNumberForm from "./VerifyPhoneNumberForm"
import { routes, VERIFY_PHONE_CODE_LENGTH } from "../../../utils/constants"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import { useHistory } from "react-router-dom"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"

const initialValues = {
  code: ""
}

const schema = yup.object().shape({
  code: yup.string().required().length(VERIFY_PHONE_CODE_LENGTH)
})

const VerifyPhoneNumberPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    succeeded, error, pending
  } = useSelector(authSelectors.verifyPhoneState)

  useEffect(() => () => {
    dispatch(authActions.verifyUserPhoneReset())
  }, [dispatch])

  useEffect(() => {
    if (!succeeded) {
      return undefined
    }

    history.push(routes.registerComplete)
  }, [history, succeeded])

  const handleSubmit = useCallback(({ code }) => {
    if (!succeeded) { dispatch(authActions.verifyUserPhoneRequest({ code })) }
  }, [dispatch, succeeded])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <PageContainer>
        <ContentContainer>
          <RegisterFormContainer>
            <FormInnerContainer>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                <VerifyPhoneNumberForm pending={pending} error={error} succeeded={succeeded} />
              </Formik>
            </FormInnerContainer>
          </RegisterFormContainer>
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(VerifyPhoneNumberPage)
