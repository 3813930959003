import styled from "styled-components"
import BackArrowIcon from "../../../assets/svgs/BackArrowIcon"
import Logo from "./Logo"
import MenuIcon from "./MenuIcon"

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 44px;
  background-color: white;
  padding: 0 24px;
`

export const StyledLogo = styled(Logo)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17.5px;
`

export const LeftButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 44px;
  height: 44px;
  margin-left: -14px;
  color: #C6C6C6;
`

export const StyledMenuIcon = styled(MenuIcon)`
  width: 16px;
  height: auto;
`

export const StyledBackIcon = styled(BackArrowIcon)`
  font-size: 16px;
`
