import authSlice from "../reducers/auth"
export { userLogout } from "./authExtra"

export const {
  userLoginRequest,
  userLoginSuccess,
  userLoginFailure,
  userLoginReset,
  userRegisterRequest,
  userRegisterSuccess,
  userRegisterFailure,
  userRegisterReset,
  userRegisterPhoneRequest,
  userRegisterPhoneSuccess,
  userRegisterPhoneFailure,
  userRegisterPhoneReset,
  verifyUserPhoneRequest,
  verifyUserPhoneSuccess,
  verifyUserPhoneFailure,
  verifyUserPhoneReset,
  verifyUserPhoneSetEncryptedNumber,
  setLoginCredentials,
  resetLoginCredentials,
  recoverPasswordRequest,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  recoverPasswordReset,
  loadUserRequest,
  loadUserSuccess,
  loadUserFailure,
  setTermsAccepted
} = authSlice.actions