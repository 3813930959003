import React, { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useFormikContext } from "formik"
import FormStatus from "../Common/FormStatus"
import OtpInputField from "../Common/OtpInputField"
import { InputGroup, StyledForm, SubmitButton, TopFormPart } from "../Common/styles"
import { ChangePhoneLink, Description, RegisterButtonContainer } from "./styles"
import { Dictionary } from "../../../utils/i18n"
import useOnChange from "../../../hooks/useOnChange"
import * as authSelectors from "../../../store/selectors/auth"
import { routes, VERIFY_PHONE_CODE_LENGTH } from "../../../utils/constants"
import { useHistory } from "react-router-dom"

const VerifyPhoneNumberForm = ({ pending, error }) => {
  const history = useHistory()
  const formik = useFormikContext()
  const { t } = useTranslation()

  const encryptedPhone = useSelector(authSelectors.encryptedPhoneNumber)

  const fieldNames = {
    code: "code"
  }

  const status = useMemo(() => {
    if (error) {
      return {
        type: "error",
        title: t(Dictionary.common.oops),
        message: error.message
      }
    }

    return null
  }, [error, t])

  useOnChange(() => {
    if (!pending) {
      formik.setSubmitting(false)
    }
  }, [pending])

  //const handleChangePhone = useCallback(() => { history.push(routes.registerPhone) }, [history])

  return (
    <StyledForm>
      <TopFormPart>
        <FormStatus fieldNames={fieldNames} status={status} />
        <Description>{t(Dictionary.register.enterPhoneCode, {
          digitsAmount: VERIFY_PHONE_CODE_LENGTH, phoneNumber: encryptedPhone
        })}
        </Description>

        <InputGroup>
          <OtpInputField name="code" />
        </InputGroup>
        <RegisterButtonContainer>
          <SubmitButton primary type="submit" loading={formik.isSubmitting} disabled={!formik.dirty || !formik.isValid}>
            {t(Dictionary.register.finish)}
          </SubmitButton>
          {/* <ChangePhoneLink onClick={handleChangePhone}>
            {t(Dictionary.register.changePhoneNumber)}
          </ChangePhoneLink> */}
        </RegisterButtonContainer>
      </TopFormPart>
    </StyledForm>
  )
}

export default memo(VerifyPhoneNumberForm)
