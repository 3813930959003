import { types } from "../../assets/types/types"
import i18n, { Dictionary } from "../../utils/i18n"
import { error as ErrorUtils } from "./controllers/error"

const message = Dictionary.login.errorFields;
const field = Dictionary.login.password;

export class NetworkError extends Error {
  constructor(message, url) {
    super(message)

    this.url = url
  }
}

export class ApiError extends Error {
  constructor(statusCode, meta, url) {
    const apiErrorTypeDescription = {
      "authorizationException": `${i18n.t(message)}: ${i18n.t(field)}`
    }
    super(apiErrorTypeDescription[meta?.errorType] ?? meta?.errorMessage ?? "")

    this.meta = meta
    this.statusCode = statusCode
    this.url = url
  }

  get code() {
    return this.meta.code
  }

  get type() {
    return this.meta.errorType
  }
}

export const extractErrorType = (error) => {
  if (!error) {
    return null
  }

  if (error instanceof NetworkError) {
    return types.error.NetworkingError
  }

  return error instanceof ApiError ?
    ErrorUtils.http(error.code).errorType :
    types.error.UnknownError
}
