import {
  deckPageData,
  userStatsData,
  userDecksData,
  studySessionPageData
} from "./../assets/data/data"//"../assets/data";
import keys from "./keys"
import { returnActivePageNumbersRange, returnPageNumbersTotalCount } from "./controls/pagination"

export const routes = {
  memhack: {
    androidAppLink: `https://play.google.com/store/apps/details?id=com.devmaker.hackit`,
    iosAppLink: `https://apps.apple.com/br/app/memhack/id1528218113`,
    termsAndConditions: `https://terms.hackit.app/`,
    library: `https://www.rhavicarneirosacademy.com/library`,
    activeRegisterCtaLink: `https://www.fluencytv.com/turma-intensiva-nov`
  },
  login: `/login`,
  register: `/cadastrar`,
  registerPhone: `/register-phone`,
  verifyPhoneNumber: `/verify-phone-number`,
  registerAuthenticate: `/register-authenticate`,
  registerComplete: "/register-complete",
  registerCTA: `/conheca-mais`,
  termsAndConditions: `/termos-e-condicoes`,
  enableNotificationsSplash: `/habilitar-notificacoes`,
  support: `/suporte`,
  configurations: `/configuracoes`,
  forgot: `/esqueci-minha-senha`,
  forgotComplete: `/forgot-complete`,
  recover: `/mudar-minha-senha`,
  dashboard: `/dashboard`,
  deckPage: `/deck`,
  mergePage: `/merge`,
  deckMode: `/deck-mode`,
  studySession: `/sessao-de-estudos`,
  studySessionStats: `/sessao-de-estudos-estatisticas`,
  studyModeBase: "/study-mode",
  deckStudyMode: "/study-mode/deck/:id",
  mergeStudyMode: "/study-mode/merge/:id",
  studySessionBase: "/study-session",
  deckStudySession: "/study-session/deck/:id/:mode",
  mergeStudySession: "/study-session/merge/:id/:mode",
  enLocale: "/en",
  ptLocale: "/pt",
  esLocale: "/es"
}

export const initialDashboardState = {
  userDecksArray: userDecksData.userDecksArray,
  masterDecksArray: userDecksData.masterDecksArray,
  activeStatsTimespanKey: keys.timespans.passedWeek,
  activeDeckSearchResults: [],
  activeRecentDeckSearchResults: [],
  activeUserDecksFilters: [keys.languageFilters.allLanguages],
  activeDeckSearchFilters: [keys.languageFilters.allLanguages],
  activeMasterDecksIds: [`deck1`, `deck2`, `deck3`],
  activeQuickStartLanguage: `german`,
  userDecksCardsPerPageCount: 6,

  activeUserDecksPageNumber: 1,

  activeCardsReviewedValue: userStatsData.passedWeek.cardsReviewedValue,
  activeCardsStudiedValue: userStatsData.passedWeek.cardsStudiedValue,
  activeDecksStudiedValue: userStatsData.passedWeek.decksStudiedValue,
  activeCardsForgottenValue: userStatsData.passedWeek.cardsForgottenValue,
  activeNewCardsValue: userStatsData.passedWeek.newCardsValue,
  activeWordsLearnedValue: userStatsData.passedWeek.wordsLearnedValue,
  activeTimeStudiedValue: userStatsData.passedWeek.timeStudiedValue,
  activeAverageSessionTimeValue: userStatsData.passedWeek.averageSessionTimeValue,
  activeDifficultyValue: userStatsData.passedWeek.difficultyValue,

  get activeUserDecksIds() {
    return userDecksData.userDecksArray.map((yourDeckCard) => yourDeckCard.id)
  },
  get activeUserDecksCardsTotalCount() {
    return this.activeUserDecksIds.length
  },
  get activePageNumbersRange() {
    return returnActivePageNumbersRange(
      this.activeUserDecksPageNumber,
      this.activeUserDecksPageNumbersTotalCount
    )
  }
}

export const initialDeckPageState = {
  activeDeckSearchFilters: [keys.languageFilters.allLanguages],

  userDecksArray: userDecksData.userDecksArray,

  activeDeckSearchResults: [],
  activeRecentDeckSearchResults: [],

  activeDeck: deckPageData.activeDeck,
  activeDeckName: deckPageData.activeDeckName,
  activeDeckStudyCardsArray: deckPageData.activeDeckStudyCardsArray,
  activeDeckStudyCardsArrayIds: deckPageData.activeDeckStudyCardsArray.map((card) => card.id),
  activeCardSoundId: `card1CardTypeSound0`,

  reviewedCardsStatsByTimespan: userStatsData.reviewedCardsStatsByTimespan,

  activeReviewedCardsStatsTimespanId: `monthly`,

  activeReviewedCardsStatsMax: 145,

  activeReviewedCardsStats: userStatsData.activeReviewedCardsStats,

  monthlyGoalDaysStudyingDeck: userStatsData.monthlyGoalDaysStudyingDeck,
  activeDeckStudyCardsPageNumber: 1,
  activeDeckStudyCardsPerPageCount: 6,

  activeStatsTimespanKey: keys.timespans.general,

  activeCardsReviewedValue: userStatsData.passedSession.cardsReviewedValue,
  activeCardsForgottenValue: userStatsData.passedSession.cardsForgottenValue,
  activeNewCardsValue: userStatsData.passedSession.newCardsValue,
  activeWordsLearnedValue: userStatsData.passedSession.wordsLearnedValue,
  activeTimeStudiedValue: userStatsData.passedSession.timeStudiedValue,
  activeDifficultyValue: userStatsData.passedSession.difficultyValue,

  activeEasyCardsReviewedValue: 100,
  activeNormalCardsReviewedValue: 300,
  activeHardCardsReviewedValue: 120,

  get activeDeckStudyCardsArrayTotalCount() {
    return deckPageData.activeDeckStudyCardsArray.length
  },
  get activeDeckStudyCardsPageNumbersTotalCount() {
    return returnPageNumbersTotalCount(
      this.activeDeckStudyCardsPerPageCount,
      this.activeDeckStudyCardsArrayTotalCount
    )
  },
  get activeDeckStudyCardsPageNumbersRange() {
    return returnActivePageNumbersRange(
      this.activeDeckStudyCardsPageNumber,
      this.activeDeckStudyCardsPageNumbersTotalCount
    )
  }
}

export const initialDeckModePageState = {
  activeDeck: {
    title: `Fast thinking drill with extra large name very large`
  }
}

export const initialStudySessionPageState = {
  activeCardIndex: 1,
  get activeCardId() {
    return `card${this.activeCardIndex}`
  },
  activeDeckId: `deck1`,
  get activeCardsTotal() {
    return Object.keys(studySessionPageData[this.activeDeckId].cards).length
  },
  get activeCardAudioSideAUrls() {
    return studySessionPageData[this.activeDeckId].cards[this.activeCardId].cardAudioSideAUrls[0]
  }
}

export const initialStudySessionStatsPageState = {
  activeStatsTimespanKey: keys.timespans.passedSession,
  activeCardsReviewedValue: userStatsData.passedSession.cardsReviewedValue,
  activeCardsForgottenValue: userStatsData.passedSession.cardsForgottenValue,
  activeNewCardsValue: userStatsData.passedSession.newCardsValue,
  activeWordsLearnedValue: userStatsData.passedSession.wordsLearnedValue,
  activeTimeStudiedValue: userStatsData.passedSession.timeStudiedValue,
  activeDifficultyValue: userStatsData.passedSession.difficultyValue
}

export const VERIFY_PHONE_CODE_LENGTH = 6