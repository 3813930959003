import styled from "styled-components"
import { MOBILE_MAX, MOBILE_MIDDLE, LAPTOP_MAX } from "../../../common/breakpoints"
import { transitions } from "../../../assets/animations/transitions"
import FormStatus from "../Common/FormStatus"
import { FormContainer, FormInnerContainer, MobileLogo } from "../Common/styles"
import sidebarCover from './../../../assets/graphics/login/Login-Register.png';

export const LoginFormContainer = styled(FormContainer)`
  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 122px;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;
  }
`

export const StyledFormInnerContainer = styled(FormInnerContainer)`
  @media (max-width: ${MOBILE_MAX}px) {
    @media (max-height: 638px) {
      padding-top: 24px;
    }
  }
`

export const StyledMobileLogo = styled(MobileLogo)``

export const StyledFormStatus = styled(FormStatus)`
  &[data-show-logo='true'] {
    @media (max-width: ${MOBILE_MAX}px) {
      @media (max-height: 638px) {
        margin-bottom: 16px;
      }
    }
  }
`

export const JoinMobile = styled.div`
  margin-bottom: 25px;
`

export const JoinContainer = styled(FormContainer)`
  margin-top: 40px;
  background-color:;
  color: #fff;
  border-radius: 38px;
  height: 178px;
  border: 1px solid #B8A5FD;
  display: flex;
  align-items: center;
  background: url("${sidebarCover}") no-repeat, #6B47ED;
  background-position: center right;
  background-size: contain;
  @media (max-width: ${LAPTOP_MAX}px) {
    height: 150px;
    background-position: 120% center;
  }
  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 0px;
    justify-content: center;
  }
  @media (max-width: ${MOBILE_MIDDLE}px) {
    background: #6B47ED;
  }
`

export const JoinContent = styled.div`
  margin-right: 244px;
  padding-left: 32px;
  padding-right: 15px;
  font-size: 16px;
  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    margin-right: 150px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
  }
  @media (max-width: ${MOBILE_MIDDLE}px) {
    margin-right: 0px;
    padding: 0 20px;
  }
`

export const JoinButton = styled.div`
  background: #6B47ED;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 24px;
  color: #ffffff;
  transition: ${transitions.quickTransitionAll};
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    color: #6B47ED;
    border-color: #6B47ED;
    background: #fff;
  }
`