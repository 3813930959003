import { createSlice } from "@reduxjs/toolkit"
import { DeckType } from "../../constants"
import {cloneDeep} from 'lodash';

const initialState = {
  masterDecks: {
    data: [],
    loading: true,
    dataLoaded: false,
    error: null
  },
  userDecks: {
    data: [],
    loading: true,
    dataLoaded: false,
    error: null
  },
  dashboardDeckType: DeckType.merge,
  dashboardDeckIndex: 0,
  userDecksPage: 0,
  virtualToPhysicalDeckId: null,
  deckWithCardsById: {},
  mergeWithCardsById: {},
  viewDeckCards: {
    loading: false,
    error: null,
    loadingPage: false,
    pageError: null,
    page: 0,
    actualPage: 0,
    pageSize: 0
  }
}

export default createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardDeckType(state, { payload }) {
      state.dashboardDeckType = payload
    },
    setDashboardDeckIndex(state, { payload }) {
      state.dashboardDeckIndex = payload
    },
    loadMasterDecksRequest(state) {
      state.masterDecks.error = null
      state.masterDecks.loading = true
    },
    loadMasterDecksSuccess(state, { payload }) {
      const virtualToPhysical = {}

      state.masterDecks.dataLoaded = true
      state.masterDecks.data = payload

      payload.forEach((item) => item.decks.forEach((deck) => {
        virtualToPhysical[deck.virtualId] = deck.physicalId
      }))

      state.virtualToPhysicalDeckId = Object.assign(
        state.virtualToPhysicalDeckId ?? {},
        virtualToPhysical
      )
    },
    loadMasterDecksFailure(state, { payload }) {
      state.masterDecks.error = payload
      state.masterDecks.loading = false
    },
    masterDecksReset(state) {
      state.masterDecks = cloneDeep(initialState.masterDecks);
    },
    loadUserDecksRequest(state) {
      state.userDecks.error = null
      state.userDecks.loading = true
    },
    loadUserDecksSuccess(state, { payload }) {
      state.userDecks.dataLoaded = true
      state.userDecks.data = payload
      state.userDecks.loading = false
      state.virtualToPhysicalDeckId = Object.assign(
        state.virtualToPhysicalDeckId ?? {},
        Object.fromEntries(payload.map((deck) => [deck.virtualId, deck.deckId]))
      )
    },
    loadUserDecksFailure(state, { payload }) {
      state.userDecks.error = payload
      state.userDecks.loading = false
    },

    userDecksReset(state) {
      state.userDecks = cloneDeep(initialState.userDecks);
    },

    setUserDecksPage(state, { payload }) {
      state.userDecksPage = payload
    },

    loadDeckWithCardsRequest(state, { payload: { page, pageSize } }) {
      state.viewDeckCards.loading = true
      state.viewDeckCards.error = null
      state.viewDeckCards.page = page
      state.viewDeckCards.pageSize = pageSize
    },
    loadDeckWithCardsSuccess(state, { payload: { virtualId, deck, page } }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.actualPage = page
      state.deckWithCardsById[virtualId] = deck
    },
    loadDeckWithCardsFailure(state, { payload }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.error = payload
    },

    loadDeckReferralWithCardsRequest(state, { payload: { page, pageSize } }) {
      state.viewDeckCards.loading = true
      state.viewDeckCards.error = null
      state.viewDeckCards.page = page
      state.viewDeckCards.pageSize = pageSize
    },
    loadDeckReferralWithCardsSuccess(state, { payload: { virtualId, deck, page } }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.actualPage = page
      state.deckWithCardsById[virtualId] = deck
    },
    loadDeckReferralWithCardsFailure(state, { payload }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.error = payload
    },

    loadDeckPageRequest(state, { payload: { page, pageSize } }) {
      state.viewDeckCards.loadingPage = true
      state.viewDeckCards.pageError = null
      state.viewDeckCards.page = page
      state.viewDeckCards.pageSize = pageSize
    },
    loadDeckPageSuccess(state, { payload: { virtualId, cards } }) {
      state.viewDeckCards.loadingPage = false
      state.viewDeckCards.actualPage = state.viewDeckCards.page

      if (state.deckWithCardsById[virtualId]) {
        state.deckWithCardsById[virtualId].cards = cards
      }
    },
    loadDeckPageFailure(state, { payload: error }) {
      state.viewDeckCards.loadingPage = false
      state.viewDeckCards.pageError = error
    },

    loadMergeWithCardsRequest(state, { payload: { page, pageSize } }) {
      state.viewDeckCards.loading = true
      state.viewDeckCards.error = null
      state.viewDeckCards.page = page
      state.viewDeckCards.pageSize = pageSize
    },
    loadMergeWithCardsSuccess(state, { payload: { mergeId, merge, page } }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.actualPage = page
      state.mergeWithCardsById[mergeId] = merge
    },
    loadMergeWithCardsFailure(state, { payload }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.error = payload
    },

    loadMergePageRequest(state, { payload: { page, pageSize } }) {
      state.viewDeckCards.loadingPage = true
      state.viewDeckCards.pageError = null
      state.viewDeckCards.page = page
      state.viewDeckCards.pageSize = pageSize
    },
    loadMergePageSuccess(state, { payload: { mergeId, cards } }) {
      state.viewDeckCards.loadingPage = false
      state.viewDeckCards.actualPage = state.viewDeckCards.page

      if (state.mergeWithCardsById[mergeId]) {
        state.mergeWithCardsById[mergeId].cards = cards
      }
    },
    loadMergePageFailure(state, { payload }) {
      state.viewDeckCards.loading = false
      state.viewDeckCards.error = payload
    },

    viewDeckCardsReset(state) {
      state.viewDeckCards = initialState.viewDeckCards
    }
  }
})