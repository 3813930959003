import React, { forwardRef, useState, useCallback } from "react"
import { useField } from "formik"
import {
  Container,
  HidePasswordIcon,
  IconContainer,
  Input,
  InputRow,
  InvalidIcon,
  ShowPasswordIcon,
  ToggleShowPasswordButton,
  ValidIcon,
  StatusIconContainer
} from "./styles"

const InputField = ({ name, type = "text", icon, disablePaste = false, ...restProps }, ref) => {
  const [field, meta] = useField(name)
  const [showPassword, setShowPassword] = useState(false)

  const handleToggleIcon = useCallback(() => {
    setShowPassword((show) => !show)
  }, [])

  const handlePreventDefault = useCallback((event) => {
    event.preventDefault()
  }, [])

  return (
    <Container>
      <InputRow>
        <IconContainer>{icon}</IconContainer>
        <Input
          {...field}
          {...restProps}
          ref={ref}
          name={name}
          type={type === "password" && showPassword ? "text" : type}
          data-error={meta.touched && !!meta.error}
          onPaste={disablePaste ? handlePreventDefault : undefined}
        />
        {type === "password" && (
          <ToggleShowPasswordButton type="button" onClick={handleToggleIcon}>
            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </ToggleShowPasswordButton>
        )}
        {meta.touched && (
          <StatusIconContainer>
            {meta.error ? <InvalidIcon /> : <ValidIcon />}
          </StatusIconContainer>
        )}
      </InputRow>
    </Container>
  )
}

export default forwardRef(InputField)
