export default {
  mobileAppNavBarStates: {
    base: `base`,
    returnFromAllDecks: `returnFromAllDecks`
  },
  deckStats: {
    cardsReviewed: `cardsReviewed`,
    cardsStudied: `cardsStudied`,
    averageSessionTime: `averageSessionTime`,
    decksStudied: `decksStudied`,
    newCards: `newCards`,
    wordsLearned: `wordsLearned`,
    timeStudied: `timeStudied`,
    difficulty: `difficulty`,
    cardsForgotten: `cardsForgotten`
  },
  timespans: {
    allTime: `allTime`,
    general: `general`,
    passedDay: `passedDay`,
    passedYear: `passedYear`,
    passed3Months: `passed3Months`,
    passedMonth: `passedMonth`,
    passedWeek: `passedWeek`,
    passedSession: `passedSession`
  },
  pages: {
    register: `register`,
    login: `login`,
    forgotPassword: `forgotPassword`,
    changePassword: `changePassword`,
    dashboard: `dashboard`,
    deck: `deck`,
    deckMode: `deckMode`,
    studySession: `studySession`,
    studySessionStats: `studySessionStats`
  },
  mobileDeckPageTabs: {
    stats: `stats`,
    cards: `cards`,
    monthlyGoal: `monthlyGoal`
  },
  inputStates: {
    email: `email`,
    confirmEmail: `confirmEmail`,
    password: `password`,
    fullName: `fullName`,
    phoneNumber: `phoneNumber`,
    firstPassword: `firstPassword`,
    secondPassword: `secondPassword`,
    newFirstPassword: `newFirstPassword`,
    newSecondPassword: `newSecondPassword`
  },
  filteringStates: {
    activeDeckSearchFilters: `activeDeckSearchFilters`,
    activeUserDecksFilters: `activeUserDecksFilters`
  },
  errors: {
    InvalidInputsException: `InvalidInputsException`,
    EmailAlreadyRegisteredException: `EmailAlreadyRegisteredException`,
    ConnectionFailedException: `ConnectionFailedException`,
    EmailNotFoundException: `EmailNotFoundException`
  },
  deckDifficulties: {
    easy: `easy`,
    normal: `normal`,
    hard: `hard`,
    dontRemember: `dontRemember`
  },
  studySessionControls: {
    autoplay: `autoplay`,
    showTranslation: `showTranslation`,
    showDaysUntilReview: `showDaysUntilReview`
  },
  deckIcons: {
    flag: `flag`,
    deck: `deck`,
    teacherStatus: `teacherStatus`
  },
  deckConfigurationControls: {
    addDeckToMasterDeck: `addDeckToMasterDeck`,
    followDeck: `followDeck`
  },
  languageFilters: {
    allLanguages: `allLanguages`,
    english: `english`,
    spanish: `spanish`,
    french: `french`,
    german: `german`,
    italian: `italian`
  },
  deckSearchFilters: {
    alphabeticOrder: `alphabeticOrder`,
    reversedAlphabeticOrder: `reversedAlphabeticOrder`
  },
  userDeckFilters: {
    alphabeticOrder: `alphabeticOrder`,
    mostRecentStudiedDecks: `mostRecentStudiedDecks`,
    reversedAlphabeticOrder: `reversedAlphabeticOrder`
  },
  deckModes: {
    idealMode: `idealMode`,
    normalMode: `normalMode`,
    mediumMode: `mediumMode`,
    shortMode: `shortMode`
  }
}
