import React from "react"

export const appText = {
  fallbackImgAltText: `Oops!`,
  returnLabel: `Voltar`,
  controlsLabel: `Controles`,
  filterButtonLabel: `Ordenar Por`,
  continueButtonLabel: `Continuar`,
  difficultyEasyLabel: `Fácil`,
  difficultyNormalLabel: `Bom`,
  difficultyHardLabel: `Difícil`,
  yourStatsHeader: `Seus Dados:`,
  configurationsLabel: `Configurações`,
  orderByFilterButtonLabel: `Ordenar Por`,

  searchTabLabel: `Pesquise decks...`,
  breadcrumbHomeCrumb: `Home`,
  breadcrumbDecksCrumb: `Decks:`,

  appNavBarConfigurationsHeader: `CONFIGURAÇÕES`,
  appNavBarMenuHeader: `MENU`,
  appNavBarLinkConfigurations: `Configurar`,
  appNavBarLinkDashboard: `Dashboard`,
  appNavBarLinkDiscover: `Descobrir`,
  appNavBarLinkSupport: `Suporte`,
  appNavBarLinkTermsAndConditions: `Termos de Uso`,
  appNavBarLinkTakeATour: `Faça um Tour`,
  appNavBarLinkSignout: `Sair`
}

export const inputText = {
  labels: {
    email: `E-mail`,
    password: `Senha`,
    fullName: `Nome`,
    phoneNumber: `Telefone`,
    firstPassword: `Senha`,
    secondPassword: `Repita a Senha`,
    newFirstPassword: `Digite sua nova senha`,
    newSecondPassword: `Repita sua nova senha`,
    firstPasswordInvalid: `Primeira senha`,
    secondPasswordInvalid: `Segunda senha`
  },
  emailId: `email`,
  emailName: `email`,
  emailType: `text`,
  emailMinLength: `5`,
  emailMaxLength: `255`,
  emailFeedbackWrongFormat: [`Oops!`, `O e-mail deve ter formato de um e-mail.`],

  passwordId: `password`,
  passwordType: `password`,
  passwordName: `password`,
  passwordMinLength: `6`,
  passwordMaxLength: `255`,

  fullNameName: `fullName`,
  fullNameId: `fullName`,
  fullNameType: `text`,
  fullNameMinLength: `2`,
  fullNameMaxLength: `255`,
  fullNameFeedbackInvalidLength: [`Oops!`, `Prencha seu nome!`],

  phoneNumberName: `phoneNumber`,
  phoneNumberId: `phoneNumber`,
  phoneNumberType: `tel`,
  phoneNumberMinLength: `2`,
  phoneNumberMaxLength: `255`,
  phoneNumberFeedbackInvalidLength: [`Oops!`, `Prencha seu telefone!`],

  firstPasswordId: `firstPassword`,
  firstPasswordType: `password`,
  firstPasswordName: `firstPassword`,
  firstPasswordMinLength: `6`,
  firstPasswordMaxLength: `255`,

  secondPasswordId: `secondPassword`,
  secondPasswordType: `password`,
  secondPasswordName: `secondPassword`,
  secondPasswordMinLength: `6`,
  secondPasswordMaxLength: `255`,

  newFirstPasswordId: `newFirstPassword`,
  newFirstPasswordType: `password`,
  newFirstPasswordName: `newFirstPassword`,
  newFirstPasswordMinLength: `6`,
  newFirstPasswordMaxLength: `255`,

  newSecondPasswordId: `newSecondPassword`,
  newSecondPasswordType: `password`,
  newSecondPasswordName: `newSecondPassword`,
  newSecondPasswordMinLength: `6`,
  newSecondPasswordMaxLength: `255`,

  cardSearchInputLabel: `Pesquise por uma carta`
}

export const configurationsText = {
  pageHeader: `Configurações`,
  notificationsLabel: `Notificações`,
  toggleNotificationsSwitchLabel: `Alertas de notificação`
}

export const supportText = {
  pageHeader: `Suporte`,
  contactLabel: `Contato`,
  emailContact: `alunos@equiperhavi.com`,
  facebookContact: `/inglescomrhavicarneiro/`,
  instagramContact: `@rhavicarneiro`,
  frequentQuestionsLabel: `Perguntas frequentes`,
  frequentQuestions: [
    {
      number: `1`,
      question: `Preciso estudar pelo Master Deck ou pelo deck de cada unidade? `,
      answer: `Você deve sempre estudar pelo seu Master Deck, é ele que organiza suas cartas na ordem correta de estudos. Os decks de cada unidade são apenas para revisões rápidas quando o Master Deck não estiver disponível.`
    },
    {
      number: `2`,
      question: `Por que eu não posso ver as cartas mais de uma vez no dia?`,
      answer: `Quando não tiver mais cartas para estudar em seu Master Deck quer dizer que seus estudos do dia estão completos. Ou seja, suas cartas foram estudadas e o Memhack vai posicioná-las corretamente para próxima sessão de estudos.`
    },
    {
      number: `3`,
      question: `No meu Memoriation Hack tem dois decks, um com o nome do idioma que eu estou cursando e outr com o nome da aula de conjugação, o que serie esse segundo deck? Eu preciso estudá-lo também? Se sim, quando?`,
      answer: `Esse segundo deck também é um Master Deck, mas voltando para a prática de conjugação verbal, ele deve ser estudado todos os dias também.`
    },
    {
      number: `4`,
      question: `Minha sessão de estudos finalizou, mas eu ainda tenho cartas pendentes no Master Deck, como faço para estudá-las?`,
      answer: `Você pode selecionar um dos modos de estudo, ou escolher a opção que permita estudar todo o Master Deck, sem interrupções. O importante é continuar estuando todos os dias, ok?`
    },
    {
      number: `5`,
      question: `Já finalizei meus estudos, mas quero retomar do início (ou fiquei muito tempo sem estudar e quero recomeçar), tem como zerar o master deck?`,
      answer: `Não temos como zerar o Master Deck. Mas como nosso algoritmo é intelligente, você estudará as cartas na ordem correta.`
    },
    {
      number: `6`,
      question: `Fiquei um tempão sem estudar e agora meu MH tem cartas demais para revisar, o que fazer?`,
      answer: `O ideal é estudar todos os dias, sugerimos escolher um modo de estudos que encaixe com seu tempo disponivel. É muito importante que continue com a prática diária de estudos e vá retomando seu ritmo até colocar em dia o número de cartas.`
    }
  ],
  speakToUsLabel: `Fale conosco`,
  speakToUsFormDescription: `Digite abaixo sua mensagem:`,
  speakToUsFormSubmitButtonLabel: `ENVIAR`
}

export const loginText = {
  backgroundHeader: `Welcome!`,
  spashHeader: `Let's hackit!`,
  sidebarHeader: `Let's hackit!`,
  appDlCtaHeader: [
    `Hey!`,
    `Você já conhece nosso app? Nele`,
    ` você estuda em modo offline e muito mais!`
  ],
  forgotPasswordLabel: `Esqueceu sua senha?`,

  loginButtonLabel: `ENTRAR`,

  loginSucessFeedbackHeader: `Tudo certo!`,
  loginSucessFeedbackDescription: `Estamos te redirecionando!`,

  registerButtonLabel: `CADASTRE-SE AGORA!`
}

export const dashboardText = {
  placeholderDeckDescription: `Fast Thinking Drill All Decks Invertidos Lorem ippsum dolor simet`,
  welcomeMessage: `Bem-vindo de volta, `,
  viewCarouselButtonLabel: `Modo Carousel`,
  changeActiveQuickStartDeckLabel: `Selecione um deck abaixo para início rápido!`,
  addDecksButtonLabel: `ADICIONAR DECK`,
  quickStartHeader: `Início Rápido!`,
  quickStartTutorialDescription: `Adicione aqui o Deck que está estudando no momento! Assim você terá um acesso mais rápido e fácil para continuar seus estudos de onde parou!`,
  quickStartTutorialTip: (
    <>
      <b>#Tip:</b>
      Você pode adicionar um para cada idioma que você estuda ;)
    </>
  ),
  addQuickStartDeckSuccessFeedbackHeader: `Deck Adicionado!`,
  addQuickStartDeckSuccessContent: (
    <>
      #Tip Yay! Você ativou o deck <b>“Unit 01 - Vocal Rocket #1 large…” </b>para início rápido ;)
    </>
  ),
  searchFilterSideBarTabLabel: `Filtros de Busca`,
  searchInputPlaceholder: `Pesquise decks...`,
  viewAllDecksButtonLabel: `Ver todos`,
  decksHeader: `Decks`,
  masterDecksHeader: `Master Decks`,
  masterDecksDeckActivatedButtonLabel: `Deck Ativo para inicio rapido`,
  masterDecksActivateDeckButtonLabel: `Ativar deck para início rápido`,

  userDecksAvailableCardsCallToAction: [`Hey!`, `Você ainda tem cartas disponíveis`],
  userDecksCardStudyNowButtonLabel: `ESTUDAR AGORA`,
  filtersLabel: `Filtros`,
  userDecksFilterByHeader: `Filtros:`,
  userDecksFiltersRemoveAllButtonLabel: `Limpar Filtros`,

  userDecksHeader: `Seus Decks`,

  cardsPerPageDropdownLabel: `Exibir`,

  cardsPerPageDropdownOption6Label: `6 Cards`,
  cardsPerPageDropdownOption12Label: `12 Cards`,
  cardsPerPageDropdownOption18Label: `18 Cards`,
  cardsPerPageDropdownOptionAllLabel: `All Cards`,

  emptyDecksHeader: `Seus Decks`,
  emptyDecksDescriptionWelcomeLabel: `Bem-Vindo!`,
  emptyDecksDescription: `Você ainda não possui nenhum deck...`,
  emptyDecksAddDecksCta: `Selecione um idioma acima e adicione um deck para inicio rápido!`,
  emptyDecksVisitCourseDescription: ` Visite seu curso e adicione novos decks.`,
  emptyDecksVisitCourseButtonLabel: `ACESSAR A FLUENCY ACADEMY`,
  accessCourseLabel: `ACESSAR A FLUENCY ACADEMY`,
  emptyFriendsHeader: `Seus Amigos`,
  emptyFriendsDescription: [
    `Você ainda não tem amigos, assim que você adicionar alguns`,
    `eles irão aparecer por aqui!`
  ],
  emptyFriendsButton: `DESCOBRIR NOVOS AMIGOS`,

  emptyStatsHeader: `Suas Estatísticas`,
  emptyStatsDescription: [
    `Você ainda não estudou nenhum deck.`,
    `Então, por enquanto, não temos nada para te mostrar!`
  ],

  statsHeader: `Estatísticas`,

  passedDayStatsTabLabel: `HOJE`,
  passedMonthStatsTabLabel: `ÚLTIMO MÊS`,
  generalStatsTabLabel: `GERAL`,

  cardsReviewedStatLabel: `Cartas revisadas`,
  cardsStudiedStatLabel: `Cartas estudadas`,
  newCardsStatLabel: `Novas Cartas`,
  wordsLearnedStatLabel: `Palavras aprendidas`,
  timeStudiedStatLabel: `Tempo de Estudo`,
  hoursStudiedStatLabel: `Horas de Estudo`,
  averageSessionTimeStatLabel: `Tempo médio por sessao`,
  difficultyStatLabel: `Dificuldade`,
  cardsForgottenStatLabel: `Cartas não lembradas`,
  decksStudiedStatLabel: `Decks estudados`,

  easyCardsStatLabel: `FÁCIL`,
  normalCardsStatLabel: `BOM`,
  hardCardsStatLabel: `DIFÍCIL`,

  easyCardsReviewedStatLabel: `Cartas fáceis`,
  normalCardsReviewedStatLabel: `Cartas boas`,
  hardCardsReviewedStatLabel: `Cartas difíceis`,

  yourLanguagesDropdownDescription: `Seus idiomas:`,
  yourLanguagesDropdownLabel: `Selecione`,

  deckToBeStudiedDescription: `Deck a ser estudado:`,
  deckToBeStudiedEditDescription: `Alterar`,
  editDeckLabel: `Alterar deck`,

  studyNowButtonLabel: `ESTUDAR AGORA!`,
  deckTypeLabels: {
    master: `Master Decks`,
    all: `Todos Decks`
  },
  filterLabels: {
    allLanguages: `Todos`,
    mostRecentStudiedDecks: `Mais recentes`,
    alphabeticOrder: `A-Z`,
    reversedAlphabeticOrder: `Z-A`,
    english: `Inglês`,
    spanish: `Espanhol`,
    french: `Francês`,
    german: `Alemão`,
    italian: `Italiano`,
    mandarin: `Mandarin`,
    japonese: `Japonês`,
    mostStudiedDecks: `Mais estudados`
  },
  languageLabels: {
    english: `Inglês`,
    spanish: `Espanhol`,
    french: `Francês`,
    german: `Alemão`,
    italian: `Italiano`,
    mandarin: `Mandarin`,
    japonese: `Japonês`
  },
  deckFilterSideBarOrderByFiltersHeader: `Filtros`,

  orderbyDeckLanguageHeader: `Decks`,

  orderByLabel: `Ordenar Por`,
  orderByDropdownLabel: `Ordem de exibição`,

  deckSearchResultsHeader: `Buscas`,
  recentDeckSearchResultsHeader: `Buscas Recentes`,
  isFollowingDeckLabel: `Seguindo`,
  mobileDeckSearchYetToBeMadeFeedback: `Faça sua busca acima, se não tiver resultados, acesse a página de descoberta!`,
  mobileAccessCourseLabel: `Acessar a Fluency Academy`,
  deckSearchYetToBeMadeFeedback: [
    `Você ainda não pesquisou nenhum dado.`,
    `Então, por enquanto, não temos nada para te mostrar!`
  ],

  emptySearchResultsMessage: [
    `Oops! Não encontramos nenhum deck para essa busca.`,
    `Tente novamente com outras palavras!`
  ],

  mobileEmptySearchResultsMessage: [
    `Oops,`,
    `não encontramos nenhum resultado. Refaça sua busca ou`,
    ` adicione novos decks.`
  ]
}

export const deckPageText = {
  addToMasterDeckLabel: `Adicionar ao masterdeck`,
  activateDeckForQuickStartLabel: `Ativar deck para início rapido`,
  statsLabel: `Dados`,
  cardsLabel: `Cartas`,
  goalsLabel: `Metas`,
  navListActiveLink: `Dashboard`,
  studyNowLabel: `Estudar agora!`,
  subscribeLabel: `Subscribe!`,
  deckOverviewContainerHeader: ``,
  deckOverviewContainerDescription: ``,
  deckTotalCardsLabel: `Cartas no total`,
  deckRemainingCardsLabel: `Cartas restantes`,
  deckOverviewContainerContinueStudiesButtonLabel: `CONTINUAR ESTUDOS`,
  deckOverviewContainerSubscribeButtonLabel: `SUBSCRIBE`,
  deckOverviewContainerDailySessionCompleteButtonLabel: `VOCÊ ESTÁ ATUALIZADO!`,
  deckOverviewContainerMonthlyGoalComplete: ``,
  deckOverviewContainerDeckActivatedButtonLabel: `Deck ativo para estudo rápido`,

  activeDeckName: `Fast thinking drill with extra large name very large`,
  deckConfigurationsSideBarHeader: `Configurar Deck`,
  deckConfigurationsSideBarBackgroundHeader: `Configurar Deck`,

  addDeckToMasterDecksCard: [
    `Caso vocẽ confirme, esse deck será adicionado ao seu Masterdeck de`,
    `(língua correta)`
  ],

  deckConfigurationsSideBarAddDeckToMasterDeckSwitchName: `Adicionar`,
  deckConfigurationsSideBarAddDeckToMasterDeckSwitchDescription: `Ao masterdeck`,

  deckConfigurationsSideBarFollowDeckSwitchName: `Seguir Deck`,
  deckConfigurationsSideBarFollowDeckSwitchDescription: `Ativo para estudo`,

  deckDeleteCardHeader: `Wait!`,
  deleteDeckButtonLabel: `Deletar o deck`,
  deckDeleteCardDescription: [`Vocẽ quer mesmo `, `deletar o deck?`],

  deckDeleteCardCancelButtonLabel: `CANCELAR`,
  deckDeleteCardDeleteButtonLabel: `DELETAR`,

  deckDeletedCardHeader: `All Done!`,
  deckDeletedCardDescription: `O deck foi deletado.`,
  deckDeletedCardUndoButtonLabel: `DESFAZER`,
  deckDeletedCardReturnToDashboardButtonLabel: `VOLTAR AO DASHBOARD`,

  emptyStatsHeader: `Suas Estatísticas`,
  emptyStatsDescription: [
    `Você ainda não estudou nenhum deck.`,
    `Então, por enquanto, não temos nada para te mostrar!`
  ],
  emptyDeckStatsDescription: [
    `Você ainda não estudou esse deck.`,
    `Então, por enquanto, não temos nada para te mostrar!`
  ],

  userStatsHeader: `Seus Dados:`,

  passedSessionStatsTabLabel: `Última sessão`,
  passedWeekStatsTabLabel: `Últimos 7 dias`,
  passedMonthStatsTabLabel: `Último mês`,
  passed3MonthsStatsTabLabel: `Últimos 3 meses`,
  passedYearStatsTabLabel: `Último ano`,
  allTimeStatsTabLabel: `Todo o período`,

  cardsReviewedStatLabel: `Cartas revisadas`,
  newCardsStatLabel: `Novas Cartas`,
  wordsLearnedStatLabel: `Palavras aprendidas`,
  timeStudiedStatLabel: `Tempo de Estudo`,
  difficultyStatLabel: `Dificuldade`,
  cardsForgottenStatLabel: `Cartas não lembradas`,
  easyCardsReviewedStatLabel: `Cartas fáceis`,
  normalCardsReviewedStatLabel: `Cartas boas`,
  hardCardsReviewedStatLabel: `Cartas difíceis`,

  monthlyGoalStartNewGoal: `Iniciar Nova Meta!`,
  monthlyGoalComplete: [`Your Rock! `, `Meta`, `mensal completo`],
  monthlyGoalDaysComplete: [`22 dias`, `Estudando este deck!`],
  monthlyGoalDaysLeft: [`Faltam:`, `08`],

  monthlyGoalActivatedAlert: `Ativada!`,
  monthlyGoalDeactivateHeader: `Meta mensal!`,
  monthlyGoalDeactivateDescription: `Com uma meta você se mantém motivado enquanto aprende!`,
  monthlyGoalDeactivateButtonLabel: `Desativar meta mensal`,

  monthlyGoalStartHeader: `Meta mensal!`,
  monthlyGoalStartDescription: `Com uma meta você se mantém motivado enquanto aprende!`,
  monthlyGoalStartButtonLabel: `Ativar meta de 30 dias`,

  cardsReviewedHeader: `Cartas Revisadas:`,
  cardsReviewedDailyTabLabel: `HOJE`,
  cardsReviewedWeeklyTabLabel: `ÚLTIMOS 7 DIAS`,
  cardsReviewedMonthlyTabLabel: `ÚLTIMOS MÊS`,

  listItemCardViewStatsButtonLabel: `Dados`,
  listItemCardStatsDescription: `Short term memory`,
  gradingsTotalCountLabel: `reviews !`,
  cardReviewSubHeader: `Short term memory`,

  deckPageSideA: `A`,
  deckPageSideB: `B`,

  cardsHeader: `Cartas`,
  cardsViewByCardsTabLabel: `VER EM CARDS`,
  cardsViewByListTabLabel: `VER EM LISTA`
}

export const deckModeText = {
  // placeholder data until integration
  activeCardTitle: `Fast thinking drill`,
  activeCardDescription: `Fast thinking drill all decks invertido`,
  mobileDeckModeHeader: `Tempo disponível`,
  activeDeckName: `Fast thinking drill with extra large name very large`,

  deckModeHeader: [`Quanto tempo`, `você pode estudar?`],
  deckModeSubHeader: `Quanto mais estudar mais rápido aprenderá!`,
  deckModeQuestionsLink: `Está com dúvidas?`,
  deckModeQuestionsLinkButtonLabel: `Clique Aqui`,

  deckModeNotEnoughCardsAlert: `Você ainda não tem carta suficientes para este modo :(`,

  deckModeIdealLabel: `Ideal`,
  deckModeIdealSubTitle: `Estudar todas as cartas do Masterdeck`,
  deckModeIdealDescription: `Modo ideal de estudo. Nele você ira estudar todo seu masterdeck.`,

  deckModeNormalLabel: `Bom`,
  deckModeNormalSubTitle: `Até 01 hora de estudo `,
  deckModeNormalDescription: `Modo indicado para uma boa sessão de estudos diária, mas ainda nãoé o ideal...`,

  deckModeMediumLabel: `Médio`,
  deckModeMediumSubTitle: `Até 45 minutos de estudo`,
  deckModeMediumDescription: `Tempo intermediário para sua sessão de estudos diária, porém ainda não é o ideal...`,

  deckModeShortLabel: `Curtinho`,
  deckModeShortSubTitle: `Até 30 minutos de estudo`,
  deckModeShortDescription: `Modo indicado para os que tem pouco tempo disponível, mas não é o deal para seus estudos diários...`
}

export const studySessionText = {
  controlsTab: `Controles`,
  leaveTab: `Sair`,
  spacebarSymbol: `⎵`,

  cardFlipButtonLabel: `Virar carta`,
  cardFlipShortcutTooltip: [
    `Dica: `,
    `Você também pode virar a carta com a tecla espaço do seu teclado`
  ],

  showTranslationShortcutTooltip: [
    `Os textos das cartas estão desativados para você treinar melhor seu `,
    `listening!`,
    `Você pode ativar os textos no campo`,
    `”controles”`,
    `acima sempre que precisar.`
  ],

  difficultyGradingEasy: `FÁCIL`,
  difficultyGradingEasyDaysUntilReview: `4D`,
  difficultyGradingEasyShortcutTooltip: `Digite 1`,

  difficultyGradingNormal: `BOM`,
  difficultyGradingNormalDaysUntilReview: `3D`,
  difficultyGradingNormalShortcutTooltip: `Digite 2`,

  difficultyGradingHard: `DIFÍCIL`,
  difficultyGradingHardDaysUntilReview: `2D`,
  difficultyGradingHardShortcutTooltip: `Digite 3`,

  difficultyGradingDontRemember: `NÃO LEMBRO`,
  difficultyGradingDontRememberDaysUntilReview: `1D`,
  difficultyGradingDontRememberShortcutTooltip: `Digite 4`,

  controlsSideBarHeader: `Controles`,

  controlsSideBarAutoPlaySwitchName: `Autoplay`,
  controlsSideBarAutoPlaySwitchDescription: `Som automático`,

  controlsSideBarShowTranslationSwitchName: `Exibir Texto`,
  controlsSideBarShowTranslationSwitchDescription: `Tradução na carta`,

  controlsSideBarShowDaysUntilReviewSwitchName: `Mostrar Dias`,
  controlsSideBarShowDaysUntilReviewSwitchDescription: `Da próxima revisão`,

  controlsSideBarShortcutsHeader: `Atalhos de teclado`,

  controlsSideBarEnterShortcutDescription: [`Enter: `, `Dar replay no áudio`],
  controlsSideBarEnterShortcutSymbol: [`↵`],

  controlsSideBarSpaceShortcutDescription: [`Espaço: `, `Virar a carta`],
  controlsSideBarSpaceShortcutSymbol: [`⎵`],

  controlsSideBarEasyGradeShortcutDescription: [`1: `, `Avaliar carta como "Fácil"`],
  controlsSideBarEasyGradeShortcutSymbol: [`1`],

  controlsSideBarNormalGradeShortcutDescription: [`2: `, `Avaliar carta como "Bom"`],
  controlsSideBarNormalGradeShortcutSymbol: [`2`],

  controlsSideBarHardGradeShortcutDescription: [`3: `, `Avaliar carta como "Difícil"`],
  controlsSideBarHardGradeShortcutSymbol: [`3`],

  controlsSideBarDontRememberGradeShortcutDescription: [`4: `, `Avaliar carta como "Não lembro"`],
  controlsSideBarDontRememberGradeShortcutSymbol: [`4`],

  endSessionToastHeader: `Encerrar sessão?`,
  endSessionToastDescription: `Todo seu progresso nesta sessão de estudos será perdido.`,
  endSessionToastEndSessionButtonLabel: `ENCERRAR SESSÃO`,
  endSessionToastCancelButtonLabel: `CANCELAR`,

  sessionCompletedHeader: `Você Terminou!`,
  sessionCompletedSubHeader: `Seus resultados estão sendo carregados…`,
  sessionCompletedJustAMomentNote: `Levara so alguns instantes!`
}

export const studySessionStatsText = {
  cardsReviewedBarsHeader: ` cartas foram revisadas!`,
  statisticsCrumb: `Estatísticas`,
  mobileStatsOverviewHeader: `Yeah! \n Você estudou o `,
  statsOverviewDeckStudiedHeader: `Você estudou o deck `,

  statsOverviewDescription: [
    `Confira abaixo seus dados e progresso de estudos! Você pode navegar pelos gráficos e conferir seus dados em `,
    `diferentes períodos!`
  ],

  deckStatsHeader: `Seus Dados:`,

  passedSessionStatsTabLabel: `ÚLTIMA SESSÃO`,
  passedWeekStatsTabLabel: `ÚLTIMOS 7 DIAS`,
  passedMonthStatsTabLabel: `ÚLTIMO MÊS`,
  passed3MonthsStatsTabLabel: `ÚLTIMOS 3 MESES`,
  passedYearStatsTabLabel: `ÚLTIMO ANO`,
  allTimeStatsTabLabel: `TODO O PERÍODO`,

  cardsReviewedStatLabel: `Cartas revisadas`,
  newCardsStatLabel: `Novas Cartas`,
  wordsLearnedStatLabel: `Palavras aprendidas`,
  timeStudiedStatLabel: `Tempo de Estudo`,
  difficultyStatLabel: `Dificuldade`,
  cardsForgottenStatLabel: `Cartas não lembradas`,
  easyCardsReviewedStatLabel: `Cartas fáceis`,
  normalCardsReviewedStatLabel: `Cartas boas`,
  hardCardsReviewedStatLabel: `Cartas difíceis`,

  dashboardRedirectButtonLabel: `IR PARA O DASHBOARD`
}

export const registerCTAPageText = {
  sidebarHeader: `Let's hackit!`,
  backgroundHeader: `Cadastre-se!`,

  registerCTACardHeader: `Quer se cadastrar?`,

  registerCTACardBlock1: `O Memhack é um produto da Rhavi Carneiro’s Fluency Academy, e para ter acesso à ele você precisa ser um de nossos alunos. Se esse for o caso, basta acessar sua plataforma do curso e seguir as instruções que estão por lá… `,
  registerCTACardBlock2: `Se ainda não for um de nossos alunos, clique no link abaixo e saiba como se matricular.`,

  registerCTAButtonLabel: `CONHEÇA A FLUENCY ACADEMY!`
}

export const enableNotificationsSplashText = {
  pageHeader: `You Rock!`,
  pageContentParagraphs: [
    <p key="paragraph1">
      Você mandou bem, mas para virar um ninja da fluência, você deve estudar em intervalos
      regulares.
    </p>,
    <p key="paragraph2">
      Se você quiser, nós podemos te ajudar com notificações quando for a hora de estudar novamente.
      Para fazermos isso, você precisa habilitar as notificações.
    </p>,
    <p key="paragraph3">
      Nós não vamos te mandar spam ok? E se você mudar de ideia e uiser alterar suas permissões é só
      acesar seu perfil.
    </p>
  ],
  enableLaterLabel: `FAZER DEPOIS`,
  enableNotificationsLabel: `HABILITAR NOTIFICAÇÕES`
}
