import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    min-height: 100%;
    overflow: visible;
  }

  body {
    background: linear-gradient(180deg, #FF5363 0%, #F93779 100%) #F93779;
  }
`

export const StatusContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const TopContainer = styled.div`
  background-color: #F24556;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
`

export const TabsContainer = styled.div`
  display: flex;
  color: white;
  padding: 0 18px;
  height: 44px;
`

export const TabItem = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF80;
  border-bottom: solid 2px transparent;
  text-transform: uppercase;
  transition: color 0.2s ease-out, border-bottom-color 0.2s ease-out;

  &[data-selected='true'] {
    color: #FFFFFF;
    border-bottom-color: #FFFFFF;
  }
`

export const ListContainer = styled.div`
  padding: 20px 24px 4px 24px;
  margin-bottom: 24px;
`

export const PageControlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`

export const StatsPlaceholder = styled.div`
  padding: 58px 53px 58px 23px;
  color: white;
  opacity: 0.85;
  font-size: 12px;
  line-height: 18px;
  font-style: italic;
`
