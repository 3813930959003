import React, { memo, useEffect, useMemo, useRef } from "react"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import FormStatus from "../Common/FormStatus"
import InputField from "../Common/InputField"
import SelectField from "../Common/SelectField"
import { CountryIcon, InputGroup, PhoneIcon, StyledForm, SubmitButton, TopFormPart } from "../Common/styles"
import countryPhoneCodes from "../../../common/glossaries/CountryPhoneCodes.json"
import { Description, RegisterButtonContainer } from "./styles"
import { Dictionary } from "../../../utils/i18n"
import useOnChange from "../../../hooks/useOnChange"

const RegisterPhoneForm = ({ pending, error }) => {
  const formik = useFormikContext()
  const { t } = useTranslation()

  const phoneNumberRef = useRef(null)

  const fieldNames = {
    areaCode: t(Dictionary.register.areaCode),
    phoneNumber: t(Dictionary.register.phoneNumber)
  }

  const status = useMemo(() => {
    if (error) {
      return {
        type: "error",
        title: t(Dictionary.common.oops),
        message: error.message
      }
    }

    return null
  }, [error, t])

  useOnChange(() => {
    if (!pending) {
      formik.setSubmitting(false)
    }
  }, [pending])

  useEffect(() => {
    if (formik.values.areaCode && phoneNumberRef.current) {
      phoneNumberRef.current.focus()
    }
  }, [formik.values.areaCode])

  return (
    <StyledForm>
      <TopFormPart>
        <FormStatus fieldNames={fieldNames} status={status} />
        <Description>{t(Dictionary.register.validatePhone)}</Description>
        <InputGroup>
          <SelectField
            name="areaCode"
            placeholder={fieldNames.areaCode}
            icon={<CountryIcon />}
            options={countryPhoneCodes}
          />

          <InputField
            name="phoneNumber"
            type="number"
            placeholder={fieldNames.phoneNumber}
            icon={<PhoneIcon />}
            ref={phoneNumberRef}
          />
        </InputGroup>
        <RegisterButtonContainer>
          <SubmitButton primary type="submit" loading={formik.isSubmitting} disabled={!formik.dirty || !formik.isValid}>
            {t(Dictionary.register.codeBySms)}
          </SubmitButton>
          {/* <SubmitButton primary type="submit" loading={formik.isSubmitting} disabled={!formik.dirty || !formik.isValid}>
            {t(Dictionary.register.codeByWhatsApp)}
          </SubmitButton> */}
        </RegisterButtonContainer>
      </TopFormPart>
    </StyledForm>
  )
}

export default memo(RegisterPhoneForm)
