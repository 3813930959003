import React, { memo, useCallback, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useSelector } from "react-redux"
import Navigation from "../../common/components/Navigation"
import TopBar from "./TopBar"
import { PageContainer, PageRoot } from "../../common/styles/page"
import * as selectors from "../../store/selectors/sessions"
import {
  ContentContainer,
  DashboardButton,
  DashboardButtonRow,
  TopCardContainer,
  // TopCardSubTitle,
  TopCardTitle
} from "./styles"
import { routes } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";

const StudySessionStatsPage = () => {
  const {t} = useTranslation();
  const history = useHistory()
  const match = useRouteMatch()
  const type = match.params.mergeId ? "merge" : "deck"
  const id = match.params.mergeId ?? match.params.deckId

  const deckName = useSelector((state) => selectors.sessionName(state, type, id)) ?? ""

  const [navMenuExpanded, setNavMenuExpanded] = useState(true)

  const handleToggleExpanded = useCallback(() => {
    setNavMenuExpanded(expanded => !expanded)
  }, [])

  const handleOpenDashboard = () => {
    history.replace(routes.dashboard)
  }

  return (
    <PageRoot>
      <Navigation expanded={navMenuExpanded} toggleExpanded={handleToggleExpanded} />
      <PageContainer data-nav-expanded={navMenuExpanded}>
        <TopBar type={type} id={id} title={deckName} navMenuExpanded={navMenuExpanded} />
        <ContentContainer>
          <TopCardContainer>
            <TopCardTitle>
              {t(Dictionary.finishedStudySession.deckStudiedInfo)} {deckName}!
            </TopCardTitle>
            {/* <TopCardSubTitle>
              Confira abaixo seus dados e progresso de estudos!{" "}
              Você pode navegar pelos gráficos e conferir seus dados em <strong>diferentes períodos!</strong>
            </TopCardSubTitle> */}
          </TopCardContainer>
          <DashboardButtonRow>
            <DashboardButton onClick={handleOpenDashboard}>
              {t(Dictionary.finishedStudySession.goToDashboard)}</DashboardButton>
          </DashboardButtonRow>
        </ContentContainer>
      </PageContainer>
    </PageRoot>
  )
}

export default memo(StudySessionStatsPage)
