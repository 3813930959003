import React, { memo } from "react"
import { routes } from "../../../../utils/constants"
import { ButtonsRow, Container, HandImage, Text, LinkButton, IosLinkImage, AndroidLinkImage } from "./styles"
import { useTranslation } from 'react-i18next';
import { Dictionary } from "./../../../../utils/i18n";


const MobileAppsBar = (props) => {
  const { t } = useTranslation();

  return (<Container {...props}>
    <HandImage />
    <Text>
      <strong>{t(Dictionary.common.hey)}&nbsp;</strong> {t(Dictionary.register.message)}
    </Text>
    <ButtonsRow>
      <LinkButton href={routes.memhack.iosAppLink} target="_blank"><IosLinkImage /></LinkButton>
      <LinkButton href={routes.memhack.androidAppLink} target="_blank"><AndroidLinkImage /></LinkButton>
    </ButtonsRow>
  </Container>)
}

export default memo(MobileAppsBar)
