import styled from "styled-components"
import hackitLogo from "../../../../assets/svgs/memhack-logo-horizontal.svg"
import bgImage from "./bg-image.png"
import { CARD_CHANGE_DURATION } from "../../Card/constants"

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #008EE0 0%, #0874B2 100%) 0% 0% no-repeat padding-box;
  transition: opacity ${CARD_CHANGE_DURATION}ms ease;
  opacity: 0;
  display: flex;
  flex-direction: column;

  &[data-show='true'] {
    opacity: 1;
  }
`

export const Content = styled.div`
  position: relative;
`

export const TopRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 12px;
  margin-bottom: 34px;
`

export const HackitLogo = styled.img.attrs({
  src: hackitLogo
})`
  width: 81.5px;
  height: 20px;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 14px;
  margin: -15px;
  color: white;
`

export const Title = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-weight: 900;
  font-size: 42px;
  line-height: 43px;
  color: white;
  padding: 0 21px;
`

export const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 432px;
  background: right bottom / cover no-repeat url("${bgImage}");
`

export const ProgressContainer = styled.div`
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 29px;
`

export const ProgressTitle = styled.div`
  color: white;
  opacity: 0.65;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  margin-bottom: 7px;
`

export const ProgressBar = styled.div`
  height: 8px;
  background-color: #FFFFFF73;
  border-radius: 3px;
  overflow: hidden;
`

export const ProgressFill = styled.div`
  height: 100%;
  width: 0;
  background-color: white;
`
