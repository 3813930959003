import { createSelector, createStructuredSelector } from "reselect"

export const authToken = (state) => state.auth.authToken

export const isLoggedIn = (state) => !!state.auth.authToken

export const currentUser = createSelector(
  (state) => state.auth.user.data,
  (user) => user ?? ({
    firstName: "",
    lastName: ""
  })
)

export const currentUserId = (state) => state.auth.user.data?.id ?? 0

export const appAuthInfo = createStructuredSelector({
  isLoggedIn: (state) => !!state.auth.authToken,
  isLoading: (state) =>
    state.auth.login.pending || (!state.auth.user.data && state.auth.user.loading),
  user: (state) => state.auth.user.data,
  isUserCached: (state) => state.auth.user.cached
})

export const loginState = (state) => state.auth.login

export const registerState = (state) => state.auth.register

export const registerPhoneState = (state) => state.auth.registerPhone

export const recoverPasswordState = (state) => state.auth.recoverPassword

export const verifyPhoneState = (state) => state.auth.verifyPhone

export const encryptedPhoneNumber = createSelector(verifyPhoneState, state => state.data?.encryptedPhone)

export const loginCredentials = (state) => state.auth.loginCredentials
