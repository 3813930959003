import styled from "styled-components"
import { LAPTOP_MAX } from "../breakpoints"
import {
  navBarTransition,
  navBarWidthDesktop,
  navBarWidthDesktopCollapsed,
  navBarWidthLaptop,
  navBarWidthLaptopCollapsed
} from "./navBar"

export const PageRoot = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`

export const PageContainer = styled.div`
  margin-left: ${navBarWidthDesktopCollapsed}px;
  transition: margin-left ${navBarTransition};

  &[data-nav-expanded='true'] {
    margin-left: ${navBarWidthDesktop}px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-left: ${navBarWidthLaptop}px;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-left: ${navBarWidthLaptopCollapsed}px;
  }
`
