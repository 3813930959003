import React, { memo } from "react"
import LinesEllipsis from "react-lines-ellipsis"
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC"
import { useHistory } from "react-router-dom"
import BackArrowIcon from "../../../assets/svgs/BackArrowIcon"
import { routes } from "../../../utils/constants"
import { formatCardsCount } from "../../../utils/format";
import i18n, { Dictionary } from "./../../../utils/i18n";
import {
  BackButton,
  BackButtonWrapper,
  Container,
  OopsImage,
  Title,
  Description,
  StatsContainer,
  StyledFlag,
  CountersContainer,
  CounterLabel,
  OverdueIcon,
  TotalIcon,
  ContinueButton,
  ButtonWrapper
} from "./styles"

const Ellipsis = responsiveHOC()(LinesEllipsis)

const fallbackNode = <OopsImage />

const DeckInfo = ({ deck, referral, backToList, onContinueStudy }) => {
  const history = useHistory()

  const handleGoBack = () => {
    if (backToList) {
      history.replace(`${routes.dashboard}/list`)
    } else {
      history.replace(routes.dashboard)
    }
  }

  const selectActionCaption = referral?.params ? i18n.t(Dictionary.deck.addDeck) : i18n.t(Dictionary.deck.continueStudy);

  return (
    <Container src={deck.image} fallback={fallbackNode}>
      <BackButtonWrapper>
        <BackButton onClick={handleGoBack}><BackArrowIcon /></BackButton>
      </BackButtonWrapper>
      <Title>
        <Ellipsis
          text={deck.title}
          maxLine="3"
          ellipsis="..."
        />
      </Title>
      <Description>
        <Ellipsis
          text={deck.description}
          maxLine="3"
          ellipsis="..."
        />
      </Description>
      <StatsContainer>
        <StyledFlag language={deck.language} />
        <CountersContainer>
          <CounterLabel>{formatCardsCount(deck.overdueCount)}</CounterLabel>
          <OverdueIcon />
          <CounterLabel>{formatCardsCount(deck.totalCount)}</CounterLabel>
          <TotalIcon />
        </CountersContainer>
      </StatsContainer>
      <ButtonWrapper>
        <ContinueButton primary outlined onClick={onContinueStudy}>
          {selectActionCaption}
        </ContinueButton>
      </ButtonWrapper>
    </Container>
  )
}

export default memo(DeckInfo)
