import React, { memo } from "react"

const FluencyLogoLight = (props) => (
  <svg height="1em" viewBox="0 0 138.124 49.33" {...props}>
    <defs>
      <clipPath id="a">
        <rect width="138.124" height="49.33" transform="translate(0 0)" />
      </clipPath>
    </defs>
    <g className="b">
      <g className="c" transform="translate(0 0)">
        <path
          d="M20.847,25.264c0,1.9-.8,3.285-1.9,3.285a1.476,1.476,0,0,1-.987-.493,3.38,3.38,0,0,1-.909-2.366v-.155l-.024-.193a7.7,7.7,0,0,1,2.052-5.485A9.141,9.141,0,0,1,20.84,18.37ZM33.04,9.128A42.958,42.958,0,0,0,27.3,9.51V0H20.847a7.206,7.206,0,0,1-2.165,5A7.208,7.208,0,0,1,13.64,7.052,7.209,7.209,0,0,1,8.6,5,7.209,7.209,0,0,1,6.434,0H0A13.6,13.6,0,0,0,1.365,5.991a13.6,13.6,0,0,0,3.862,4.779,13.6,13.6,0,0,0,11.714,2.468,15.785,15.785,0,0,0-2.744,2.43,14.072,14.072,0,0,0-3.572,10.172A9.8,9.8,0,0,0,13.413,32.6a7.833,7.833,0,0,0,5.555,2.389c4.755,0,8.344-4.184,8.344-9.733V16.038a34.462,34.462,0,0,1,5.765-.463Z"
          transform="translate(104.808 3.194)"
        />
        <path
          d="M14.457,26.389A8.938,8.938,0,0,1,8.341,10.936a13.275,13.275,0,0,0,6.083,1.717c2.1.041,7.113-.2,8.232-3.474A3.718,3.718,0,0,0,22.112,5.8c-1.284-1.811-4.055-2.866-7.654-2.9A14.5,14.5,0,0,0,8.49,4.184,25.152,25.152,0,0,1,4.752,0L.176,3.251A31.453,31.453,0,0,0,3.819,7.533,14.531,14.531,0,1,0,27.353,24.189l-4.974-2.6a8.922,8.922,0,0,1-7.922,4.8Z"
          transform="translate(79.093 -0.368)"
        />
        <path
          d="M0,6.759a6.759,6.759,0,0,0,3.1,5.674A6.759,6.759,0,0,0,0,18.11v9.705H2.173V18.11a4.593,4.593,0,0,1,4.586-4.589h2.66V11.348H6.759a4.589,4.589,0,0,1,0-9.165H10.27V0H6.759A6.759,6.759,0,0,0,0,6.759Z"
          transform="translate(-0.058 3.194)"
        />
        <path
          d="M0,19.2a8.628,8.628,0,0,0,8.509,8.6h2.028l.041-4.113H8.55A4.484,4.484,0,0,1,8.6,14.724h1.97V10.611h-3.2a3.251,3.251,0,1,1,0-6.5h3.285V0H7.367A7.357,7.357,0,0,0,2.656,13.014,8.551,8.551,0,0,0,0,19.2"
          transform="translate(45.728 3.194)"
        />
        <path d="M10.756,24.95H2.866V0H0V27.816H10.756Z" transform="translate(13.658 3.194)" />
        <path
          d="M7.164,28.458A7.208,7.208,0,0,0,14.3,21.209V0H10.716V21.209a3.569,3.569,0,1,1-7.134,0V0H0V21.209a7.208,7.208,0,0,0,7.164,7.249Z"
          transform="translate(27.924 3.194)"
        />
        <path
          d="M7.864,0A7.874,7.874,0,0,0,0,7.877V28.441H5V7.877a2.852,2.852,0,1,1,5.7,0V28.441h5.015V7.877A7.873,7.873,0,0,0,7.864,0Z"
          transform="translate(59.821 2.57)"
        />
        <path
          d="M4.484,3.2H2.234L3.379,1.041ZM3.092,0,0,5.91H.811L1.859,3.917h3L5.9,5.91h.811L3.626,0Z"
          transform="translate(-0.058 42.62)"
        />
        <path
          d="M4.474,3.2H2.23L3.356,1.041ZM3.082,0,0,5.9H.811L1.852,3.91H4.846L5.887,5.9H6.7L3.616,0Z"
          transform="translate(42.819 42.637)"
        />
        <path
          d="M4.792,2.954c0,1.588-.588,2.234-2.028,2.234H.737V.713H2.764c1.436,0,2.028.649,2.028,2.237ZM2.764,0H0V5.9H2.764c1.852,0,2.751-.963,2.751-2.947S4.616,0,2.764,0Z"
          transform="translate(64.455 42.637)"
        />
        <path
          d="M6.123,0,3.683,4.812,1.311,0H.679L0,5.9H.723L1.24,1.484,3.419,5.9h.507L6.181,1.446,6.7,5.9h.727L6.748,0Z"
          transform="translate(104.805 42.637)"
        />
        <path
          d="M4.123,0,2.643,2.825.841,0H0L2.261,3.548,1.024,5.9h.8L4.917,0Z"
          transform="translate(127.172 42.625)"
        />
        <path
          d="M1.628,3.275l3.316-.034V2.565L1.636,2.6a.963.963,0,1,1,0-1.923H4.947V0H1.639a1.636,1.636,0,0,0-.99,2.94,1.636,1.636,0,0,0,.99,2.94l3.312-.017V5.187L1.642,5.2a.963.963,0,0,1,0-1.926"
          transform="translate(84.924 42.654)"
        />
        <path
          d="M5.525,4.075c-.206.9-.923,1.3-2.328,1.3C1.382,5.377.74,4.765.74,3.048S1.382.72,3.2.72c1.392,0,2.105.4,2.308,1.3l.02.081h.73l-.02-.122C6,.649,5,0,3.193,0,.987,0,0,.939,0,3.041S.987,6.083,3.193,6.083a3.374,3.374,0,0,0,2.491-.774,2.166,2.166,0,0,0,.585-1.2l.02-.122H5.542Z"
          transform="translate(21.591 42.529)"
        />
      </g>
    </g>
  </svg>
)

export default memo(FluencyLogoLight)
