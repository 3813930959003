import styled from "styled-components"
import { MOBILE_MAX } from "../../../common/breakpoints"
import { FormContainer } from "../Common/styles"

export const RegisterFormContainer = styled(FormContainer)`
`

export const RegisterButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 32px;
  }
`

export const ChangePhoneLink = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: rgb(0, 128, 213);
  text-transform: uppercase;

  @media (max-width: ${MOBILE_MAX}px) {
    color: #f8f8f8;
  }
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 18px;
  opacity: 0.65;
  margin: 15px 0 40px;

  @media (max-width: ${MOBILE_MAX}px) {
    opacity: 1;
    color: #f8f8f8;
  }
`
