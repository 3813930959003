import keys from "../../utils/keys"
// placeholder values to be fetched

export const userStatsData = {
  monthlyGoalDaysStudyingDeck: 8,

  activeReviewedCardsStats: {
    easy: 100,
    medium: 145,
    hard: 80
  },
  reviewedCardsStatsByTimespan: {
    daily: {
      easy: 50,
      medium: 145,
      hard: 80
    },
    weekly: {
      easy: 100,
      medium: 40,
      hard: 80
    },
    monthly: {
      easy: 100,
      medium: 145,
      hard: 80
    }
  },

  passedSession: {
    cardsForgottenValue: 400,
    cardsReviewedValue: 365,
    newCardsValue: 133,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 100,
    timeStudiedValue: 19000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 100,
    normalCardsReviewedValue: 145,
    hardCardsReviewedValue: 80
  },
  passedDay: {
    cardsForgottenValue: 400,
    cardsReviewedValue: 365,
    newCardsValue: 133,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 100,
    timeStudiedValue: 19000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 100,
    normalCardsReviewedValue: 145,
    hardCardsReviewedValue: 80
  },

  passedWeek: {
    cardsForgottenValue: 235,
    cardsReviewedValue: 400,
    newCardsValue: 300,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 1333,
    timeStudiedValue: 900000,
    difficultyValue: `Normal`,
    easyCardsReviewedValue: 220,
    normalCardsReviewedValue: 230,
    hardCardsReviewedValue: 500
  },

  passedMonth: {
    cardsForgottenValue: 1200,
    cardsReviewedValue: 1000,
    newCardsValue: 2500,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 9654,
    timeStudiedValue: 1800000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 200,
    normalCardsReviewedValue: 300,
    hardCardsReviewedValue: 300
  },

  passed3Months: {
    cardsForgottenValue: 3600,
    cardsReviewedValue: 365,
    newCardsValue: 133,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 100,
    timeStudiedValue: 9000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 500,
    normalCardsReviewedValue: 700,
    hardCardsReviewedValue: 300
  },

  passedYear: {
    cardsForgottenValue: 5000,
    cardsReviewedValue: 365,
    newCardsValue: 133,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    wordsLearnedValue: 100,
    timeStudiedValue: 9000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 300,
    normalCardsReviewedValue: 700,
    hardCardsReviewedValue: 200
  },

  general: {
    cardsForgottenValue: 5000,
    cardsReviewedValue: 365,
    cardsStudiedValue: 100,
    decksStudiedValue: 200,
    averageSessionTimeValue: 9000,
    newCardsValue: 133,
    wordsLearnedValue: 100,
    timeStudiedValue: 9000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 300,
    normalCardsReviewedValue: 700,
    hardCardsReviewedValue: 200
  },

  allTime: {
    cardsForgottenValue: 8000,
    cardsReviewedValue: 5000,
    newCardsValue: 24000,
    wordsLearnedValue: 50000,
    timeStudiedValue: 18000000,
    difficultyValue: `Fácil`,
    easyCardsReviewedValue: 600,
    normalCardsReviewedValue: 1000,
    hardCardsReviewedValue: 400
  }
}

export const initialUserDecksData = {
  activeDeckSearchFilters: [keys.languageFilters.allLanguages]
}

export const userDecksData = {
  masterDecksArray: [
    {
      deckId: `deck1`,
      title: `Unit 01`,
      category: `Master Decks`,
      type: `Fast Thinking Drill All Decks`,
      description: `Introduction (Common Words and Sayings)`,
      avatar: {
        path: `https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80`
      },
      cardsCompleted: 10,
      cardCount: 12,
      language: `english`,
      tags: [{ tag: `english` }]
    },
    {
      deckId: `deck2`,
      title: `Unidad 1`,
      category: `Mazos Maestros`,
      type: `Lección diaria Mazos Invertidos`,
      description: `Introducción`,
      avatar: {
        path: `https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80`
      },
      cardsCompleted: 0,
      cardCount: 12,

      language: `spanish`,
      tags: [{ tag: `spanish` }]
    },
    {
      deckId: `deck3`,
      title: `Unité 02`,
      category: `Jeu maîtresses`,
      type: `Étude de réflexion rapide`,
      description: `Mettez en pratique vos capacités de réflexion rapide`,
      avatar: {
        path: `https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80`
      },
      cardsCompleted: 0,
      cardCount: 12,
      language: `french`,
      tags: [{ tag: `french` }]
    }
  ],

  recentDeckSearchResults: [
    {
      deckId: `recentResultDeck1`,
      title: `Short term memory practice`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 3,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_1_img.png`,
      dateCreated: `2019-07-29`,
      language: `english`,
      tags: [{ tag: `english` }]
    },
    {
      deckId: `recentResultDeck2`,
      title: `Pensamiento rápido`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 1,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_2_img.png`,
      dateCreated: `2019-09-02`,
      language: `spanish`,
      tags: [{ tag: `spanish` }]
    },
    {
      deckId: `recentResultDeck3`,
      title: `Fast thinking drill`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 4,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_3_img.png`,
      dateCreated: `2019-05-22`,
      language: `english`,
      tags: [{ tag: `english` }]
    },
    {
      deckId: `recentResultDeck4`,
      title: `Mots Practiquer`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 1,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_4_img.jpg`,
      dateCreated: `2019-06-27`,
      language: `french`,
      tags: [{ tag: `french` }]
    },
    {
      deckId: `recentResultDeck5`,
      title: `Grammar quiz`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 1,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_5_img.jpg`,
      dateCreated: `2019-07-29`,
      language: `english`,
      tags: [{ tag: `english` }]
    },
    {
      deckId: `recentResultDeck6`,
      title: `Fast thinking drill`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      studyCount: 4,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_6_img.jpg`,
      dateCreated: `2018-06-27`,
      language: `english`,
      tags: [{ tag: `english` }]
    },
    {
      title: `Word retention drill`,
      category: `Master Decks`,
      type: `Fast Thinking Drill ALl Decks Invertidos`,
      description: `Lorem ipsu Dolor ismet`,
      deckId: `recentResultDeck7`,
      studyCount: 3,
      searchThumbnailSrc: `https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/tags/deck_page_recent_search_result_deck_7_img.jpg`,
      dateCreated: `2017-07-29`,
      language: `english`,
      tags: [{ tag: `english` }]
    }
  ]
}

export const dashboardData = {
  quickStartDropdownLanguages: {
    english: {
      flagSrc: ``,
      label: `Inglês`,
      isActive: true,
      decksToBeStudied: {
        1: {
          title: `Unit 01 - Vocal Rocket #1 Introduction`,
          cardCount: 10,
          cardsCompleted: 10
        }
      }
    },
    spanish: {
      flagSrc: ``,
      label: `Espanhol`,
      isActive: false,
      decksToBeStudied: {
        1: {
          title: `Unit 02 - Vocal Rocket #1 Verbs (part I)`,
          cardCount: 10,
          cardsCompleted: 10
        }
      }
    },
    french: {
      flagSrc: ``,
      label: `Françês`,
      isActive: false,
      decksToBeStudied: {
        1: {
          title: `Unit 01 - Vocal Rocket #1 Expressing yourself (part II)`,
          cardCount: 10,
          cardsCompleted: 10
        }
      }
    },
    german: {
      flagSrc: ``,
      label: `Alemão`,
      isActive: false,
      decksToBeStudied: {
        1: {
          title: `Unit 01 - Vocal Rocket #1 large name (part II)`,
          cardCount: 10,
          cardsCompleted: 10
        }
      }
    },
    italian: {
      flagSrc: ``,
      label: `Italiano`,
      isActive: false,
      decksToBeStudied: {
        1: {
          title: `Unit 01 - Vocal Rocket #1 large name (part II)`,
          cardCount: 10,
          cardsCompleted: 10
        }
      }
    }
  }
}

export const deckPageData = {
  activeDeckName: `Fast thinking drill with extra large name very large`,

  activeDeck: {
    deckId: "deck1",
    title: "Unit 01 part II - Grammar Hacks #1 (part III)",
    description: "Fast Thinking Drill All Decks.",
    isDeckQuickStartActive: false,
    totalCards: 58,
    remainingCards: 26,

    tags: [{ tag: `english` }]
  },
  activeDeckStudyCardsArray: [
    {
      id: `card1`,
      language: "english",
      translatedPhrase: "How is it going?",
      nativePhrase: "Como vai?",
      phraseSounds: [{ url: `/sounds/deck1Card1.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      gradingsCountHard: 2,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg"
    },
    {
      id: `card2`,
      language: "english",
      translatedPhrase: "What have you been up to?",
      nativePhrase: "Como esta o tempo hoje?",
      phraseSounds: [{ url: `/sounds/deck1Card2.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      gradingsCountHard: 2,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg"
    },
    {
      id: `card3`,
      language: "english",
      translatedPhrase: "Don’t take it to heart",
      nativePhrase: "Não leve para o lado pessoal",
      phraseSounds: [{ url: `/sounds/deck1Card3.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg",
      gradingsCountHard: 2
    },
    {
      id: `card4`,
      language: "english",
      translatedPhrase: "Thank you in advance!",
      nativePhrase: "Obrigado desde ja!",
      phraseSounds: [{ url: `/sounds/deck1Card4.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg",
      gradingsCountHard: 2
    },
    {
      id: `card5`,
      language: "english",
      translatedPhrase: "Good morning.",
      nativePhrase: "Bom dia.",
      phraseSounds: [{ url: `/sounds/deck1Card5.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      gradingsCountHard: 2,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg"
    },
    {
      id: `card6`,
      language: "english",
      translatedPhrase: "What’s for breakfast today?",
      nativePhrase: "O que tem para o café da manhã hoje?",
      phraseSounds: [{ url: `/sounds/deck1Card6.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg",
      gradingsCountHard: 2
    },
    {
      id: `card7`,
      language: "english",
      translatedPhrase: "Do you want some water?",
      nativePhrase: "Você quer um pouco de água?",
      phraseSounds: [{ url: `/sounds/deck1Card7.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      gradingsCountHard: 2,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg"
    },
    {
      id: `card8`,
      language: "english",
      translatedPhrase: "I’m taking a shower and going to bed.",
      nativePhrase: "Eu vou tomar um banho e ir para a cama.",
      phraseSounds: [{ url: `/sounds/deck1Card8.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      gradingsCountHard: 2,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg"
    },
    {
      id: `card9`,
      language: "english",
      translatedPhrase: "I’m running a little late.",
      nativePhrase: "Eu estou um pouco atrasado.",
      phraseSounds: [{ url: `/sounds/deck1Card9.mp3`, durationSecs: "30", hasBeenListened: false }],
      isFrontSideActive: true,
      hasCardBeenStudied: true,
      gradingsCountEasy: 3,
      gradingsCountNormal: 4,
      imgSrc:
        "https://carneiro.s3-sa-east-1.amazonaws.com/public/assets/DeckPageCardPlaceholderImage.jpeg",
      gradingsCountHard: 2
    }
  ]
}

export const studySessionPageData = {
  deck1: {
    cardsCompleted: 0,
    cardCount: 12,
    cards: {
      card1: {
        cardForeignLabel: `Do they travel every weekend?`,
        cardNativeLabel: `Eles viajam todo o fim de semana?`,
        cardAudioSideAUrls: [`/sounds/deck1Card1.mp3`]
      },
      card2: {
        cardForeignLabel: `What have you been up to?`,
        cardNativeLabel: `O que você anda fazendo?`,
        cardAudioSideAUrls: [`/sounds/deck1Card2.mp3`]
      },
      card3: {
        cardForeignLabel: `Don’t take it to heart.`,
        cardNativeLabel: `Não leve para o lado pessoal`,
        cardAudioSideAUrls: [`/sounds/deck1Card3.mp3`]
      },
      card4: {
        cardForeignLabel: `Thank you in advance!`,
        cardNativeLabel: `Obrigado desde ja!`,
        cardAudioSideAUrls: [`/sounds/deck1Card4.mp3`]
      },
      card5: {
        cardForeignLabel: `Good morning.`,
        cardNativeLabel: `Bom dia.`,
        cardAudioSideAUrls: [`/sounds/deck1Card5.mp3`]
      },
      card6: {
        cardForeignLabel: `What’s for breakfast today? `,
        cardNativeLabel: `O que tem para o café da manhã hoje?`,
        cardAudioSideAUrls: [`/sounds/deck1Card6.mp3`]
      },
      card7: {
        cardForeignLabel: `Do you want some water?`,
        cardNativeLabel: `Você quer um pouco de água?`,
        cardAudioSideAUrls: [`/sounds/deck1Card7.mp3`]
      },
      card8: {
        cardForeignLabel: `I’m taking a shower and going to bed.`,
        cardNativeLabel: `Eu vou tomar um banho e ir para a cama.`,
        cardAudioSideAUrls: [`/sounds/deck1Card8.mp3`]
      },
      card9: {
        cardForeignLabel: `I’m running a little late.`,
        cardNativeLabel: `Eu estou um pouco atrasado.`,
        cardAudioSideAUrls: [`/sounds/deck1Card9.mp3`]
      },
      card10: {
        cardForeignLabel: `I didn’t sleep very well, I had a bad dream.`,
        cardNativeLabel: `Eu não dormi muito bem, eu tive um pesadelo.`,
        cardAudioSideAUrls: [`/sounds/deck1Card10.mp3`]
      },
      card11: {
        cardForeignLabel: `Could you set the table, please?`,
        cardNativeLabel: `Você poderia arrumar a mesa, por favor?`,
        cardAudioSideAUrls: [`/sounds/deck1Card11.mp3`]
      },
      card12: {
        cardForeignLabel: `I’ll be in my room.`,
        cardNativeLabel: `Eu estarei no meu quarto.`,
        cardAudioSideAUrls: [`/sounds/deck1Card12.mp3`]
      }
    }
  }
}
