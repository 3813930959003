import styled from "styled-components"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX, LAPTOP_SMALL_MAX, TABLET_SMALL_MAX } from "../../common/breakpoints"
import { navBarTransition } from "../../common/styles/navBar"
import { PageRoot } from "../../common/styles/page"
import LoadingSpinner from "../../legacyComponents/Desktop/Animations/LoadingSpinner"

export const StyledPageRoot = styled(PageRoot)`
  &[data-no-decks='true'] {
    background-color: #04294F;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -30px -34px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin: -30px -17px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: -21px -17px;
  }
`

export const CardCell = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 30px 34px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 30px 17px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 21px 17px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: ${TABLET_SMALL_MAX}px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const MasterDecksContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 109px;
  padding-left: 214px;
  padding-right: 214px;
  transition: padding-left ${navBarTransition}, padding-right ${navBarTransition};

  &[data-nav-expanded='true'] {
    padding-left: 94px;
    padding-right: 94px;

    @media (max-width: ${LAPTOP_MAX}px) {
      padding-left: 66px;
      padding-right: 66px;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-top: 56px;
    padding-bottom: 76px;
    padding-left: 150px;
    padding-right: 150px;
  }
`

export const UserDecksContainer = styled.div`
  padding-top: 108px;
  padding-bottom: 64px;
  padding-left: 214px;
  padding-right: 214px;
  background-color: #F4F5FA;
  transition: padding-left ${navBarTransition}, padding-right ${navBarTransition};

  &[data-nav-expanded='true'] {
    padding-left: 94px;
    padding-right: 94px;

    @media (max-width: ${LAPTOP_MAX}px) {
      padding-left: 66px;
      padding-right: 66px;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-top: 76px;
    padding-bottom: 45px;
    padding-left: 150px;
    padding-right: 150px;
  }
`

export const UserDecks = styled.div`
  margin: 0 -34px;
  padding: 0 34px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin: 0 -17px;
    padding: 0 17px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: 0 -17px;
    padding: 0 17px;
  }`

export const BlockHeader = styled.div`
  padding-left: 8px;
  margin-bottom: 24px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 16px;
  }
`

export const BlockTitle = styled.span`
  font-size: 24px;
  line-height: 24px;
  font-family: "IBM Plex Serif";
  font-weight: bold;
  letter-spacing: 0px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
    line-height: 16px;
  }
`

export const CardsCount = styled.span`
  margin-left: 10px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #C2C2C2;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-left: 7px;
    font-size: 12px;
    line-height: 12px;
  }
`

export const PageControlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 45px;
  }
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

export const Spinner = styled(LoadingSpinner)`
  stroke: rgb(0, 174, 224);
`
