import styled from "styled-components"
import BackArrowIcon from "../../../../assets/svgs/BackArrowIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../../common/breakpoints"

export const ButtonRow = styled.div`
  display: flex;
  margin: 52px 0 -8px 60px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: 22px 0 -8px 30px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    position: absolute;
    left: 16px;
    top: 24px;
    margin: 0;
    color: white;
    z-index: 1;
  }
`

export const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 24px;
  padding: 0 8px;
  color: #F24556;

  @media (max-width: ${MOBILE_MAX}px) {
    color: white;

    span {
      display: none;
    }
  }
`

export const ArrowIcon = styled(BackArrowIcon)`
  margin-right: 11px;
`