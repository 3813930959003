import { call, takeLatest } from "redux-saga/effects"

export function* cancelableWorker(
  cancelType,
  worker,
  action
) {
  if (action.type === cancelType) {
    return
  }
  return yield call(worker, action)
}

export const takeLatestCancelable = (
  actionCreator,
  cancelActionCreator,
  worker
) =>
  takeLatest(
    [actionCreator, cancelActionCreator],
    cancelableWorker,
    cancelActionCreator.toString(),
    worker
  )
