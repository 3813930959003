import styled from "styled-components"
import LoadingSpinner from "../../../legacyComponents/Desktop/Animations/LoadingSpinner"
import { LAPTOP_MAX, MOBILE_MAX } from "../../breakpoints"

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #00AEE0;
  color: #00AEE0;
  background-color: white;
  font-size: 18px;
  width: 380px;
  height: 70px;
  padding: 0 16px;
  border-radius: 35px;
  transition: background-color 200ms ease-out, color 200ms ease-out;

  &[data-primary='true'] {
    color: white;
    background-color: #00AEE0;
  }

  &[data-primary='true'][data-outlined='true'] {
    border-color: white;
  }

  &[data-loading='true'] {
    pointer-events: none;
  }

  &[data-disabled='true'] {
    background-color: #0080d5;
    color: rgba(255,255,255,0.5);
    border-color: rgba(255,255,255,0.5);
    pointer-events: none;
  }

  &[data-primary='true'][data-disabled='true'] {
    border-color: rgba(255,255,255,0.5);
  }

  &:hover {
    color: white;
    background-color: #00AEE0;
  }

  &[data-primary='true']:hover {
    background-color: #00C6FF;
  }

  &:active {
    opacity: 0.7;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 262px;
    height: 48px;
    border-radius: 24px;
    font-size: 12px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    width: 240px;
    height: 44px;
    border-radius: 22px;
  }
`

export const ButtonSpinner = styled(LoadingSpinner)`
  stroke: currentColor;
`