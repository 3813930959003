import { put, all, call, takeLatest, select } from "redux-saga/effects"
import queryString from "query-string"
import axios from "axios"
import { rest, endpoints, defaultHeaders } from "../../services/api"
import * as actions from "../actions/auth"
import { HackitAuthTokenKey, HackitTermsAcceptedKey, HackitUserKey, HackitPlayerReady } from "../constants"
import { localSave } from "../utils/localStorage"
import { takeLatestCancelable } from "../utils/sagas"
import { baseURL } from "../../services/api/common"
import {
  FBAnalyticsLoginFailure,
  FBAnalyticsSetUserData,
  FBAnalyticsSetUserId,
  FBAnalyticsRegistrationClicked,
  FBAnalyticsSignUp
} from "./../../firebase/logEvents"
import { pushTagRegistration } from "./../../googleTagManage"
import i18n, { Dictionary } from "./../../utils/i18n"

const encryptPhone = (phone) => {
  const str = phone.toString()

  return "*".repeat(str.length - 2) + str.slice(-2)
}

export function* userLogin({ payload }) {
  const { email, password, firstLogin } = payload

  try {
    const { token } = yield call(rest.post, endpoints.auth, { email, password }, {
      noAuth: true
    })
    const { user } = yield call(rest.get, endpoints.auth, {
      noAuth: true,
      headers: {
        ...defaultHeaders,
        "x-hackit-auth": token
      }
    })

    localSave(HackitAuthTokenKey, token)
    localSave(HackitUserKey, user)

    const paramsForFBAndGoogle = {
      userId: user.id,
      email: user.email
    }

    if (firstLogin) {
      FBAnalyticsRegistrationClicked()
      FBAnalyticsSetUserData(paramsForFBAndGoogle)
      pushTagRegistration(user.email)

      FBAnalyticsSignUp({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      })
    } else {
      FBAnalyticsSetUserData(paramsForFBAndGoogle)
      // pushTagLogin(paramsForFBAndGoogle)
    }
    yield put(actions.userLoginSuccess({ token, user, isUserCached: false }))
  } catch (error) {
    FBAnalyticsLoginFailure()
    yield put(actions.userLoginFailure(error))
  }
}

export function* registerUser({ payload: params }) {
  let { organizationId, ...formData } = params

  if (!organizationId) {
    organizationId = baseURL.includes("dev.") ? "5" : "3"
  }

  const api = endpoints.register(organizationId)

  try {
    const response = yield call([axios, axios.post],
      api,
      queryString.stringify(formData),
      {
        baseURL: baseURL,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )

    if (response.data === "form with error") {
      throw new Error(i18n.t(Dictionary.register.errorEmailRegistered))//("Email already registered")
    }

    if (response.data?.meta?.code === 400) {
      throw new Error(i18n.t(Dictionary.register.errorEmailRegistered))//("Email already registered")
    }

    yield put(actions.setLoginCredentials({ email: params.email, password: params.firstPassword }))
    yield put(actions.userRegisterSuccess())
  } catch (error) {
    yield put(actions.userRegisterFailure(error))
  }
}

export function* registerPhone({ payload }) {
  const { phoneNumber } = payload

  try {
    const email = yield select((state) => state.auth.loginCredentials?.email)

    if (!email) { throw new Error("Email not found") }

    const userPhoneEndpoint = endpoints.userPhone(email)
    const sendCodeEndpoint = endpoints.sendVerificationCode(email, phoneNumber)

    const createUserPhoneRes = yield call(rest.post, userPhoneEndpoint, { phoneNumber }, { noAuth: true })

    if (createUserPhoneRes?.meta.code === 400) {
      throw new Error(createUserPhoneRes.meta.errorMessage)
    }

    const sendCodeRes = yield call(rest.post, sendCodeEndpoint, null, { noAuth: true })

    if (sendCodeRes?.meta.code === 404) {
      throw new Error(sendCodeRes.data?.meta.errorMessage)
    }
    yield put(actions.verifyUserPhoneSetEncryptedNumber(encryptPhone(phoneNumber)))
    yield put(actions.userRegisterPhoneSuccess())
  } catch (error) {
    yield put(actions.userRegisterPhoneFailure(error))
  }
}

export function* verifyPhone({ payload }) {
  const { code } = payload

  try {
    const email = yield select((state) => state.auth.loginCredentials?.email)

    if (!email) { throw new Error("Email not found") }

    const verifyPhoneEndpoint = endpoints.verifyPhone(email, code)

    const res = yield call(rest.post, verifyPhoneEndpoint, null, { noAuth: true })

    if (res?.meta?.code === 400) {
      throw new Error(res.meta.errorMessage)
    }

    yield put(actions.verifyUserPhoneSuccess())
  } catch (error) {
    yield put(actions.verifyUserPhoneFailure(error))
  }
}


export function* recoverPassword({ payload: { email } }) {
  try {
    yield call(rest.post, endpoints.recover(email), null, { noAuth: true })
    yield put(actions.recoverPasswordSuccess())
  } catch (error) {
    yield put(actions.recoverPasswordFailure(error))
  }
}

export function* fetchCurrentUser() {
  try {
    const { user } = yield call(rest.get, endpoints.auth)

    localSave(HackitUserKey, user)
    FBAnalyticsSetUserId(user.id)
    yield put(actions.loadUserSuccess(user))
  } catch (error) {
    yield put(actions.loadUserFailure(error))
  }
}

function onTermsAccepted() {
  localStorage.setItem(HackitTermsAcceptedKey, "true")
}

function onLogout() {
  localStorage.removeItem(HackitAuthTokenKey)
  localStorage.removeItem(HackitUserKey)
  localStorage.removeItem(HackitTermsAcceptedKey)
  localStorage.removeItem(HackitPlayerReady)
}

export default function* () {
  yield all([
    takeLatestCancelable(actions.userLoginRequest, actions.userLoginReset, userLogin),
    takeLatestCancelable(actions.userRegisterRequest, actions.userRegisterReset, registerUser),
    takeLatestCancelable(actions.userRegisterPhoneRequest, actions.userRegisterPhoneReset, registerPhone),
    takeLatestCancelable(actions.verifyUserPhoneRequest, actions.verifyUserPhoneReset, verifyPhone),
    takeLatestCancelable(actions.recoverPasswordRequest, actions.recoverPasswordReset, recoverPassword),
    takeLatest(actions.loadUserRequest, fetchCurrentUser),
    takeLatest(actions.setTermsAccepted, onTermsAccepted),
    takeLatest(actions.userLogout, onLogout)
  ])
}