import styled, { css } from "styled-components"
import ForwardArrowIcon from "../../../assets/svgs/ForwardArrowIcon"
import LoginLogo from "../../../assets/svgs/LoginLogo"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { FormInnerContainer } from "../Common/styles"
import CheckboxField from "./CheckboxField"

export const StyledInnerContainer = styled(FormInnerContainer)`
  padding: 55px 72px; 

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 38px 50px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    padding: 70px 24px 21px 24px;
  }
`

export const Logo = styled(LoginLogo)`
  font-size: 52px;
  color: #F33660;
  margin-bottom: 8px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 36px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`

export const Title = styled.div`
  font-family: BlackerDisplayHeavy;
  font-size: 60px;
  line-height: 1;
  letter-spacing: 0px;
  color: #F33660;
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 41px;
    margin-bottom: 20px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    color: white;
    font-size: 48px;
    text-align: left;
    margin-bottom: 28px;
  }
`

const TextStyle = css`
  font-style: italic;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0px;
  color: #4B4B4B;
  width: 100%;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 16px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
    line-height: 18px;
    color: white;
    opacity: 0.85;
  }
`

export const TermsParagraph = styled.div`
  ${TextStyle}
  margin-bottom: 25px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 17px;
  }
`

export const TermsLinkContainer = styled.div`
  margin-bottom: 37px;
  width: 100%;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 25px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    margin-bottom: 39px;
  }
`

export const TermsLink = styled.a`
  ${TextStyle}
  text-decoration: underline;
`

export const AlertTermsLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  text-transform: lowercase;
`

export const BottomRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContinueButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border: 2px solid #00AEE0;
  border-radius: 35px;
  color: white;
  background-color: #00AEE0;
  width: 275px;
  height: 70px;
  font-size: 18px;
  font-weight: 600;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;

  &:hover {
    background-color: #00C6FF;
    border-color: #00C6FF;
  }

  &[data-disabled='true'] {
    color: #00AEE07A;
    background-color: transparent;
    border-color: #00AEE030;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 20px;
    width: 188px;
    height: 48px;
    border-radius: 24px;
    font-size: 12px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    && {
      width: 162px;
      height: 44px;
      border-color: white;
      color: white;
      background-color: #00AEE0;
      font-size: 10px;
    }
  }
`

export const RightArrow = styled(ForwardArrowIcon)`
  font-size: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
  }
`

export const StyledCheckboxField = styled(CheckboxField)`
  @media (max-width: ${MOBILE_MAX}px) {
    color: white;
    font-size: 10px;
    line-height: 22px;

    .checkbox__check-icon {
      font-size: 18px;
      margin-right: 7px;
    }
  }
`
