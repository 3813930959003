import styled from "styled-components"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import Button from "../../../common/components/Button"

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 250ms ease-out;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &[data-show='true'] {
    opacity: 1;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    align-items: flex-end;
    padding: 0 20px 20px 20px;
  }
`

export const AlertContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 460px;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  transition: transform 250ms ease-out;
  transform: scale(0.7, 0.7);

  ${Overlay}[data-show='true'] & {
    transform: none;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 322px;
    padding: 28px;
    border-radius: 14px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    width: auto;
    padding: 24px 22px 22px 25px;
    border-radius: 10px;
  }
`

export const Title = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-size: 28px;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 8px;
  color: #F24556;
  padding: 0 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 6px;
    padding: 0 11px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 6px;
    padding: 0 3px 0 0;
  }
`

export const Message = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #878787;
  padding: 0 16px;
  margin-bottom: 32px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 14px;
    padding: 0 11px;
    margin-bottom: 22px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 13px;
    line-height: 16px;
    padding: 0 3px 0 0;
    margin-bottom: 28px;
  }
`

export const ButtonsContainer = styled.div`
  @media (max-width: ${MOBILE_MAX}px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`

export const EndButton = styled(Button).attrs({
  primary: true
})`
  display: block;
  width: 100%;
  margin-bottom: 26px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 18px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: -2px;
    margin-bottom: -2px;
    width: auto;
    flex: 1;
  }

  && {
    background-color: #F24556;
  }

  &&:hover {
    background-color: #FF4A5C;
  }
`

export const CancelButtonRow = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${MOBILE_MAX}px) {
    padding: 0 25px 0 3px;
  }
`

export const CancelButton = styled.button`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #00AEE0;
  padding: 10px;
  margin: -10px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 14px;
  margin: -15px;
  color: #00AEE0;

  @media (max-width: ${LAPTOP_MAX}px) {
    top: 17px;
    right: 20px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`
