export const Grade = {
  Easy: 3,
  Good: 2,
  Difficult: 1,
  Repeat: 0
}

export const GradeStatus = {
  Pending: "pending",
  Succeeded: "succeeded"
}

export const DeckType = {
  merge: "merge",
  deck: "deck"
}

export const links = {
  accessCourse: "https://cursos.fluencyacademy.io",
  support: "https://suporte-bot-memhack.webflow.io",
  termsOfUse: "https://go.fluencyacademy.io/termosprivacidade2021",//"https://terms.hackit.app",
  fluencyTVAcademy: "https://www.fluencytv.com/fluency-academy"
}

export const GTM_dataLayerName = 'dataLayer';