import { put, all, call, takeLatest } from "redux-saga/effects"
import { rest, endpoints } from "../../services/api"

import * as actions from "../actions/referral"

export function* subscribe({ payload: { params, userId } }) {
  try {
    put(actions.closeReferral())
    const {deckId, unitName, unitThumbUrl, mergeName, mergeThumbUrl} = params

    let result = yield call(
        rest.post,
        endpoints.subscribeToDeck(deckId, unitName, unitThumbUrl, mergeName, mergeThumbUrl, userId)
    )

    if (JSON.parse(result).status === 400) {
      yield put(actions.subscribeFailure(new Error("Esse deck já foi adicionado")))
    } else {
      yield put(actions.subscribeSuccess(null))
    }
  } catch (error) {
    yield put(actions.subscribeFailure(new Error("Deck subscription failure")))
  }
}

export default function* () {
  yield all([takeLatest(actions.subscribe, subscribe)])
}