import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import {
  FIREBASE_CONFIG
  // ,
  // firebaseConfigTest
} from "./config";

// Initialize Firebase
// const firebase = initializeApp(firebaseConfigTest);
const firebase = initializeApp(FIREBASE_CONFIG);
export const analytics = getAnalytics(firebase);

export default firebase

