/**
 * * msToMS
 * converts miliseconds => 00:00(MS)
 * @function msToMS(@param miliseconds)
 * @param miliseconds defines miliseconds to convert
 */
export const msToMS = (miliseconds) => {
  // 1- Convert to seconds:
  let seconds = miliseconds / 1000
  // 2- Extract hours:
  seconds = seconds % 3600 // seconds remaining after extracting hours
  // 3- Extract minutes:
  let minutes = parseInt(seconds / 60) // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60
  if (minutes < 10) {
    minutes = `0` + minutes
  }
  if (seconds < 10) {
    seconds = `0` + seconds
  }
  return minutes + `:` + seconds
}
