import React, { memo } from "react"

const EyeIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 28.229 19.247"
    {...props}
  >
    <g transform="translate(0 -74.667)">
      <g transform="translate(0 74.667)">
        <g transform="translate(0 0)">
          <path fill="currentColor" d="M174.516,170.667a3.849,3.849,0,1,0,3.849,3.849A3.852,3.852,0,0,0,174.516,170.667Z" transform="translate(-160.402 -164.893)"/>
          <path fill="currentColor" d="M14.114,74.667A15.175,15.175,0,0,0,0,84.291a15.162,15.162,0,0,0,28.229,0A15.169,15.169,0,0,0,14.114,74.667Zm0,16.039a6.416,6.416,0,1,1,6.416-6.416A6.418,6.418,0,0,1,14.114,90.706Z" transform="translate(0 -74.667)"/>
        </g>
      </g>
    </g>
  </svg>
)

export default memo(EyeIcon)