import styled, { createGlobalStyle, css } from "styled-components"
import Button from "../../../common/components/Button"
import noDecksBg from "../images/no-decks.png"

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    overflow: visible;
  }

  body {
    background-color: #FD4A69;
  }
`

const TitleText = css`
  font-family: "BlackerDisplayExtraBold";
  font-weight: bold;
  color: white;
  font-size: 56px;
  line-height: 1;
  padding: 24px 40px 0 40px;
`

export const Title = styled.div`
  ${TitleText}
  margin-bottom: 8px;
`

export const DropdownRow = styled.div`
  display: flex;
  padding: 0 40px;
  margin-bottom: 2px;

  > :not(:last-child) {
    margin-right: 19px;
  }
`

export const LayoutBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  visibility: hidden;
`

export const ItemBox = styled.div`
  max-width: calc(100vmin - 70px);
  padding-bottom: 20px;
`

export const CardBox = styled.div`
  position: relative;
`

export const CardBoxHeight = styled.div`
  padding-bottom: 80%;
`

export const ItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardsContainer = styled.div`
  > * {
    margin: 0 auto;
    outline: none;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  svg {
    stroke: white;
  }
`

export const NoDecksTitle = styled.div`
  ${TitleText}
  margin-bottom: 10px;
`

export const NoDecksNotice = styled.div`
  color: white;
  opacity: 0.65;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  padding: 0 40px 13px 40px; 
`

export const NoDecksImageCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

export const NoDecksImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 377px;
  height: 377px;
  box-sizing: border-box;
  padding-top: 189px;
  background: center / contain url("${noDecksBg}") no-repeat;
`
export const ButtonFluencyTV = styled(Button)`
  background-color: #6b47ed;
`