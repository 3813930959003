import React, { memo } from "react"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";
import {
  Container,
  LeftSide,
  RightSide,
  OopsImage,
  FlagContainer,
  StyledFlag,
  Title,
  Description,
  StatsContainer,
  StatsValueContainer,
  StyledTotalCardsIcon,
  ValueContainer,
  ValueTitle,
  Value,
  StyledOverdueCardsIcon,
  ContinueButton,
  StyledPlayIcon,
  LeftSideContent,
  RightSideContent
} from "./styles"

const TopCard = ({ deck, caption, onProceedAction }) => {
  const {t} = useTranslation();

  return (
    <Container>
      <LeftSide src={deck.image} fallback={<OopsImage />}>
        <LeftSideContent>
          <FlagContainer>
            <StyledFlag language={deck.language} />
          </FlagContainer>
          <Title>{deck.title}</Title>
          <Description>{deck.description}</Description>
        </LeftSideContent>
      </LeftSide>
      <RightSide>
        <RightSideContent>
          <StatsContainer>
            <StatsValueContainer>
              <StyledTotalCardsIcon />
              <ValueContainer>
                <ValueTitle>{t(Dictionary.deck.totalCardInfo)}</ValueTitle>
                <Value>{deck.totalCount}</Value>
              </ValueContainer>
            </StatsValueContainer>
            <StatsValueContainer>
              <StyledOverdueCardsIcon />
              <ValueContainer>
                <ValueTitle>{t(Dictionary.deck.remainingCardInfo)}</ValueTitle>
                <Value>{deck.overdueCount}</Value>
              </ValueContainer>
            </StatsValueContainer>
          </StatsContainer>
          <ContinueButton primary outlined onClick={onProceedAction} disabled={!deck.overdueCount}>
            <span>{caption}</span>
            <StyledPlayIcon />
          </ContinueButton>
        </RightSideContent>
      </RightSide>
    </Container>
  )
}

export default memo(TopCard)
