import React, { memo } from "react"

const IdealIcon = (props) => (
  <svg height="1em" viewBox="0 0 30 35.07" {...props}>
    <rect x="10" width="10" height="3.35" fill="#fff" />
    <path
      d="M26.67,20v.05A11.68,11.68,0,0,1,15,31.81h0A11.72,11.72,0,1,1,26.67,20Z"
      transform="translate(0 0)"
      fill="#00aee0"
    />
    <path
      d="M26.72,10.7l2.36-2.38A18.71,18.71,0,0,0,26.73,6L24.37,8.34a14.94,14.94,0,0,0-20.83,2,2.75,2.75,0,0,0-.22.26,15,15,0,1,0,23.4.1ZM15,31.81h0A11.72,11.72,0,1,1,26.67,20v.05A11.68,11.68,0,0,1,15,31.81Z"
      transform="translate(0 0)"
      fill="#fff"
    />
  </svg>
)

export default memo(IdealIcon)
