import React, { memo } from "react"

const BackArrowIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M16 7H3.8L9.4 1.4L8 0L0 8L8 16L9.4 14.6L3.8 9H16V7Z" fill="currentColor"/>
  </svg>
)

export default memo(BackArrowIcon)
