import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Svg from "../Svgs/Svg"

import { appIcons } from "../../../assets/svgs/icons"
import { appPaths } from "../../../assets/paths/images"
import { rotate360Keyframes } from "../../../assets/animations/keyframes"

const LoadingSpinnerWrapper = styled(Svg)`
  overflow: visible;
  animation-name: ${rotate360Keyframes};
  animation-duration: 3900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  top: 1px;
`

export default class LoadingSpinner extends React.Component {
  static propTypes = {
    /** defines loading spinners styles and dimensions*/
    className: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string
  }

  render = () => {
    return (
      <LoadingSpinnerWrapper
        className={this.props.className}
        width={this.props.width || appIcons.loadingButtonSpinnerWidth}
        height={this.props.height || appIcons.loadingButtonSpinnerHeight}
        viewBox={this.props.viewBox || appIcons.loadingButtonSpinnerViewBox}
      >
        <g transform="translate(-1.5 -2)">{appPaths.spinner}</g>
      </LoadingSpinnerWrapper>
    )
  }
}
