import { put, all, call, takeLatest, select } from "redux-saga/effects"
import { rest, endpoints } from "../../services/api"
import * as actions from "../actions/decks"
import * as selectors from "../selectors/decks"
import { currentUserId as currentUserIdSelector } from "../selectors/auth"
import { takeLatestCancelable } from "../utils/sagas"

const sortCardsElements = (cards) => {
  cards.forEach((card) => {
    if (card.notes[0]?.note?.order !== undefined) {
      card.notes.sort((x, y) => x.note.order - y.note.order)
    }
  })
}

export function* fetchMasterDecks() {
  const userId = yield select(currentUserIdSelector)

  try {
    const masterDecks = yield call(rest.get, endpoints.masterDecks(userId))
    yield put(actions.loadMasterDecksSuccess(masterDecks))
  } catch (error) {
    yield put(actions.loadMasterDecksFailure(error))
  }
}

export function* fetchUserDecks() {
  const userId = yield select(currentUserIdSelector)

  try {
    const userDecks = yield call(rest.get, endpoints.userDecks(userId))

    yield put(actions.loadUserDecksSuccess(userDecks))
  } catch (error) {
    yield put(actions.loadUserDecksFailure(error))
  }
}

export function* resolvePhysicalDeckId(virtualId) {
  let virtualToPhysicalDeckId = yield select(selectors.virtualToPhysicalDeckId)

  if (!virtualToPhysicalDeckId) {
    const userId = yield select(currentUserIdSelector)

    const [userDecks] = yield all([
      call(rest.get, endpoints.userDecks(userId))
    ])
    yield put(actions.loadUserDecksSuccess(userDecks))

    virtualToPhysicalDeckId = yield select(selectors.virtualToPhysicalDeckId)
  }

  const deckId = virtualToPhysicalDeckId[virtualId]

  if (!deckId) {
    throw new Error("Unknown deck id")
  }

  return deckId
}

export function* fetchDeckCards({ payload: { virtualId, page, pageSize } }) {
  try {
    const deckId = yield call(resolvePhysicalDeckId, virtualId)

    const [cards, deck, meta] = yield all([
      call(rest.get, endpoints.deckCards(deckId), { params: {
        page,
        items: pageSize
      } }),
      call(rest.get, endpoints.deck(deckId)),
      call(rest.get, endpoints.deckMeta(deckId))
    ])

    sortCardsElements(cards)

    yield put(actions.loadDeckWithCardsSuccess({
      virtualId,
      deck: { ...deck, cards, meta },
      page,
      pageSize
    }))
  } catch (error) {
    yield put(actions.loadDeckWithCardsFailure(error))
  }
}

export function* fetchDeckReferralCards({ payload: { virtualId, page, pageSize } }) {
  try {
    const [deck] = yield all([ call(rest.get, endpoints.deckReferral(virtualId)) ]);

    const deckId = deck.deck.id;

    const [cards, meta] = yield all([
      call(rest.get, endpoints.deckCards(deckId), { params: {
        page,
        items: pageSize
      } }),
      call(rest.get, endpoints.deckMeta(deckId))
    ])

    sortCardsElements(cards)
    const params = {
      virtualId,
      deck: { ...deck, cards, meta },
      page,
      pageSize
    }

    yield put(actions.loadDeckReferralWithCardsSuccess(params))
  } catch (error) {
    yield put(actions.loadDeckReferralWithCardsFailure(error))
  }
}

export function* fetchDeckPage({ payload: { virtualId, page, pageSize, isReferral = false } }) {
  const virtualDecks = isReferral ? selectors.virtualToDeckWithCardsById : selectors.virtualToPhysicalDeckId;
  const virtualToPhysicalDeckId = yield select(virtualDecks)

  try {
    let deckId = virtualToPhysicalDeckId[virtualId]

    if(isReferral && typeof deckId === 'object') {
      deckId = deckId.deck.id
    }

    if (!deckId) {
      yield put(actions.loadDeckPageFailure(new Error("Unknown deck id")))
      return
    }

    const cards = yield call(rest.get, endpoints.deckCards(deckId), { params: {
      page,
      items: pageSize
    } })

    sortCardsElements(cards)

    yield put(actions.loadDeckPageSuccess({ virtualId, cards }))
  } catch (error) {
    yield put(actions.loadDeckPageFailure(error))
  }
}

export function* fetchMergeCards({ payload: { mergeId, page, pageSize } }) {
  const userId = yield select(currentUserIdSelector)

  try {
    const [merge, cards] = yield all([
      call(rest.get, endpoints.merge(userId, mergeId)),
      call(rest.get, endpoints.mergeCards(userId, mergeId), { params: {
        page,
        items: pageSize
      } })
    ])

    sortCardsElements(cards)

    yield put(actions.loadMergeWithCardsSuccess({
      mergeId,
      merge: { ...merge, cards },
      page,
      pageSize
    }))
  } catch (error) {
    yield put(actions.loadMergeWithCardsFailure(error))
  }
}

export function* fetchMergePage({ payload: { mergeId, page, pageSize } }) {
  const userId = yield select(currentUserIdSelector)

  try {
    const cards = yield call(rest.get, endpoints.mergeCards(userId, mergeId), {
      params: {
        page,
        items: pageSize
      }
    })

    sortCardsElements(cards)

    yield put(actions.loadMergePageSuccess({ mergeId, cards }))
  } catch (error) {
    yield put(actions.loadMergePageFailure(error))
  }
}

export default function* () {
  yield all([
    takeLatest(actions.loadMasterDecksRequest, fetchMasterDecks),
    takeLatest(actions.loadUserDecksRequest, fetchUserDecks),
    takeLatestCancelable(actions.loadDeckWithCardsRequest, actions.viewDeckCardsReset, fetchDeckCards),
    takeLatestCancelable(actions.loadDeckReferralWithCardsRequest, actions.viewDeckCardsReset, fetchDeckReferralCards),
    takeLatestCancelable(actions.loadMergeWithCardsRequest, actions.viewDeckCardsReset, fetchMergeCards),
    takeLatestCancelable(actions.loadDeckPageRequest, actions.viewDeckCardsReset, fetchDeckPage),
    takeLatestCancelable(actions.loadMergePageRequest, actions.viewDeckCardsReset, fetchMergePage),
    // takeLatestCancelable(actions.loadDeckReferralPageRequest, actions.viewDeckCardsReset, fetchDeckReferralPage),
  ])
}