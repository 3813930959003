import { createGlobalStyle } from "styled-components"
import { fadeInOpacityKeyframes, quickTiming } from "../animations/keyframes"
import { screen } from "./screen"

/* App initially developed for 1366px-768px */

const baseFontSize = `1em`
const baseLineHeight = `1.5`

export default createGlobalStyle`

/* Base styles */
.memhackFox {
  transform: scale(0.7) translate(0.5rem, 0.3rem);
}
button {
font-family: 'Fira Sans', sans-serif;
}
html,
 body,
 #root {


  font-family: 'Fira Sans', sans-serif;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  scrollbar-width: none;
  overflow: hidden;
  font-size: ${baseFontSize};
  line-height: ${baseLineHeight};
  --memhack-theme-color-blue: rgba(0, 174, 224, 1);
  --memhack-theme-color-pink: rgba(249, 55, 121, 1);
  --memhack-theme-gradient-pink: transparent linear-gradient(180deg, rgba(255, 83, 99, 1) 0%, rgba(249, 55, 121, 1) 100%) 0% 0% no-repeat padding-box;
  --memhack-theme-gradient-success: linear-gradient(180deg, rgb(27, 212, 111) 0%, rgb(70, 176, 118) 100%);
  --darken-bg-img-gradient: linear-gradient(180deg, rgba(0, 0, 0.1) 0%, rgba(0, 0, 0, 0.15) 100%);
  @media ${screen.tablet} {
    font-size: ${baseFontSize}*1.2;
    line-height: ${baseLineHeight}*1.2;
  }
  @media ${screen.small} {
    font-size: ${baseFontSize}*1.3;
  }
  @media ${screen.medium} {
    font-size: ${baseFontSize}*1.3;
  }
  @media ${screen.wide} {
    font-size: ${baseFontSize}*1.4;
  }
  @media ${screen.big} {
    font-size: ${baseFontSize}*1.6;
  }
}
/* Mobile */

/* Utility classes */
.hidden {
display: none;
}
.visisble {
display: initial;
}
.lightBoxShadow {
  box-shadow: 0px 6px 16px rgb(159 159 159 / 9%);

}

/* THEME STYLES */

/* Tablet and up themes */
@media ${screen.tablet} {
  .tabletUpThemeFill {
  fill: rgb(242, 69, 86);
  path {
    fill: rgb(242, 69, 86);
  }
}
.tabletUpSuccessThemeFill {
  fill: rgb(120, 215, 111);
  path {
    fill: rgb(120, 215, 111);
  }

}
.tabletUpErrorThemeFill {
  fill: rgb(255, 90, 91);
  path {
    fill: rgb(255, 90, 91);
}
}
}


/** Default transition / animations */
svg * {
    transition: fill ${quickTiming} ease-in-out, opacity ${quickTiming} ease-in-out;
}
* {
  transition: opacity ${quickTiming} ease-in;
}

/* Custom animations */
.quickFadeIn {
  animation-name: ${fadeInOpacityKeyframes};
    animation-duration: ${quickTiming};
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-direction: normal;
    }



/* Default hover effects */

/* CSS RESET */
p {
margin: 0;}
ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Prevent modern browsers from displaying "audio" without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body "font-size" is set using
 *    "em" units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  scroll-behavior: smooth;
  font-size: 16px; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * 1. Remove border when inside "a" element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  vertical-align: middle;
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75rem;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  overflow: visible; /* 1 */
  vertical-align: top; /* 2 */
}
/* Remove default input highlight colors */
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
/**
 * Address Firefox 3+ setting "line-height" on "input" using "!important" in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent "text-transform" inheritance for "button" and "select".
 * All other form control elements do not inherit "text-transform" values.
 * Correct "button" style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct "select" style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native "audio"
 *    and "video" controls.
 * 2. Correct inability to style clickable "input" types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    "input" and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

/* reset button styles */
button {
  border: none;
  outline: none;
  background-color: transparent;
}

/* Make Firefox not display the dotted focus outlines */
button::-moz-focus-inner {
  border: 0;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input:focus {
  outline: none !important;
}

/* Change bg to white in autofill textbox */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/**
 * 1. Address "appearance" set to "searchfield" in Safari 5 and Chrome.
 * 2. Address "box-sizing" set to "border-box" in Safari 5 and Chrome
 *    (include "-moz" to future-proof).
 */
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Address "outline" inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

`
