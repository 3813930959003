import React, { memo, useCallback } from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import CloseIcon from "../../assets/svgs/CloseIcon"
import { DeckType } from "../../constants"
import * as selectors from "../../store/selectors/sessions"
import { routes } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";
import { GlobalStyle, TopContainer, TopRow, HackitLogo, CloseButton, Slogan, DeckName } from "./styles"

const StudySessionStatsPageMobile = () => {
  const history = useHistory()
  const {t} = useTranslation();
  const match = useRouteMatch()
  const type = match.params.mergeId ? DeckType.merge : DeckType.deck
  const id = match.params.mergeId ?? match.params.deckId

  const deckName = useSelector((state) => selectors.sessionName(state, type, id)) ?? ""

  const handleClose = useCallback(() => {
    const baseRoute = type === DeckType.merge ? routes.mergePage : routes.deckPage
    history.replace(`${baseRoute}/${id}`)
  }, [history, type, id])

  return (
    <>
      <GlobalStyle />
      <TopContainer>
        <TopRow>
          <HackitLogo />
          <CloseButton onClick={handleClose}><CloseIcon /></CloseButton>
        </TopRow>
        <Slogan>
          {t(Dictionary.finishedStudySession.mobileYeah)}<br />{t(Dictionary.finishedStudySession.mobileText)}
        </Slogan>
        <DeckName>
          {deckName}
        </DeckName>
      </TopContainer>
    </>
  )
}

export default memo(StudySessionStatsPageMobile)
