import React, { memo } from "react"
import styled from "styled-components"
import { StudyMode } from "../../../store/selectors/common"

const StyledSvg = styled.svg`
  .st0 { fill: #0B4772; }
	.st1 { fill: #FEBC21; }
	.st2 { clip-path: url(#SVGID_2_); }
	.st3 { fill: #EF4174; }
`

const LiquidPath = styled.path`
  transition: transform 0.25s ease-out;

  &[data-mode='${StudyMode.Short}'] {
    transform: translateY(47px);
  }

  &[data-mode='${StudyMode.Fair}'] {
    transform: translateY(10px);
  }

  &[data-mode='${StudyMode.Medium}'] {
    transform: translateY(20px);
  }
`

const HourglassIcon = ({ mode, ...props }) => (
  <StyledSvg height="1em" viewBox="0 0 40.6 76.4" {...props}>
    <g id="bottom">
      <path id="end-top" className="st0" d="M6.5,0h27.6c3.6,0,6.5,2.9,6.5,6.5l0,0l0,0C41.2,28.7-0.1,25.6,0,7C0.1,3.4,2.9,0.1,6.5,0z"/>
      <path
        id="end-top-2" className="st0" d="M34.1,76.4H6.5c-3.6,0-6.5-2.9-6.5-6.5l0,0l0,0c-0.3-9.9,40.6-4.7,40.6-0.7
		C40.6,72.8,37.7,76.4,34.1,76.4z"
      />
      <path
        className="st1" d="M40.6,69.2L40.6,69.2v-0.9l0,0V54.6c-0.1-3-1.7-5.7-4.3-7.2l-13.4-8V37l13.4-8c2.6-1.5,4.2-4.2,4.3-7.2V7
		c0-1.7-1.3-3-3-3H3C1.3,4,0,5.3,0,7v0.1l0,0V8l0,0v13.7c0,3,1.7,5.7,4.3,7.2l13.4,8v2.4l-13.3,8c-2.6,1.5-4.2,4.2-4.3,7.2v14.7
		c0,1.7,1.3,3,3,3l0,0h34.5C39.3,72.2,40.6,70.9,40.6,69.2z"
      />
    </g>
    <g>
      <g>
        <defs>
          <path
            id="SVGID_1_" d="M40.6,69.5L40.6,69.5v-0.9l0,0V54.8c-0.1-3-1.5-5.8-4.1-7.3l-13.6-8.1v-2.3l13.4-8
				c2.6-1.5,4.2-4.2,4.3-7.2V7c0-1.7-1.3-3-3-3H2.9c-1.7,0-3,1.3-3,3v0.1l0,0L0,20.5c-0.2,4.5,1.4,6.6,4.3,8.4L17.6,37v2.4L4.8,47
				c-3,1.7-4.7,4.7-4.8,7.7v14.9c0,1.6,1.3,2.8,2.9,2.8H3h34.8c1.5,0.1,2.8-1.1,2.8-2.6C40.6,69.7,40.6,69.6,40.6,69.5z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }}/>
        </clipPath>
        <g className="st2">
          <LiquidPath
            className="st3"
            data-mode={mode}
            d="M40.6,13.4c0-2.4-3.2-4.1-6.4-3.7l-3.5,0.4c-4.6,0.6-10.3-4.2-12.1,1.4c-1.7,4.8-7,7.3-11.8,5.6
				c-0.1,0-0.1,0-0.2-0.1c-3-1-6.6,1.5-6.7,3.9v40.2c-0.1,0.4-0.1,0.8-0.1,1.2v7.3c0,0.4,0,0.8,0.1,1.2v1.6h0.5
				c1,2.1,3.1,3.4,5.4,3.5h28.7c2.3,0,4.4-1.4,5.4-3.5h0.7V13.4z"
          />
        </g>
      </g>
    </g>
  </StyledSvg>
)

export default memo(HourglassIcon)
