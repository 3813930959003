import React, { memo } from "react"

const PasswordInputIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 17.678 22.981"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="rgb(255 83 99)" />
        <stop offset="1" stopColor="#f93779" />
      </linearGradient>
    </defs>
    <path
      id="Senha"
      d="M15.91,7.071a7.071,7.071,0,1,0-14.142,0A1.768,1.768,0,0,0,0,8.839V21.213a1.768,1.768,0,0,0,1.768,1.768H15.91a1.768,1.768,0,0,0,1.768-1.768V8.839A1.768,1.768,0,0,0,15.91,7.071ZM8.839,15.91a1.768,1.768,0,1,1,1.768-1.768A1.768,1.768,0,0,1,8.839,15.91ZM5.3,7.071a3.536,3.536,0,1,1,7.071,0Z"
      fill="url(#linear-gradient)"
    />
  </svg>
)

export default memo(PasswordInputIcon)
