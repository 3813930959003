import React, { memo, useCallback, useEffect } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { ContentContainer, FormContainer, FormInnerContainer, PageContainer } from "../Common/styles"
import ForgotPasswordForm from "./ForgotPasswordForm"
import BackButton from "../Common/BackButton"
import { routes } from "../../../utils/constants"
import sidebarCover from "../../../assets/graphics/login/recover-sidebar-cover-2x.png"
import { StyledEntrancePage } from "./styles"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../common/breakpoints"
import i18n,{ Dictionary } from "../../../utils/i18n"

const initialValues = {
  email: ""
}

const schema = yup.object().shape({
  email: yup.string().email().required()
})

const ForgotPasswordPage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    succeeded, error, pending
  } = useSelector(authSelectors.recoverPasswordState)

  useEffect(() => () => {
    dispatch(authActions.recoverPasswordReset())
  }, [dispatch])

  useEffect(() => {
    if (!succeeded) {
      return undefined
    }

    if (isMobile) {
      history.push(routes.forgotComplete)
      return
    }

    const timeoutId = setTimeout(() => {
      history.push(routes.login)
    }, 2400)

    return () => { clearTimeout(timeoutId) }
  }, [history, succeeded, isMobile])

  const handleSubmit = useCallback((values) => {
    dispatch(authActions.recoverPasswordRequest({ email: values.email }))
  }, [dispatch])

  return (
    <StyledEntrancePage slogan={i18n.t(Dictionary.forgotPassword.sidebar)} coverImage={sidebarCover}>
      <PageContainer>
        <BackButton backUrl={routes.login} />
        <ContentContainer>
          <FormContainer>
            <FormInnerContainer>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                <ForgotPasswordForm pending={pending} error={error} succeeded={succeeded} />
              </Formik>
            </FormInnerContainer>
          </FormContainer>
        </ContentContainer>
      </PageContainer>
    </StyledEntrancePage>
  )
}

export default memo(ForgotPasswordPage)
