import styled from "styled-components"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowButton = styled.button.attrs({
  className: "page-control__arrow-button"
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 15px;
  color: #00AEE0;

  &[disabled] {
    color: #BABABA;
    opacity: 0.46;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 22px;
    height: 22px;
    font-size: 11px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    color: white;
    width: 32px;
    height: 32px;
    font-size: 15px;
    padding: 0;
  }
`

export const NumbersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;

  > :not(:last-child) {
    margin-right: 16px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-right: 11px;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 22px;
  }
`

export const PageCircle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
  color: black;
  padding: 0;
  min-width: 32px;
  height: 32px;
  padding: 0 8px;
  border-radius: 16px;
  user-select: none;

  &[data-selected='true'] {
    background: linear-gradient(180deg, #FF5363 0%, #F93779 100%);
    color: white;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 11px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    color: white;
    font-size: 14px;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 11px;

    &[data-selected='true'] {
      background: #00AEE0;
    }
  }
`

export const Ellipsis = styled.div`
  color: #BABABA;
  opacity: 0.46;
  font-size: 20px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
  }
`
