import styled, { css } from "styled-components"
import CheckedBoxIcon from "../../../assets/svgs/CheckedBoxIcon"
import UncheckedBoxIcon from "../../../assets/svgs/UncheckedBoxIcon"
import { LAPTOP_MAX } from "../../breakpoints"

export const Container = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #00AEE0;
  cursor: pointer;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 16px;
  }
`

const IconStyle = css`
  font-size: 16px;
  margin-right: 12px;
`

export const CheckedIcon = styled(CheckedBoxIcon).attrs({
  className: "checkbox__check-icon"
})`
  ${IconStyle}
`

export const UncheckedIcon = styled(UncheckedBoxIcon).attrs({
  className: "checkbox__check-icon"
})`
  ${IconStyle}
`

export const StyledInput = styled.input`
  position: absolute;
  visibility: hidden;
`