import React, { memo } from "react"

const PhoneInputIcon = (props) => (
  <svg height="1em" viewBox="0 0 18.298 18.337" {...props}>
    <g transform="translate(-0.539 0)">
      <g transform="translate(0.539 0)">
        <path fill="currentColor" d="M18.358,13.457,15.8,10.9a1.7,1.7,0,0,0-2.833.64,1.741,1.741,0,0,1-2.011,1.1A7.891,7.891,0,0,1,6.2,7.883,1.656,1.656,0,0,1,7.3,5.872a1.7,1.7,0,0,0,.64-2.833L5.38.48a1.825,1.825,0,0,0-2.468,0L1.176,2.216C-.56,4.044,1.359,8.888,5.654,13.183s9.139,6.306,10.967,4.478l1.736-1.736A1.825,1.825,0,0,0,18.358,13.457Z" transform="translate(-0.539 0)" />
      </g>
    </g>
  </svg>
)

export default memo(PhoneInputIcon)
