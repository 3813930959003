import React from "react"
import { Container, ContentContainer, CoverImage, GlobalStyle, Logo, PanelContainer, Slogan } from "./styles"

const EntrancePage = ({ className, slogan, coverImage, children }) => (
  <Container className={className}>
    <GlobalStyle />
    <PanelContainer>
      <div>
        <Logo />
      </div>
      <CoverImage
        className="entrance-page__cover-image"
        style={{ backgroundImage: `url(${coverImage})` }}
      />
      <Slogan>{slogan}</Slogan>
    </PanelContainer>
    <ContentContainer>{children}</ContentContainer>
  </Container>
)

export default EntrancePage
