import React, { memo } from "react"

const UserInputIcon = (props) => (
  <svg height="1em" viewBox="0 0 12.573 19.474" {...props}>
    <defs>
      <linearGradient
        id="use-input-icon-gradient" x1="0.5" x2="0.5" y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff5363"/><stop offset="1" stopColor="#f93779"/>
      </linearGradient>
    </defs>
    <path fill="url(#use-input-icon-gradient)" d="M104,9.152a4.576,4.576,0,1,0-4.576-4.576A4.581,4.581,0,0,0,104,9.152Z" transform="translate(-97.717)" />
    <path fill="url(#use-input-icon-gradient)" d="M79.015,207.543l-.791-4.006a1.241,1.241,0,0,0-1.184-.974H68.462a1.241,1.241,0,0,0-1.184.974l-.791,4.006a1.615,1.615,0,0,0,.57,1.489c.135.1,3.339,2.488,5.693,2.488s5.558-2.387,5.693-2.488A1.615,1.615,0,0,0,79.015,207.543Z" transform="translate(-66.465 -192.047)" />
  </svg>
)

export default memo(UserInputIcon)
