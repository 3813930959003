import styled from "styled-components"
import FoxIcon from "../../../assets/svgs/FoxIcon"
import { LAPTOP_MAX } from "../../../common/breakpoints"

export const BreadcrumbContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 8px;
  font-size: 14px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 21px;
    font-size: 10px;
  }
`

export const Logo = styled(FoxIcon)`
  color: #FF4470;
  font-size: 20px;
  margin-right: 14px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    margin-right: 10px;
  }
`
