/**
 * * pxToW
 * converts px -> w: 100px => 100w
 * @function pxToW(@param px)
 * @param px defines pixel to transform to w
 */
export const pxToW = (px) => {
  return px.split(`px`)[0] + `w`
}

/**
 * * pxToInt
 * converts px -> w: 100px => 100
 * @function pxToInt(@param px)
 * @param px defines pixel to trasform to int
 */
export const pxToInt = (px) => {
  return parseInt(px.split(`px`)[0])
}

/**
 * * restartAnimation
 * restarts animation
 * @function restartAnimation(@param document, @param elementId, @param animationClass)
 * @param document defines document
 * @param elementId defines element's id to restart animation
 * @param animationClass defines animation class to retart
 */
export const restartAnimation = (document, elementId, animationClassName) => {
  let element = document.getElementById(elementId)

  // remove the class
  element.classList.remove(animationClassName)

  /* The actual magic */
  // triggering reflow
  // Uncommenting the line and the transition won't be retriggered.

  void element.offsetWidth

  // re-add the class
  element.classList.add(animationClassName)
}
