import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    fill: currentColor;
  }
`

const DashboardIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 12.633 15.44" {...props}>
    <path className="a" d="M12.165,35.615H.468A.469.469,0,0,1,0,35.147V30.468A.469.469,0,0,1,.468,30h11.7a.469.469,0,0,1,.468.468v4.679A.469.469,0,0,1,12.165,35.615Z" transform="translate(0 -22.982)"/>
    <path className="a" d="M12.165,61.4H.468A.469.469,0,0,1,0,60.936v-.468A.469.469,0,0,1,.468,60h11.7a.469.469,0,0,1,.468.468v.468A.469.469,0,0,1,12.165,61.4Z" transform="translate(0 -45.964)"/>
    <path className="a" d="M4.445,5.615H.468A.469.469,0,0,1,0,5.147V.468A.469.469,0,0,1,.468,0H4.445a.469.469,0,0,1,.468.468V5.147A.469.469,0,0,1,4.445,5.615Z" transform="translate(0 0)"/>
    <path className="a" d="M32.848,5.615H27.468A.469.469,0,0,1,27,5.147V.468A.469.469,0,0,1,27.468,0h5.381a.469.469,0,0,1,.468.468V5.147A.469.469,0,0,1,32.848,5.615Z" transform="translate(-20.684 0)"/>
  </StyledSvg>
)

export default memo(DashboardIcon)
