import styled from "styled-components"
import FoxIcon from "../../../assets/svgs/FoxIcon"
import { LAPTOP_MAX, TABLET_MAX,TABLET_MIN } from "../../../common/breakpoints"
import { transitions } from "../../../assets/animations/transitions"

export const WelcomeContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: #797979;
  font-size: 15px;
  padding-left: 98px;

  strong {
    font-size: 16px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    padding-left: 77px;

    strong {
      font-size: 12px;
    }
  }

  @media (max-width: ${TABLET_MIN}px) {
    ${props => props.navMenuExpanded ? 'padding-left: 30px;' : ''}
  }
`

export const WelcomeTexts = styled.div`
  dispay: flex;
  flex-wrap: wrap;
`

export const Logo = styled(FoxIcon)`
  color: #FF4470;
  font-size: 26px;
  margin-right: 14px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 18px;
    margin-right: 10px;
  }
`

export const FluencyTVContainer = styled.div`
  transition: ${transitions.quickTransitionAll};
  background-color: #6B47ED;
  border-left: 1px solid #E6E6E6;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 28px;
  & path { fill: #fff; }
  font-size: 16px;
  position: relative;

  &::after{
    position: absolute;
    content: '';
    bottom: -1px;
    width: 100%;
    height: 1px;
    left:0;
    right:0;
    background-color: #6B47ED;
    transition: ${transitions.quickTransition('background-color')};

  }

  & b {
    padding-left: 5px;
  }
  & svg {
    margin-left: 74px;
  }

  &:hover {
    background-color: #fff;
    color: #6B47ED;
    & path { fill: #6B47ED; }
    &::after{
      background-color: #E6E6E6;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    & svg {
      margin-left: 50px;
      height: 23px;
    }
  }
`

export const FluencyTVTexts = styled.div`
  display: flex;

  @media (max-width: ${TABLET_MAX}px) {
    flex-direction: column;
    & b {
      padding-left: 0;
    }
  }
`
