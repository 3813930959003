import React, { memo } from "react"

const PauseIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 27 30"
    fill="none"
    {...props}
  >
    <path d="M2.91667 0C2.14312 0 1.40125 0.307291 0.854272 0.854272C0.307291 1.40125 0 2.14312 0 2.91667V27.0833C0 28.6933 1.30667 30 2.91667 30H8.75C9.52355 30 10.2654 29.6927 10.8124 29.1457C11.3594 28.5987 11.6667 27.8569 11.6667 27.0833V2.91667C11.6667 2.14312 11.3594 1.40125 10.8124 0.854272C10.2654 0.307291 9.52355 0 8.75 0H2.91667Z" fill="white"/>
    <path d="M17.9167 0C17.1431 0 16.4013 0.307291 15.8543 0.854272C15.3073 1.40125 15 2.14312 15 2.91667V27.0833C15 28.6933 16.3067 30 17.9167 30H23.75C24.5235 30 25.2654 29.6927 25.8124 29.1457C26.3594 28.5987 26.6667 27.8569 26.6667 27.0833V2.91667C26.6667 2.53364 26.5912 2.15437 26.4446 1.80051C26.2981 1.44664 26.0832 1.12511 25.8124 0.854272C25.5416 0.583434 25.22 0.368594 24.8662 0.222018C24.5123 0.0754419 24.133 0 23.75 0H17.9167Z" fill="white"/>
  </svg>
)

export default memo(PauseIcon)
