import React, { memo } from "react"

const PlayIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 19 25"
    {...props}
  >
    <path d="M18.6373 11.5941L1.2213 0.19408C1.12963 0.135105 1.02424 0.100884 0.915409 0.0947499C0.806578 0.0886162 0.698015 0.11078 0.600297 0.15908C0.505103 0.204782 0.424593 0.276203 0.367867 0.365268C0.311141 0.454333 0.280462 0.557491 0.279297 0.66308V23.4631C0.281314 23.568 0.312387 23.6702 0.369058 23.7585C0.425729 23.8468 0.505776 23.9176 0.600297 23.9631C0.697947 24.0111 0.806385 24.0331 0.915041 24.0268C1.0237 24.0205 1.12887 23.9861 1.2203 23.9271L18.6363 12.5271C18.7156 12.4772 18.781 12.408 18.8263 12.326C18.8716 12.244 18.8954 12.1518 18.8954 12.0581C18.8954 11.9644 18.8716 11.8722 18.8263 11.7902C18.781 11.7081 18.7156 11.639 18.6363 11.5891L18.6373 11.5941Z" fill="currentColor"/>
  </svg>
)

export default memo(PlayIcon)