import React, { memo } from "react"

const ChevronDown = (props) => (
  <svg height="1em" viewBox="0 0 16.836 10.511" {...props}>
    <g transform="translate(15.836) rotate(90)">
      <path
        fill="currentColor"
        d="M10.133,9.252,2.2,16.491a1.379,1.379,0,0,1-1.826,0,1.108,1.108,0,0,1,0-1.667L7.394,8.418.378,2.013a1.108,1.108,0,0,1,0-1.667A1.38,1.38,0,0,1,2.2.345l7.929,7.239a1.108,1.108,0,0,1,0,1.667Z"
        transform="translate(0 -1)"
      />
    </g>
  </svg>
)

export default memo(ChevronDown)
