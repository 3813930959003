import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import React from "react"
import { Redirect } from "react-router-dom"
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n

export function EnLocale() {
  i18n.changeLanguage("en")
  return <Redirect to="/" />
}

export function PtLocale() {
  i18n.changeLanguage("pt")
  return <Redirect to="/" />
}

export function EsLocale() {
  i18n.changeLanguage("es")
  return <Redirect to="/" />
}

export const Dictionary = Object.freeze({
  common: {
    oops: "common.oops",
    back: "common.back",
    thankYou: "common.thankYou",
    send: "common.send",
    welcome: "common.welcome",
    hey: "common.hey",
    loading: "common.loading",
    home: "common.home",
    continue: "common.continue",
    sorry: "common.sorry"
  },
  language: {
    english: "language.english",
    spanish: "language.spanish",
    french: "language.french",
    german: "language.german",
    italian: "language.italian",
    japanese: "language.japanese",
    mandarin: "language.mandarin",
    portuguese: "language.portuguese",
    other: "language.other"
  },
  login: {
    email: "login.email",
    password: "login.password",
    forgotPassword: "login.forgotPassword",
    logIn: "login.logIn",
    signUp: "login.signUp",
    errorFields: "login.errorFields"
  },
  termsAndConditionsText: {
    pageHeader: "termsAndConditionsText.pageHeader",
    pageParagraph1: "termsAndConditionsText.pageParagraph1",
    pageParagraph2: "termsAndConditionsText.pageParagraph2",
    link: "termsAndConditionsText.link",
    acceptLabel: "termsAndConditionsText.acceptLabel",
    sorryText: "termsAndConditionsText.sorryText"
  },
  forgotPassword: {
    title: "forgotPassword.title",
    send: "forgotPassword.send",
    registerText: "forgotPassword.registerText",
    registerTextLink: "forgotPassword.registerTextLink",
    ready: "forgotPassword.ready",
    readyDesc: "forgotPassword.readyDesc",
    sidebar: "forgotPassword.sidebar"
  },
  register: {
    email: "register.email",
    password: "register.password",
    firstName: "register.firstName",
    lastName: "register.lastName",
    confirmEmail: "register.confirmEmail",
    confirmPassword: "register.confirmPassword",
    success: "register.success",
    ready: "register.ready",
    message: "register.message",
    errorEmailRegistered: "register.errorEmailRegistered",
    validatePhone: "register.validatePhone",
    areaCode: "register.areaCode",
    phoneNumber: "register.phoneNumber",
    codeBySms: "register.codeBySms",
    codeByWhatsApp: "register.codeByWhatsApp",
    enterPhoneCode: "register.enterPhoneCode",
    changePhoneNumber: "register.changePhoneNumber",
    phoneNumberVerified: "register.phoneNumberVerified",
    finish: "register.finish"
  },
  menu: {
    title: "menu.title",
    dashboard: "menu.dashboard"
  },
  settings: {
    title: "settings.title",
    support: "settings.support",
    terms: "settings.terms",
    logout: "settings.logout"
  },
  dashboard: {
    header: "dashboard.header",
    masterDecksOption: "dashboard.masterDecksOption",
    yourDecksOption: "dashboard.yourDecksOption",
    your: "dashboard.your",
    decks: "dashboard.decks",
    studyNowDeck: "dashboard.studyNowDeck",
    infoDeckHey: "dashboard.infoDeckHey",
    infoDeck: "dashboard.infoDeck",
    noDecks: "dashboard.noDecks",
    buttonFluencyTV: "dashboard.buttonFluencyTV",
    mobileNoMasterDecks: "dashboard.mobileNoMasterDecks",
    mobileNoYourDecks: "dashboard.mobileNoYourDecks",
    mobileNoDecks: "dashboard.mobileNoDecks",
    mobileDiscoverDecks: "dashboard.mobileDiscoverDecks",
    mobileAllButton: "dashboard.mobileAllButton",
    mobileStatistics: "dashboard.mobileStatistics",
    mobileNoActivity: "dashboard.mobileNoActivity"
  },
  deck: {
    totalCardInfo: "deck.totalCardInfo",
    remainingCardInfo: "deck.remainingCardInfo",
    cards: "deck.cards",
    cardMode: "deck.cardMode",
    cardList: "deck.cardList",
    continueStudy: "deck.continueStudy",
    addDeck: "deck.addDeck",
    review: "deck.review",
    data: "deck.data",
    notStudiedYetText1: "deck.notStudiedYetText1",
    notStudiedYetText2: "deck.notStudiedYetText2"
  },
  studyModeDeck: {
    title: "studyModeDeck.title",
    mobileTitle: "studyModeDeck.mobileTitle",
    subtitle: "studyModeDeck.subtitle",
    question: "studyModeDeck.question",
    link: "studyModeDeck.link",
    pickNotEnoughtCards: "studyModeDeck.pickNotEnoughtCards",
    ideal: {
      title: "studyModeDeck.ideal.title",
      titleHelper: "studyModeDeck.ideal.titleHelper",
      subtitle: "studyModeDeck.ideal.subtitle",
      description: "studyModeDeck.ideal.description"
    },
    fair: {
      title: "studyModeDeck.fair.title",
      titleHelper: "studyModeDeck.fair.titleHelper",
      subtitle: "studyModeDeck.fair.subtitle",
      description: "studyModeDeck.fair.description"
    },
    medium: {
      title: "studyModeDeck.medium.title",
      titleHelper: "studyModeDeck.medium.titleHelper",
      subtitle: "studyModeDeck.medium.subtitle",
      description: "studyModeDeck.medium.description"
    },
    short: {
      title: "studyModeDeck.short.title",
      titleHelper: "studyModeDeck.short.titleHelper",
      subtitle: "studyModeDeck.short.subtitle",
      description: "studyModeDeck.short.description"
    }
  },
  studySession: {
    commandsButton: "studySession.commandsButton",
    turnCard: "studySession.turnCard",
    tip: "studySession.tip",
    tipText: "studySession.tipText",
    easy: "studySession.easy",
    good: "studySession.good",
    hard: "studySession.hard",
    forgot: "studySession.forgot",
    shortcut: "studySession.shortcut",
    back: "studySession.back",
    exitHeader: "studySession.exitHeader",
    exitBody: "studySession.exitBody",
    exitCancel: "studySession.exitCancel",
    exitYes: "studySession.exitYes",
    commands: {
      title: "studySession.commands.title",
      space: "studySession.commands.space",
      spaceCommand: "studySession.commands.spaceCommand",
      enter: "studySession.commands.enter",
      enterCommand: "studySession.commands.enterCommand",
      keyText: "studySession.commands.keyText"
    }
  },
  finishedStudySession: {
    deckStudiedInfo: "finishedStudySession.deckStudiedInfo",
    goToDashboard: "finishedStudySession.goToDashboard",
    youFinished: "finishedStudySession.youFinished",
    lodingResults: "finishedStudySession.lodingResults",
    lodingResultsSubtext: "finishedStudySession.lodingResultsSubtext",
    mobileYeah: "finishedStudySession.mobileYeah",
    mobileText: "finishedStudySession.mobileText",
    mobileProgressTitle: "finishedStudySession.mobileProgressTitle"
  }
})
