import { useState, useRef, useLayoutEffect, useEffect, useCallback } from "react"

export const BEFORE_ENTER = "beforeEnter"
export const ENTERING = "entering"
export const ENTERED = "entered"
export const BEFORE_EXIT = "beforeExit"
export const EXITING = "exiting"
export const EXITED = "exited"

export const ENTER_STAGES = new Set([BEFORE_ENTER, ENTERING, ENTERED])
export const EXIT_STAGES = new Set([BEFORE_EXIT, EXITING, EXITED])

export default (show, duration, delay = 0, { disabled = false } = {}) => {
  const [transitionState, setTransitionState] = useState(show ? ENTERED : EXITED)
  const didMount = useRef(false)
  const enterDuration = typeof duration === "number" ? duration : duration.enter
  const exitDuration = typeof duration === "number" ? duration : duration.exit
  const enterDelay = typeof delay === "number" ? delay : delay.enter
  const exitDelay = typeof delay === "number" ? delay : delay.exit

  const switchAfterDelay = useCallback((newState, switchDelay) => {
    const timeoutId = setTimeout(() => {
      setTransitionState(newState)
    }, switchDelay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useLayoutEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return () => {}
    }

    if (
      (show && EXIT_STAGES.has(transitionState)) ||
      (!show && ENTER_STAGES.has(transitionState))
    ) {
      const transitionDelay = show ? enterDelay : exitDelay
      const nextState = show ? BEFORE_ENTER : BEFORE_EXIT

      if (transitionDelay) {
        return switchAfterDelay(nextState, transitionDelay)
      }
      setTransitionState(nextState)
    }
    return () => {}
  }, [show, transitionState, enterDelay, exitDelay, switchAfterDelay])

  useEffect(() => {
    switch (transitionState) {
      case BEFORE_ENTER:
        return switchAfterDelay(ENTERING, 20)
      case ENTERING:
        return switchAfterDelay(ENTERED, enterDuration)
      case BEFORE_EXIT:
        return switchAfterDelay(EXITING, 20)
      case EXITING:
        return switchAfterDelay(EXITED, exitDuration)
      default:
        return () => {}
    }
  }, [transitionState, enterDuration, exitDuration, switchAfterDelay])

  if (disabled) {
    return show ? ENTERED : EXITED
  }

  return transitionState
}
