import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a { opacity: 0.5; }
  .b { 
    fill: none; 
    stroke: currentColor; 
    stroke-linecap: round; 
    stroke-linejoin: round; 
    stroke-width: 2.5px; 
  }
`

const MenuIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 18.5 14.5" {...props}>
    <g className="a" transform="translate(1.25 1.25)">
      <line className="b" x2="16" transform="translate(0 6)"/>
      <line className="b" x2="16"/>
      <line className="b" x2="14" transform="translate(0 12)"/>
    </g>
  </StyledSvg>
)

export default memo(MenuIcon)
