import React, { memo, useCallback, useMemo, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Navigation from "../../common/components/Navigation"
import ModeButton from "./ModeButton"
import IdealIcon from "./images/IdealIcon"
import NormalIcon from "./images/NormalIcon"
import MediumIcon from "./images/MediumIcon"
import ShortIcon from "./images/ShortIcon"
import { InfoStickerTitle, InfoStickerDescription } from "./InfoSticker"
import { StudyMode } from "../../store/selectors/common"
import useOnMount from "../../hooks/useOnMount"
import * as actions from "../../store/actions/sessions"
import * as selectors from "../../store/selectors/sessions"
import {
  StyledPageRoot,
  StyledPageContainer,
  BlocksContainer,
  ModesSide,
  InfoSide,
  StyledHourglassIcon,
  ModesBlock,
  Title,
  SubTitle,
  SecondSubTitle,
  StyledFoxIcon,
  InfoLink,
  IdealSticker,
  NormalSticker,
  MediumSticker,
  ShortSticker,
  StatusContainer
} from "./styles"
import { routes } from "../../utils/constants"
import TopBar from "./TopBar"
import { TopBarSpacer } from "../../common/styles/topBar";
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";
import { FBAnalyticsDeckStudySessionStarted } from './../../firebase/logEvents';

const DeckModePage = ({ sessionTargetType }) => {
  const history = useHistory()
  const { params } = useRouteMatch()
  const sessionTargetId = parseInt(params.id)
  const {t} = useTranslation();

  const dispatch = useDispatch()
  const sessionModeSelector = useMemo(
    () => selectors.sessionModeSelector(sessionTargetType, sessionTargetId),
    [sessionTargetType, sessionTargetId]
  )
  const modeData = useSelector(sessionModeSelector)
  const { loadError} = useSelector(selectors.viewSessionState)

  const [navMenuExpanded, setNavMenuExpanded] = useState(false)
  const [hoveredButton, setHoveredButton] = useState(null)

  useOnMount(() => {
    if (!modeData) {
      dispatch(actions.viewSessionReset())
      dispatch(actions.startSessionRequest({ type: sessionTargetType, id: sessionTargetId }))
    }
  })

  const handleToggleExpanded = useCallback(() => {
    setNavMenuExpanded(expanded => !expanded)
  }, [])

  const handleButtonHover = useCallback((mode, hover) => {
    setHoveredButton(hover ? mode : null)
  }, [])

  const handleButtonClick = useCallback((mode) => {
    let params = {
      mode,
      deckType: sessionTargetType,
      totalCardsCount: modeData.totalCardsCount,
      overdueCardsCount: 0,
      chosenCardsCount: 0,
      virtualId: sessionTargetId,
      deckId: modeData.deckId,
      deckTitle: modeData.name
    }
    FBAnalyticsDeckStudySessionStarted(params)
    history.push(`${routes.studySessionBase}/${sessionTargetType}/${sessionTargetId}/${mode}`)
  }, [history, sessionTargetType, sessionTargetId, modeData])

  const renderModes = () => {
    if (modeData) {
      return (
        <>
          <Title>
            {t(Dictionary.studyModeDeck.title)}
          </Title>
          <SubTitle>
            {t(Dictionary.studyModeDeck.subtitle)}
          </SubTitle>
          <SecondSubTitle>
            <StyledFoxIcon />
            <span>{t(Dictionary.studyModeDeck.question)}&nbsp;</span>
            <InfoLink
              href="https://suporte-bot-memhack.webflow.io/"
              target="_blank"
              rel="noreferrer"
            >
              {t(Dictionary.studyModeDeck.link)}
            </InfoLink>
          </SecondSubTitle>
          <div>
            <ModeButton
              mode={StudyMode.Ideal}
              disabled={!modeData.availableModes.has(StudyMode.Ideal)}
              title={t(Dictionary.studyModeDeck.ideal.title)}
              subTitle={t(Dictionary.studyModeDeck.ideal.subtitle)}
              icon={<IdealIcon />}
              iconBgColor="#00C6FF"
              labelBgColor="#00AEE0"
              filled
              onHover={handleButtonHover}
              onClick={handleButtonClick}
            />
            <ModeButton
              mode={StudyMode.Fair}
              disabled={!modeData.availableModes.has(StudyMode.Fair)}
              title={t(Dictionary.studyModeDeck.fair.title)}//"Bom"
              subTitle={t(Dictionary.studyModeDeck.fair.subtitle)}//"Até 01 hora de estudo"
              icon={<NormalIcon />}
              iconBgColor="#F75336"
              labelBgColor="#FF7045"
              onHover={handleButtonHover}
              onClick={handleButtonClick}
            />
            <ModeButton
              mode={StudyMode.Medium}
              disabled={!modeData.availableModes.has(StudyMode.Medium)}
              title={t(Dictionary.studyModeDeck.medium.title)}//"Médio"
              subTitle={t(Dictionary.studyModeDeck.medium.subtitle)}//"Até 45 minutos de estudo"
              icon={<MediumIcon />}
              iconBgColor="#FF7D02"
              labelBgColor="#FF9634"
              onHover={handleButtonHover}
              onClick={handleButtonClick}
            />
            <ModeButton
              mode={StudyMode.Short}
              disabled={!modeData.availableModes.has(StudyMode.Short)}
              title={t(Dictionary.studyModeDeck.short.title)}
              subTitle={t(Dictionary.studyModeDeck.short.subtitle)}
              icon={<ShortIcon />}
              iconBgColor="#FFD62C"
              labelBgColor="#FFC502"
              onHover={handleButtonHover}
              onClick={handleButtonClick}
            />
          </div>
        </>
      )
    }

    if (loadError) {
      return (
        <StatusContainer>
          Error: {loadError.message}
        </StatusContainer>
      )
    }

    return (
      <StatusContainer>
        {t(Dictionary.common.loading)}...
      </StatusContainer>
    )
  }

  return (
    <StyledPageRoot>
      <Navigation expanded={navMenuExpanded} toggleExpanded={handleToggleExpanded} />
      <StyledPageContainer data-nav-expanded={navMenuExpanded}>
        {modeData ? (
          <TopBar
            type={sessionTargetType}
            id={sessionTargetId}
            title={modeData.name}
            navMenuExpanded={navMenuExpanded}
          />
        ) : (
          <TopBarSpacer />
        )}
        <BlocksContainer>
          <ModesSide>
            <ModesBlock>
              {renderModes()}
            </ModesBlock>
          </ModesSide>
          <InfoSide>
            <StyledHourglassIcon mode={hoveredButton} />
            {modeData && (
              <>
                <IdealSticker
                  show={hoveredButton === StudyMode.Ideal}
                  disabled={!modeData.availableModes.has(StudyMode.Ideal)}
                >
                  <InfoStickerTitle>{t(Dictionary.studyModeDeck.ideal.titleHelper)}!</InfoStickerTitle>
                  <InfoStickerDescription>
                    {t(Dictionary.studyModeDeck.ideal.description)}
                  </InfoStickerDescription>
                </IdealSticker>
                <NormalSticker
                  show={hoveredButton === StudyMode.Fair}
                  disabled={!modeData.availableModes.has(StudyMode.Fair)}
                >
                  <InfoStickerTitle>{t(Dictionary.studyModeDeck.fair.titleHelper)}</InfoStickerTitle>
                  <InfoStickerDescription>
                    {t(Dictionary.studyModeDeck.fair.description)}
                  </InfoStickerDescription>
                </NormalSticker>
                <MediumSticker
                  show={hoveredButton === StudyMode.Medium}
                  disabled={!modeData.availableModes.has(StudyMode.Medium)}
                >
                  <InfoStickerTitle>{t(Dictionary.studyModeDeck.medium.titleHelper)}</InfoStickerTitle>
                  <InfoStickerDescription>
                    {t(Dictionary.studyModeDeck.medium.description)}
                  </InfoStickerDescription>
                </MediumSticker>
                <ShortSticker
                  show={hoveredButton === StudyMode.Short}
                  disabled={!modeData.availableModes.has(StudyMode.Short)}
                >
                  <InfoStickerTitle>{t(Dictionary.studyModeDeck.short.titleHelper)}</InfoStickerTitle>
                  <InfoStickerDescription>
                    {t(Dictionary.studyModeDeck.short.description)}
                  </InfoStickerDescription>
                </ShortSticker>
              </>
            )}
          </InfoSide>
        </BlocksContainer>
      </StyledPageContainer>
    </StyledPageRoot>
  )
}

export default memo(DeckModePage)
