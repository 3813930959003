
import styled from "styled-components"
import { LAPTOP_MAX } from "../breakpoints"

export const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 78px;
  background-color: white;
  border-bottom: 1px solid #E6E6E6;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 54px;
  }
`

export const TopBarSpacer = styled(TopBarContainer)`
  background-color: transparent;
  border-color: transparent;
`
