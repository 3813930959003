import React, { memo } from "react"

const FrenchIcon = (props) => (
  <svg height="28" viewBox="0 0 28 28" {...props}>
    <path d="M20.2537 26.1282C21.8769 26.0873 22.6537 23.219 23.7717 22.1077C24.8896 20.9963 25.8231 22.1318 25.7819 20.6L26.5643 10.4005C26.6055 8.86873 23.6617 5.48105 22.5438 4.36975C21.4258 3.25844 21.8769 2.40039 20.2537 2.3595H17.6997V26.5476L20.2537 26.1282Z" fill="#6A48ED"/>
    <path d="M18.0205 26.4826V1.35437H9.97949V26.4826H18.0205Z" fill="white"/>
    <path d="M7.68982 25.6257L10.0266 26.6308V1.35437L7.68982 2.3595C6.19517 2.40068 5.70381 3.25053 4.67443 4.36975C3.64504 5.48898 1.39813 8.85782 1.43604 10.4005L2.16161 19.5949C2.1237 21.1377 3.64505 21.4911 4.67443 22.6103C5.70382 23.7295 6.19516 25.5844 7.68982 25.6257Z" fill="#D31B24"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26802 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26802 28 0 21.732 0 14ZM14 1.86667C7.29895 1.86667 1.86667 7.29894 1.86667 14C1.86667 20.7011 7.29895 26.1333 14 26.1333C20.7011 26.1333 26.1333 20.7011 26.1333 14C26.1333 7.29895 20.7011 1.86667 14 1.86667Z" fill="white"/>
  </svg>
)

export default memo(FrenchIcon)
