import { logEvent } from "firebase/analytics";
import { setUserId, setUserProperties } from "firebase/analytics";
import { DeckType } from "./../constants"
import { analytics } from './index';
import {
  FBAnalyticsEventsKeys as events,
  FBAnalyticsParametersKeys as parameters,
  FBAnalyticsCardGrade,
  FBAnalyticsStudySessionDurationType
} from './keys';

export const FBAnalyticsLoginFailure = () => {
  logEvent(analytics, events.login_failed)
}

export const FBAnalyticsSignUp = () => {
  // const paramsEvents = {
  //   [parameters.user_email]: params.email,
  //   [parameters.user_first_name]: params.firstName,
  //   [parameters.user_last_name]: params.lastName
  // }
  logEvent(analytics, events.sign_up)//, paramsEvents)
}

export const FBAnalyticsClearUserData = () => {
  setUserId(analytics, null)
  setUserProperties(analytics, {
    id: null,
    email: null
  })
}

export const FBAnalyticsSetUserId = (userId) => {
  setUserId(analytics, userId)
}

export const FBAnalyticsSetUserData = ({userId, email}) => {
  FBAnalyticsSetUserId(userId)
  setUserProperties(analytics, {
    id: userId,
    email
  })
}

/// Registration
export const FBAnalyticsRegistrationClicked = () => {
  logEvent(analytics, events.registration_button_click)
}

export const FBAnalyticsGradeCard = ({cardId, grade:gradeNumber, language, imagesCount}) => {
  const grade = FBAnalyticsCardGrade(gradeNumber);
  const paramsEvents = {
    [parameters.card_id]: cardId,
    [parameters.grade]: grade,
    [parameters.card_language]: language,
    [parameters.card_images_count]: imagesCount
  }
  logEvent(analytics, events.card_graded, paramsEvents)
}

export const FBAnalyticsDeckStudySessionStarted = data => {
  const { deckType, mode, virtualId } = data;

  let params = studySessionCommon(data)
  params[parameters.mode] = FBAnalyticsStudySessionDurationType(mode)
  const isMergeDesc = deckType === DeckType.merge
  if(!isMergeDesc) {
    params[parameters.virtual_id] = virtualId
  }
  const event = isMergeDesc ? events.masterdeck_study_session_started: events.deck_study_session_started
  logEvent(analytics, event, params)
}

export const FBAnalyticsDeckStudySessionEnded = (data) => {
  const {deckType,
    deckTitle,
    deckId,
    duration,
    virtualId,
    totalCardsCount,
    overdueCardsCount,
    chosenCardsCount,
    studiedCardsCount,
    aborted,
    difficulty} = data;
  let params = studySessionCommon({deckId, deckTitle, totalCardsCount, overdueCardsCount, chosenCardsCount})

  params[parameters.studied_cards_count] = studiedCardsCount
  const isMergeDesc = deckType === DeckType.merge
    if(!isMergeDesc) {
      params[parameters.virtual_id] = virtualId
    }

  params[parameters.duration] = duration
  params[parameters.aborted] = aborted
  params[parameters.difficulty] = difficulty
  const event = isMergeDesc ? events.masterdeck_study_session_ended : events.deck_study_session_ended;
  logEvent(analytics, event, params)
}

const studySessionCommon = ({deckId,
  deckTitle,
  totalCardsCount,
  overdueCardsCount,
  chosenCardsCount}) => {
  return {
    [parameters.physical_id]: deckId,
    [parameters.deck_title]: deckTitle,
    [parameters.total_cards_count]: totalCardsCount,
    [parameters.overdue_cards_count]: overdueCardsCount,
    [parameters.chosen_cards_count]: chosenCardsCount
  }
}