import styled, { createGlobalStyle } from "styled-components"
import { MOBILE_MAX } from "../../../common/breakpoints"
import Button from "../../../common/components/Button"
import { FormContainer } from "../Common/styles"
import flameBg from "./flame-success.png"

export const GlobalStyle = createGlobalStyle`
  body {
    @media (max-width: ${MOBILE_MAX}px) {
      background: linear-gradient(180deg, #FF5363 0%, #F93779 100%) #F93779;
    }
  }
`

export const RegisterFormContainer = styled(FormContainer)``

export const Container = styled.div`
position: fixed;
left: 0;
top: 0;
right: 0;
bottom: 0;
`

export const BgImage = styled.div`
position: absolute;
right: 0;
bottom: 0;
height: 402px;
width: 277px;
background: url("${flameBg}") no-repeat center;
`

export const ContentContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: calc(100vh - 124px);

@media(max-width: ${MOBILE_MAX}px) {
  height: calc(100vh - 44px);
}
`

export const BackButtonRow = styled.div`
margin-bottom: 25px;
`

export const BackButton = styled.button`
padding: 14px;
margin: -14px;
font-size: 16px;
color: white;
`

export const Title = styled.div`
font-family: "BlackerDisplayHeavy";
font-weight: 900;
font-size: 48px;
line-height: 54px;
margin: 0 -3px;
`

export const Description = styled.div`
font-size: 18px;
line-height: 18px;
opacity: 0.65;
margin: 0 -3px;
text-align: center;

@media(max-width: ${MOBILE_MAX}px) {
  color: #f8f8f8;
  opacity: 1;
}
`

export const RegisterButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 50px;

@media(max-width: ${MOBILE_MAX}px) {
  margin-top: 32px;
}
`

export const OkButton = styled(Button)`
width: auto;
`
