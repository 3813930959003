import React from "react"
import PropTypes from "prop-types"

export default class Svg extends React.Component {
  static propTypes = {
    /** defines the svg's id attribute */
    id: PropTypes.string,
    /** defines the svg's width attribute */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** defines the svg's height attribute */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** defines the svg's viewbox attribute */
    viewBox: PropTypes.string,
    /** defines the svg's fill attribute */
    fill: PropTypes.string,
    /** defines the svg's style attribute */
    style: PropTypes.object,
    /** defines the svg's classname attribute */
    className: PropTypes.string,
    /** defines the svg's path children */
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** defines the svg's handlers */
    onClick: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func
  }

  render = () => {
    return (
      <svg
        onClick={this.props.onClick}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        id={this.props.id}
        width={this.props.width}
        height={this.props.height}
        viewBox={this.props.viewBox}
        fill={this.props.fill}
        className={this.props.className}
      >
        {this.props.children}
      </svg>
    )
  }
}
