import React, { memo, useState, useCallback } from "react"
import ReactDOM from "react-dom"
import useOnChange from "../../../../hooks/useOnChange"
import useTransitionState, { BEFORE_ENTER, EXITED, EXITING } from "../../../../hooks/useTransitionState"
import { Message, Overlay, Title, ToastContainer } from "./styles"

const ErrorToast = ({ status }) => {
  const [show, setShow] = useState(false)
  const transition = useTransitionState(show, 330)

  useOnChange(() => {
    setShow(!!status)
  }, [status])

  const handleOverlayClick = useCallback(() => {
    setShow(false)
  }, [])

  if (transition === EXITED) {
    return null
  }

  const style = {}

  if (transition === BEFORE_ENTER || transition === EXITING) {
    style.opacity = 0
  }

  return (
    ReactDOM.createPortal((
      <Overlay style={style} onClick={handleOverlayClick}>
        {status && (
          <ToastContainer data-type={status.type}>
            <Title>{status.title}</Title>
            <Message>{status.message}</Message>
          </ToastContainer>
        )}
      </Overlay>
    ),
    document.getElementById("root"))
  )
}

export default memo(ErrorToast)