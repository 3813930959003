export const MOBILE_MAX = 719.98
export const MOBILE_MIDDLE = 480

export const TABLET_SMALL_MIN = 720
export const TABLET_SMALL_MAX = 871.98

export const TABLET_MIN = 872
export const TABLET_MAX = 1023.98

export const LAPTOP_SMALL_MIN = 1024
export const LAPTOP_SMALL_MAX = 1231.98

export const LAPTOP_MIN = 1232
export const LAPTOP_MAX = 1439.98

export const DESKTOP_SMALL_MIN = 1440
export const DESKTOP_SMALL_MAX = 1679.98

export const DESKTOP_MIN = 1680
