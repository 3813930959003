import React, { memo, useRef, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n"
import {
    Container,
    CardContainer,
    TopNotice,
    TopContainer,
    CountersContainer,
    OverdueLabel,
    OverdueIcon,
    CounterLabel,
    TotalIcon,
    Title,
    Description,
    HoverTitle,
    BottomBar,
    BottomTitle,
    BottomForwardArrow,
    OopsImage,
    CardShadow,
    StyledFlag
} from "./styles"

const DeckCard = ({
  className,
  deck,
  onClick
}) => {
  const contentRef = useRef()
  const descriptionRef = useRef()
  const bottomBarRef = useRef()
  const [, setContainerHover] = useState(false)
  const hasOverdue = deck.overdueCount > 0
  const {t} = useTranslation();

  const handleMouseEnter = useCallback(() => {
    setContainerHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setContainerHover(false)
  }, [])

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(deck)
    }
  }, [deck, onClick])

  let hoverTitleBottom = 0

  if (contentRef.current && bottomBarRef.current && descriptionRef.current) {
    hoverTitleBottom =
      bottomBarRef.current.offsetHeight +
      parseFloat(getComputedStyle(contentRef.current).getPropertyValue("padding-bottom")) +
      descriptionRef.current.offsetHeight
  }

  const hoverTitleStyle = {
    bottom: `${hoverTitleBottom}px`
  }

  return (
    <Container
      className={className}
      data-has-overdue={hasOverdue}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <CardShadow />
      <CardContainer contentRef={contentRef} src={deck.image} fallback={<OopsImage />}>
        <TopContainer>
          <StyledFlag language={deck.language} />
          <CountersContainer>
            <OverdueLabel>{deck.overdueCount}</OverdueLabel>
            <OverdueIcon />
            <CounterLabel>{deck.totalCount}</CounterLabel>
            <TotalIcon />
          </CountersContainer>
        </TopContainer>
        <div>
          <Title>{deck.title}</Title>
          <Description ref={descriptionRef}>{deck.description}</Description>
          <HoverTitle style={hoverTitleStyle}>{deck.title}</HoverTitle>
        </div>
      </CardContainer>
      <BottomBar ref={bottomBarRef}>
        <BottomTitle>{t(Dictionary.dashboard.studyNowDeck)}</BottomTitle>
        <BottomForwardArrow />
      </BottomBar>
      <TopNotice>
        <strong>{t(Dictionary.dashboard.infoDeckHey)}&nbsp;</strong>
        <span>{t(Dictionary.dashboard.infoDeck)}</span>
      </TopNotice>
    </Container>
  )
}

export default memo(DeckCard)
