import React from "react"
import { ButtonSpinner, StyledButton } from "./styles"

const Button = ({ primary = false, outlined = true, loading = false, disabled = false, children, ...restProps }) => {
  return (
    <StyledButton
      data-primary={primary}
      data-outlined={outlined}
      data-loading={loading}
      data-disabled={disabled}
      {...restProps}
    >
      {loading ? <ButtonSpinner /> : children}
    </StyledButton>
  )
}

export default Button
