import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a, .d { 
    fill: none;
  }
  .a { 
    stroke: currentColor;
    stroke-width: 1.5px;
  }
  .b { 
    fill: currentColor; 
  }
  .c {
    stroke: none;
  }
`

const SpaceIcon = (props) => (
  <StyledSvg width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g transform="translate(-43 21)">
      <g className="a" transform="translate(43 -21)">
        <rect className="c" width="22" height="22" rx="5" />
        <rect
          className="d" x="0.75" y="0.75" width="20.5"
          height="20.5" rx="4.25"
        />
      </g>
      <path className="b" d="M10.791,4.126H.89A.805.805,0,0,1,0,3.45V.23A1.208,1.208,0,0,1,1.334-.785,1.208,1.208,0,0,1,2.669.23V2.1H9.012V.23A1.208,1.208,0,0,1,10.346-.785,1.208,1.208,0,0,1,11.681.23V3.45A.805.805,0,0,1,10.791,4.126Zm0,0" transform="translate(48.042 -11.717)" />
    </g>
  </StyledSvg>
)

export default memo(SpaceIcon)
