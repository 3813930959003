import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    stroke: rgba(255,255,255,0.67); 
    fill: url('#lgr');
  }
  .b { fill: #fff; }
  .c { stroke: none; }
  .d { fill: none; }
`

const TotalCardsIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 54 54" {...props}>
    <defs>
      <linearGradient
        id="lgr" x1="0.5" x2="0.5" y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fd4a6b"/>
        <stop offset="1" stopColor="#f93779"/>
      </linearGradient>
    </defs>
    <g className="a">
      <rect className="c" width="54" height="54" rx="27"/>
      <rect
        className="d" x="0.5" y="0.5" width="53"
        height="53" rx="26.5"
      />
    </g>
    <g transform="translate(17 15)">
      <path className="b" d="M25.114,0H13.38A1.38,1.38,0,0,0,12,1.38V17.256a1.38,1.38,0,0,0,1.38,1.38H25.114a1.38,1.38,0,0,0,1.38-1.38V1.38A1.38,1.38,0,0,0,25.114,0Z" transform="translate(-7.859)"/>
      <path className="b" d="M2.071,27.185V12.69A.692.692,0,0,0,1.38,12H.69a.692.692,0,0,0-.69.69V27.53a3.106,3.106,0,0,0,3.106,3.106H13.46a.692.692,0,0,0,.69-.69v-.69a.692.692,0,0,0-.69-.69H3.451A1.38,1.38,0,0,1,2.071,27.185Z" transform="translate(0 -7.859)"/>
    </g>
  </StyledSvg>
)

export default memo(TotalCardsIcon)
