import styled from "styled-components"
import bgLeft from "./bg-left.png"
import bgRight from "./bg-right.png"
import { LAPTOP_MAX, TABLET_MIN } from "../../../common/breakpoints"

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: 
    ${"right"} center / contain no-repeat url("${bgRight}"), 
    ${"left"} -120px center / contain no-repeat url("${bgLeft}"),
    #04294F;
  height: 711px;
  padding-left: 98px;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 498px;
    padding-left: 77px;
    background-position: ${"right"} center, ${"left"} -80px center 
  }
`

export const NoticeBlock = styled.div`
  background-color: #022C53;
  color: white;
  border: 1px solid #D6DAEB48;
  box-sizing: border-box;
  border-radius: 20px;
  width: 660px;
  padding: 50px 55px;
  margin-right: 40px;

  @media (max-width: ${LAPTOP_MAX}px) {
    border-radius: 14px;
    width: 500px;
    padding: 35px 38px 35px 38px;
  }
`

export const Title = styled.div`
  font-family: BlackerDisplayExtraBold;
  font-size: 60px;
  line-height: 1;
  letter-spacing: 0px;
  margin-bottom: 10px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 42px;
    margin-bottom: 7px;
  }
`

export const SubTitle = styled.div`
  font-size: 16px;
  line-height: 23px;
  font-style: italic;
  letter-spacing: 0px;
  opacity: 0.86;
  margin-bottom: 52px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 36px;
  }
`

export const ContainerButton = styled.div`
  display: flex;

  @media (max-width: ${TABLET_MIN}px) {
    flex-direction: column;
  }
`

export const AccessButton = styled.button`
  background-color: #00AEE0;
  width: 300px;
  height: 70px;
  border: 1px solid white;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  color: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 220px;
    height: 50px;
    border-radius: 25px;
    font-size: 13px;
  }

  @media (max-width: ${TABLET_MIN}px) {
    margin-right: 0px;
  }

  :active {
    opacity: 0.7;
  }
`
export const FluencyTVButton = styled.button`
  background-color: #6b47ed;
  width: 230px;
  height: 70px;
  border: 1px solid white;
  border-radius: 35px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  &:hover {
    background-color: #00AEE0;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    font-size: 13px;
  }

  @media (max-width: ${TABLET_MIN}px) {
    margin-left: 0px;
    margin-top: 20px;
  }

  :active {
    opacity: 0.7;
  }
`
