import React, { memo } from "react"

const MediumIcon = (props) => (
  <svg height="1em" viewBox="0 0 30.15 35.18" {...props}>
    <rect x="10.15" width="10" height="3.35" fill="#fff" />
    <path
      d="M26.72,10.71l2.36-2.38A17.47,17.47,0,0,0,26.73,6L24.37,8.35a14.9,14.9,0,0,0-7.63-3.22A14.5,14.5,0,0,0,15,5a15.08,15.08,0,0,0-.15,30.15H15a15.05,15.05,0,0,0,5.09-.91c.23-.08.46-.17.68-.27a15.08,15.08,0,0,0,5.95-23.29Zm0,9.41A11.69,11.69,0,0,1,15,31.85h0a11.74,11.74,0,0,1-.14-23.47H15A11.74,11.74,0,0,1,26.67,20.05Z"
      transform="translate(0.15 0)"
      fill="#fff"
    />
    <path
      d="M15,8.38V31.85a11.74,11.74,0,0,1-.14-23.47Z"
      transform="translate(0.15 0)"
      fill="#fff"
    />
    <path
      d="M26.67,20.05v.07A11.69,11.69,0,0,1,15,31.85h0V8.38A11.74,11.74,0,0,1,26.67,20.05Z"
      transform="translate(0.15 0)"
      fill="#00aee0"
    />
  </svg>
)

export default memo(MediumIcon)
