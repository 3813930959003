import React, {memo, useCallback, useMemo, useRef, useState} from 'react';
import { useHistory } from "react-router-dom";
import useSize from "@react-hook/size";
import DeckCard from "./../DeckCard";
import {CardCell} from './../styles';
import {
  StyledFlag,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  AccordionArrow,
  AccordionHeaderLang,
  AccordionCardsContainer
} from './styles';
import { routes } from "./../../../utils/constants";
import AccordionArrowIcon from './../../../assets/svgs/AccordionArrowIcon.js'
// import animateScrollTo from 'animated-scroll-to';

const AccordionLang = (props) => {
  const {language, cards, name, index, isActive} = props;
  const history = useHistory();
  const contentEl = useRef(null);
  const accordionRef = useRef(null);
  const isOpen = isActive === language;
  const [width] = useSize(contentEl);
  const [activeHeight, setActiveHeight] = useState(0);

  useMemo(() => {
    let height = 0;
    if(isOpen && contentEl?.current?.scrollHeight) {
      height = Math.floor(contentEl?.current?.scrollHeight || 0);
    }
    if(activeHeight !== height) {
      setActiveHeight(height)
    }
  // eslint-disable-next-line 
  }, [isOpen,width])

  // useEffect(() => {
  //   if(isActive === language && accordionRef.current) {

  //     setTimeout(()=> {
  //       if(accordionRef.current){
  //         const isShow = isScrolledIntoView();
  //         console.log("🚀 ~ file: index.js ~ line 32 ~ setTimeout ~ isShow", isShow)
  //         if(!isShow) {
  //           // animateScrollTo(accordionRef.current)
  //         }
  //       }
  //     //   console.log("🚀 ~ file: index.js ~ line 30 ~ setTimeout ~ accordionRef.current", accordionRef.current)
  //     //   
  //     }, 400)
  //   }
  // }, [isActive]);

  // const isScrolledIntoView = () => {
  //   const el = accordionRef.current;
  //   var bounds = el.getBoundingClientRect();
  //   return (
  //       (bounds.top + bounds.height > 0) && // Елемент ниже верхней границы
  //       (window.innerHeight - bounds.top > 0) && // Выше нижней
  //       (bounds.left + bounds.width > 0) && // Правее левой
  //       (window.innerWidth - bounds.left > 0)// Левее правой
  //   );
  // }

  const handleUserDeckClick = useCallback((deck) => {
    history.push(`${routes.deckPage}/${deck.virtualId}`)
  }, [history]);

  const handleClick = () => {
    props.handleClick(!isOpen ? language : null)
  }

  return (<AccordionItem isFirst={!index} ref={accordionRef}>
    <AccordionHeader onClick={handleClick} activeHover={!isOpen}>
      <AccordionHeaderLang>
        <StyledFlag language={language} />
        <div>{name}</div>
      </AccordionHeaderLang>
      <AccordionArrow isOpen={isOpen}>
        <AccordionArrowIcon />
      </AccordionArrow>
    </AccordionHeader>
    <AccordionBody
      isOpen={isOpen}
      ref={contentEl}
      style={
        isOpen
          ? { height: activeHeight }
          : { height: "0px" }
      }
    >
      <AccordionCardsContainer>
        {cards.map((deck) => (
          <CardCell key={deck.deckId}>
            <DeckCard deck={deck} onClick={handleUserDeckClick} />
          </CardCell>
        ))}
      </AccordionCardsContainer>
    </AccordionBody>
  </AccordionItem>)
}

export default memo(AccordionLang);