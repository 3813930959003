import React, { memo, useCallback } from "react"
import ReactDOM from "react-dom"
import CloseIcon from "../../../assets/svgs/CloseIcon"
import useTransitionState, { ENTERED, ENTERING, EXITED } from "../../../hooks/useTransitionState"
import { Overlay, AlertContainer, IconRow, Title, Message, StyledExclamationIcon, CloseButton } from "./styles"

const ModalAlert = ({
  show,
  title,
  onClose,
  children
}) => {
  const transition = useTransitionState(show, 330)

  const handleOverlayClick = useCallback((event) => {
    if (onClose && event.target === event.currentTarget) {
      onClose()
    }
  }, [onClose])

  const handleCloseClick = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  if (transition === EXITED) {
    return null
  }

  return (
    ReactDOM.createPortal((
      <Overlay data-show={transition === ENTERING || transition === ENTERED} onClick={handleOverlayClick}>
        <AlertContainer>
          <CloseButton onClick={handleCloseClick}><CloseIcon /></CloseButton>
          <IconRow><StyledExclamationIcon /></IconRow>
          <Title>{title}</Title>
          <Message>{children}</Message>
        </AlertContainer>
      </Overlay>
    ),
    document.getElementById("root"))
  )
}

export default memo(ModalAlert)
