import React, { memo, useCallback, useEffect, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as selectors from "../../store/selectors/decks"
import * as actions from "../../store/actions/decks"
import DeckInfo from "./DeckInfo"
import { DeckType } from "../../constants"
// import useOnMount from "../../hooks/useOnMount"
import Card from "./Card"
import {
  GlobalStyle,
  StatusContainer,
  TopContainer,
  TabsContainer,
  TabItem,
  ListContainer,
  PageControlContainer,
  StatsPlaceholder
} from "./styles"
import * as referralSelectors from "../../store/selectors/referral"
import PageControl from "../../common/components/PageControl"
import useOnChange from "../../hooks/useOnChange"
import { routes } from "../../utils/constants"
import * as authSelectors from "../../store/selectors/auth"
import { handleRedirect } from "../../utils/controls/routing"
import { subscribe, closeReferral } from "../../store/actions/referral"
import i18n, { Dictionary } from "./../../utils/i18n";

const PageSize = 12

const DeckPageMobile = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const dashboardViewType = location.state?.dashboardViewType ?? ""
  const deckType = match.params.mergeId ? DeckType.merge : DeckType.deck
  const deckId = match.params.mergeId ? parseInt(match.params.mergeId) : parseInt(match.params.deckId)
  const deckSelector = deckType === DeckType.merge ? selectors.mergeDetails : selectors.deckDetails

  const dispatch = useDispatch()
  const deckDetails = useSelector((state) => deckSelector(state, deckId))
  const viewState = useSelector(selectors.deckCardsViewState)
  const referral = useSelector(referralSelectors.referral)
  const userId = useSelector(authSelectors.currentUserId)

  const [showStats, setShowStats] = useState(false)

  useEffect(() => {
    if (referral?.params) {
      dispatch(actions.loadDeckReferralWithCardsRequest({ virtualId: referral?.params.deckId, page: 0, pageSize: PageSize }))
    } else if (deckType === DeckType.merge) {
      dispatch(actions.loadMergeWithCardsRequest({ mergeId: deckId, page: 0, pageSize: PageSize }))
    } else {
      dispatch(actions.loadDeckWithCardsRequest({ virtualId: deckId, page: 0, pageSize: PageSize }))
    }

    window.scrollTo(0, 0)
    return () => {
      if (referral?.params) {
        dispatch(closeReferral())
      }
      dispatch(actions.viewDeckCardsReset())
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => () => {
    dispatch(actions.viewDeckCardsReset())
  }, [dispatch])

  useOnChange(() => {
    window.scrollTo(0, 0)
  }, [viewState.actualPage])

  const handlePageChange = useCallback((page) => {
    if (deckType === DeckType.merge) {
      dispatch(actions.loadMergePageRequest({
        mergeId: deckId,
        page,
        pageSize: PageSize
      }))
    } else {
      dispatch(actions.loadDeckPageRequest({
        virtualId: deckId,
        page,
        pageSize: PageSize
      }))
    }
  }, [dispatch, deckType, deckId])

  const selectAction = () => {
    return isSubscribeMode() ? handleSubscribe : handleContinueStudy
  }

  const handleSubscribe = useCallback(() => {
    dispatch(subscribe({...referral, userId}))
  }, [dispatch, referral, userId])

  const handleContinueStudy = useCallback(() => {
    history.push(`${routes.studyModeBase}/${deckType}/${deckId}`)
  }, [history, deckType, deckId])

  // const handleShowStats = useCallback(() => {
  //   setShowStats(true)
  // }, [])

  const handleShowCards = useCallback(() => {
    setShowStats(false)
  }, [])

  const isSubscribeMode = () => {
    return referral?.params
  }

  const isSubscribed = () => {
    return referral?.redirectOnCompletion
  }

  const subscriptionFailed = () => {
    return referral?.error
  }

  const subscriptionDeckDetails = () => {
    return isSubscribeMode() ? {
      "deck": {
        "id": 1986,
        "originalId": 1986,
        "userId": userId,
        "authorId": userId,
        "isPublic": true,
        "origin": 1,
        "title": referral.params.unitName,
        "dateCreated": 1611164184751,
        "dateUpdated": 1611164184751,
        "deckId": referral.params.deckId,
        "description": null,//"Description placeholder",
        "totalCount": 0,
        "overdueCount": 0,
        "image": referral.params.unitThumbUrl,
        "language": "gavin"
      },
      "pagesCount": 1,
      "cards": [
      ]
    } : null
  }

  const renderContent = (deckDetails) => (
    <>
      <TopContainer>
        <DeckInfo
          referral={referral}
          deck={deckDetails.deck}
          backToList={dashboardViewType === "list"}
          onContinueStudy={selectAction()}
        />
        <TabsContainer>
          {/* <TabItem data-selected={showStats} onClick={handleShowStats}>
            {t(Dictionary.deck.data)}
          </TabItem> */}
          <TabItem data-selected={!showStats} onClick={handleShowCards}>
            {i18n.t(Dictionary.deck.cards)} ({deckDetails.deck.totalCount})
          </TabItem>
        </TabsContainer>
      </TopContainer>
      {showStats ? (
        <StatsPlaceholder>
          {i18n.t(Dictionary.deck.notStudiedYetText1)}
          <br />
          {i18n.t(Dictionary.deck.notStudiedYetText2)}
        </StatsPlaceholder>
      ) : (
        <>
          <ListContainer>
            {deckDetails.cards.map((card) => (
              <Card key={card.id} card={card} />
            ))}
          </ListContainer>
          <PageControlContainer>
            <PageControl
              page={viewState.page}
              totalPages={deckDetails.pagesCount}
              onChange={handlePageChange}
            />
          </PageControlContainer>
        </>
      )}
    </>
  )

  const renderPage = () => {

    if (isSubscribed()) {
      dispatch(closeReferral())
      handleRedirect(history, routes.dashboard)
      return null
    }

    if (subscriptionFailed()) {
      return <StatusContainer>Error: {referral.error.message}</StatusContainer>
    }

    if (deckDetails) {
      return renderContent(deckDetails)
    }

    if (viewState.error) {
      if (isSubscribeMode()) {
        return renderContent(subscriptionDeckDetails())
      } else {
        return <StatusContainer>Error: {viewState.error.message}</StatusContainer>
      }
    }

    return <StatusContainer>{i18n.t(Dictionary.common.loading)}...</StatusContainer>
  }

  return (
    <>
      <GlobalStyle />
      {renderPage()}
    </>
  )
}

export default memo(DeckPageMobile)
