import styled, { css } from "styled-components"
import { LAPTOP_MAX } from "../../../common/breakpoints"

const borderColor = "#DBE5EF"

export const TopBarContainer = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  height: 68px;
  border-bottom: solid 1px ${borderColor}; 

  &[data-completed='true'] {
    border-bottom-color: #C9DEEA63;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 60px;
  }
`

const RightBorder = css`
  border-right: solid 1px ${borderColor};

  ${TopBarContainer}[data-completed='true'] & {
    border-right-color: #C9DEEA63;
  }
`

const BarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00AEE0;
  font-size: 16px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;

  &:hover {
    color: white;
    background-color: #00AEE0;
  }

  &:active {
    opacity: 0.8;
  }

  ${TopBarContainer}[data-completed='true'] & {
    color: white;
  }

  > svg:first-child {
    margin-right: 8px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
  }
`

export const DeckNameContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 260px;
  padding: 0 0 0 31px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 0 0 22px;
  }
`

export const DeckName = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  height: 36px;
  padding: 0 16px;
  border: 1px solid ${borderColor};
  border-radius: 6px;
  font-size: 12px;
  min-width: 40px;
  white-space: nowrap;
  overflow: hidden;
  color: #414142;
  
  span strong {
    font-weight: 800;
    opacity: 1;
  }

  span span {
    opacity: 0.74;
  }

  ${TopBarContainer}[data-completed='true'] & {
    color: white;

    strong {
      color: white;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
  }
`

export const ProgressSpacer = styled.div`
  flex: 1;
  width: 193px;
  ${RightBorder}
`

export const ProgressContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  padding: 0 32px;
  min-width: 200px;
`

export const DeckNameEllipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ProgressBarOuter = styled.div`
  flex: 2;
  height: 26px;
  border-radius: 34px;
  overflow: hidden;
  box-shadow: inset 1px 1px ${borderColor}, inset -1px -1px ${borderColor};
  margin-right: 16px;
  min-width: 100px;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 19px;
  }
`

export const ProgressBarInner = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #1BD46F;
  height: 100%;
  min-width: 60px;
  font-size: 13px;
  color: white;
  padding-left: 22px;
  transition: width 0.3s ease-in-out;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
  }
`

export const ProgressCounter = styled.div`
  font-size: 12px;
  color: #7E7E7F;

  ${TopBarContainer}[data-completed='true'] & {
    color: white;
  }
`

export const BackButton = styled(BarButton)`
  width: 155px;
  font-size: 15px;
  color: #F33660;
  ${RightBorder}

  &:hover {
    background-color: #F33660;
  }

  > svg:first-child {
    margin-right: 12px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
    width: 120px;
  }
`

export const TourButton = styled(BarButton)`
  width: 194px;
`

export const ControlsButton = styled(BarButton)`
  width: 166px;
  ${RightBorder}

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 128px;
  }

  ${TopBarContainer}[data-completed='true'] & {
    color: white;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  font-size: 28px;
  color: #D0D0D0;

  ${TopBarContainer}[data-completed='true'] & {
    color: white;
  }
`
