import React, { memo } from "react"

const ChineseIcon = (props) => (
  <svg viewBox="0 0 28 28" {...props}>
    <path d="M14.091 25.9165C20.8043 25.9165 26.2465 20.4743 26.2465 13.761C26.2465 7.04767 20.8043 1.60547 14.091 1.60547C7.37775 1.60547 1.93555 7.04767 1.93555 13.761C1.93555 20.4743 7.37775 25.9165 14.091 25.9165Z" fill="#D31C24"/>
    <path d="M8.86533 8.71875L9.91494 11.9515H13.3144L10.5668 13.9527L11.6164 17.1855L8.86416 15.1889L6.11189 17.1855L7.16731 13.9527L4.41504 11.9515H7.81457L8.86533 8.71875Z" fill="#F7BD31"/>
    <path d="M16.6335 20.1632L15.83 19.1742L14.6416 19.6407L15.3308 18.5713L14.5273 17.5776L15.7588 17.9054L16.4586 16.8359L16.525 18.1095L17.7612 18.4372L16.5682 18.8932L16.6335 20.1632Z" fill="#F7BD31"/>
    <path d="M18.2315 17.2572L18.6117 16.0397L17.5703 15.3026L18.8449 15.2839L19.2204 14.0664L19.6345 15.2734L20.908 15.2595L19.8864 16.0198L20.2957 17.2269L19.2543 16.4898L18.2315 17.2572Z" fill="#F7BD31"/>
    <path d="M20.3853 10.2443L19.8243 11.3907L20.7375 12.2793L19.4779 12.0985L18.917 13.2391L18.6978 11.9843L17.4336 11.8035L18.5648 11.2087L18.3456 9.94922L19.2587 10.8379L20.3853 10.2443Z" fill="#F7BD31"/>
    <path d="M16.6677 6.77344L16.5721 8.0423L17.7558 8.52277L16.515 8.82717L16.4252 10.1018L15.7546 9.01843L14.5137 9.3228L15.3359 8.34784L14.6607 7.2691L15.8444 7.74957L16.6677 6.77344Z" fill="#F7BD31"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26802 0 14 0C21.732 0 28 6.26802 28 14C28 21.732 21.732 28 14 28C6.26802 28 0 21.732 0 14ZM14 2C7.37258 2 2 7.37259 2 14C2 20.6274 7.37258 26 14 26C20.6275 26 26 20.6274 26 14C26 7.37258 20.6275 2 14 2Z" fill="white"/>
  </svg>
)

export default memo(ChineseIcon)
