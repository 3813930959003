import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    fill: currentColor;
    font-size: 14px; 
    font-family: FiraSans-SemiBold, Fira Sans;
    font-weight: 600;
  }
  .b, .d { fill: none; }
  .b { stroke: currentColor; stroke-width: 1.5px; }
  .c { stroke: none; }
`

const DigitIcon = ({ children, ...props }) => (
  <StyledSvg width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g transform="translate(-43 -61)">
      <text className="a" transform="translate(50 77)">
        <tspan x="0" y="0">{children}</tspan>
      </text>
      <g className="b" transform="translate(43 61)">
        <rect className="c" width="22" height="22" rx="5"/>
        <rect
          className="d" x="0.75" y="0.75" width="20.5"
          height="20.5" rx="4.25"
        />
      </g>
    </g>
  </StyledSvg>
)

export default memo(DigitIcon)
