import React, { memo, useRef , useCallback , useMemo } from "react"

import { useMediaQuery } from "react-responsive"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

import {
  SideContainer,
  SideMainContent,
  SideMainContentContainer,
  SideScroll,
  TextsContainer,
  SideScrollContent,
  CardHeader,
  FlipButton,
  StyledFlipIcon,
  HeaderRight,
  TagsContainer,
  TagContainer,
  TagLabel,
  StyledAudioPlayer,
  SideBSideATexts,
  SideBSideAHeader,
  SideBSideAParagraph,
  SideBTranscription,
  SideBParagraph,
  SideImageContainer,
  MobileImage
} from "./styles"
import { getTextSizes } from "./textSizes"
import useTextSizes from "./useTextSizes"

const SideB = ({
  visible,
  noteA,
  note,
  isMandarim,
  autoplay = false,
  onFlipButtonHover,
  onFlip,
  playerReady,
  handlePlaybackRate
}) => {
  const {t} = useTranslation();
  const {
    headers: sideAHeaders,
    paragraphs: sideAParagraphs,
    audios: sideAAudios,
    images: sideAImages
  } = noteA

  const {
    paragraphs, transcription, tags, audios: sideBAudios
  } = note;

  const audios = sideBAudios && sideBAudios[0]?.url ? sideBAudios : sideAAudios;
  const hasAudio = audios && audios.length > 0;

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const isLaptop = useMediaQuery({ query: `(max-width: ${LAPTOP_MAX}px)` })
  const isAsian = (sideAParagraphs && sideAParagraphs.length > 0) || (sideAHeaders[0]?.content || sideAHeaders[0])?.length < 4;
  const testSizes = getTextSizes(isMobile, isLaptop, isAsian, true)
  const scrollRef = useRef()
  const texts = useMemo(() => ({
    sideAHeader: sideAHeaders ?? [],
    sideAParagraph: sideAParagraphs ?? [],
    paragraph: paragraphs ?? [],
    transcription: isMandarim && transcription ? transcription : []
  }), [sideAHeaders, sideAParagraphs, paragraphs, transcription, isMandarim])
  const { sizes, makeSpanRef } = useTextSizes(scrollRef, testSizes, texts, [], true)
  const hasSideAContent = ((sideAHeaders?.length ?? 0) + (sideAParagraphs?.length ?? 0)) > 0

  const onFlipButtonMouseenter = useCallback(() => {
    onFlipButtonHover(true)
  }, [onFlipButtonHover])

  const onFlipButtonMouseleave = useCallback(() => {
    onFlipButtonHover(false)
  }, [onFlipButtonHover])

  return (
    <SideContainer data-back-side={true}>
      {!isMobile && sideAImages && sideAImages.length > 0 && (
        <SideImageContainer style={{ backgroundImage: `url(${sideAImages[0].url})` }} />
      )}
      <SideMainContentContainer>
        <SideMainContent>
          <CardHeader>
            <FlipButton
              onMouseEnter={onFlipButtonMouseenter}
              onMouseLeave={onFlipButtonMouseleave}
              onClick={onFlip}
            >
              <StyledFlipIcon />
              <span>{t(Dictionary.studySession.turnCard)}</span>
            </FlipButton>
            <HeaderRight>
              A&nbsp;/&nbsp;<strong>B</strong>
            </HeaderRight>
          </CardHeader>
          {tags && tags.length > 0 && (
            <TagsContainer>
              {tags.map(({ tag }, index) => (
                <TagContainer key={index}>
                  <TagLabel>{tag}</TagLabel>
                </TagContainer>
              ))}
            </TagsContainer>
          )}
          <SideScroll ref={scrollRef}>
            <SideScrollContent onClick={onFlip}>
              <TextsContainer hasAudio={hasAudio}>
                {isMobile && sideAImages && sideAImages.length > 0 && (
                  <MobileImage style={{ backgroundImage: `url(${sideAImages[0].url})` }} />
                )}
                {hasSideAContent && (
                  <SideBSideATexts>
                    {sideAHeaders && sideAHeaders.map((header, index) => (
                      <SideBSideAHeader key={index} style={{ fontSize: sizes.sideAHeader }}>
                        <span ref={makeSpanRef(`a-hdr-${index}`)}>
                          {header.content}
                        </span>
                      </SideBSideAHeader>
                    ))}
                    {sideAParagraphs && sideAParagraphs.map((header, index) => (
                      <SideBSideAParagraph key={index} style={{ fontSize: sizes.sideAParagraph }}>
                        <span ref={makeSpanRef(`a-par-${index}`)}>
                          {header.content}
                        </span>
                      </SideBSideAParagraph>
                    ))}
                  </SideBSideATexts>
                )}
                {paragraphs && paragraphs.map((header, index) => (
                  <SideBParagraph key={index} style={{ fontSize: sizes.paragraph }}>
                    <span ref={makeSpanRef(`par-${index}`)}>
                      {header.content}
                    </span>
                  </SideBParagraph>
                ))}
                {isMandarim && transcription && transcription.map((text, index) => (
                  <SideBTranscription key={index} style={{ fontSize: sizes.transcription }}>
                    <span ref={makeSpanRef(`a-par-${index}`)}>
                      {text.content}
                    </span>
                  </SideBTranscription>
                ))}
              </TextsContainer>
            </SideScrollContent>
          </SideScroll>
          {hasAudio && (
            <StyledAudioPlayer
              needPlayerReady
              url={audios[0].url}
              enabled={visible}
              autoplay={autoplay}
              playerReady={playerReady}
              handlePlaybackRate={handlePlaybackRate}
            />
          )}
        </SideMainContent>
      </SideMainContentContainer>
    </SideContainer>
  )
}

export default memo(SideB)