import React, { memo } from "react"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../common/breakpoints"
import SessionCompleteDesktop from "./SessionCompleteDesktop"
import SessionCompleteMobile from "./SessionCompleteMobile"

const SessionComplete = ({
  show,
  onGoBack
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })

  if (isMobile) {
    return <SessionCompleteMobile show={show} onGoBack={onGoBack} />
  }

  return (
    <SessionCompleteDesktop show={show} />
  )
}

export default memo(SessionComplete)