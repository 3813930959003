import React, { memo } from "react"

const NextPageIcon = (props) => (
  <svg height="1em" viewBox="0 0 10.346 16.143" {...props}>
    <g transform="translate(0.5 1.587)">
      <path fill="currentColor" stroke="currentColor" d="M9.009,8.226,1.96,14.662a1.226,1.226,0,0,1-1.624,0,.985.985,0,0,1,0-1.482l6.238-5.7L.336,1.79a.985.985,0,0,1,0-1.482,1.227,1.227,0,0,1,1.624,0L9.01,6.743a.985.985,0,0,1,0,1.482Z" transform="translate(0 -1)" />
    </g>
  </svg>
)

export default memo(NextPageIcon)
