import styled, { createGlobalStyle } from "styled-components"
import FluencyLogoLight from "../../../../assets/svgs/FluencyLogoLight"
import sidebarCover from "../../../../assets/graphics/login/sidebar-cover-2x.png"
import { LAPTOP_MAX, MOBILE_MAX, TABLET_MAX } from "../../../../common/breakpoints"

export const GlobalStyle = createGlobalStyle`
  body {
    @media (max-width: ${MOBILE_MAX}px) {
      background: linear-gradient(180deg, #FF5363 0%, #F93779 100%) #F93779;
    }
  }
`

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export const PanelContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 616px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #FF5363 0%, #F93779 100%);

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 416px;
  }

  @media (max-width: ${TABLET_MAX}px) {
    display: none;
  }
`

export const Logo = styled(FluencyLogoLight)`
  font-size: 50px;
  margin: 55px 0 46px 46px;
  fill: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 35px;
    margin: 38px 0 32px 32px;
  }
`

export const CoverImage = styled.div`
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-image: url(${sidebarCover});
  margin: 0 -90px -36px -120px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: -10px -60px -22px -80px;
  }
`

export const Slogan = styled.div`
  padding-left: 65px;
  padding-bottom: 86px;
  font-family: BlackerDisplayHeavy, Arial, Helvetica, Sans-Serif;
  font-size: 96px;
  line-height: 1;
  color: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-bottom: 46px;
    padding-left: 45px;
    font-size: 66px;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  left: 616px;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  @media (max-width: ${LAPTOP_MAX}px) {
    left: 416px;
  }

  @media (max-width: ${TABLET_MAX}px) {
    left: 0;
  }
`