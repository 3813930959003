import styled, { keyframes } from "styled-components"
import { BEFORE_ENTER, ENTERING, EXITED, EXITING } from "../../../../hooks/useTransitionState"
import LoadingSpinner from "../../../../legacyComponents/Desktop/Animations/LoadingSpinner"

export const ButtonContainer = styled.button`
  position: relative;
  width: 54px;
  height: 54px;
  top: 0;

  &[data-pending='true'] {
    pointer-events: none;
  }

  &[data-transition='${EXITED}'] {
    visibility: hidden;
  }

  &[data-transition='${BEFORE_ENTER}'] {
    top: -100px;
  }

  &[data-transition='${ENTERING}'] {
    transition: top 200ms cubic-bezier(0.33, 1, 0.68, 1);
  }

  &[data-transition='${EXITING}'] {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.33, 1, 0.68, 1);
  }
`

export const Content = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #00AEE0;

  &[data-show-next-due='true'] {
    color: #F24556;
  }
`

export const Spinner = styled(LoadingSpinner)`
  stroke: #00AEE0;
`

export const ProgressSvg = styled.svg`
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  stroke: #00AEE0;
  fill: transparent;
`

const stroke = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

export const ProgressCircle = styled.circle`
  stroke-dasharray: 170;
  stroke-dashoffset: 170;
  animation: 1s linear forwards ${stroke};
`
