import React, { memo } from "react"
// import { links } from "../../../constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n"
import {
  Container,
  NoticeBlock,
  Title,
  SubTitle,
  ContainerButton,
  // AccessButton,
  // FluencyTVButton
} from "./styles"

const NoDecksNotice = () => {
  const {t} = useTranslation();
  // const handleAccessCourse = () => {
  //   window.open(links.accessCourse, "_blank")
  // }

  // const handleAccessFluencyTV = () => {
  //   window.open(links.fluencyTVAcademy, "_blank")
  // }

  return (
    <Container>
      <NoticeBlock>
        <Title>
          {t(Dictionary.dashboard.your)}<br />{t(Dictionary.dashboard.decks)}
        </Title>
        <SubTitle>
          <strong>{t(Dictionary.common.welcome)}</strong>&nbsp;{t(Dictionary.dashboard.noDecks)}
        </SubTitle>
        <ContainerButton>
          {/* <AccessButton onClick={handleAccessCourse}>ACESSAR A FLUENCY ACADEMY</AccessButton> */}
          {/* <FluencyTVButton onClick={handleAccessFluencyTV}>{t(Dictionary.dashboard.buttonFluencyTV)}</FluencyTVButton> */}
        </ContainerButton>
      </NoticeBlock>
    </Container>
  )
}

export default memo(NoDecksNotice)
