import { useLayoutEffect, useRef, useState, useCallback, useMemo } from "react"
import { mapValues } from "lodash"
import useSize from "@react-hook/size"
import useOnChange from "../../../hooks/useOnChange"


export default (scrollRef, availableSizes, texts, dependencies = []) => {
  const spanRefs = useRef({})
  const [currentSizesIndex, setCurrentSizesIndex] = useState(null)
  const [width, height] = useSize(scrollRef)

  const sizes = useMemo(() => {
    const textLengths = mapValues(
      texts,
      (items) => items.reduce((totalLength, text) => totalLength + text.content.length, 0)
    )

    return availableSizes.filter((size) => {
      for (const [type, params] of Object.entries(size)) {

        const isTrue = params.maxCharacters && textLengths[type] && textLengths[type] > params.maxCharacters;
        if (isTrue) {
          return false
        }
      }

      return true
    })
  }, [availableSizes, texts])


  const spanRefCallback = (key) => (element) => {
    if (element) {
      spanRefs.current[key] = element
    } else {
      delete spanRefs.current[key]
    }
  }

  const isNeedShrink = useCallback(() => {
    const scroll = scrollRef.current;
    const firstChild = scroll.firstChild;

    const sizeParent = scroll.getBoundingClientRect();
    const sizeFirstChild = firstChild.getBoundingClientRect();

    const parentHeight = Math.floor(sizeParent.height);
    const textHeight = Math.floor(sizeFirstChild.height);

    if (textHeight > parentHeight) {
      return true
    }

    for (const [, span] of Object.entries(spanRefs.current)) {
      const isNeed = span.offsetWidth > (scroll.clientWidth - 16);
      if (isNeed) {
        return true
      }
    }

    return false
  }, [scrollRef])
    

  useLayoutEffect(() => {
    const index = currentSizesIndex ?? 0
    const _isNeedShrink = isNeedShrink();

    if (
      _isNeedShrink &&
      sizes.length > 0 &&
      index < (sizes.length - 1)
    ) {
      setCurrentSizesIndex(index + 1)
    } else if (currentSizesIndex === null) {
      setCurrentSizesIndex(0)
    }
  }, [isNeedShrink, sizes, currentSizesIndex])

  useOnChange(() => {
    setCurrentSizesIndex(null)
  }, [sizes, ...dependencies])

  useOnChange(([prevWidth, prevHeight]) => {
    if (prevWidth && prevHeight) {
      setCurrentSizesIndex(null)
    }
  }, [width, height])

  const currentSizes = sizes[currentSizesIndex ?? 0]

  return {
    sizes: mapValues(currentSizes, (({ size }) => `${size}px`)),
    makeSpanRef: spanRefCallback
  }
}