import styled from "styled-components"
import { TopBarContainer } from "../../../common/styles/topBar"
import TopBackButton from "../../../common/components/TopBackButton"
import { LAPTOP_MAX } from "../../../common/breakpoints"
import FoxIcon from "../../../assets/svgs/FoxIcon"

export const StyledTopBarContainer = styled(TopBarContainer)`
  background-color: #00294D;
  border-bottom: solid 1px #D6DAEB48;
`

export const StyledBackButton = styled(TopBackButton)`
  border-right: 1px solid #D6DAEB48;

  .top-back-button_back-button {
    color: white;
  }
`

export const BreadcrumbContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 8px;
  font-size: 14px;
  color: #B6C1CB;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 21px;
    font-size: 10px;
  }
`

export const DeckName = styled.span`
  color: #6587A8;
`

export const Logo = styled(FoxIcon)`
  color: white;
  opacity: 0.5;
  font-size: 20px;
  margin-right: 14px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    margin-right: 10px;
  }
`
