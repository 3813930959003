import styled from "styled-components"
import OopsPlaceholder from "../../../assets/svgs/OopsPlaceholder"
import OverdueCardsIcon from "../../../assets/svgs/OverdueCardsIcon"
import TotalCardsIcon from "../../../assets/svgs/TotalCardsIcon"
import Flag from "../../../common/components/Flag"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"

export const CardContainer = styled(ImageContainer)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px #3707179E;

  .image-container__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 18px 22px 18px;
  }
`

export const OopsImage = styled(OopsPlaceholder)`
  font-size: 49px;
  color: white;
  opacity: 0.18;
`

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledFlag = styled(Flag)`
  width: 18px;
  height: 18px;
  
  .flag__circle {
    box-shadow: inset 0px 0px 0px 1px white;
  }
`

export const CountersContainer = styled.div`
  display: flex;
  align-items: center;
  color: #F7F7F7;
`

export const CounterLabel = styled.div`
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-right: 6px;
  text-align: right;
`

export const OverdueIcon = styled(OverdueCardsIcon)`
  font-size: 14px;
  margin-right: 11px;
`

export const TotalIcon = styled(TotalCardsIcon)`
  font-size: 17px;
`

export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
`

export const Description = styled.div`
  font-style: italic;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.85;
`
