import styled from "styled-components"
import ChevronDown from "./ChevronDown"

export const Container = styled.div`
  display: inline-block;
`

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 1;
  padding: 8px 0;
  color: white;
  text-transform: uppercase;
`

export const StyledChevron = styled(ChevronDown)`
  font-size: 4px;
  color: white;
  margin-left: 6px;
`

export const BottomContainer = styled.div`
  position: relative;
`

export const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  max-width: 200px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 3px 3px 6px #00000040;
  padding: 6px 0;
  font-size: 10px;
  color: black;
  opacity: 0;
  z-index: 100;
  width: max-content;
  transition: opacity 0.2s ease-out;

  &[data-show='true'] {
    opacity: 1;
  }
`

export const MenuItem = styled.button`
  display: block;
  padding: 0 16px;
  height: 44px;
  text-transform: uppercase;

  :not(:last-child) {
    border-bottom: solid 1px lightgray;
  }
`
