import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    fill: currentColor;
  }
`

const TermsIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 16.842 13.1" {...props}>
    <path className="a" d="M15.855,2.251c-1.6.091-4.787.422-6.753,1.625a.45.45,0,0,0-.213.385V14.9a.463.463,0,0,0,.681.394,17.834,17.834,0,0,1,6.395-1.372.912.912,0,0,0,.878-.9V3.149a.917.917,0,0,0-.987-.9ZM7.741,3.877C5.775,2.673,2.59,2.343.988,2.251a.917.917,0,0,0-.988.9v9.879a.912.912,0,0,0,.878.9,17.832,17.832,0,0,1,6.4,1.373.461.461,0,0,0,.679-.394V4.257A.44.44,0,0,0,7.741,3.877Z" transform="translate(0 -2.25)"/>
  </StyledSvg>
)

export default memo(TermsIcon)
