import styled from "styled-components"
import ExclamationIcon from "../../../assets/svgs/ExclamationIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../breakpoints"

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 330ms ease;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &[data-show='true'] {
    opacity: 1;
  }
`

export const AlertContainer = styled.div`
  position: relative;
  background-color: #F24556;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 42px 38px 47px 38px;
  color: white;
  box-sizing: border-box;
  width: 419px;

  @media (max-width: ${LAPTOP_MAX}px) {
    border-radius: 14px;
    padding: 29px 27px 33px 27px;
    width: 293px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 25px;
    border-radius: 10px;
    width: auto;
    padding: 22px 18px 20px 18px;
  }
`

export const IconRow = styled.div`
  opacity: 0.52;
  line-height: 0;
  margin-bottom: 28px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`

export const StyledExclamationIcon = styled(ExclamationIcon)`
  font-size: 32px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 22px;
  }
`

export const Title = styled.div`
  font-family: "BlackerDisplayExtraBold";
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 8px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 22px;
    margin-bottom: 6px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 20px;
    margin-bottom: 7px;
  }
`

export const Message = styled.div`
  font-size: 16px;
  line-height: 22px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.65;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  font-size: 14px;
  color: white;
  padding: 15px;
  top: 11px;
  right: 15px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 10px;
    padding: 10px;
    top: 8px;
    right: 10px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`
