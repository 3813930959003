import { all, call } from "redux-saga/effects"
import auth from "./auth"
import decks from "./decks"
import sessions from "./sessions"
import referral from "./referral"

export default function* () {
  yield all([
    call(auth),
    call(decks),
    call(sessions),
    call(referral)
  ])
}
