import React, { memo, useCallback } from "react"
import { GradeLabels } from "./constants"
import { useTranslation } from "react-i18next"
import MobileGradeButton from "./MobileGradeButton"
import {
  ButtonContainer,
  Button,
  ShortcutTip,
  DaysUntilReview,
  ButtonSpinner,
  ButtonShadow,
  ButtonWrapper
} from "./styles"
import { Dictionary } from "../../../utils/i18n"

const GradeButton = ({
  index,
  transition,
  isMobile,
  grade,
  disabled,
  pending,
  nextDue,
  onClick
}) => {
  const {t} = useTranslation();
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(grade)
    }
  }, [grade, onClick])

  const now = Date.now()
  let nextDueLabel = null

  if (nextDue) {
    const hours = Math.max(Math.round((nextDue - now) / 3600000), 0)

    nextDueLabel = hours > 23 ? `${Math.round(hours / 24)}D` : `${hours}H`
  }

  if (isMobile) {
    return (
      <MobileGradeButton
        index={index}
        transition={transition}
        grade={grade}
        disabled={disabled}
        pending={pending}
        nextDueLabel={nextDueLabel}
        onClick={handleClick}
      />
    )
  }

  return (
    <ButtonContainer>
      <ButtonWrapper data-disabled={disabled}>
        <ButtonShadow />
        <Button disabled={disabled} data-pending={pending} onClick={handleClick}>
          {pending ? <ButtonSpinner /> : <span>{GradeLabels[grade] && t(GradeLabels[grade]?.translationLabel)}</span>}
        </Button>
      </ButtonWrapper>
      <ShortcutTip data-disabled={disabled || pending}>{t(Dictionary.studySession.shortcut)} {GradeLabels[grade]?.tipNumber}</ShortcutTip>
      <DaysUntilReview data-show={nextDue !== null}>{nextDueLabel}</DaysUntilReview>
    </ButtonContainer>
  )
}

export default memo(GradeButton)
