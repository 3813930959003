import React, { memo, useState } from "react"
// import LinesEllipsis from "react-lines-ellipsis"
// import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC"
import FlipIcon from "../../../assets/svgs/FlipIcon"
import {
  Container,
  SideAContainer,
  TopRow,
  ForeignText,
  TranslatedText,
  FlipButton,
  BottomRow,
  StyledPlayer,
  SideBContainer,
  SideBTopRow,
  ReviewCount,
  // MemoryType,
  SideBFlipButton,
  BarsContainer,
  // MinBarHeight,
  // Bar,
  // BarName,
  // BarValue
} from "./styles";

// const Ellipsis = responsiveHOC()(LinesEllipsis)

const Card = ({ card }) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleFlip = () => {
    setIsFlipped(prev => !prev)
  }

  // const maxGradings = Math.max(
  //   card.gradingsCountEasy,
  //   card.gradingsCountNormal,
  //   card.gradingsCountHard
  // )

  // const minGradings = Math.min(
  //   card.gradingsCountEasy,
  //   card.gradingsCountNormal,
  //   card.gradingsCountHard
  // )

  // const barStyle = (value) => {
  //   const percentage = maxGradings > 0 ? (value - minGradings) / (maxGradings - minGradings) : 0

  //   return {
  //     height: `calc((100% - ${MinBarHeight}px) * ${percentage} + ${MinBarHeight}px)`
  //   }
  // }

  return (
    <Container>
      {isFlipped ? (
        <SideBContainer>
          <SideBTopRow>
            <div>
              <ReviewCount>{card.gradingsCountReviews || 0} review{card.gradingsCountReviews > 1 ? 's' : ''}</ReviewCount>
              {/* <MemoryType>Short term memory</MemoryType> */}
            </div>
            <SideBFlipButton onClick={handleFlip}>
              <FlipIcon />
            </SideBFlipButton>
          </SideBTopRow>
          <BarsContainer>
            {/* <Bar style={barStyle(card.gradingsCountEasy)}>
              <BarName>Fácil</BarName>
              <BarValue>{card.gradingsCountEasy}</BarValue>
            </Bar>
            <Bar style={barStyle(card.gradingsCountNormal)}>
              <BarName>Bom</BarName>
              <BarValue>{card.gradingsCountNormal}</BarValue>
            </Bar>
            <Bar style={barStyle(card.gradingsCountHard)}>
              <BarName>Difícil</BarName>
              <BarValue>{card.gradingsCountHard}</BarValue>
            </Bar> */}
          </BarsContainer>
        </SideBContainer>
      ) : (
        <SideAContainer>
          <TopRow>
            <ForeignText>
              {card.foreignText}
            </ForeignText>
            <FlipButton onClick={handleFlip}>
              <FlipIcon />
            </FlipButton>
          </TopRow>
          <BottomRow>
            <TranslatedText>
              {card.translatedText}
            </TranslatedText>
            {card.audio && <StyledPlayer url={card.audio} />}
          </BottomRow>
        </SideAContainer>
      )}
    </Container>
  )
}

export default memo(Card)
