import React, { memo } from "react"

const ItalianIcon = (props) => (
  <svg height="28" viewBox="0 0 28 28"  {...props}>
    <path d="M10.8054 1.31519L3.87207 5.07031L0.87207 15.0703L3.87207 23.0703L10.8054 27.0703V1.31519Z" fill="#9AFFCF"/>
    <path d="M17.8721 1.07031H9.87207V27.0703H17.8721V1.07031Z" fill="white"/>
    <path d="M25.4546 7.3849L21.6972 3.85163L17.8721 1.07031V27.0703L21.6972 24.0418L25.8721 19.499L26.8721 13.5703L25.4546 7.3849Z" fill="#D31B24"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26802 0 14 0C21.732 0 28 6.26802 28 14C28 21.732 21.732 28 14 28C6.26802 28 0 21.732 0 14ZM14 2C7.37258 2 2 7.37259 2 14C2 20.6274 7.37258 26 14 26C20.6275 26 26 20.6274 26 14C26 7.37258 20.6275 2 14 2Z" fill="white"/>
  </svg>
)

export default memo(ItalianIcon)
