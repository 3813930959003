import styled from "styled-components"
import Button from "../../../common/components/Button"
import buttonArrow from "../images/button-arrow.svg"

export const Container = styled.div`
  box-shadow: 0px 3px 6px #0310BE3B;
  border-radius: 10px;
  overflow: hidden;

  &[data-disabled='true'] {
    box-shadow: none;
  }
`

export const TopContainer = styled.div.attrs({
  className: "mode-button__top-container"
})`
  display: flex;
  align-items: center;
  color: white;
  background: linear-gradient(180deg, #FF5363 0%, #F93779 100%);
  min-height: 54px;
  box-sizing: border-box;
  padding: 11px 18px 11px 16px;
  cursor: pointer;

  ${Container}[data-disabled='true'] & {
    border-radius: 10px;
    color: white;
    background: #11395D;
    border: 1px solid #D6DAEB48;
  }
`

export const Labels = styled.div`
  flex: 1;
  margin-right: 8px;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 2px;
`

export const Description = styled.div`
  font-size: 11px;
  line-height: 13px;
  opacity: 0.65;
`

export const HourglassIcon = styled.img`
  flex-shrink: 0;
  width: 24px;
  height: 28px;
`

export const BottomWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transition: height 300ms ease-out;
`

export const BottomContainer = styled.div.attrs({
  className: "mode-button__bottom-container"
})`
  background-color: #FA3E73;
  border-top: solid 1px #FC476D;
  padding: 16px 16px 19px 16px;
  box-sizing: border-box;

  &[data-in-transition='true'] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
`

export const Notice = styled.div`
  font-style: italic;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.51;
  letter-spacing: 0px;
  margin-bottom: 14px;
`

export const ContinueButton = styled(Button)`
  width: 100%;
  justify-content: space-between;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  text-transform: uppercase;
`

export const ArrowIcon = styled.img.attrs({
  src: buttonArrow
})`
  width: 24px;
  height: 24px;
`
