import styled, { css } from "styled-components"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX, LAPTOP_SMALL_MAX } from "../../../common/breakpoints"
import AudioPlayer from "../../../common/components/AudioPlayer"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"
import GraphIcon from "./GraphIcon"

export const Container = styled.div`
  display: flex;
  height: 98px;
  border: 1px solid #E5E5E5;
  background-color: white;
  border-radius: 10px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 92px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 68px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 87px;
  }

  :not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 14px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      margin-bottom: 20px;
    }
  }
`

export const DadosContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  border-right: 1px solid #E5E5E5;
  cursor: pointer;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    width: 125px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 95px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    width: 119px;
  }
`

export const DadosBg = styled.div`
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(180deg, #FF5363 0%, #F93779 100%);
  opacity: 0;
  transition: opacity 0.2s ease-out;

  ${DadosContainer}[data-active='true'] & {
    opacity: 1;
  }
`

export const DadosContent = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
`

export const StyledGraphIcon = styled(GraphIcon)`
  height: 12px;
  margin-right: 7px;
  color: #FF4371;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 11px;
    margin-right: 7px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 12px;
    margin-right: 6px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 11px;
    margin-right: 7px;
  }

  ${DadosContainer}[data-active='true'] & {
    color: white;
  }
`

export const DadosLabel = styled.div`
  position: relative;
  bottom: 1px;
  font-size: 16px;
  color: black;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 14px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 13px;
  }

  ${DadosContainer}[data-active='true'] & {
    color: white;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  flex: 1;
`

export const SideContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 0 36px 0 30px;
  z-index: 1;
  transition: opacity 0.2s ease-out;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 0 31px 0 26px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 25px 0 21px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    padding: 0 22px 0 25px;
  }

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
`

const EllipsisContainer = css`
  min-width: 40px;
`

export const TextsAndImageContainer = styled.div`
  display: flex;
  align-items: center;
  ${EllipsisContainer}
`

export const TextsContainer = styled.div`
  flex: 1;
  margin-right: 16px;
  ${EllipsisContainer}

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-right: 11px;
  }
`

const Ellipsis = css`
  ${EllipsisContainer}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const ForeignText = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #050304;
  margin-bottom: 4px;
  ${Ellipsis}

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 3px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
  }
`

export const TranslatedText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #9B9B9B;
  opacity: 0.76;
  ${Ellipsis}

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
  }
`

export const StyledAudioPlayer = styled(AudioPlayer)`
  display: flex;
  align-items: center;

  .audio-player__duration {
    font-size: 16px;
    color: #8B8A8A;
    min-width: 42px;
    text-align: right;
    opacity: 1;
    margin: 0 14px 0 0;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      font-size: 14px;
      min-width: 38px;
      margin: 0 12px 0 0;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 11px;
      min-width: 29px;
      margin: 0 10px 0 0;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      font-size: 13px;
      min-width: 38px;
      margin: 0 14px 0 0;
    }
  }

  .audio-player__waveform {
    width: 66px;
    height: 40px;
    margin-right: 10px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      width: 51px;
      margin-right: 9px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      width: 46px;
      margin-right: 7px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      width: 58px;
      margin-right: 9px;
    }
  }

  .audio-player__play-button {
    width: 40px;
    height: 40px;
    font-size: 16px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      width: 37px;
      height: 37px;
      font-size: 15px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      width: 28px;
      height: 28px;
      font-size: 11px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      width: 35px;
      height: 35px;
      font-size: 14px; 
    }
  }
`

export const StyledImage = styled(ImageContainer).attrs({
  fallback: "Oops!"
})`
  width: 100px;
  height: 65px;
  margin-right: 22px;
  border: solid 1px #70707040;
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 70px;
    height: 46px;
    margin-right: 15px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    width: 94px;
    height: 61px;
    margin-right: 20px;
  }

  .image-container__image {
    filter: none;
  }

  .image-container__fallback {
    color: white;
  }
`

export const StatsTextsContainer = styled.div`
  margin-right: 64px;
  ${EllipsisContainer}

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-right: 44px;
  }
`

export const ReviewsCount = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  ${Ellipsis}

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 3px;
  }
`

export const MemoryType = styled.div`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: block;
  opacity: 0.33;
  ${Ellipsis}

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
  }
`

export const BarsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 816px;

  @media (max-width: ${LAPTOP_MAX}px) {
    max-width: 571px;
  }
`

export const BarLabel = styled.div`
  font-size: 16px;
  color: #414141;
  margin-right: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    margin-right: 11px;
  }
`

export const StatBar = styled.div`
  flex: 1;
  min-width: 40px;
  background-color: #E8FFF0;
  border: 1px solid #138B4C1C;
  border-radius: 6px;
  overflow: hidden;
  height: 38px;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 26px;
    height: 26px;
  }
`

export const StatsBarFill = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 14px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  height: 100%;
  min-width: 40px;
  background: linear-gradient(180deg, #1BD46F 0%, #14BC68 100%);

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 10px;
    font-size: 14px;
    min-width: 28px;
  }
`

export const StatsBarSpacer = styled.div`
  flex-basis: 38px;
  flex-shrink: 6;
  min-width: 8px;

  @media (max-width: ${LAPTOP_MAX}px) {
    flex-basis: 26px;
  }
`
