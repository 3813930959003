export const localSave = (key, value) => {
  const json = JSON.stringify(value)

  localStorage.setItem(key, json)
}

export const localLoad = (key) => {
  const json = localStorage.getItem(key)

  if (!json) {
    return undefined
  }

  return JSON.parse(json)
}
