import React, { memo } from "react"
import CloseIcon from "../../../assets/svgs/CloseIcon"
import useTransitionState, { ENTERED, ENTERING, EXITED } from "../../../hooks/useTransitionState"
import {
  AlertContainer,
  Overlay,
  Title,
  Message,
  EndButton,
  CancelButtonRow,
  CancelButton,
  CloseButton,
  ButtonsContainer
} from "./styles"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

const ExitSessionAlert = ({
  show,
  pending,
  onCancel,
  onConfirm
}) => {
  const transition = useTransitionState(show, 250)
  const {t} = useTranslation();

  if (transition === EXITED) {
    return null
  }

  return (
    <Overlay data-show={transition === ENTERING || transition === ENTERED}>
      <AlertContainer>
        <Title>{t(Dictionary.studySession.exitHeader)}</Title>
        <Message>{t(Dictionary.studySession.exitBody)}</Message>
        <ButtonsContainer>
          <EndButton loading={pending} onClick={onConfirm}>{t(Dictionary.studySession.exitYes)}</EndButton>
          <CancelButtonRow>
            <CancelButton onClick={onCancel}>{t(Dictionary.studySession.exitCancel)}</CancelButton>
          </CancelButtonRow>
          <CloseButton onClick={onCancel}><CloseIcon /></CloseButton>
        </ButtonsContainer>
      </AlertContainer>
    </Overlay>
  )
}

export default memo(ExitSessionAlert)
