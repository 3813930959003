import React, { memo } from "react"
import { useMediaQuery } from "react-responsive"
import DesktopTopBar from "./DesktopTopBar"
import { MOBILE_MAX } from "../../../common/breakpoints"
import MobileTopBar from "./MobileTopBar"

const TopBar = ({
  type,
  sessionData,
  sessionCompleted,
  onGoBack,
  onOpenControls
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const current = sessionData.currentCardIndex
  const total = sessionData.cardsCount + sessionData.repeatQueue.length;
  const progress = sessionCompleted ? 100 : Math.round(current / total * 100)
  const count = {current, total,  progress};

  if (isMobile) {
    return (
      <MobileTopBar
        sessionData={sessionData}
        sessionCompleted={sessionCompleted}
        onGoBack={onGoBack}
        count={count}
      />
    )
  }

  return (
    <DesktopTopBar
      type={type}
      sessionData={sessionData}
      sessionCompleted={sessionCompleted}
      onGoBack={onGoBack}
      onOpenControls={onOpenControls}
      count={count}
    />
  )
}

export default memo(TopBar)
