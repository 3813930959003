import React, { memo, useCallback } from "react"
import { useHistory } from "react-router"
import BackArrowIcon from "../../../assets/svgs/BackArrowIcon"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";
import { routes } from "../../../utils/constants"
import {
  BgImage,
  Container,
  ContentContainer,
  GlobalStyle,
  BackButtonRow,
  Title,
  Description,
  OkButton,
  BackButton
} from "./styles"

const RegisterCompletePage = ({
  resetPassword = false
}) => {
  const history = useHistory()
  const {t} = useTranslation();

  const handleBackClick = useCallback(() => {
    if (resetPassword) {
      history.replace(routes.forgot)
    } else {
      history.replace(routes.register)
    }
  }, [history, resetPassword])

  const handleOkClick = useCallback(() => {
    if (resetPassword) {
      history.replace(routes.login)
    } else {
      history.push(routes.termsAndConditions)
    }
  }, [history, resetPassword])

  return (
    <Container>
      <GlobalStyle />
      <BgImage />
      <ContentContainer>
        <div>
          <BackButtonRow>
            <BackButton onClick={handleBackClick}>
              <BackArrowIcon />
            </BackButton>
          </BackButtonRow>
          <Title>{t(Dictionary.forgotPassword.ready)}</Title>
          <Description>{t(Dictionary.common.thankYou)} {t(Dictionary.register.success)}</Description>
        </div>
        <OkButton primary bordered onClick={handleOkClick}>{t(Dictionary.register.ready)}</OkButton>
      </ContentContainer>
    </Container>
  )
}

export default memo(RegisterCompletePage)