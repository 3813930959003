import React, { memo } from "react"

const CheckedBoxIcon = (props) => (
  <svg height="1em" viewBox="0 0 16 16" {...props}>
    <path
      d="M18.722,4.5H6.278A1.777,1.777,0,0,0,4.5,6.278V18.722A1.777,1.777,0,0,0,6.278,20.5H18.722A1.777,1.777,0,0,0,20.5,18.722V6.278A1.777,1.777,0,0,0,18.722,4.5Zm-8,12.444L6.278,12.5l1.253-1.253,3.191,3.182,6.747-6.747,1.253,1.262Z"
      transform="translate(-4.5 -4.5)"
      fill="currentColor"
    />
  </svg>
)

export default memo(CheckedBoxIcon)
