import React, { memo, useCallback, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import * as yup from "yup"
import EntrancePage from "../Common/EntrancePage"
import { ContentContainer, FormInnerContainer, PageContainer } from "../Common/styles"
import { RegisterFormContainer } from "./styles"
import RegisterPhoneForm from "./RegisterPhoneForm"
import BackButton from "../Common/BackButton"
import { routes } from "../../../utils/constants"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"

const initialValues = {
  areaCode: "+55", //Brazil
  phoneNumber: ""
}

const schema = yup.object().shape({
  areaCode: yup.string().required(),
  phoneNumber: yup.string().required()
})

const RegisterPhonePage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    succeeded, error, pending
  } = useSelector(authSelectors.registerPhoneState)

  useEffect(() => () => {
    dispatch(authActions.userRegisterPhoneReset())
  }, [dispatch])

  useEffect(() => {
    if (!succeeded) {
      return undefined
    }

    history.push(routes.verifyPhoneNumber)
  }, [history, succeeded])

  const handleSubmit = useCallback((values) => {
    if (!succeeded) {
      dispatch(authActions.userRegisterPhoneRequest({
        phoneNumber: `${values.areaCode}${values.phoneNumber}`
      }))
    }
  }, [dispatch, succeeded])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <PageContainer>
        <BackButton backUrl={location.state?.from ?? routes.login} />
        <ContentContainer>
          <RegisterFormContainer>
            <FormInnerContainer>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                <RegisterPhoneForm pending={pending} error={error} succeeded={succeeded} />
              </Formik>
            </FormInnerContainer>
          </RegisterFormContainer>
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(RegisterPhonePage)
