export const secondsPerCard = 45

export const StudyMode = {
  Ideal: "ideal",
  Fair: "fair",
  Medium: "medium",
  Short: "short"
}

export const minutesPerMode = {
  [StudyMode.Ideal]: 1,//0,
  [StudyMode.Fair]: 0.8,//60,
  [StudyMode.Medium]: 0.65,//45,
  [StudyMode.Short]: 0.5,//30
}

export const sessionCardsCount = (mode, totalCardsCount) => {
  // const cardsCapacity = Math.floor((minutesPerMode[mode] ?? 0) * 60 / secondsPerCard) || Number.POSITIVE_INFINITY

  // return Math.min(totalCardsCount, cardsCapacity)
  const cardsCapacity = Math.floor(totalCardsCount*(minutesPerMode[mode] ?? 1));

  return cardsCapacity < 1 && totalCardsCount >= 1 ? 1 : cardsCapacity;
}