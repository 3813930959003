import React from "react"
import { ListItemContainer, CardBox, CardBoxHeight, CardYShift } from "./styles"

const ListItem = ({ width, originShift, xPos, children }) => {
  let yShift = 1

  if (Math.abs(xPos) < width) {
    yShift = 1 - (Math.cos(xPos / width * Math.PI) + 1) * 0.5
  }

  const translateX = originShift + xPos
  const translateY = yShift * CardYShift

  const style = {
    width: `${width}px`,
    transform: `translate(${translateX}px, ${translateY}px)`
  }

  return (
    <ListItemContainer style={style}>
      <CardBox>
        <CardBoxHeight />
        {children}
      </CardBox>
    </ListItemContainer>
  )
}

export default ListItem
