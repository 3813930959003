import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import { useWindowWidth } from '@react-hook/window-size';
import { useHistory, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Navigation from "../../common/components/Navigation"
import { PageContainer, PageRoot } from "../../common/styles/page"
import TopCard from "./TopCard"
import * as selectors from "../../store/selectors/decks"
import * as actions from "../../store/actions/decks"
import TopBar from "./TopBar"
import {
  StatusContainer,
  ContentContainer,
  CardsTitleRow,
  CardsTitle,
  CardsCount,
  TabsContainer,
  TabButton,
  CardsContainer,
  CardCell,
  PageControlContainer,
  ContentWrapper
} from "./styles"
import Card from "./Card"
import PageControl from "../../common/components/PageControl"
import { routes } from "../../utils/constants"
import ListItem from "./ListItem"
import { DeckType } from "../../constants"
import * as referralSelectors from "../../store/selectors/referral"
import * as authSelectors from "../../store/selectors/auth"
import { subscribe, closeReferral } from "../../store/actions/referral"
import { handleRedirect } from "../../utils/controls/routing"
import { calculationHeightTexts } from './calculationHeightTexts';
import { useTranslation } from "react-i18next";
import { Dictionary } from "./../../utils/i18n";

const PageSize = 12

const DeckPage = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const onlyWidth = useWindowWidth()

  const refCardsContainer = useRef(null);
  const [minHeightCardHeader, setMinHeightCardHeader] = useState({});
  const deckType = match.params.mergeId ? DeckType.merge : DeckType.deck
  const deckId = match.params.mergeId ? parseInt(match.params.mergeId) : parseInt(match.params.deckId)
  const deckSelector = deckType === DeckType.merge ? selectors.mergeDetails : selectors.deckDetails

  const dispatch = useDispatch()
  const deckDetails = useSelector((state) => deckSelector(state, deckId))
  const viewState = useSelector(selectors.deckCardsViewState)
  const referral = useSelector(referralSelectors.referral)
  const userId = useSelector(authSelectors.currentUserId)

  const [navMenuExpanded, setNavMenuExpanded] = useState(true)
  const [isListView, setIsListView] = useState(false)
  const {t} = useTranslation();

  useEffect(() => {
    if (referral?.params) {
      dispatch(actions.loadDeckReferralWithCardsRequest({ virtualId: referral?.params.deckId, page: 0, pageSize: PageSize }))
    } else if (deckType === DeckType.merge) {
      dispatch(actions.loadMergeWithCardsRequest({ mergeId: deckId, page: 0, pageSize: PageSize }))
    } else {
      dispatch(actions.loadDeckWithCardsRequest({ virtualId: deckId, page: 0, pageSize: PageSize }))
    }

    return () => {
      if (referral?.params) {
        dispatch(closeReferral())
      }
      dispatch(actions.viewDeckCardsReset())
    }
    // eslint-disable-next-line
  }, []);



  useEffect(() => () => {
    dispatch(actions.viewDeckCardsReset())
  }, [dispatch])

  const handleToggleExpanded = useCallback(() => {
    setNavMenuExpanded(expanded => !expanded)
  }, [])

  const handleContinueStudy = useCallback(() => {
    history.push(`${routes.studyModeBase}/${deckType}/${deckId}`)
  }, [history, deckType, deckId])

  const handleCardViewSelect = useCallback(() => {
    setIsListView(false)
  }, [])

  const handleListViewSelect = useCallback(() => {
    setIsListView(true)
  }, [])

  const handlePageChange = useCallback((page) => {
    if (deckType === DeckType.merge) {
      dispatch(actions.loadMergePageRequest({
        mergeId: deckId,
        page,
        pageSize: PageSize
      }))
    } else {
      dispatch(actions.loadDeckPageRequest({
        virtualId: deckId,
        page,
        pageSize: PageSize,
        isReferral: true
      }))
    }
  }, [dispatch, deckType, deckId])

  const isSubscribeMode = () => {
    return referral?.params
  }

  const selectActionCaption= () => {
    return isSubscribeMode() ? t(Dictionary.deck.addDeck) : t(Dictionary.deck.continueStudy);
  }

  const handleSubscribe = useCallback(() => {
    dispatch(subscribe({...referral, userId}))
  }, [dispatch, referral, userId])

  const selectAction = () => {
    return isSubscribeMode() ? handleSubscribe : handleContinueStudy
  }

  const calcHCardHeader = () => {

    const parent = refCardsContainer.current;

    const newH = calculationHeightTexts(parent);

    setMinHeightCardHeader(newH);
  }

  const initRefCardsContainer = useCallback(node => {
    if (node !== null) {
      refCardsContainer.current = node;
      calcHCardHeader()
    }
  // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    calcHCardHeader()
  // eslint-disable-next-line
  }, [onlyWidth, deckDetails?.cards])

  const isSubscribed = () => {
    return referral?.redirectOnCompletion
  }

  const subscriptionFailed = () => {
    return referral?.error
  }

  const subscriptionDeckDetails = () => {
    return isSubscribeMode() ? {
      "deck": {
        "id": 1986,
        "originalId": 1986,
        "userId": userId,
        "authorId": userId,
        "isPublic": true,
        "origin": 1,
        "title": referral.params.unitName,
        "dateCreated": 1611164184751,
        "dateUpdated": 1611164184751,
        "deckId": referral.params.deckId,
        "description": null,//"Description placeholder",
        "totalCount": 0,
        "overdueCount": 0,
        "image": referral.params.unitThumbUrl,
        "language": "gavin"
      },
      "pagesCount": 1,
      "cards": [
      ]
    } : null
  }

  const renderContent = (deckDetails) => (
    <>
      <TopBar
        type={deckType}
        title={deckDetails.deck.title}
        navMenuExpanded={navMenuExpanded}
      />
      <ContentContainer data-nav-expanded={navMenuExpanded}>
        <ContentWrapper>
          <TopCard deck={deckDetails.deck} caption={selectActionCaption()} onProceedAction={selectAction()} />
          <CardsTitleRow>
            <CardsTitle>{t(Dictionary.deck.cards)}</CardsTitle>
            <CardsCount>
              {viewState.actualPage * PageSize + deckDetails.cards.length}/{deckDetails.deck.totalCount}
            </CardsCount>
          </CardsTitleRow>
          <TabsContainer>
            <TabButton data-selected={!isListView} onClick={handleCardViewSelect}>
              {t(Dictionary.deck.cardMode)}
            </TabButton>
            <TabButton data-selected={isListView} onClick={handleListViewSelect}>
              {t(Dictionary.deck.cardList)}
            </TabButton>
          </TabsContainer>
          {isListView ? (
            <div>
              {deckDetails.cards.map((card) => (
                <ListItem key={card.id} card={card} />
            ))}
            </div>
        ) : (
          <CardsContainer ref={initRefCardsContainer}>
            {deckDetails.cards.map((card, index) => (
              <CardCell key={card.id}>
                <Card card={card} minHeightHeader={minHeightCardHeader[`card_${index}`]} updateHeight={calcHCardHeader}/>
              </CardCell>
            ))}
          </CardsContainer>
        )}
          <PageControlContainer>
            <PageControl
              page={viewState.page}
              totalPages={deckDetails.pagesCount}
              onChange={handlePageChange}
            />
          </PageControlContainer>
        </ContentWrapper>
      </ContentContainer>
    </>
  )

  const renderPage = () => {

    if (isSubscribed()) {
      dispatch(closeReferral())
      handleRedirect(history, routes.dashboard)
      return null
    }

    if (subscriptionFailed()) {
      return <StatusContainer>{referral.error.message}</StatusContainer>
    }

    if (deckDetails) {
      return renderContent(deckDetails)
    }

    if (viewState.error) {
      if (isSubscribeMode()) {
        return renderContent(subscriptionDeckDetails())
      } else {
        return <StatusContainer>Error: {viewState.error.message}</StatusContainer>
      }
    }

    return <StatusContainer>{t(Dictionary.common.loading)}...</StatusContainer>
  }

  return (
    <PageRoot>
      <Navigation expanded={navMenuExpanded} toggleExpanded={handleToggleExpanded} />
      <PageContainer data-nav-expanded={navMenuExpanded}>
        {renderPage()}
      </PageContainer>
    </PageRoot>
  )
}

export default memo(DeckPage)
