import React, { memo } from "react"
import { useHistory } from "react-router-dom"
import { Container, StyledLogo, LeftButton, StyledBackIcon, StyledMenuIcon } from "./styles"

const MobileHeader = ({
  backUrl = null,
  onShowMenu = null
}) => {
  const history = useHistory()

  const handleLeftButtonClick = () => {
    if (backUrl) {
      history.replace(backUrl)
    } else if (onShowMenu) {
      onShowMenu()
    }
  }

  return (
    <Container>
      <StyledLogo />
      <LeftButton onClick={handleLeftButtonClick}>
        {backUrl ? <StyledBackIcon /> : <StyledMenuIcon />}
      </LeftButton>
    </Container>
  )
}

export default memo(MobileHeader)
