import React, { memo } from "react"

const CloseIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 14 14"
    {...props}
  >
    <path d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z" fill="currentColor"/>
  </svg>
)

export default memo(CloseIcon)
