
const laptop = {
  common: {
    a: [
      { header: { size: 28 }, paragraph: { size: 14 } },
      { header: { size: 14 }, paragraph: { size: 14 } }
    ],
    b: [
      { sideAHeader: { size: 16 }, sideAParagraph: { size: 16 }, paragraph: { size: 24 }, transcription: { size: 16 } },
      { sideAHeader: { size: 14 }, sideAParagraph: { size: 12 }, paragraph: { size: 16 }, transcription: { size: 12 } },
      { sideAHeader: { size: 10 }, sideAParagraph: { size: 10 }, paragraph: { size: 10 }, transcription: { size: 10 } }
    ]
  },
  asian: {
    a: [
      { header: { size: 118, maxCharacters: 1 }, paragraph: { size: 20 } },
      { header: { size: 108, maxCharacters: 2 }, paragraph: { size: 20 } },
      { header: { size: 100, maxCharacters: 3 }, paragraph: { size: 18 } },
      { header: { size: 40 }, paragraph: { size: 16 } },
      // { header: { size: 30 }, paragraph: { size: 16 } },
      // { header: { size: 23 }, paragraph: { size: 14 } },
      // { header: { size: 14 }, paragraph: { size: 14 } }
    ],
    b: [
      { sideAHeader: { size: 36 }, sideAParagraph: { size: 20 }, paragraph: { size: 36 }, transcription: { size: 20 }, },
      { sideAHeader: { size: 28 }, sideAParagraph: { size: 16 }, paragraph: { size: 28 }, transcription: { size: 16 }, },
      { sideAHeader: { size: 20 }, sideAParagraph: { size: 14 }, paragraph: { size: 20 }, transcription: { size: 14 }, }
    ]
  }
}

const desktop = {
  ...laptop,
  common: {
    ...laptop.common,
    a: [
      { header: { size: 34 }, paragraph: { size: 14 } },
      ...laptop.common.a
    ],
    b: [
      { sideAHeader: { size: 20 }, sideAParagraph: { size: 20 }, paragraph: { size: 32 }, transcription: { size: 20 } },
      ...laptop.common.b
    ]
  }
}

const mobile = {
  common: {
    a: [
      { header: { size: 24 }, paragraph: { size: 14 } },
      { header: { size: 14 }, paragraph: { size: 14 } }
    ],
    b: [
      { sideAHeader: { size: 18 }, sideAParagraph: { size: 16 }, paragraph: { size: 24 }, transcription: { size: 16 } },
      { sideAHeader: { size: 14 }, sideAParagraph: { size: 12 }, paragraph: { size: 16 }, transcription: { size: 12 } },
      { sideAHeader: { size: 10 }, sideAParagraph: { size: 10 }, paragraph: { size: 10 }, transcription: { size: 10 } }
    ]
  },
  asian: {
    a: [
      { header: { size: 132, maxCharacters: 1 }, paragraph: { size: 38 } },
      { header: { size: 100, maxCharacters: 2 }, paragraph: { size: 36 } },
      { header: { size: 88,  maxCharacters: 3 }, paragraph: { size: 32 } },
      { header: { size: 40 }, paragraph: { size: 14 } },
      { header: { size: 34 }, paragraph: { size: 14 } },
      { header: { size: 23 }, paragraph: { size: 14 } },
      { header: { size: 14 }, paragraph: { size: 14 } }
    ],
    b: [
      { sideAHeader: { size: 28 }, sideAParagraph: { size: 18 }, paragraph: { size: 60 }, transcription: { size: 18 } },
      { sideAHeader: { size: 14 }, sideAParagraph: { size: 12 }, paragraph: { size: 16 }, transcription: { size: 12 } },
      { sideAHeader: { size: 10 }, sideAParagraph: { size: 10 }, paragraph: { size: 10 }, transcription: { size: 10 } }
    ]
  }
}

const sizes = {
  desktop,
  laptop,
  mobile
}

export const getTextSizes = (isMobile, isLaptop, isAsian, isReverseSide) => {
  let platform = "desktop"

  if (isMobile) {
    platform = "mobile"
  } else if (isLaptop) {
    platform = "laptop"
  }

  const byPlatform = sizes[platform]
  const byLanguage = byPlatform[isAsian ? "asian" : "common"] ?? byPlatform.common

  return byLanguage[isReverseSide ? "b" : "a"]
}