import axios from "axios"
import { call, cancelled } from "redux-saga/effects"
import { defaultHeaders, baseURL } from "./common"
import { ApiError, NetworkError } from "./errors"
import { authToken as authTokenSelector } from "../../store/selectors/auth"
import { userLogout } from "../../store/actions/authExtra"

console.log('baseURL', baseURL)

export const instance = axios.create({
  headers: defaultHeaders,
  baseURL
})

export const setupApi = (store) => {
  instance.interceptors.request.use(async (config) => {
    if (config.noAuth) {
      return config
    }

    const authToken = authTokenSelector(store.getState())

    if (authToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          "x-hackit-auth": authToken
        }
      }
    }
    throw new Error("Not logged in")
  })

  instance.interceptors.response.use(
    async (response) => {
      if (response.data.meta.code >= 400) {
        const url = response.config.url?.substring(response.config.baseURL?.length ?? 0)
        const error = new ApiError(response.status, response.data.meta, url)

        if (error.code === 401 && !response.config.noAuth) {
          store.dispatch(userLogout())
        }

        throw error
      }

      return response
    },
    async (error) => {
      if (!error.config) {
        throw error
      }

      const url = error.config.url?.substring(error.config.baseURL?.length ?? 0)
      throw new NetworkError(error.message, url)
    }
  )
}


function* sagaRequest(config, returnData) {
  const cancel = axios.CancelToken.source()
  config.cancelToken = cancel.token

  try {
    const response = yield call([instance, instance.request], config)

    return returnData ? response.data.data : response
  } finally {
    if (yield cancelled()) {
      cancel.cancel()
    }
  }
}

export default {
  request: (config) => sagaRequest(config, false),
  get: (url, config = {}) => sagaRequest({ ...config, method: "get", url }, true),
  delete: (url, config = {}) => sagaRequest({ ...config, method: "delete", url }, true),
  head: (url, config = {}) => sagaRequest({ ...config, method: "head", url }, true),
  options: (url, config = {}) => sagaRequest({ ...config, method: "options", url }, true),
  post: (url, data, config = {}) =>
    sagaRequest({ ...config, method: "post", url, data }, true),
  put: (url, data, config) => sagaRequest({ ...config, method: "put", url, data }, true),
  patch: (url, data, config) =>
    sagaRequest({ ...config, method: "patch", url, data }, true)
}
