import styled from "styled-components"
import OopsPlaceholder from "../../../assets/svgs/OopsPlaceholder"
import OverdueCardsIcon from "../../../assets/svgs/OverdueCardsIcon"
import TotalCardsIcon from "../../../assets/svgs/TotalCardsIcon"
import Flag from "../../../common/components/Flag"
import Button from "../../../common/components/Button"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"

export const Container = styled(ImageContainer)`
  box-shadow: 0px 3px 10px #3707179E;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;

  .image-container__image {
    filter: brightness(0.45);
  }

  .image-container__fallback {
    filter: brightness(0.45);
  }

  .image-container__content {
    color: white;
  }
`

export const OopsImage = styled(OopsPlaceholder)`
  font-size: 49px;
  color: white;
  opacity: 0.18;
`

export const BackButtonWrapper = styled.div`
  padding-left: 10px;
  margin-bottom: 35px;
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 16px;
  opacity: 0.65;
  color: white;
`

export const Title = styled.div`
  font-family: "BlackerDisplayExtraBold";
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  padding: 0 23px;
  margin-bottom: 15px;
`

export const Description = styled.div`
  font-style: italic;
  font-size: 12px;
  opacity: 0.85;
  line-height: 16px;
  padding: 0 23px;
  margin-bottom: 12px;
`

export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border: solid 1px #FFFFFF20;
  border-right: none;
  border-left: none;
  padding: 0 24px;
  margin-bottom: 20px;
`

export const StyledFlag = styled(Flag)`
  width: 30px;
  height: 30px;

  .flag__circle {
    box-shadow: inset 0px 0px 0px 1px white;
  }
`

export const CountersContainer = styled.div`
  display: flex;
  align-items: center;
  color: #F7F7F7;
`

export const CounterLabel = styled.div`
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-right: 6px;
  text-align: right;
`

export const OverdueIcon = styled(OverdueCardsIcon)`
  font-size: 14px;
  margin-right: 11px;
`

export const TotalIcon = styled(TotalCardsIcon)`
  font-size: 17px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 22px;
  padding-bottom: 19px;
`

export const ContinueButton = styled(Button)`
  flex: 1;
  width: auto;
`
