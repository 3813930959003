import React, { memo } from "react"
import { StyledForm, TopFormPart } from "../Common/styles"
// import { termsAndConditionsText } from "../../../assets/textual/text"
import {
  Logo,
  Title,
  TermsParagraph,
  TermsLinkContainer,
  TermsLink,
  BottomRow,
  ContinueButton,
  RightArrow,
  StyledCheckboxField
} from "./styles"
import { routes } from "../../../utils/constants"
import { useFormikContext } from "formik"
import { useTranslation } from 'react-i18next';
import { Dictionary } from "./../../../utils/i18n";

const TermsAndConditionsForm = () => {
  const { values } = useFormikContext()
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TopFormPart>
        <Logo />
        <Title>{t(Dictionary.termsAndConditionsText.pageHeader)}</Title>
        <TermsParagraph>{t(Dictionary.termsAndConditionsText.pageParagraph1)}</TermsParagraph>
        <TermsParagraph>{t(Dictionary.termsAndConditionsText.pageParagraph2)}</TermsParagraph>
        <TermsLinkContainer>
          <TermsLink href={routes.memhack.termsAndConditions} target="_blank">
            {t(Dictionary.termsAndConditionsText.link)}
          </TermsLink>
        </TermsLinkContainer>
        <BottomRow>
          <StyledCheckboxField name="accepted">{t(Dictionary.termsAndConditionsText.acceptLabel)}</StyledCheckboxField>
          <ContinueButton type="submit" data-disabled={!values.accepted}>
            <span>{t(Dictionary.common.continue)}</span>
            <RightArrow />
          </ContinueButton>
        </BottomRow>
      </TopFormPart>
    </StyledForm>
  )
}

export default memo(TermsAndConditionsForm)
