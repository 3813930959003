import { combineReducers } from "@reduxjs/toolkit"
import { userLogout } from "../actions/authExtra"
import auth from "./auth"
import decks from "./decks"
import referral from "./referral"
import sessions from "./sessions"

const rootReducer = combineReducers({
  auth: auth.reducer,
  decks: decks.reducer,
  sessions: sessions.reducer,
  referral: referral.reducer
})

export default (state, action) => {
  if (action.type === userLogout.type) {
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}
