import useSize from "@react-hook/size"
import React, { memo, useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { WindowScroller, List } from "react-virtualized"
// import Button from "../../../common/components/Button"
import MobileDropdown from "../../../common/components/MobileDropdown"
import MobileHeader from "../../../common/components/MobileHeader"
import LoadingSpinner from "../../../legacyComponents/Desktop/Animations/LoadingSpinner"
import { DeckType, links } from "../../../constants"
import * as selectors from "../../../store/selectors/decks"
import * as actions from "../../../store/actions/decks"
import { routes } from "../../../utils/constants"
import DeckCard from "../DeckCard"
import {
  GlobalStyle,
  Title,
  DropdownRow,
  CardsContainer,
  ItemBox,
  CardBox,
  CardBoxHeight,
  ItemContainer,
  LayoutBox,
  SpinnerContainer,
  NoDecksTitle,
  NoDecksNotice,
  NoDecksImageContainer,
  NoDecksImageCenterWrapper,
  ButtonFluencyTV
} from "./styles"
import { Dictionary } from "../../../utils/i18n"
import { useTranslation } from "react-i18next"

const ListView = ({deckTypes}) => {
  const history = useHistory()
  const layoutRef = useRef()
  const itemBoxRef = useRef()

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const masterDecksData = useSelector(selectors.masterDecksData)
  const userDecksData = useSelector(selectors.userDecksData)
  const deckType = useSelector(selectors.dashboardDeckType)

  const title = t(Dictionary.dashboard.yourDecksOption)

  const handleDeckTypeSelect = useCallback((item) => {
    dispatch(actions.setDashboardDeckType(item.id))
  }, [dispatch])

  const handleDeckClick = useCallback((deck) => {
    if (deck.type === DeckType.merge) {
      history.push(`${routes.mergePage}/${deck.deckId}`, { dashboardViewType: "list" })
    } else {
      history.push(`${routes.deckPage}/${deck.virtualId}`, { dashboardViewType: "list" })
    }
  }, [history])

  // const handleAccessCourse = () => {
  //   window.open(links.accessCourse, "_blank")
  // }

  const handleAccessFluencyTV = () => {
    window.open(links.fluencyTVAcademy, "_blank")
  }

  useSize(layoutRef)

  const loading = deckType === DeckType.merge ? masterDecksData.loading : userDecksData.loading
  const decks = deckType === DeckType.merge ? masterDecksData.data : userDecksData.data

  const containerWidth = Math.floor(layoutRef.current?.clientWidth ?? 0)
  const itemWidth = Math.floor(itemBoxRef.current?.clientWidth ?? 0)
  const itemHeight = Math.floor(itemBoxRef.current?.clientHeight ?? 0)

  const rowRenderer = useCallback(({ index, key, style }) => {
    return (
      <ItemContainer key={key} style={style}>
        <CardBox style={{ width: `${itemWidth}px` }}>
          <CardBoxHeight />
          <DeckCard key={decks[index].deckId} deck={decks[index]} onClick={handleDeckClick} />
        </CardBox>
      </ItemContainer>
    )
  }, [decks, itemWidth, handleDeckClick])

  const renderList = () => (
    <>
      <Title>{title}</Title>
      <DropdownRow>
        <MobileDropdown items={deckTypes} selectedId={deckType} onSelect={handleDeckTypeSelect} />
      </DropdownRow>
      <WindowScroller>
        {({height, isScrolling, registerChild, onChildScroll, scrollTop}) => (
          <CardsContainer ref={registerChild}>
            <List
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={2}
              rowCount={decks.length}
              rowHeight={itemHeight}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              width={containerWidth}
            />
          </CardsContainer>
        )}
      </WindowScroller>
      {decks.length === 0 && loading && (
        <SpinnerContainer>
          <span><LoadingSpinner /></span>
        </SpinnerContainer>
      )}
    </>
  )

  const renderNoDecks = () => (
    <>
      <NoDecksTitle>{title}</NoDecksTitle>
      <NoDecksNotice>
        {t(Dictionary.dashboard.noDecks)}
      </NoDecksNotice>
      <NoDecksImageCenterWrapper>
        <NoDecksImageContainer>
          {/* <Button primary outlined onClick={handleAccessCourse}>ACESSAR A FLUENCY ACADEMY</Button> */}
          <ButtonFluencyTV primary outlined onClick={handleAccessFluencyTV}>{t(Dictionary.dashboard.buttonFluencyTV)}</ButtonFluencyTV>
        </NoDecksImageContainer>
      </NoDecksImageCenterWrapper>
    </>
  )

  return (
    <>
      <GlobalStyle />
      <MobileHeader backUrl={routes.dashboard} />
      <LayoutBox ref={layoutRef}>
        <ItemBox ref={itemBoxRef}>
          <CardBox>
            <CardBoxHeight />
          </CardBox>
        </ItemBox>
      </LayoutBox>
      {
        userDecksData.loading === false && userDecksData.data.length === 0 ?
        renderNoDecks() :
        renderList()
      }
    </>
  )
}

export default memo(ListView)
