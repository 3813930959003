import TagManager from 'react-gtm-module';
import { GTM_dataLayerName } from'./../constants';

const tagManagerArgs = {
  gtmId: 'GTM-5BHPLGL',
  dataLayerName: GTM_dataLayerName,
  // auth: '6sBOnZx1hqPcO01xPOytLK',
  // preview: 'env-2'
}

export const initialGTM = () => TagManager.initialize(tagManagerArgs);

export const pushTagSignup = () => {

}

export const pushTagLogin = (user) => {
  const {userId, email} = user;
  const tagManagerArgs = {
    dataLayer: {
      'pageCategory': 'login',
      userId,
      email
    },
    dataLayerName: GTM_dataLayerName
  }

  TagManager.dataLayer(tagManagerArgs)
}

export const pushTagRegistration = (email) => {
  // const tagManagerArgs = {
  //   dataLayer: {
  //     'event': 'formSubmitted',
  //     'formName': 'registration',
  //     'userEmail': email
  //   },
  //   dataLayerName: GTM_dataLayerName
  // }

  // TagManager.dataLayer(tagManagerArgs)
  window.dataLayer.push({
    'event': 'formSubmitted',
    'formName': 'registration',
    'userEmail': email
  });
}

