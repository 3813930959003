import React, { memo, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"
import TopBackButton from "../../../common/components/TopBackButton"
import { TopBarContainer } from "../../../common/styles/topBar"
import { routes } from "../../../utils/constants"
import { BreadcrumbContainer, Logo } from "./styles"
import { Dictionary } from "../../../utils/i18n"
import { useTranslation } from "react-i18next"

const TopBar = ({ type, title, navMenuExpanded }) => {
  const history = useHistory()
  const {t} = useTranslation()

  const handleGoBack = useCallback(() => {
    history.replace(routes.dashboard)
  }, [history])

  return (
    <TopBarContainer data-nav-expanded={navMenuExpanded}>
      <TopBackButton onClick={handleGoBack} />
      <BreadcrumbContainer>
        <Logo />
        <span>
          <Link to={routes.dashboard}>{t(Dictionary.common.home)}</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
        </span>
        {type === "merge" ? "Master deck:" : "Deck:"}&nbsp;
        {title}
      </BreadcrumbContainer>
    </TopBarContainer>
  )
}

export default memo(TopBar)
