import React, { memo } from "react"

const GraphIcon = (props) => (
  <svg height="1em" viewBox="0 0 14.779 12.445" {...props}>
    <g transform="translate(-3.386 -40)">
      <path fill="currentColor" d="M35.889,104.778a.778.778,0,0,0-.778-.778H32.778a.778.778,0,0,0-.778.778v5.445h3.889Z" transform="translate(-28.614 -57.777)"/>
      <path fill="currentColor" d="M91.889,72.778A.778.778,0,0,0,91.111,72H88.778a.778.778,0,0,0-.778.778v8.556h3.889Z" transform="translate(-79.169 -28.889)"/>
      <path fill="currentColor" d="M147.889,40.778a.778.778,0,0,0-.778-.778h-2.333a.778.778,0,0,0-.778.778V52.445h3.889Z" transform="translate(-129.724)"/>
    </g>
  </svg>
)

export default memo(GraphIcon)
