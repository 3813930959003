import React, { memo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";
import { Container, InfoContainer, NonAvailableNotice } from "./styles"

export { InfoStickerTitle, InfoStickerDescription } from "./styles"

const InfoSticker = ({
  className,
  show = false,
  disabled = false,
  children
}) => {
  const {t} = useTranslation();
  const containerRef = useRef()
  const containerStyle = {}

  return (
    <Container
      ref={containerRef}
      className={className}
      data-disabled={disabled}
      data-show={show}
      style={containerStyle}
    >
      <InfoContainer>
        {children}
      </InfoContainer>
      {disabled && (
        <NonAvailableNotice>
          {t(Dictionary.studyModeDeck.pickNotEnoughtCards)}
        </NonAvailableNotice>
      )}
    </Container>
  )
}

export default memo(InfoSticker)
