import React, { memo } from "react"
import CloseIcon from "../../../../assets/svgs/CloseIcon"
import useTransitionState, { ENTERED, ENTERING } from "../../../../hooks/useTransitionState"
import { CARD_CHANGE_DELAY, CARD_CHANGE_DURATION } from "../../Card/constants"
import {
  CloseButton,
  CloseButtonRow,
  Container,
  Title,
  ProgressNumbersRow,
  ProgressBar,
  ProgressBarFill
} from "./styles"

const MobileTopBar = ({
  sessionData,
  sessionCompleted,
  onGoBack,
  count
}) => {
  const completeTransition = useTransitionState(sessionCompleted, CARD_CHANGE_DURATION, CARD_CHANGE_DELAY)
  const {current, total, progress} = count;

  if (completeTransition === ENTERED) {
    return null
  }

  return (
    <Container data-completed={completeTransition === ENTERING}>
      <CloseButtonRow>
        <CloseButton onClick={onGoBack}>
          <CloseIcon />
        </CloseButton>
      </CloseButtonRow>
      <Title>{sessionData.name}</Title>
      <ProgressNumbersRow>
        <div>{progress}%</div>
        <div>{Math.min(current+1, total)}/{total}</div>
      </ProgressNumbersRow>
      <ProgressBar>
        <ProgressBarFill style={{ width: `${progress}%` }} />
      </ProgressBar>
    </Container>
  )
}

export default memo(MobileTopBar)
