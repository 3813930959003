import React, { memo } from "react"
import { useDispatch } from "react-redux"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import useOnMount from "../../hooks/useOnMount"
import * as actions from "../../store/actions/decks"
import ListView from "./ListView"
import CarouselView from "./CarouselView";
import { DeckType } from "../../constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";

const DashboardPageMobile = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const deckTypes = [
    {
      id: DeckType.merge,
      label: t(Dictionary.dashboard.masterDecksOption)// "MASTER DECKS"
    },
    {
      id: DeckType.deck,
      label: t(Dictionary.dashboard.yourDecksOption)// "SEUS DECKS"
    }
  ]

  useOnMount(() => {
    dispatch(actions.loadMasterDecksRequest())
    dispatch(actions.loadUserDecksRequest())
  })

  return (
    <Switch>
      <Route path={`${match.path}/list`}><ListView deckTypes={deckTypes}/></Route>
      <Route path={`${match.path}`}><CarouselView deckTypes={deckTypes}/></Route>
    </Switch>
  )
}

export default memo(DashboardPageMobile)
