import { Link } from "react-router-dom"
import styled from "styled-components"
import { Form } from "formik"
import EmailInputIcon from "../../../assets/svgs/EmailInputIcon"
import PasswordInputIcon from "../../../assets/svgs/PasswordInputIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import Button from "../../../common/components/Button"
import FluencyLogoLight from "../../../assets/svgs/FluencyLogoLight"
import countryIcon from "../../../assets/svgs/CountryIcon.svg"
import phoneIcon from "../../../assets/svgs/PhoneIcon.svg"

export const CountryIcon = styled.img.attrs({
  src: countryIcon
})``

export const PhoneIcon = styled.img.attrs({
  src: phoneIcon
})``

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

export const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;

  @media (max-width: ${MOBILE_MAX}px) {
    background-color: #ED3166;
    padding: 0;
  }
`

export const FormContainer = styled.div`
  position: relative;
  width: 632px;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 436px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const FormSlogan = styled.div`
  position: absolute;
  top: -56px;
  right: -80px;
  color: #F5F6FA;
  font-family: BlackerDisplayHeavy;
  font-size: 112px;
  line-height: 1;

  @media (max-width: ${LAPTOP_MAX}px) {
    top: -38px;
    right: -55px;
    font-size: 77px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`

export const FormInnerContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border: 1px solid #D6DAEB;
  border-radius: 38px;
  padding: 55px 88px 55px 88px;
  background-color: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 38px 60px 38px 60px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border: none;
    padding: 44px 24px 0 24px;
    background: linear-gradient(180deg, #FF5363 0%, #F93779 100%)
  }
`

export const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_MAX}px) {
    justify-content: space-between;
  }
`

export const TopFormPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const BottomFormPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const EmailIcon = styled(EmailInputIcon)`
  font-size: 12px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 9px;
  }
`

export const PasswordIcon = styled(PasswordInputIcon)`
  font-size: 23px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
  }
`

export const InputGroup = styled.div`
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 40px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 38px;
    }
  }

  @media (max-width: ${MOBILE_MAX}px) {
    background-color: white;
    box-shadow: 0px 3px 6px #00000040;
    border-radius: 15px;
    padding: 4px 8px;
    box-sizing: border-box;

    > *:not(:last-child) {
      margin-bottom: 0;
    }

    > *:last-child {
      border-bottom: none;
    }
  }
`

export const ForgotPasswordLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ForgotPasswordLink = styled(Link)`
  display: block;
  font-size: 16px;
  line-height: 41px;
  color: #00AEE0;
  margin-top: 7px;
  margin-bottom: 48px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    margin-bottom: 33px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    height: 36px;
    padding: 0 16px;
    border: 2px solid rgba(255, 2555, 2555, 0.15);
    border-radius: 24px;
    color: white;
    font-size: 12px;
    line-height: 36px;
  }
`

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormButton = styled(Button)`
  width: 380px;
  height: 70px;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 262px;
    height: 48px;
    border-radius: 24px;
    font-size: 12px;
  }

  :not(:last-child) {
    margin-bottom: 25px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 17px;
    }
  }
`

export const SubmitButton = styled(FormButton)`
  text-transform: uppercase;
  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 16px;
    margin-bottom: 20px;
    width: 240px;
    height: 44px;
    border-color: white;
  }
`

export const RegisterButton = styled(FormButton)`
  @media (max-width: ${MOBILE_MAX}px) {
    width: 100%;
    height: 46px;
    border: 2px solid #FFFFFF;
    border-bottom: none;
    border-radius: 24px 24px 0px 0px;
    background-color: transparent;
    color: rgba(255, 2555, 2555, 0.72);
  }
`

export const MobileLogo = styled(FluencyLogoLight)`
  font-size: 34px;
  fill: white;
  opacity: 0.68;
  margin-bottom: 23px;
`