import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import { setTermsAccepted, userLoginSuccess } from "./actions/auth"
import { localLoad } from "./utils/localStorage"
import { HackitAuthTokenKey, HackitTermsAcceptedKey, HackitUserKey } from "./constants"
import { setupApi } from "../services/api"

export { rootReducer }

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

const authToken = localLoad(HackitAuthTokenKey)
const user = localLoad(HackitUserKey)
const termsAccepted = localStorage.getItem(HackitTermsAcceptedKey) === "true"

if (authToken && user) {
  store.dispatch(
    userLoginSuccess({ token: authToken, user, isUserCached: true })
  )
}

if (termsAccepted) {
  store.dispatch(setTermsAccepted())
}

setupApi(store)

export default store
