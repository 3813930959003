import React, { memo, useCallback, useState } from "react"
import { useTranslation } from "react-i18next";
import { Dictionary } from "../../../utils/i18n"
import {
  Container,
  DadosContainer,
  DadosBg,
  DadosContent,
  StyledGraphIcon,
  DadosLabel,
  ContentContainer,
  SideContainer,
  TextsContainer,
  ForeignText,
  TranslatedText,
  StyledAudioPlayer,
  StyledImage,
  TextsAndImageContainer,
  StatsTextsContainer,
  ReviewsCount,
  // MemoryType,
  BarsContainer,
  // BarLabel,
  // StatBar,
  // StatsBarFill,
  // StatsBarSpacer
} from "./styles"

const playerConfig = {
  height: 40,
  barWidth: 2,
  barGap: 2,
  barRadius: 2,
  barMinHeight: 2
}

const ListItem = ({ card }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const {t} = useTranslation();

  const handleFlip = useCallback(() => {
    setIsFlipped((flipped) => !flipped)
  }, [])

  // const maxGradings = Math.max(
  //   card.gradingsCountEasy,
  //   card.gradingsCountNormal,
  //   card.gradingsCountHard
  // )

  return (
    <Container>
      <DadosContainer data-active={isFlipped} onClick={handleFlip}>
        <DadosBg />
        <DadosContent>
          <StyledGraphIcon />
          <DadosLabel>{t(Dictionary.deck.data)}</DadosLabel>
        </DadosContent>
      </DadosContainer>
      <ContentContainer>
        <SideContainer data-visible={!isFlipped}>
          <TextsAndImageContainer>
            {card.image && <StyledImage src={card.image} />}
            <TextsContainer>
              <ForeignText>{card.foreignText}</ForeignText>
              <TranslatedText>{card.translatedText}</TranslatedText>
            </TextsContainer>
          </TextsAndImageContainer>
          <StyledAudioPlayer config={playerConfig} url={card.audio} />
        </SideContainer>
        <SideContainer data-visible={isFlipped}>
          <StatsTextsContainer>
            <ReviewsCount>
              {card.gradingsCountReviews || 0} review{card.gradingsCountReviews > 1 ? 's' : ''}!
            </ReviewsCount>
            {/* <MemoryType>
              Short term memory
            </MemoryType> */}
          </StatsTextsContainer>
          <BarsContainer>
            {/* <BarLabel>Fácil</BarLabel>
            <StatBar>
              <StatsBarFill style={{ width: `${card.gradingsCountEasy / maxGradings * 100}%` }}>
                {card.gradingsCountEasy}
              </StatsBarFill>
            </StatBar>
            <StatsBarSpacer />
            <BarLabel>Bom</BarLabel>
            <StatBar>
              <StatsBarFill style={{ width: `${card.gradingsCountNormal / maxGradings * 100}%` }}>
                {card.gradingsCountNormal}
              </StatsBarFill>
            </StatBar>
            <StatsBarSpacer />
            <BarLabel>Difícil</BarLabel>
            <StatBar>
              <StatsBarFill style={{ width: `${card.gradingsCountHard / maxGradings * 100}%` }}>
                {card.gradingsCountHard}
              </StatsBarFill>
            </StatBar> */}
          </BarsContainer>
        </SideContainer>
      </ContentContainer>
    </Container>
  )
}

export default memo(ListItem)
