import React, { useCallback, memo } from "react"
import { useHistory } from "react-router-dom"
import { ArrowIcon, ButtonRow, StyledBackButton } from "./styles"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../../utils/i18n";

const BackButton = ({ className, backUrl }) => {
  const history = useHistory()
  const {t} = useTranslation();

  const handleBack = useCallback(() => {
    history.push(backUrl)
  }, [history, backUrl])

  return (
    <ButtonRow className={className}>
      <StyledBackButton onClick={handleBack}>
        <ArrowIcon />
        <span>{t(Dictionary.common.back)}</span>
      </StyledBackButton>
    </ButtonRow>
  )
}

export default memo(BackButton)
