import styled from "styled-components"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { BEFORE_CHANGE, CHANGING } from "../../../hooks/useChangeTransition"
import Card from "../Card"
import { CARD_CHANGE_DURATION } from "../Card/constants"
import GradeButtons from "../GradeButtons"

export const Container = styled.div`
  height: calc(100% - 68px);
  display: flex;
  justify-content: center;
  padding: 0 24px 0 24px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: calc(100% - 60px);
  }

  &[data-in-transition='true'] {
    pointer-events: none;
  }

  &[data-change-transition='${BEFORE_CHANGE}'],
  &[data-change-transition='${CHANGING}'] {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: left ${CARD_CHANGE_DURATION}ms cubic-bezier(0.65, 0, 0.35, 1);
  }

  &[data-change-transition='${BEFORE_CHANGE}'][data-is-next='false'] {
    left: 0;
  }

  &[data-change-transition='${BEFORE_CHANGE}'][data-is-next='true'] {
    left: -100%;
  }

  &[data-change-transition='${CHANGING}'][data-is-next='false'] {
    left: 100%;
  }

  &[data-change-transition='${CHANGING}'][data-is-next='true'] {
    left: 0;
  }

  &[data-change-transition='${BEFORE_CHANGE}'][data-is-last='true'],
  &[data-change-transition='${CHANGING}'][data-is-last='true'] {
    left: 0;
    opacity: 0;
    transition: opacity ${CARD_CHANGE_DURATION}ms ease-out;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    padding-top: 16px;
    height: calc(100% - 97px);

    &[data-in-transition='true'] {
      overflow: visible;
    }
  }
`

export const CenteredContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  max-width: 948px;
  min-width: 400px;
  min-height: 596px;

  &[data-has-image='true'] {
    max-width: 1234px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    max-width: 800px;

    &[data-has-image='true'] {
      max-width: 876px;
    }
  }

  @media (max-width: ${MOBILE_MAX}px) {
    min-width: 280px;
    min-height: 420px;
    padding-bottom: 17px;
  }
`

export const StyledCard = styled(Card)`
  flex: 1;
  max-height: 480px;

  @media (max-width: ${LAPTOP_MAX}px) {
    max-height: 390px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    max-height: none;
    z-index: 1;
  }
`

export const StyledGradeButtons = styled(GradeButtons)`
  margin-top: 80px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 57px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 16px;
  }
`
