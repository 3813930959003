import React from "react"
import OtpInput from "react-otp-input"
import { useField } from "formik"
import "./OtpInputField.scss"

const OtpInputField = ({ name, digitsAmount = 6, isInputNum = true, ...restProps }) => {
  const [field, , actions] = useField(name)

  return (
    <OtpInput
      value={field.value}
      onChange={actions.setValue}
      numInputs={digitsAmount}
      inputStyle="otp-input"
      containerStyle="otp-input-container"
      isInputNum={isInputNum}
      shouldAutoFocus
      {...restProps}
    />
  )
}

export default OtpInputField
