import React, { memo, useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as selectors from "../../../store/selectors/decks"
import * as actions from "../../../store/actions/decks"
import DecksCarousel from "../DecksCarousel"
import MobileDropdown from "../../../common/components/MobileDropdown"
import MobileHeader from "../../../common/components/MobileHeader"
import { routes } from "../../../utils/constants"
import {
  TopContainer,
  Title,
  GlobalStyle,
  DropdownRow,
  NoDecksImageContainer,
  TopContent,
  NoDecksContent,
  NoDecksTitle,
  NoDecksBottom,
  NoDecksNotice,
  NoDecksImageWrapper,
  // StatisticsContainer,
  // StatisticsTitle,
  // StatisticsShadowTitle,
  // NoStatsImage,
  // NoStatsNotice
} from "./styles"
// import Button from "../../../common/components/Button"
import { DeckType
  // , links
} from "../../../constants"
import MobileNavigation from "../../../common/components/Navigation/MobileNavigation"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

const CarouselView = ({deckTypes}) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const masterDecksData = useSelector(selectors.masterDecksData)
  const userDecksData = useSelector(selectors.userDecksData)
  const deckType = useSelector(selectors.dashboardDeckType)
  const initialIndex = useSelector(selectors.dashboardDeckIndex)

  const [showNavigation, setShowNavigation] = useState(false)

  const handleShowNavigation = useCallback(() => {
    setShowNavigation(true)
  }, [])

  const handleHideNavigation = useCallback(() => {
    setShowNavigation(false)
  }, [])

  const handleDeckTypeSelect = useCallback((item) => {
    dispatch(actions.setDashboardDeckType(item.id))
  }, [dispatch])

  const { decks, loading, placeholder } = useMemo(
    () => deckType === DeckType.merge ?
    ({
      decks: masterDecksData.data,
      loading: masterDecksData.loading,
      placeholder: t(Dictionary.dashboard.mobileNoMasterDecks) //"No master decks."
    }) :
    ({
      decks: userDecksData.data,
      loading: userDecksData.loading,
      placeholder: t(Dictionary.dashboard.mobileNoYourDecks) //"No seus decks."
    }),
    [deckType, masterDecksData, userDecksData, t]
  )

  const handleDeckClick = useCallback((deck) => {
    if (deck.type === DeckType.merge) {
      history.push(`${routes.mergePage}/${deck.deckId}`)
    } else {
      history.push(`${routes.deckPage}/${deck.virtualId}`)
    }
  }, [history])

  const handleOpenListView = useCallback(() => {
    history.push(`${routes.dashboard}/list`)
  }, [history])

  const handleIndexChange = useCallback((index) => {
    dispatch(actions.setDashboardDeckIndex(index))
  }, [dispatch])

  // const handleAccessCourse = () => {
  //   window.open(links.accessCourse, "_blank")
  // }

  const noDecks = userDecksData.loading === false && userDecksData.data.length === 0;

  return (
    <>
      <GlobalStyle />
      <MobileNavigation show={showNavigation} onClose={handleHideNavigation} />
      <MobileHeader onShowMenu={handleShowNavigation} />
      <TopContainer noDecks={noDecks}>
        {noDecks ? (
          <NoDecksContent>
            <NoDecksImageWrapper>
              <NoDecksImageContainer>
                <NoDecksTitle>{t(Dictionary.dashboard.your)}<br />{t(Dictionary.dashboard.decks)}</NoDecksTitle>
              </NoDecksImageContainer>
            </NoDecksImageWrapper>
            <NoDecksBottom>
              <NoDecksNotice>
                {t(Dictionary.dashboard.noDecks)}
              </NoDecksNotice>
              {/* <Button primary outlined onClick={handleAccessCourse}>
                {t(Dictionary.dashboard.mobileDiscoverDecks)}
              </Button> */}
            </NoDecksBottom>
          </NoDecksContent>
        ) : (
          <TopContent>
            <Title>{t(Dictionary.dashboard.decks)}</Title>
            <DropdownRow>
              <MobileDropdown items={deckTypes} selectedId={deckType} onSelect={handleDeckTypeSelect} />
            </DropdownRow>
            <DecksCarousel
              deckType={deckType}
              decks={decks}
              initialIndex={initialIndex}
              loading={loading}
              placeholder={placeholder}
              onDeckClick={handleDeckClick}
              onOpenListClick={handleOpenListView}
              onIndexChange={handleIndexChange}
            />
          </TopContent>
        )}
      </TopContainer>
      {/* <StatisticsContainer>
        <StatisticsTitle>
          <StatisticsShadowTitle>{t(Dictionary.dashboard.mobileStatistics)}</StatisticsShadowTitle>
          <span>{t(Dictionary.dashboard.mobileStatistics)}</span>
        </StatisticsTitle>
        <NoStatsImage />
        <NoStatsNotice>
          {t(Dictionary.dashboard.mobileNoActivity)}
        </NoStatsNotice>
      </StatisticsContainer> */}
    </>
  )
}

export default memo(CarouselView)
