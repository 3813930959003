export const CN_TAGS = 'card-tags';
export const CN_FOREIGN_TEXT = 'card-header';

export const calculationHeightTexts = (parent) => {
  let newH = {};

  if(!parent) return newH
  const children = [...parent.children];
  if(children.length > 1) {

    let listY = []; 
    children.forEach((card, index) => {
      const top = card.offsetTop;
      const hasTop = listY.some(item => item.top === top);

      if(!hasTop){
        listY.push({top, cardsIndex: [index]})
      } else {
        listY.forEach(item => {
          if(item.top === top) {
            item.cardsIndex.push(index)
          }
        });
      }
    });

    const cl = CN_FOREIGN_TEXT;
    const cl_tags = CN_TAGS;
    listY.forEach(({cardsIndex}) => {
      let maxHeaderH = 0;
      cardsIndex.forEach(index => {
        const elem = children[index];
        const elemHeader = elem.querySelector(`.${cl}`);
        let h = Math.floor(elemHeader.offsetHeight);

        const elemTags = elem.querySelector(`.${cl_tags}`);
        if(elemTags) {
          h += elemTags.offsetHeight;
        }
        maxHeaderH = Math.max(maxHeaderH, h)
      });

      cardsIndex.forEach(index => {
        let h = maxHeaderH;
        const elem = children[index];
        const elemTags = elem.querySelector(`.${cl_tags}`);
        if(elemTags) {
          h -= elemTags.offsetHeight;
        }
        newH[`card_${index}`] = h
      });
    });
  }

  return newH
}