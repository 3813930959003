import React, { memo, useCallback, useEffect } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import EntrancePage from "../Common/EntrancePage"
import { ContentContainer, FormInnerContainer, PageContainer } from "../Common/styles"
import { RegisterFormContainer } from "./styles"
import RegisterForm from "./RegisterForm"
import BackButton from "../Common/BackButton"
import { routes } from "../../../utils/constants"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import { useHistory, useLocation } from "react-router-dom"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"
import qs from "query-string"

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: ""
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  confirmEmail: yup.string().email().required().test("mail-match", "Emails don't match", function (value) {
    return this.parent.email === value
  }),
  password: yup.string().required().min(6),
  confirmPassword: yup.string().required().test("pwd-match", "Password don't match", function (value) {
    return this.parent.password === value
  })
})

const RegisterPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    succeeded, error, pending
  } = useSelector(authSelectors.registerState)

  useEffect(() => () => {
    dispatch(authActions.userRegisterReset())
  }, [dispatch])

  useEffect(() => {
    if (!succeeded) {
      return undefined
    }

    history.push(routes.registerPhone)
  }, [history, succeeded])

  const handleSubmit = useCallback((values) => {
    const { search } = location
    const params = qs.parse(search)
    const { organizationId } = params
    if (!succeeded) {
      dispatch(authActions.userRegisterRequest({
        organizationId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        firstPassword: values.password,
        secondPassword: values.confirmPassword
      }))
    }
    // eslint-disable-next-line
  }, [dispatch, succeeded])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <PageContainer>
        <BackButton backUrl={location.state?.from ?? routes.login} />
        <ContentContainer>
          <RegisterFormContainer>
            <FormInnerContainer>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                <RegisterForm pending={pending} error={error} succeeded={succeeded} />
              </Formik>
            </FormInnerContainer>
          </RegisterFormContainer>
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(RegisterPage)
