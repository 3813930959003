import styled, { css } from "styled-components"
import OverdueCardsIcon from "../../../assets/svgs/OverdueCardsIcon"
import TotalCardsIcon from "../../../assets/svgs/TotalCardsIcon"
import ForwardArrowIcon from "../../../assets/svgs/ForwardArrowIcon"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"
import OopsPlaceholder from "../../../assets/svgs/OopsPlaceholder"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX } from "../../../common/breakpoints"
import Flag from "../../../common/components/Flag"

const highlightedColor = "#00AEE0"
const transitionParams = "0.3s ease-out"

export const Container = styled.div`
  position: relative;
  padding-top: 72%;
  color: white;
`

export const CardShadow = styled.div`
  position: absolute;
  top: 17px;
  right: 18px;
  bottom: 11px;
  left: 19px;
  box-shadow: 2px 10px 48px #0E528D;
  opacity: 0.65;
  display: none;

  ${Container}[data-has-overdue='true'] & {
    display: block;
  }
`

export const CardContainer = styled(ImageContainer)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  transition: box-shadow ${transitionParams};

  ${Container}[data-has-overdue='true'] & {
    border: solid 3px ${highlightedColor};
  }

  ${Container}[data-has-overdue='true']:hover & {
    box-shadow: 0px 0px 0px 1px ${highlightedColor};
  }

  .image-container__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 21px 26px 28px 25px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      padding: 17px 24px 21px 23px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      padding: 15px 18px 20px 18px;
    }
  }
`

export const TopNotice = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: -14px;
  right: 20px;
  max-width: calc(100% - 40px);
  background-color: #00AEE0;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1;
  border-radius: 9px;
  opacity: 0;

  strong {
    font-style: BlackerDisplayHeavy;
    font-size: 16px;
  }

  ${Container}[data-has-overdue='true']:hover & {
    opacity: 1;
  }

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 14px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    top: -10px;
    right: 14px;
    max-width: calc(100% - 28px);
    padding: 6px 10px;
    font-size: 11px;

    strong {
      font-size: 11px;
    }
  }
`

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CountersContainer = styled.div`
  display: flex;
  align-items: center;
  color: #D3D3D3;
`

const shiftDown = css`
  transition: transform ${transitionParams};
  backface-visibility: hidden;

  ${Container}[data-has-overdue='true']:hover & {
    transform: translateY(4px)
  }
`

export const CounterLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
  text-align: right;
  ${shiftDown}

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    margin-right: 6px;
  }
`

export const OverdueLabel = styled(CounterLabel)`
  ${Container}[data-has-overdue='true'] & {
    color: ${highlightedColor};
  }
`

export const OverdueIcon = styled(OverdueCardsIcon)`
  ${shiftDown}
  font-size: 21px;
  margin-right: 16px;

  ${Container}[data-has-overdue='true'] & {
    color: ${highlightedColor};
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
    margin-right: 11px;
  }
`

export const TotalIcon = styled(TotalCardsIcon)`
  ${shiftDown}
  font-size: 21px;
  width: 17px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
    width: 12px;
  }
`

export const Title = styled.div`
  position: relative;
  color: white;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1.1px;
  transition: opacity ${transitionParams};

  ${Container}:hover & {
    opacity: 0;
  }

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
    line-height: 19px;
  }
`

export const Description = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #E7E4E5;
  padding-top: 4px;
  bottom: 0;
  transition: bottom ${transitionParams};

  ${Container}:hover & {
    bottom: 62px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      bottom: 59px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      bottom: 43px;
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 13px;
  }
`

export const HoverTitle = styled.div`
  position: absolute;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: #FFFFFF;
  left: 25px;
  opacity: 0;
  bottom: 100%;
  transition: opacity ${transitionParams};

  ${Container}:hover & {
    opacity: 1;
    pointer-events: auto;
  }

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
    line-height: 19px;
    left: 18px;
  }
`

export const BottomBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 62px;
  background-color: ${highlightedColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px 0 26px;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity ${transitionParams};

  ${Container}:hover & {
    opacity: 1;
  }

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 59px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 18px 0 18px;
    height: 43px;
  }
`

export const BottomTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
  }
`

export const BottomForwardArrow = styled(ForwardArrowIcon)`
  font-size: 18px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 15px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
  }
`

export const OopsImage = styled(OopsPlaceholder)`
  font-size: 76px;
  color: white;
  opacity: 0.18;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 53px;
  }
`

export const StyledFlag = styled(Flag)`
  width: 35px;
  height: 35px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 25px;
    height: 25px;
  }
`
