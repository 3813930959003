import React, { memo } from "react"
import { Container, ArrowButton, NumbersContainer, PageCircle, Ellipsis } from "./styles"
import PrevPageIcon from "./icons/PrevPageIcon"
import NextPageIcon from "./icons/NextPageIcon"
import { range } from "lodash"

const PageControl = ({
  className,
  page: currentPage,
  totalPages,
  onChange
}) => {
  const handlePrevPageSelect = () => {
    onChange(currentPage - 1)
  }

  const handleNextPageSelect = () => {
    onChange(currentPage + 1)
  }

  const selectPageHandler = (page) => () => {
    onChange(page)
  }

  const renderRange = (start, end) => range(start, end).map((page) => (
    <PageCircle key={page} data-selected={page === currentPage} onClick={selectPageHandler(page)}>
      <span>{page + 1}</span>
    </PageCircle>
  ))

  const renderNumbers = () => {
    if (totalPages < 8) {
      return renderRange(0, totalPages)
    }

    if (currentPage < 3) {
      return (
        <>
          {renderRange(0, 4)}
          <Ellipsis>...</Ellipsis>
          {renderRange(totalPages - 1, totalPages)}
        </>
      )
    }

    if (currentPage > totalPages - 4) {
      return (
        <>
          {renderRange(0, 1)}
          <Ellipsis>...</Ellipsis>
          {renderRange(totalPages - 4, totalPages)}
        </>
      )
    }

    return (
      <>
        {renderRange(0, 1)}
        <Ellipsis>...</Ellipsis>
        {renderRange(currentPage - 1, currentPage + 2)}
        <Ellipsis>...</Ellipsis>
        {renderRange(totalPages - 1, totalPages)}
      </>
    )
  }

  if (totalPages < 2) {
    return null
  }

  return (
    <Container className={className}>
      <ArrowButton disabled={currentPage <= 0} onClick={handlePrevPageSelect}>
        <PrevPageIcon />
      </ArrowButton>
      <NumbersContainer>
        {renderNumbers()}
      </NumbersContainer>
      <ArrowButton disabled={currentPage >= totalPages - 1} onClick={handleNextPageSelect}>
        <NextPageIcon />
      </ArrowButton>
    </Container>
  )
}

export default memo(PageControl)
