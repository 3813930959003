import React, { memo } from "react"

const BrazilIcon = (props) => (
  <svg viewBox="0 0 28 28" {...props}>
    <path d="M25.1999 5.85458L19.0908 2.80004L13.4908 0.763672L2.79992 5.85458L1.78174 13.4909V21.1273L13.4908 27.2364L20.109 25.2L26.2181 20.6182L26.7272 13.4909L25.1999 5.85458Z" fill="#99FFCF"/>
    <path d="M14.1543 5.40515L24.4986 13.7745L14.1543 22.1438L3.81006 13.7785L14.1543 5.40515Z" fill="#F7BD31"/>
    <path d="M14.1544 18.1582C16.3517 18.1582 18.133 16.1954 18.133 13.7743C18.133 11.3532 16.3517 9.39038 14.1544 9.39038C11.9571 9.39038 10.1758 11.3532 10.1758 13.7743C10.1758 16.1954 11.9571 18.1582 14.1544 18.1582Z" fill="#6B47ED"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM14 2.03636C7.39267 2.03636 2.03636 7.39267 2.03636 14C2.03636 20.6073 7.39267 25.9636 14 25.9636C20.6073 25.9636 25.9636 20.6073 25.9636 14C25.9636 7.39267 20.6073 2.03636 14 2.03636Z" fill="white"/>
  </svg>
)

export default memo(BrazilIcon)
