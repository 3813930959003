import EnglishIcon from "./../../common/components/Flag/FlagIcons/EnglishIcon";
import FrenchIcon from "./../../common/components/Flag/FlagIcons/FrenchIcon";
import ItalianIcon from "./../../common/components/Flag/FlagIcons/ItalianIcon";
import GermanIcon from "./../../common/components/Flag/FlagIcons/GermanIcon";
import SpanishIcon from "./../../common/components/Flag/FlagIcons/SpanishIcon";
import PortugueseIcon from "./../../common/components/Flag/FlagIcons/BrazilIcon";
import ChineseIcon from "./../../common/components/Flag/FlagIcons/ChineseIcon";
import JapaneseIcon from "./../../common/components/Flag/FlagIcons/JapaneseIcon";
import { Dictionary } from "../i18n";

const italian = "italian";
const german = "german";
export const english = "english";
const french = "french";
const spanish = "spanish";
const business = "business";
const PT_BR = "pt-BR";
export const portuguese = "portuguese";
const gavin = "gavin";
const englsih = "englsih";
const mandarim = "mandarim";
export const mandarin = "mandarin";
export const japanese = "japanese";

export const flagComponents = {
  [italian]: ItalianIcon,
  [german]: GermanIcon,
  [english]: EnglishIcon,
  [french]: FrenchIcon,
  [spanish]: SpanishIcon,
  [business]: EnglishIcon,
  [PT_BR]: PortugueseIcon,
  [portuguese]: PortugueseIcon,
  [gavin]: EnglishIcon,
  [englsih]: EnglishIcon,
  [mandarim]: ChineseIcon,
  [mandarin]: ChineseIcon,
  [japanese]: JapaneseIcon
}

export const ENGLISH_TAGS = [english, business, englsih, gavin];
export const PORTUGUESE_TAGS = [PT_BR, portuguese];
export const CHINESE_TAGS = [mandarim, mandarin];

export const languageInfo = {
  [english]: {
    name: 'Inglês',
    keyTranslation: Dictionary.language.english,
    flag: flagComponents[english],
  },
  [portuguese]: {
    name: 'Português',
    keyTranslation: Dictionary.language.portuguese,
    flag: flagComponents[portuguese],
  },
  [italian]: {
    name: 'Italiano',
    keyTranslation: Dictionary.language.italian,
    flag: flagComponents[italian]
  },
  [german]: {
    name: 'Alemão',
    keyTranslation: Dictionary.language.german,
    flag: flagComponents[german]
  },
  [french]: {
    name: 'Francês',
    keyTranslation: Dictionary.language.french,
    flag: flagComponents[french]
  },
  [spanish]: {
    name: 'Espanhol',
    keyTranslation: Dictionary.language.spanish,
    flag: flagComponents[spanish]
  },
  [japanese]: {
    name: 'Japonês',
    keyTranslation: Dictionary.language.japanese,
    flag: flagComponents[japanese]
  },
  [mandarin]: {
    name: 'Mandarim',
    keyTranslation: Dictionary.language.mandarin,
    flag: flagComponents[mandarin]
  }
}