import React, { memo, useState, useCallback } from "react"
import { Container, BgImage, FallbackContainer, ContentContainer } from "./styles"

const ImageContainer = ({ src, fallback, contentRef, children, ...restProps }) => {
  const [imageFailed, setImageFailed] = useState(!src)

  const handleImageLoad = useCallback(() => {
    setImageFailed(false)
  }, [])

  const handleImageError = useCallback(() => {
    setImageFailed(true)
  }, [])

  return (
    <Container {...restProps}>
      <BgImage src={src} onLoad={handleImageLoad} onError={handleImageError} />
      {imageFailed && <FallbackContainer>{fallback}</FallbackContainer>}
      <ContentContainer ref={contentRef}>{children}</ContentContainer>
    </Container>
  )
}

export default memo(ImageContainer)
