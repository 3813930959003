import React, { memo, useCallback } from "react"
import DigitIcon from "./Icons/DigitIcon"
import EnterIcon from "./Icons/EnterIcon"
import SpaceIcon from "./Icons/SpaceIcon"
import CloseIcon from "./Icons/CloseIcon"
import { CloseButton, IconWrapper, Label, Overlay, PanelContainer, Row, Title } from "./styles"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

const DesktopControls = ({
  show,
  onClose
}) => {
  const {t} = useTranslation();
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Overlay data-show={show}>
      <PanelContainer>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <Title>{t(Dictionary.studySession.commands.title)}</Title>
        <Row>
          <Label><strong>{t(Dictionary.studySession.commands.space)}:</strong> {t(Dictionary.studySession.commands.spaceCommand)}</Label>
          <IconWrapper><SpaceIcon /></IconWrapper>
        </Row>
        <Row>
          <Label><strong>{t(Dictionary.studySession.commands.enter)}:</strong> {t(Dictionary.studySession.commands.enterCommand)}</Label>
          <IconWrapper><EnterIcon /></IconWrapper>
        </Row>
        <Row>
          <Label><strong>1:</strong> {t(Dictionary.studySession.commands.keyText)} “{t(Dictionary.studySession.easy)}”</Label>
          <IconWrapper><DigitIcon>1</DigitIcon></IconWrapper>
        </Row>
        <Row>
          <Label><strong>2:</strong> {t(Dictionary.studySession.commands.keyText)} “{t(Dictionary.studySession.good)}”</Label>
          <IconWrapper><DigitIcon>2</DigitIcon></IconWrapper>
        </Row>
        <Row>
          <Label><strong>3:</strong> {t(Dictionary.studySession.commands.keyText)} “{t(Dictionary.studySession.hard)}”</Label>
          <IconWrapper><DigitIcon>3</DigitIcon></IconWrapper>
        </Row>
        <Row>
          <Label><strong>4:</strong> {t(Dictionary.studySession.commands.keyText)} “{t(Dictionary.studySession.forgot)}”</Label>
          <IconWrapper><DigitIcon>4</DigitIcon></IconWrapper>
        </Row>
      </PanelContainer>
    </Overlay>
  )
}

export default memo(DesktopControls)
