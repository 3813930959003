import React, { memo } from "react"

const EyeCrossedIcon = (props) => (
  <svg height="1em" viewBox="0 0 28.254 22.128" {...props}>
    <path fill="currentColor" d="M4.436,18.954l3.687-3.687a6.369,6.369,0,0,1,8.142-8.142l2.519-2.519a15.344,15.344,0,0,0-4.657-.716A15.508,15.508,0,0,0,.057,12.865a.611.611,0,0,0,0,.513A15.621,15.621,0,0,0,4.436,18.954Z" transform="translate(0 -2.058)"/>
    <path fill="currentColor" d="M34.458,11.11,30.771,14.8a6.369,6.369,0,0,1-8.142,8.142L20.11,25.458a15.534,15.534,0,0,0,18.728-8.264.611.611,0,0,0,0-.513A15.646,15.646,0,0,0,34.458,11.11Z" transform="translate(-10.64 -5.878)"/>
    <path fill="currentColor" d="M29.762,27.33a4.08,4.08,0,0,0,4.078-4.078c0-.071,0-.141,0-.212L29.55,27.325C29.621,27.33,29.691,27.33,29.762,27.33Z" transform="translate(-15.635 -12.19)"/>
    <path fill="currentColor" d="M25.418,14.84a4.08,4.08,0,0,0-4.078,4.078c0,.071,0,.141,0,.212l4.285-4.285C25.559,14.84,25.489,14.84,25.418,14.84Z" transform="translate(-11.291 -7.852)"/>
    <path fill="currentColor" d="M8.862,17.626,6.95,19.543a1.514,1.514,0,0,0,1.074,2.585A1.535,1.535,0,0,0,9.1,21.685l2.557-2.557,2.614-2.614,1.7-1.7,5.354-5.354,1.7-1.7,3.254-3.254L28.193,2.59a1.512,1.512,0,0,0,0-2.143A1.493,1.493,0,0,0,27.119,0a1.527,1.527,0,0,0-1.074.443L23.488,3,20.875,5.613l-1.7,1.7-5.354,5.354-1.7,1.7Z" transform="translate(-3.442 0)"/>
  </svg>
)

export default memo(EyeCrossedIcon)
