import styled from "styled-components"
import { LAPTOP_MAX } from "../../../common/breakpoints"

const transitionParams = "0.25s ease-out"

export const Container = styled.div`
  display: flex;
  height: 68px;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 48px;
  }

  :not(:last-child) {
    margin-bottom: 36px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 25px;
    }
  }

  &[data-disabled='false'] {
    cursor: pointer;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  font-size: 35px;
  border: solid 1px #D6DAEB48;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: border-color ${transitionParams};

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 60px;
    font-size: 25px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  ${Container}[data-disabled='false'] {
    border-right: none;
  }

  ${Container}[data-disabled='true'] & > * {
    opacity: 0.38;
  }
`

export const LabelContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0px;
  padding: 0 32px;
  border: solid 1px #D6DAEB48;
  border-left: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  transition: border-color ${transitionParams}, font-size ${transitionParams}, color ${transitionParams};

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    padding: 0 22px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  ${Container}[data-disabled='false'][data-hover='true'] & {
    color: black;
    font-size: 20px;

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 14px;
    }
  }

  ${Container}[data-disabled='true'] & > * {
    opacity: 0.38;
  }

  > span, > strong {
    position: relative;
  }
`

export const LabelBackground = styled.div`
  position: absolute;
  left: 0;
  top: -1px;
  bottom: -1px;
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;

  @media (max-width: ${LAPTOP_MAX}px) {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
`

export const BackgroundFill = styled.div`
  height: 100%;
  width: 0;
  transition: width ${transitionParams};
`

export const StatusContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
