import React, { memo, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../common/breakpoints"
import { CHANGED } from "../../../hooks/useChangeTransition"
import useTransitionState, { ENTERING, EXITING } from "../../../hooks/useTransitionState"
import * as selectors from "../../../store/selectors/sessions"
import { CARD_FLIP_DURATION, BUTTONS_FADE_DURATION } from "../Card/constants"
import Tips from "../Tips"
import { Container, CenteredContent, StyledCard, StyledGradeButtons } from "./styles"

const GradeCard = ({
  card,
  autoplay = true,
  showFrontText = true,
  changeTransition = CHANGED,
  isNext = false,
  isLast = false,
  onGrade,
  playerReady,
  handlePlaybackRate
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const buttonsFadeDuration = isMobile ? BUTTONS_FADE_DURATION : 0
  const gradeStateSelector = useMemo(() => selectors.gradeStateSelector(card.id), [card])

  const {
    currentGrade,
    gradeStatus,
    nextDue
  } = useSelector(gradeStateSelector)

  const [flipped, setFlipped] = useState(false)
  const [cardWasFlipped, setCardWasFlipped] = useState(false)
  const [showSpaceBarTip, setShowSpaceBarTip] = useState(false)
  const flipTransition = useTransitionState(flipped, CARD_FLIP_DURATION+((-1*(+flipped))*100), { enter: 0, exit: buttonsFadeDuration })
  const buttonsTransition = useTransitionState(flipped, buttonsFadeDuration, { enter: CARD_FLIP_DURATION, exit: 0 })
  
  const handleFlipButtonHover = useCallback((hover) => {
    setShowSpaceBarTip(hover)
  }, [])

  const handleCardFlip = useCallback(() => {
    setFlipped(prev => !prev)
    setCardWasFlipped(true)
  }, [])

  const cardHasImage =
    card.notes.length > 1 &&
    (
      (card.notes[0].images && card.notes[0].images.length > 0) ||
      (card.notes[1].images && card.notes[1].images.length > 0)
    )

  const inTransition =
    changeTransition !== CHANGED ||
    flipTransition === ENTERING ||
    flipTransition === EXITING ||
    buttonsTransition === ENTERING ||
    buttonsTransition === EXITING

  return (
    <Container
      data-in-transition={inTransition}
      data-is-next={isNext}
      data-is-last={isLast}
      data-change-transition={changeTransition}
    >
      <CenteredContent data-has-image={!!cardHasImage}>
        <Tips showSpaceBarTip={showSpaceBarTip} />
        <StyledCard
          card={card}
          flipTransition={flipTransition}
          autoplay={autoplay}
          showFrontText={showFrontText}
          onFlip={handleCardFlip}
          onFlipButtonHover={handleFlipButtonHover}
          playerReady={playerReady}
          handlePlaybackRate={handlePlaybackRate}
        />
        <StyledGradeButtons
          transition={buttonsTransition}
          disabled={!cardWasFlipped}
          currentGrade={currentGrade}
          gradeStatus={gradeStatus}
          nextDue={nextDue}
          onGrade={onGrade}
        />
      </CenteredContent>
    </Container>
  )
}

export default memo(GradeCard)
