import React, { memo, useMemo, useCallback } from "react"
import { useFormikContext } from "formik"
import { Link, useHistory } from "react-router-dom"
import InputField from "../Common/InputField"
import { BottomFormPart, ButtonsGroup, EmailIcon, InputGroup, RegisterButton, StyledForm, TopFormPart } from "../Common/styles"
import {
  HeaderContainer,
  StyledFormStatus,
  Logo,
  Notice,
  StyledSubmitButton,
  RegisterContainer,
  MobileRegisterLink,
  NoticeSpacer
} from "./styles"
import { routes } from "../../../utils/constants"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../common/breakpoints"
import useOnChange from "../../../hooks/useOnChange"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";


const ForgotPasswordForm = ({ pending, error, succeeded }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const formik = useFormikContext()
  const history = useHistory()
  const {t} = useTranslation();

  const fieldNames = {
    email: t(Dictionary.login.email)
  }

  const status = useMemo(() => {
    if (succeeded) {
      return {
        type: "success",
        title: t(Dictionary.forgotPassword.ready),
        message: t(Dictionary.forgotPassword.readyDesc)
      }
    }

    if (error) {
      return {
        type: "error",
        title: t(Dictionary.common.oops),
        message: error.message
      }
    }

    return null
  }, [error, succeeded, t])

  useOnChange(() => {
    if (!pending) {
      formik.setSubmitting(false)
    }
  }, [pending])

  const handleRegister = useCallback(() => {
    history.push(routes.register, { from: routes.forgot })
  }, [history])

  const topGroup = (
    <>
      <HeaderContainer>
        <Logo data-succeeded={succeeded && !isMobile} />
        <StyledFormStatus fieldNames={fieldNames} status={status} showLogo={false}>
          <Notice>{t(Dictionary.forgotPassword.title)}:</Notice>
        </StyledFormStatus>
        <NoticeSpacer />
      </HeaderContainer>
      <InputGroup>
        <InputField name="email" type="text" placeholder={t(Dictionary.login.email)} icon={<EmailIcon />} />
      </InputGroup>
    </>
  )

  const submitButton = <StyledSubmitButton primary type="submit" loading={formik.isSubmitting}>{t(Dictionary.forgotPassword.send)}</StyledSubmitButton>

  return isMobile ? (
    <StyledForm>
      <TopFormPart>
        {topGroup}
        {submitButton}
      </TopFormPart>
      <BottomFormPart>
        <MobileRegisterLink>
          {t(Dictionary.forgotPassword.registerText)}
        </MobileRegisterLink>
        <RegisterButton type="button" onClick={handleRegister}>{t(Dictionary.login.signUp)}</RegisterButton>
      </BottomFormPart>
    </StyledForm>
  ) : (
    <StyledForm>
      {topGroup}
      <ButtonsGroup>
        {submitButton}
        <RegisterContainer>
          <span>{t(Dictionary.forgotPassword.registerText)}&nbsp;</span>
          <Link to={{ pathname: routes.register, state: { from: routes.forgot } }}>{t(Dictionary.forgotPassword.registerTextLink)}</Link>
        </RegisterContainer>
      </ButtonsGroup>
    </StyledForm>
  )
}

export default memo(ForgotPasswordForm)
