import styled from "styled-components"
import { CARD_CHANGE_DURATION } from "../../Card/constants"

export const Container = styled.div`
  position: relative;
  padding: 15px 24px 3px 24px;

  &[data-completed='true'] {
    transition: opacity ${CARD_CHANGE_DURATION}ms ease-out;
    opacity: 0;
  }
`

export const CloseButtonRow = styled.div`
  margin-bottom: 19px;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 14px;
  margin: -15px;
  z-index: 1;
  color: white;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  letter-spacing: 0.7px;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 4px;
  color: white;
`

export const ProgressNumbersRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  line-height: 12px;
  height: 12px;
  color: #FFFFFF;
  opacity: 0.45;
  margin-bottom: 5px;
`

export const ProgressBar = styled.div`
  background-color: #FFFFFF73;
  border-radius: 3px;
  overflow: hidden;
  height: 8px;
`

export const ProgressBarFill = styled.div`
  height: 100%;
  width: 3px;
  background-color: white;
  transition: width 200ms ease-out;
`