import React, { memo } from "react"
import useKeepValue from "../../../hooks/useKeepValue"
import { Dictionary } from "../../../utils/i18n"
import { useTranslation } from 'react-i18next';
import { SpaceCharacter, TipsContainer, TipRow, AsteriskCharacter } from "./styles"

const Tips = ({
  showSpaceBarTip,
  showTranslationTip
}) => {
  let content = null;
  const {t} = useTranslation();

  if (showSpaceBarTip) {
    content = (
      <div>
        <SpaceCharacter>⎵&nbsp;&nbsp;</SpaceCharacter>
        <strong>{t(Dictionary.studySession.tip)}:&nbsp;</strong>
        <span>{t(Dictionary.studySession.tipText)}</span>
      </div>
    )
  } else if (showTranslationTip) {
    content = (
      <TipRow>
        <AsteriskCharacter>*</AsteriskCharacter>
        <span>
          <span>Os textos das cartas estão desativados para você treinar melhor seu <i>listening!</i></span>
          <br />
          <span>Você pode ativar os textos no campo </span>
          <strong>”controles” </strong>
          <span>acima sempre que precisar.</span>
        </span>
      </TipRow>
    )
  }

  const displayContent = useKeepValue(content)

  return (
    <TipsContainer data-show={showSpaceBarTip || showTranslationTip}>
      {displayContent}
    </TipsContainer>
  )
}

export default memo(Tips)