import React, { useState } from "react"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { Dictionary } from "../../../../utils/i18n"
import useOnChange from "../../../../hooks/useOnChange"
import { Container, LogoWithLabel, Logo, StatusText } from "./styles"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../../common/breakpoints"
import ErrorToast from "../ErrorToast"

const FormStatus = ({
  className,
  showLogo = true,
  fieldNames,
  status,
  children
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const formik = useFormikContext()
  const [displayStatus, setDisplayStatus] = useState(null)
  const {t} = useTranslation();

  useOnChange(() => {
    if (!formik.isSubmitting) {
      const fields =
        Object.keys(formik.errors)
          .filter((key) => formik.touched[key])
          .map((key) => fieldNames[key] ?? "")
          .join(", ")

      if (fields) {
        const title = t(Dictionary.common.oops);
        const message = t(Dictionary.login.errorFields);
        setDisplayStatus({
          type: "error",
          title,
          message: <span>{message}: <strong>{fields}.</strong></span>
        })
      } else if (status) {
        setDisplayStatus(status)
      } else {
        setDisplayStatus(null)
      }
    }
  }, [formik.isSubmitting])

  if (!isMobile && displayStatus) {
    return (
      <Container
        className={className}
        data-show-logo={showLogo}
        data-has-status={!!displayStatus}
        data-type={displayStatus.type}
      >
        {showLogo && <Logo />}
        <StatusText>
          <b>{displayStatus.title}&nbsp;&nbsp;</b>
          <span>{displayStatus.message}</span>
        </StatusText>
      </Container>
    )
  }

  return (
    <Container className={className} data-show-logo={showLogo}>
      {showLogo && <LogoWithLabel />}
      {children}
      {isMobile && (
        <ErrorToast status={displayStatus} />
      )}
    </Container>
  )
}

export default FormStatus
