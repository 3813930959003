import React, { Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import { history as appHistory } from "../../history"
import useOnMount from "../../hooks/useOnMount"
import * as authActions from "../../store/actions/auth"
import * as authSelectors from "../../store/selectors/auth"
import GlobalStyles from "../../assets/css/GlobalStyles"
import { routes } from "../../utils/constants"
import LoginPage from "../Authentication/LoginPage"
import RegisterPage from "../Authentication/RegisterPage"
import RegisterPhonePage from "../Authentication/RegisterPhonePage"
import VerifyPhoneCompletePage from "../Authentication/VerifyPhoneCompletePage"
import VerifyPhoneNumberPage from "../Authentication/VerifyPhoneNumberPage"
import RegisterCompletePage from "../Authentication/RegisterCompletePage"
import ForgotPasswordPage from "../Authentication/ForgotPasswordPage"
import TermsAndConditionsPage from "../Authentication/TermsAndConditionsPage"
import DashboardPage from "../DashboardPage"
import DashboardMobilePage from "../DashboardPageMobile"
import DeckPage from "../DeckPage"
import DeckPageMobile from "../DeckPageMobile"
import DeckModePage from "../DeckModePage"
import DeckModePageMobile from "../DeckModePageMobile"
import StudySession from "../StudySession"
import StudySessionStatsPage from "../StudySessionStatsPage"
import StudySessionStatsPageMobile from "../StudySessionStatsPageMobile"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../common/breakpoints"
import { DeckType } from "../../constants"
import ReferralRoute from "../../utils/components/ReferralRoute"
import qs from "query-string"
import i18n, { EnLocale, PtLocale, EsLocale } from "../../utils/i18n"
import firebase
, { analytics }
  from "./../../firebase"

const AppRoot = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const dispatch = useDispatch()
  const { isLoggedIn, user, isUserCached } = useSelector(authSelectors.appAuthInfo)

  useOnMount(() => {
    if (isLoggedIn && (!user || isUserCached)) {
      dispatch(authActions.loadUserRequest())
    }
    console.log("🚀 ~ file: index.js ~ line 51 ~ useOnMount ~ analytics", analytics)
    console.log("🚀 ~ file: index.js ~ line 51 ~ useOnMount ~ firebase", firebase)

    const navigatorLang = window.navigator.language || window.navigator.userLanguage
    const [userLang] = navigatorLang.split("-")
    if (!i18n.language && (userLang === "en" || userLang === "pt" || userLang === "es")) {
      i18n.changeLanguage(userLang)
    }
  })

  const renderProtected = (content) => {
    const search = window.location.search
    const { organizationId } = qs.parse(search)
    const path = `${routes.login}${organizationId ? `?organizationId=${organizationId}` : ""}`

    return isLoggedIn ? content : <Redirect to={path} />
  }

  return (
    <Suspense fallback="...">
      <Router history={appHistory}>
        <GlobalStyles />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
        <Switch>
          <Route exact path={routes.login}>
            <LoginPage />
          </Route>
          <Route exact path={routes.register}>
            <RegisterPage />
          </Route>
          <Route exact path={routes.registerPhone}>
            <RegisterPhonePage />
          </Route>
          <Route exact path={routes.verifyPhoneNumber}>
            <VerifyPhoneNumberPage />
          </Route>
          <Route exact path={routes.registerComplete}>
            <VerifyPhoneCompletePage />
          </Route>
          <Route exact path={routes.forgot}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={routes.forgotComplete}>
            <RegisterCompletePage resetPassword />
          </Route>
          <Route exact path={routes.termsAndConditions}>
            <TermsAndConditionsPage />
          </Route>

          <EnLocale exact path={[routes.enLocale, routes.dashboard + routes.enLocale]} />
          <PtLocale exact path={[routes.ptLocale, routes.dashboard + routes.ptLocale]} />
          <EsLocale exact path={[routes.esLocale, routes.dashboard + routes.esLocale]} />

          <Route path={routes.dashboard}>
            {renderProtected(isMobile ? <DashboardMobilePage /> : <DashboardPage />)}
          </Route>
          <Route path={[`${routes.deckPage}/:deckId`, `${routes.mergePage}/:mergeId`]}>
            <ReferralRoute>
              {renderProtected(isMobile ? <DeckPageMobile /> : <DeckPage />)}
            </ReferralRoute>
          </Route>
          <Route path={routes.mergeStudyMode}>
            {renderProtected(
              isMobile ?
                <DeckModePageMobile sessionTargetType={DeckType.merge} /> :
                <DeckModePage sessionTargetType={DeckType.merge} />
            )}
          </Route>
          <Route path={routes.deckStudyMode}>
            {renderProtected(
              isMobile ?
                <DeckModePageMobile sessionTargetType={DeckType.deck} /> :
                <DeckModePage sessionTargetType={DeckType.deck} />
            )}
          </Route>
          <Route path={routes.deckStudySession}>
            {renderProtected(<StudySession type="deck" />)}
          </Route>
          <Route path={routes.mergeStudySession}>
            {renderProtected(<StudySession type="merge" />)}
          </Route>
          <Route path={[`${routes.studySessionStats}/merge/:mergeId`, `${routes.studySessionStats}/deck/:deckId`]}>
            {renderProtected(isMobile ? <StudySessionStatsPageMobile /> : <StudySessionStatsPage />)}
          </Route>

          <Route path="/" exact>
            <Redirect to={routes.dashboard} />
          </Route>

        </Switch>
      </Router>
    </Suspense>
  )
}

export default AppRoot
