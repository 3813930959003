import { Log } from "../logger"
import { verbose } from "../verbose"
import { isString, isObject } from "../checks"

/**
 * * handleRedirect
 * redirects to page according to route and active page history after timeout
 * @function handleRedirect
 * @param history defines apps history object
 * @param route defines route to redirect to
 * @param event defines handler's event
 * @param timeout define timeout before redirecting
 */
export const handleRedirect = (history, route, timeout = 600, replace = false) => {
  if (isString(route) && isObject(history)) {
    setTimeout(() => {
      if (route === `back`) {
        history.goBack()
      } else {
        if (replace) {
          history.replace(route)
        } else {
          history.push(route)
        }
      }
    }, timeout)
  } else if (verbose) {
    Log.error(
      `routing.js`,
      `handleRedirect`,
      `isString(route: ${route}) && isObject(history: ${history})`
    )
  }
}
