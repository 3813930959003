import React, { memo } from "react"
import BackArrowIcon from "../../../assets/svgs/BackArrowIcon"
import ControlsIcon from "../../../assets/svgs/ControlsIcon"
import FoxIcon from "../../../assets/svgs/FoxIcon"
import useTransitionState, { ENTERED } from "../../../hooks/useTransitionState"
import { CARD_CHANGE_DELAY } from "../Card/constants"
import {
  BackButton,
  TopBarContainer,
  ProgressContainer,
  ControlsButton,
  DeckName,
  DeckNameEllipsis,
  ProgressSpacer,
  ProgressBarOuter,
  ProgressBarInner,
  ProgressCounter,
  LogoContainer,
  DeckNameContainer
} from "./styles"
import i18n, { Dictionary } from "./../../../utils/i18n";

const DesktopTopBar = ({
  type,
  count,
  sessionData,
  sessionCompleted,
  onGoBack,
  onOpenControls
}) => {
  const completeTransition = useTransitionState(sessionCompleted, 0, CARD_CHANGE_DELAY)
  const {current, total, progress} = count;

  return (
    <TopBarContainer data-completed={completeTransition === ENTERED}>
      <BackButton onClick={onGoBack}>
        <BackArrowIcon />
        <span>{i18n.t(Dictionary.studySession.back)}</span>
      </BackButton>
      <DeckNameContainer>
        <DeckName>
          <DeckNameEllipsis>
            <span>{type === "deck" ? "Deck" : "Master deck"}:</span>&nbsp;
            <b>{sessionData.name}</b>
          </DeckNameEllipsis>
        </DeckName>
      </DeckNameContainer>
      <ProgressContainer>
        <ProgressBarOuter>
          <ProgressBarInner style={{ width: `${progress}%` }}>
            {progress}%
          </ProgressBarInner>
        </ProgressBarOuter>
        <ProgressCounter>
          <strong>{Math.min(current + 1, total)}&nbsp;/&nbsp;</strong>
          <span>{total}</span>
        </ProgressCounter>
      </ProgressContainer>
      <ProgressSpacer />
      <ControlsButton onClick={onOpenControls}>
        <ControlsIcon />
        <span>{i18n.t(Dictionary.studySession.commandsButton)}</span>
      </ControlsButton>
      <LogoContainer>
        <FoxIcon />
      </LogoContainer>
    </TopBarContainer>
  )
}

export default memo(DesktopTopBar)
