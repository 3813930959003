import React, { memo, useCallback } from "react"
import { useHistory } from "react-router-dom"
import TopBackButton from "../../../common/components/TopBackButton"
import { TopBarContainer } from "../../../common/styles/topBar"
import { routes } from "../../../utils/constants"
import { Dictionary } from "../../../utils/i18n"
import { BreadcrumbContainer, Logo } from "./styles"
import { useTranslation } from "react-i18next"

const TopBar = ({ type, id, title, navMenuExpanded }) => {
  const {t} = useTranslation();
  const history = useHistory()

  const handleGoBack = useCallback(() => {
    if (type === "merge") {
      history.replace(`${routes.mergePage}/${id}`)
    } else {
      history.replace(`${routes.deckPage}/${id}`)
    }
  }, [history, type, id])

  return (
    <TopBarContainer data-nav-expanded={navMenuExpanded}>
      <TopBackButton onClick={handleGoBack} />
      <BreadcrumbContainer>
        <Logo />
        <span>{t(Dictionary.common.home)}&nbsp;&nbsp;/&nbsp;&nbsp;</span>
        {type === "merge" ? "Master deck:" : "Deck:"}&nbsp;
        {title}&nbsp;&nbsp;/&nbsp;&nbsp;{t(Dictionary.dashboard.mobileStatistics)}
      </BreadcrumbContainer>
    </TopBarContainer>
  )
}

export default memo(TopBar)
