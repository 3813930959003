import React, { memo, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"
import { routes } from "../../../utils/constants"
import i18n, { Dictionary } from "../../../utils/i18n"
import { BreadcrumbContainer, Logo, StyledBackButton, StyledTopBarContainer, DeckName } from "./styles"

const TopBar = ({ type, id, title, navMenuExpanded }) => {
  const history = useHistory()

  const handleGoBack = useCallback(() => {
    if (type === "merge") {
      history.replace(`${routes.mergePage}/${id}`)
    } else {
      history.replace(`${routes.deckPage}/${id}`)
    }
  }, [history, type, id])

  return (
    <StyledTopBarContainer data-nav-expanded={navMenuExpanded}>
      <StyledBackButton onClick={handleGoBack} />
      <BreadcrumbContainer>
        <Logo />
        <span>
          <Link to={routes.dashboard}>{i18n.t(Dictionary.common.home)}</Link>&nbsp;&nbsp;
          <DeckName>/</DeckName>&nbsp;&nbsp;
        </span>
        {type === "merge" ? "Master deck:" : "Deck:"}&nbsp;
        <DeckName>{title}</DeckName>
      </BreadcrumbContainer>
    </StyledTopBarContainer>
  )
}

export default memo(TopBar)
