import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { uniqueId } from "lodash"
import waveSurfer from "wavesurfer.js"
import { Duration, PlayButton, StyledPauseIcon, StyledPlayIcon,
  Waveform, WaveformRow, WaveformPlayerRow, RateButton
} from "./styles"
import { msToMS } from "../../../utils/formatting/time/msToMS"
import useOnChange from "../../../hooks/useOnChange"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../breakpoints"

const emptyConfig = {}
const cnPlayer = 'wavesurfer-player';

const AudioPlayer = ({
  className,
  url,
  entityId = null,
  enabled = true,
  autoplay = false,
  needPlayerReady = false,
  playerReady = 1,
  handlePlaybackRate,
  config: configOverride = emptyConfig
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const waveformIdRef = useRef(uniqueId("audio-waveform-"))
  const playerRef = useRef(null)
  const [durationText, setDurationText] = useState("--:--")
  const [wasPlayed, setWasPlayed] = useState(false)
  const [isPlayed, setIsPlayed] = useState(false)
  const [isErrorAudio, setIsErrorAudio] = useState(false)
  const [showPlayerReady, setShowPlayerReady] = useState(false);
  // const [activeSpeed, setActiveSpeed] = useState(speeds.indexOf(1));
  // const [showSpeed, setShowSpeed] = useState(false);

  const cleanup = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.destroy();
      playerRef.current = null;
    }
  }, []);

  const createPlayer = () => {
    cleanup()

    const config = {
      backend: 'MediaElement',
      container: `#${waveformIdRef.current}`,
      waveColor: "#D3D3D3",
      progressColor: "#F24556",
      barWidth: 3,
      barGap: 2,
      barRadius: 3,
      height: 50,
      barMinHeight: 1,
      cursorColor: "transparent",
      normalize: true,
      audioRate: playerReady,
      // responsive: true,
      ...configOverride
    }

    if (isMobile) {
      config.barRadius = 0
      config.barWidth = 1
      config.barGap = 1
    }

    const player = waveSurfer.create(config)

    setDurationText("--:--")
    setWasPlayed(false)
    setShowPlayerReady(false)

    player.on("ready", () => {
      const duration = Math.ceil(player.getDuration())*1000;
      setDurationText(msToMS(duration))
      setShowPlayerReady(true)
    })

    player.on("error", () => {
      setIsErrorAudio(true)
    })

    player.on("play", () => {
      setWasPlayed(true)
      setIsPlayed(true)
    })
    player.on("pause", () => {
      setIsPlayed(false)
    })

    if (url) {
      player.load(url)
    }
    playerRef.current = player
  }

  // useOnChange(() => {
  //   const _isPlayed = playerRef.current.isPlaying();

  //   // if(!_isPlayed && showSpeed) {
  //   //   setShowSpeed(false)
  //   // }
  // }, [isPlayed])

  // useOnChange(() => {
  //   playerRef.current.setPlaybackRate(speeds[activeSpeed]);
  // }, [activeSpeed])

  useOnChange(() => {
    playerRef.current.setPlaybackRate(playerReady)
  }, [playerReady])

  useEffect(() => {
    createPlayer()
    var responsiveWave = playerRef.current.util.debounce(function() {
      if(playerRef.current){
        playerRef.current.empty();
        playerRef.current.drawBuffer();
      }
    }, 150);

    window.addEventListener('resize', responsiveWave);
    return () => {
      window.removeEventListener('resize', responsiveWave);
      cleanup();
    }
  // eslint-disable-next-line 
  }, [])

  useOnChange(() => {
    createPlayer()
  }, [url, entityId])

  useOnChange(() => {
    if (!enabled && playerRef.current) {
      playerRef.current.pause()
    }
  }, [enabled])

  useEffect(() => {
    if (enabled && autoplay && !wasPlayed && showPlayerReady && playerRef.current) {
      playerRef.current.play && playerRef.current.play()
    }
  }, [enabled, autoplay, wasPlayed, showPlayerReady])

  useEffect(() => () => cleanup, [cleanup])

  const stopDifferentPlayer = () => {
    const allPlayer = document.body.querySelectorAll(`.${cnPlayer}`);

    if(allPlayer.length > 1){
      [...allPlayer].forEach(item => {
        const contPlayer = item.querySelector('.audio-player__waveform');
        const currentPlayer = contPlayer.id === waveformIdRef.current;
        if(contPlayer && !currentPlayer){
          const audio = contPlayer.querySelector('audio');
          const isPlayed = audio.paused;
          if(!isPlayed) {
            audio.pause();
          }
        }
      });

    }
  }

  const handlePlay = useCallback(() => {
    if (enabled && playerRef.current) {
      stopDifferentPlayer();
      // const _isPlayed = playerRef.current.isPlaying();
      // if(!_isPlayed && !showSpeed) {
      //   setShowSpeed(true)
      // }
      playerRef.current.playPause();
    }
  // eslint-disable-next-line
  }, [enabled])

  // const handlePlaybackRate = () => {
  //   const newSpeed = (activeSpeed+1)%(speeds.length)
  //   setActiveSpeed(newSpeed)
  // }

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.code === "Enter") {
        if (enabled && playerRef.current) {
          event.preventDefault()
          playerRef.current.play(0)
        }
      }
    }

    window.addEventListener("keyup", handleKeyUp)
    return () => {
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [enabled])

  return (
    <div className={`${className} ${cnPlayer}`}>
      <Duration>
        {durationText}
      </Duration>
      <WaveformRow>
        <WaveformPlayerRow>
          {needPlayerReady && showPlayerReady && <RateButton
            onClick={handlePlaybackRate}
            isMobile={isMobile}
            show={showPlayerReady}
          >
            <span>{playerReady}x</span>
          </RateButton>}
          <Waveform id={waveformIdRef.current} isError={isErrorAudio}/>
        </WaveformPlayerRow>
        <PlayButton onClick={handlePlay} isError={isErrorAudio}>
          {isPlayed ? <StyledPauseIcon /> : <StyledPlayIcon />}
        </PlayButton>
      </WaveformRow>
    </div>
  )
}

export default memo(AudioPlayer)