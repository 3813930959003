import React, { memo } from "react"

const ShortIcon = (props) => (
  <svg height="1em" viewBox="0 0 30 35.02" {...props}>
    <rect x="10" width="10" height="3.33" fill="#fff" />
    <path
      d="M26.72,10.65l2.36-2.37a18,18,0,0,0-2.35-2.35L24.37,8.3a15,15,0,1,0,2.35,21.08,14.77,14.77,0,0,0,3.15-7.44A14.94,14.94,0,0,0,26.72,10.65Zm-.21,11.29A11.66,11.66,0,0,1,15,31.67h0A11.67,11.67,0,1,1,26.67,20,11.29,11.29,0,0,1,26.51,21.94Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      d="M26.51,21.94A11.66,11.66,0,0,1,15,31.67h0A11.67,11.67,0,0,1,15,8.33V21.94Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      d="M26.67,20a11.29,11.29,0,0,1-.16,1.94H15V8.33A11.68,11.68,0,0,1,26.67,20Z"
      transform="translate(0 0)"
      fill="#00aee0"
    />
  </svg>
)

export default memo(ShortIcon)
