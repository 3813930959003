import styled from "styled-components"

export const Container = styled.div`
  position: relative;
`

export const BgImage = styled.img.attrs({
  className: "image-container__image"
})`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  object-fit: cover;
  background-color: white;
  background-color: #FF2B7C;
  filter: brightness(0.6);

  &[data-failed='true'] {
    visibility: hidden;
  }
`

export const FallbackContainer = styled.div.attrs({
  className: "image-container__fallback"
})`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FF2B7C;
  filter: brightness(0.6);
`

export const ContentContainer = styled.div.attrs({
  className: "image-container__content"
})`
  position: relative;
  box-sizing: border-box;
  height: 100%;
`