import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import * as actions from "../../store/actions/sessions"
import * as actionsDecks from "../../store/actions/decks"
import * as selectors from "../../store/selectors/sessions"
import useOnMount from "../../hooks/useOnMount"
import useOnChange from "../../hooks/useOnChange"
import { routes } from "../../utils/constants"
import { DeckType } from "../../constants"
import { StatusContainer, StudySessionPageContainer } from "./styles"
import TopBar from "./TopBar"
import { handleRedirect } from "../../utils/controls/routing"
import ControlsSidebar from "./ControlsSidebar"
import useKeepValue from "../../hooks/useKeepValue"
import { CARD_CHANGE_DELAY, CARD_CHANGE_DURATION } from "./Card/constants"
import SessionComplete from "./SessionComplete"
import GradeCard from "./GradeCard"
import useChangeTransition, { CHANGED } from "../../hooks/useChangeTransition"
import ExitSessionAlert from "./ExitSessionAlert"
import { HackitPlayerReady } from './../../store/constants';
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";
import { getLanguage } from "../../utils/format"
import {
  FBAnalyticsDeckStudySessionEnded
} from './../../firebase/logEvents';
import { getDifficultyImplementation } from './../../firebase/keys';

const SPEEDS = [0.5, 0.75, 1, 1.25, 1.5];
const DEFAUT_SPEED = SPEEDS.indexOf(1);

const StudySession = ({
  type: sessionTargetType
}) => {
  const {t} = useTranslation();
  const history = useHistory()
  const { params } = useRouteMatch()
  const sessionTargetId = parseInt(params.id)
  const studyMode = params.mode
  const dispatch = useDispatch()
  const sessionDataSelector = useMemo(
    () => selectors.sessionDataSelector(sessionTargetType, sessionTargetId, studyMode),
    [sessionTargetType, sessionTargetId, studyMode]
  )
  const actualSessionData = useSelector(sessionDataSelector)

  const sessionData = useKeepValue(actualSessionData)
  const {
    loadError,
    pendingCompletion
  } = useSelector(selectors.viewSessionState)

  const {
    value: currentCard,
    nextValue: nextCard,
    changeState
  } = useChangeTransition(
    sessionData?.currentCard, {
      first: 0,
      change: CARD_CHANGE_DURATION,
      last: CARD_CHANGE_DURATION
    }, {
      first: 0,
      change: CARD_CHANGE_DELAY,
      last: CARD_CHANGE_DELAY
    })

  const [sessionCompleted, setSessionCompleted] = useState(false)
  const [showGoBackConfirmation, setShowGoBackConfirmation] = useState(false)
  const [activeSpeed, setActiveSpeed] = useState(DEFAUT_SPEED);
  const [showControls, setShowControls] = useState(false)
  const [autoplayEnabled] = useState(true)
  const [showTranslationEnabled] = useState(true)
  const sessionShownAt = useRef(Date.now()) 
  const cardShownAt = useRef(Date.now()) 
  let requestGradeCardId = null

  const paramsAnalytics = {
    deckId: sessionData?.deckId,
    deckTitle: sessionData?.name,
    virtualId: sessionTargetId,
    deckType: sessionTargetType
  }

  useOnMount(() => {
    if (!sessionData) {
      dispatch(actions.startSessionRequest({ type: sessionTargetType, id: sessionTargetId }))
    }

    let saveReady = localStorage.getItem(HackitPlayerReady);

    if(saveReady){
      saveReady = +saveReady;
      if(saveReady > 0 && saveReady !== SPEEDS[activeSpeed]) {
        const indexSaveReady = SPEEDS.indexOf(saveReady);
        setActiveSpeed(indexSaveReady)
      }
    }
  });

  useEffect(() => () => {
    dispatch(actions.viewSessionReset())
  }, [dispatch])

  useOnChange(() => {
    requestGradeCardId = null;
    dispatch(actions.gradeCardReset())
  }, [currentCard])

  useLayoutEffect(() => {
    if (sessionData && !sessionData.currentCard) {
      setSessionCompleted(true)
      dispatch(actions.completeSessionRequest({ type: sessionTargetType, id: sessionTargetId }))
    }
  }, [dispatch, sessionData, sessionTargetType, sessionTargetId])

  useOnChange(() => {
    cardShownAt.current = Date.now()
  }, [currentCard])

  useEffect(() => {
    if (sessionCompleted && !actualSessionData) {
      dispatch(actionsDecks.viewDeckCardsReset())
      putAnalytics()
      handleRedirect(
        history,
        `${routes.studySessionStats}/${sessionTargetType}/${sessionTargetId}`,
        5000 + CARD_CHANGE_DELAY,
        true
      )
    }
    // eslint-disable-next-line
  }, [sessionCompleted, actualSessionData, history, sessionTargetType, sessionTargetId, dispatch, sessionData])

  const goBack = useCallback(() => {
    const baseRoute = sessionTargetType === DeckType.merge ? routes.mergePage : routes.deckPage

    putAnalytics(true)

    history.replace(`${baseRoute}/${sessionTargetId}`)
    // eslint-disable-next-line
  }, [history, sessionTargetType, sessionTargetId, sessionData])

  const handlePlaybackRate = () => {
    const newSpeed = (activeSpeed+1)%(SPEEDS.length);
    localStorage.setItem(HackitPlayerReady, SPEEDS[newSpeed]);
    setActiveSpeed(newSpeed);
  };

  const putAnalytics = useCallback((aborted = false) => {
    const now = Date.now();
    const studiedCards = sessionData.queue.filter(item => item.card.isStudied);

    const overdueCards = studiedCards.filter(item => (item.card.due ? (item.card.due < now) : false))
    const duration = (now - sessionShownAt.current) / 1000;
    const difficulty = getDifficultyImplementation(sessionData.queue);
    const params = {
      ...paramsAnalytics,
      totalCardsCount: sessionData.queue.length,
      overdueCardsCount: overdueCards.length,
      chosenCardsCount: studiedCards.length,
      studiedCardsCount: studiedCards.length,
      duration,
      difficulty,
      aborted
    }

    FBAnalyticsDeckStudySessionEnded(params)
  },[sessionData, paramsAnalytics])

  useLayoutEffect(() => {
    if (showGoBackConfirmation && !actualSessionData) {
      goBack()
    }
  }, [history, showGoBackConfirmation, actualSessionData, goBack])

  const handleGrade = useCallback((grade) => {
    const cardId = currentCard?.id;

    if(cardId) {
      onGradeCard(grade, cardId)
    }
  // eslint-disable-next-line
  }, [dispatch, sessionTargetId, sessionTargetType, studyMode, currentCard])

  const onGradeCard = (grade, cardId) => {
    if(!requestGradeCardId || requestGradeCardId !== cardId) {
      requestGradeCardId = cardId;

      const language = getLanguage(currentCard.cardTags[0]?.tag);
      const notes = currentCard.notes;
      const imagesCount = (notes[0].images?.length || 0) + (notes[1].images?.length || 0)

      const paramsAnalytics = { cardId, grade, language,  imagesCount}

      dispatch(actions.gradeCardRequest({
        id: sessionTargetId,
        type: sessionTargetType,
        mode: studyMode,
        analytic: paramsAnalytics,
        cardId,
        grade,
        time: Date.now() - cardShownAt.current
      }))
    }
  }

  const handleGoBack = useCallback(() => {
    if (sessionCompleted) {
      goBack()
    } else {
      setShowGoBackConfirmation(true)
    }
    // eslint-disable-next-line
  }, [sessionCompleted, goBack, sessionData])

  const handleConfirmGoBack = useCallback(() => {
    const params = { type: sessionTargetType, id: sessionTargetId };
    dispatch(actions.completeSessionRequest(params))
  }, [dispatch, sessionTargetType, sessionTargetId])

  const handleDismissGoBack = useCallback(() => {
    setShowGoBackConfirmation(false)
  }, [])

  const handleOpenControls = useCallback(() => {
    setShowControls(true)
  }, [])

  const handleCloseControls = useCallback(() => {
    setShowControls(false)
  }, [])

  const renderStatus = (content) => (
    <StudySessionPageContainer>
      <StatusContainer>{content}</StatusContainer>
    </StudySessionPageContainer>
  )

  if (!sessionData && !sessionCompleted) {
    if (loadError) {
      return renderStatus(<span>Error: {loadError.message}</span>)
    }

    return renderStatus(<span>{t(Dictionary.common.loading)}...</span>)
  }

  const renderGradeCard = () => {
    const renderSingleCard = (card) => {
      return card ? (
        <>
          <GradeCard
            key={card.id}
            card={card}
            autoplay={autoplayEnabled}
            showFrontText={showTranslationEnabled}
            onGrade={handleGrade}
            playerReady={SPEEDS[activeSpeed]}
            handlePlaybackRate={handlePlaybackRate}
          />
        </>
      ) : null
    }

    if (changeState === CHANGED) {
      return renderSingleCard(currentCard)
    } else if (!currentCard) {
      return renderSingleCard(nextCard)
    } else if (!nextCard) {
      return (
        <>
          <GradeCard
            key={currentCard.id}
            card={currentCard}
            changeTransition={changeState}
            isLast
            autoplay={autoplayEnabled}
            showFrontText={showTranslationEnabled}
            onGrade={handleGrade}
            playerReady={SPEEDS[activeSpeed]}
            handlePlaybackRate={handlePlaybackRate}
          />
        </>
      )
    } else if (currentCard.id === nextCard.id) {
      return renderSingleCard(currentCard)
    }

    return (
      <>
        <GradeCard
          key={currentCard.id}
          card={currentCard}
          changeTransition={changeState}
          autoplay={autoplayEnabled}
          showFrontText={showTranslationEnabled}
          onGrade={handleGrade}
          playerReady={SPEEDS[activeSpeed]}
          handlePlaybackRate={handlePlaybackRate}
        />
        <GradeCard
          key={nextCard.id}
          card={nextCard}
          changeTransition={changeState}
          isNext
          autoplay={false}
          showFrontText={showTranslationEnabled}
          onGrade={handleGrade}
          playerReady={SPEEDS[activeSpeed]}
          handlePlaybackRate={handlePlaybackRate}
        />
      </>
    )
  }

  return (
    <StudySessionPageContainer>
      <SessionComplete show={sessionCompleted} onGoBack={handleGoBack} />
      <TopBar
        type={sessionTargetType}
        sessionData={sessionData}
        sessionCompleted={sessionCompleted}
        onGoBack={handleGoBack}
        onOpenControls={handleOpenControls}
      />
      {renderGradeCard()}
      <ExitSessionAlert
        show={showGoBackConfirmation}
        pending={pendingCompletion}
        onConfirm={handleConfirmGoBack}
        onCancel={handleDismissGoBack}
      />
      <ControlsSidebar
        show={showControls}
        onClose={handleCloseControls}
      />
    </StudySessionPageContainer>
  )
}

export default memo(StudySession)
