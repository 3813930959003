import { Log } from "../../utils/logger"
import { verbose } from "../../utils/verbose"
import { isNumber } from "../checks"

/**
 * * returnActivePageNumbersRange
 * return page numbers range according to active page number and total page numbers count
 * @function returnActivePageNumbersRange
 * @param activePageNumber defines active page number
 * @param totalPageNumbersCount defines the active total page numbers count
 */
export const returnActivePageNumbersRange = (activePageNumber, totalPageNumbersCount) => {
  if (isNumber({ activePageNumber, totalPageNumbersCount })) {
    // refactor below logic when necessary
    const rangeCount = 4

    let pageNumbersRangeInitialIndex = activePageNumber - rangeCount
    // if active page number below 4 make initial range number 1
    if (pageNumbersRangeInitialIndex <= 0) {
      pageNumbersRangeInitialIndex = 1
    }
    let pageNumbersRangeFinalIndex = pageNumbersRangeInitialIndex + rangeCount
    // if finalIndex greater than page count make final index total page number count
    if (pageNumbersRangeFinalIndex > totalPageNumbersCount) {
      pageNumbersRangeFinalIndex = totalPageNumbersCount
    }
    // if last page number and finalIndex !== totalPageNumbersCount exclude it from range
    if (
      totalPageNumbersCount === activePageNumber &&
      pageNumbersRangeFinalIndex !== totalPageNumbersCount
    ) {
      pageNumbersRangeFinalIndex--
    }
    let activePageNumbersRange = []
    for (
      let pageRangeNumber = pageNumbersRangeInitialIndex;
      pageRangeNumber <= pageNumbersRangeFinalIndex;
      pageRangeNumber++
    ) {
      activePageNumbersRange.push(pageRangeNumber)
    }
    return activePageNumbersRange
  } else if (verbose) {
    Log.error(
      `pagination.js`,
      `returnActivePageNumbersRange(
        activePageNumber: ${activePageNumber}, 
        totalPageNumbersCount: ${totalPageNumbersCount}
      )`,
      `isNumber({
        activePageNumber: ${activePageNumber}
        totalPageNumbersCount: ${totalPageNumbersCount}
      })`
    )
  }
}

/**
 * * returnPageNumbersTotalCount
 * returns total page numbers count according to elements total and per page count
 * @function returnPageNumbersTotalCount
 * @param elementsTotal defines the total amount of elements
 * @param elementsPerPage defines the amount of elements per page
 */
export const returnPageNumbersTotalCount = (elementsPerPage, elementsTotal) => {
  if (isNumber({ elementsTotal, elementsPerPage })) {
    const remainderElements = elementsTotal % elementsPerPage
    let pageNumbersTotalCount = Math.floor(elementsTotal / elementsPerPage)
    // if there are aditional elements add extra page
    if (remainderElements !== 0) {
      pageNumbersTotalCount++
    }
    return pageNumbersTotalCount
  } else if (verbose) {
    Log.error(
      `pagination.js`,
      `returnPageNumbersTotalCount(
        elementsTotal: ${elementsTotal}, 
        elementsPerPage: ${elementsPerPage}
      )`,
      `isNumber({
        elementsTotal: ${elementsTotal}
        elementsPerPage: ${elementsPerPage}
      })`
    )
  }
}
