import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export default createSlice({
  name: "referral",
  initialState,
  reducers: {
    routeReferral(state, { payload: { pathname, params } }) {
      state.pathname = pathname
      state.redirectOnCompletion = false
      state.params = params
    },
    closeReferral(state) {
      delete state.pathname
      delete state.userId
      delete state.params
      delete state.loading
      delete state.error
      delete state.redirectOnCompletion
    },
    subscribe(state, { payload: { userId } }) {
      state.userId = userId
      state.loading = true
      state.error = null
    },
    subscribeSuccess(state) {
      state.loading = false
      state.redirectOnCompletion = true
      state.error = null
    },
    subscribeFailure(state, { payload }) {
      state.loading = false
      state.redirectOnCompletion = false
      state.error = payload
    }
  }
})