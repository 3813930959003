import styled from "styled-components"
import { PageContainer, PageRoot } from "../../common/styles/page"
import InfoSticker from "./InfoSticker"
import bgImage from "./images/bg.png"
import FoxIcon from "../../assets/svgs/FoxIcon"
import { LAPTOP_MAX } from "../../common/breakpoints"
import HourglassIcon from "./images/HourglassIcon"

export const StyledPageRoot = styled(PageRoot)`
  color: white;
  background-color: #00294D;
`

export const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

export const BlocksContainer = styled.div`
  flex: 1;
  display: flex;
`

export const ModesSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 71px 32px 84px; 

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: 22px 50px 22px 59px;
  }
`

export const InfoSide = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 416px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 40px 24px 40px 0;
  background-image: url('${bgImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 320px;
  }
`

export const StyledHourglassIcon = styled(HourglassIcon)`
  font-size: 594px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 416px;
  }
`

export const ModesBlock = styled.div`
  box-sizing: border-box;
  background-color: #022C53;
  border: 1px solid #D6DAEB48;
  border-radius: 20px;
  padding: 82px 80px 82px 80px;
  min-width: 730px;
  width: 761px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 57px 56px 57px 56px;
    min-width: 511px;
    width: 532px;
  }
`

export const Title = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-weight: 800;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: 0px;
  margin-bottom: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 39px;
    line-height: 43px;
    margin-bottom: 11px;
  }
`

export const SubTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0px;
  margin-bottom: 4px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 3px;
  }
`

export const SecondSubTitle = styled(SubTitle)`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 60px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 42px;
  }
`

export const StyledFoxIcon = styled(FoxIcon)`
  font-size: 20px;
  transform: matrix(0.98, -0.19, 0.19, 0.98, 0, 0);
  margin-right: 7px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    margin-right: 5px;
  }
`

export const InfoLink = styled.a`
  text-decoration: underline;
`

export const StatusContainer = styled.div`
  flex: 1 1 599px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${LAPTOP_MAX}px) {
    flex: 1 1 419px;
  }
`

export const IdealSticker = styled(InfoSticker)`
  top: 40%;
  left: 10%;
  background: linear-gradient(180deg, #4EEBFF 0%, #00D8FF 100%);

  &[data-show='true'] {
    top: 10%;
    left: 12%;
  }

  .info-sticker_non-available {
    background: linear-gradient(105deg, #4EEBFF 0%, #00D8FF 100%);
  }
`

export const NormalSticker = styled(InfoSticker)`
  right: 2%;
  bottom: 35%;
  background: linear-gradient(159deg, #FF7B62 0%, #FF4B08 100%);

  &[data-show='true'] {
    right: 10%;
    bottom: 36%;
  }

  .info-sticker_non-available {
    background: linear-gradient(117deg, #FF7B62 0%, #FF4B08 100%);
  }
`

export const MediumSticker = styled(InfoSticker)`
  left: 10%;
  bottom: 4%;
  background: linear-gradient(144deg, #FF9F58 0%, #FF7F00 100%);

  &[data-show='true'] {
    bottom: 24%;
  }

  .info-sticker_non-available {
    background: linear-gradient(105deg, #FF9F58 0%, #FF7F00 100%);
  }
`

export const ShortSticker = styled(InfoSticker)`
  right: 60%;
  bottom: 2%;
  background: linear-gradient(324deg, #FFC502 0%, #FFDE52 100%);

  &[data-show='true'] {
    right: 12%;
    bottom: 10%;
  }

  .info-sticker_non-available {
    background: linear-gradient(105deg, #FFC502 0%, #FFDE52 100%);
  }
`
