import React from "react"
import { Container, LogoWithLabel } from "./styles"

const FormStatus = ({
  className,
  showLogo = true
}) => (
  <Container className={className} data-show-logo={showLogo}>
    {showLogo && <LogoWithLabel />}
  </Container>
)

export default FormStatus
