import React, { memo } from "react"
import { TopBarContainer } from "../../../common/styles/topBar"
import { useTranslation } from 'react-i18next';
import { Dictionary } from "./../../../utils/i18n";
import { Logo, WelcomeContainer, WelcomeTexts, 
  // FluencyTVContainer, FluencyTVTexts 
} from "./styles"
// import FluencyTVIcon from './../../../assets/svgs/FluencyTVIcon';
// import { links } from "../../../constants"

const TopBar = ({
  firstName,
  lastName,
  navMenuExpanded
}) => {

  const { t } = useTranslation();

  // const handleAccessFluencyTV = () => {
  //   window.open(links.fluencyTVAcademy, "_blank")
  // }

  return (
    <TopBarContainer data-nav-expanded={navMenuExpanded}>
      <WelcomeContainer navMenuExpanded={navMenuExpanded}>
        <Logo />
        <WelcomeTexts>
          <span>{t(Dictionary.dashboard.header)}</span>&nbsp;
          <strong>{firstName} {lastName}!</strong>
        </WelcomeTexts>
      </WelcomeContainer>
      {/* <FluencyTVContainer onClick={handleAccessFluencyTV}>
        <FluencyTVTexts>
          <span>Ainda não é nosso aluno?</span>
          <b>Faça parte da Fluency Academy aqui!</b>
        </FluencyTVTexts>
        <FluencyTVIcon />
      </FluencyTVContainer> */}
    </TopBarContainer>
  )
}

export default memo(TopBar)
