import React from "react"
import { useField } from "formik"
import Checkbox from "../../../../common/components/Checkbox"

const CheckboxField = ({ name, ...checkboxProps }) => {
  const [field] = useField({ name, type: "checkbox" })

  return <Checkbox {...field} {...checkboxProps} name={name} />
}

export default CheckboxField
