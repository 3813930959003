import React, { memo, useEffect, useRef, useState } from "react"
import CloseIcon from "../../../../assets/svgs/CloseIcon"
import useOnChange from "../../../../hooks/useOnChange"
import useTransitionState, { ENTERED, ENTERING, EXITED } from "../../../../hooks/useTransitionState"
import { Dictionary } from "../../../../utils/i18n"
import { useTranslation } from "react-i18next"
import { CARD_CHANGE_DELAY, CARD_CHANGE_DURATION } from "../../Card/constants"
import {
  Container,
  Content,
  HackitLogo,
  Title,
  TopRow,
  BackgroundImage,
  CloseButton,
  ProgressContainer,
  ProgressTitle,
  ProgressBar,
  ProgressFill
} from "./styles"

const titles = [
  /* eslint-disable */
  <span>Você<br />Terminou!</span>,
  <span>You<br />Rock</span>,
  <span>Quase<br />Lá</span>
  /* eslint-enable */
]

const SessionCompleteMobile = ({ show, onGoBack }) => {
  const intervalId = useRef(0)
  const {t} = useTranslation();
  const [progress, setProgress] = useState(0)
  const transition = useTransitionState(show, CARD_CHANGE_DURATION, CARD_CHANGE_DELAY)

  useEffect(() => () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
  }, [])

  useOnChange(() => {
    if (transition === ENTERED && intervalId.current === 0) {
      intervalId.current = setInterval(() => {
        setProgress(prev => Math.min(prev + 0.16, 1))
      }, 650)
    }
  }, [transition])

  if (transition === EXITED) {
    return null
  }

  return (
    <Container data-show={transition === ENTERING || transition === ENTERED}>
      <BackgroundImage />
      <Content>
        <TopRow>
          <HackitLogo />
          <CloseButton onClick={onGoBack}><CloseIcon /></CloseButton>
        </TopRow>
        <Title>{titles[Math.min(Math.floor(progress * titles.length), titles.length - 1)]}</Title>
      </Content>
      <ProgressContainer>
        <ProgressTitle>{t(Dictionary.finishedStudySession.mobileProgressTitle)}Carregando resultados...</ProgressTitle>
        <ProgressBar><ProgressFill style={{ width: `${progress * 100}%` }} /></ProgressBar>
      </ProgressContainer>
    </Container>
  )
}

export default memo(SessionCompleteMobile)