import styled from "styled-components"
import OopsPlaceholder from "../../../assets/svgs/OopsPlaceholder"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX, LAPTOP_SMALL_MAX } from "../../../common/breakpoints"
import Flag from "../../../common/components/Flag"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"
import OverdueCardsIcon from "./images/OverdueCardsIcon"
import PlayIcon from "./images/PlayIcon"
import TotalCardsIcon from "./images/TotalCardsIcon"
import Button from "../../../common/components/Button"

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 476px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 396px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 342px;
  }
`

export const LeftSide = styled(ImageContainer)`
  flex: 666;

  .image-container__image {
    filter: brightness(0.3);
  }

  .image-container__fallback {
    filter: brightness(0.3);
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    flex: 1;
  }
`

export const LeftSideContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 87px 42px 0 42px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 62px 42px 0 42px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 61px 29px 0 29px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    position: static;
    padding: 61px 141px 0 36px;
  }
`

export const OopsImage = styled(OopsPlaceholder)`
  font-size: 76px;
  color: white;
  opacity: 0.18;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 66px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 53px;
  }
`

export const FlagContainer = styled.div`
  margin-bottom: 21px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin-bottom: 19px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 15px;
  }
`

export const StyledFlag = styled(Flag)`
  width: 38px;
  height: 38px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    width: 36px;
    height: 36px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 31px;
    height: 31px;
  }
`

export const Title = styled.div`
  font-family: BlackerDisplayExtraBold;
  font-size: 60px;
  line-height: 64px;
  margin-bottom: 22px;
  color: white;
  word-break: break-word;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 29px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 42px;
    line-height: 44px;
    margin-bottom: 15px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 28px;
  }
`

export const Description = styled.div`
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #BFBFBF;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 17px;
    line-height: 21px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 17px;
  }
`

export const RightSide = styled.div`
  position: relative;
  flex: 714;
  background: linear-gradient(33deg, #008EE0 0%, #0051B1 100%);

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    flex: 0 0 300px;
  }
`

export const RightSideContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 88px 66px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 60px 36px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 60px 46px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    left: auto;
    padding: 60px 26px;
  }
`

export const StatsContainer = styled.div`
  display: flex;
  background-color: #0081D8;
  border: 1px solid #FFFFFF65;
  border-radius: 25px;
  overflow: hidden;
  height: 168px;
  margin-bottom: 52px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 162px;
    margin-bottom: 31px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 118px;
    margin-bottom: 36px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    width: 381px;
  }
`

export const StatsValueContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 36px;
  padding-right: 8px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding-left: 35px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 22px;
  }

  :not(:last-child) {
    border-right: 1px solid #FFFFFF65;
  }
`

export const StyledTotalCardsIcon = styled(TotalCardsIcon)`
  font-size: 54px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 52px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 41px;
  }
`

export const StyledOverdueCardsIcon = styled(OverdueCardsIcon)`
  font-size: 54px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 52px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 41px;
  }
`

export const ValueContainer = styled.div`
  margin-left: 20px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin-left: 19px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-left: 13px;
  }
`

export const ValueTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.7;
  margin-bottom: 1px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 1;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 0;
  }
`

export const Value = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 34px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 27px;
  }
`

export const ContinueButton = styled(Button)`
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.36px;
  margin: 0 42px;
  padding: 0 30px 0 40px;
  height: 80px;
  border-radius: 40px;
  width: auto;
  pointer-events: ${(props) => props.disabled ? 'none' : ''};
  text-transform: uppercase;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 17px;
    margin: 0 26px;
    padding: 0 36px 0 40px;
    height: 76px;
    border-radius: 38px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 17px;
    margin: 0 29px;
    padding: 0 34px 0 30px;
    height: 60px;
    border-radius: 30px;
  }

  &[data-disabled='true'] svg {
    color: rgba(255,255,255,0.5);
  }
`

export const StyledPlayIcon = styled(PlayIcon)`
  color: white;
  font-size: 18px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 17px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
  }
`
