import styled, { css } from "styled-components"
import FlipIcon from "../../../assets/svgs/FlipIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import AudioPlayer from "../../../common/components/AudioPlayer"
import { CARD_FLIP_DURATION } from "./constants"

export const CardContainer = styled.div`
  perspective: 1000px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_MAX}px) {
    transition: margin-bottom ${CARD_FLIP_DURATION}ms;
  
    &[data-flipped='true'] {
      margin-bottom: 70px;
    }
  }
`

export const FlipContainer = styled.div`
  position: relative;
  flex: 1;
  transform-style: preserve-3d;
  border: 1px solid #CDE0E2;
  border-radius: 15px;
  box-shadow: 0px 10px 40px 0px rgb(219, 237, 247);
  transition: transform ${CARD_FLIP_DURATION}ms;

  ${CardContainer}[data-flipped='true'] & {
    transform: rotateY(180deg);
  }

  @media (max-width: ${MOBILE_MAX}px) {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.251);
    border: none;
  }
`

export const SideContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  backface-visibility: hidden;
  background-color: rgb(252, 252, 252);
  z-index: 1;

  &[data-back-side='true'] {
    transform: rotateY(180deg);
  }

  ${CardContainer}[data-flipped='false'] &[data-back-side='true'],  
  ${CardContainer}[data-flipped='true'] &[data-back-side='false'] {
    pointer-events: none;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    transition: padding-bottom ${CARD_FLIP_DURATION}ms;

    ${CardContainer}[data-flipped='false'] &[data-back-side='true'] {
      padding-bottom: 70px;
    }
  }
`

const BackgroundImage = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: #F8F8F8;
`

export const SideImageContainer = styled.div`
  flex: 608 1 0;
  ${BackgroundImage}
  border-right: 1px solid #CDE0E2;
`

export const SideMainContentContainer = styled.div`
  flex: 626 1 0;
  position: relative;
`

export const SideMainContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 0 32px 0;

  @media (max-width: ${MOBILE_MAX}px) {
    padding: 16px 0 18px 0;
  }
`

const SideHorizontalPaddings = css`
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: ${MOBILE_MAX}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 22px;
  margin-bottom: 16px;
  ${SideHorizontalPaddings}

  @media (max-width: ${MOBILE_MAX}px) {
    margin-bottom: 6px;
    flex-direction: row-reverse;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
  ${SideHorizontalPaddings}
`

export const TagContainer = styled.div`
  margin-bottom: 12px;
  padding: 5px 10px;
  max-width: 200px;
  border: 1px solid #6B47ED;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #5B5B5B;
  background-color: rgba(107, 71, 237, 0.1);
  white-space: nowrap;
  overflow: hidden;
  border-radius: 6px;
  cursor: default;

  :not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 7px;

    :not(:last-child) {
      margin-right: 7px;
    }
  }
`

export const TagLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FlipButton = styled.div`
  display: flex;
  align-items: center;
  color: #F24556;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  :active {
    opacity: 0.7;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 12px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
    line-height: 14px;
    color: #8E8E8E;
    flex-direction: row-reverse;
  }
`

export const StyledFlipIcon = styled(FlipIcon)`
  font-size: 20px;
  margin-right: 10px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 18px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    color: #00AEE0;
    font-size: 16px;
    margin-right: 0;
    margin-left: 8px;
  }
`

export const HeaderRight = styled.div`
  font-size: 18px;
  color: #9E9E9E;

  strong {
    color: black;
    font-weight: normal;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
    color: #9E9E9E;

    strong {
      color: #FF4A5C;
    }
  }
`

export const SideScroll = styled.div`
  flex: 1;
  // overflow-y: auto;
  overflow: hidden;
`

export const SideScrollContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const TextsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.hasAudio ? 'flex-end' : 'center'};

  @media (max-width: ${MOBILE_MAX}px) {
    ${SideContainer}[data-back-side='false'] &[data-is-asian='true'] > *:last-child {
      justify-content: center;
    }
  }

  padding-bottom: 8px;
`

export const MobileImage = styled.div`
  flex: 1;
  margin: 0 0 24px 0;
  min-height: 90px;
  ${BackgroundImage}
`

export const SideAHeader = styled.div`
  font-family: "IBM Plex Serif";
  font-size: 20px;
  font-weight: 600;
  color: #F24556;
  line-height: 1.2;
  ${SideHorizontalPaddings}

  &[data-hidden-text='true'] {
    word-break: break-all;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    &[data-has-image='false'][data-is-asian='true'] {
      text-align: center;
    }
  }
`

export const SideAParagraph = styled.div`
  font-size: 16px;
  color: #FF4A5C;
  line-height: 1.2;
  margin-top: 7px;
  opacity: 0.5;
  font-family: "IBM Plex Serif";
  ${SideHorizontalPaddings}

  &[data-hidden-text='true'] {
    word-break: break-all;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    &[data-has-image='false'][data-is-asian='true'] {
      text-align: center;
    }
  }
`

export const SideBSideATexts = styled.div`
  margin-bottom: 12px;

  > :not(:last-child) {
    margin-bottom: 5px;
  }
`

export const SideBSideAHeader = styled.div`
  font-family: "IBM Plex Serif";
  font-size: 26px;
  color: #9E9E9E;
  line-height: 1.2;
  ${SideHorizontalPaddings}

  @media (max-width: ${MOBILE_MAX}px) {
    color: #8E8E8E;
    font-weight: bold;
    opacity: 0.5;
  }
`

export const SideBSideAParagraph = styled.div`
  font-family: "IBM Plex Serif";  
  font-size: 24px;
  color: #A2A1A1;
  font-weight: 500;
  line-height: 1.2;
  ${SideHorizontalPaddings}
`

export const SideBParagraph = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: bold;
  font-size: 36px;
  color: black;
  line-height: 1.2;
  ${SideHorizontalPaddings}
`

export const SideBTranscription = styled(SideBSideAParagraph)`
  padding-top: 10px;
`

export const StyledAudioPlayer = styled(AudioPlayer)`
  margin-top: 40px;
  ${SideHorizontalPaddings}

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 28px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 14px;
  }

  .audio-player__duration {
    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 13px;
      margin-bottom: -5px;
    }

    @media (max-width: ${MOBILE_MAX}px) {
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 14px;
      height: 14px;
      opacity: 0.4;
    }
  }

  .audio-player__play-button {
    @media (max-width: ${MOBILE_MAX}px) {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  }
`