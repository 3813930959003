import React, { memo } from "react"

const UncheckedBoxIcon = (props) => (
  <svg height="1em" viewBox="0 0 16 16" {...props}>
    <path
      d="M18.722,6.278V18.722H6.278V6.278H18.722m0-1.778H6.278A1.783,1.783,0,0,0,4.5,6.278V18.722A1.783,1.783,0,0,0,6.278,20.5H18.722A1.783,1.783,0,0,0,20.5,18.722V6.278A1.783,1.783,0,0,0,18.722,4.5Z"
      transform="translate(-4.5 -4.5)"
      fill="currentColor"
    />
  </svg>
)

export default memo(UncheckedBoxIcon)
