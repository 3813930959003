import React, { memo } from "react"

const TotalCardsIcon = (props) => (
  <svg height="1em" viewBox="0 0 16.5 20.166" {...props}>
    <path fill="currentColor" d="M23.611,0H13.222A1.222,1.222,0,0,0,12,1.222V15.277A1.222,1.222,0,0,0,13.222,16.5H23.611a1.222,1.222,0,0,0,1.222-1.222V1.222A1.222,1.222,0,0,0,23.611,0Z" transform="translate(-8.333)" />
    <path fill="currentColor" d="M1.833,25.444V12.611A.613.613,0,0,0,1.222,12H.611A.613.613,0,0,0,0,12.611V25.75A2.75,2.75,0,0,0,2.75,28.5h9.166a.613.613,0,0,0,.611-.611v-.611a.613.613,0,0,0-.611-.611H3.055A1.222,1.222,0,0,1,1.833,25.444Z" transform="translate(0 -8.333)" />
  </svg>
)

export default memo(TotalCardsIcon)
