import React, { memo, useCallback, useEffect } from "react"
import { ENTERED, ENTERING } from "../../../hooks/useTransitionState"
import SideA from "./SideA"
import SideB from "./SideB"
import { CardContainer, FlipContainer } from "./styles"
import { CHINESE_TAGS } from './../../../utils/helpers/FlagAndLanguage';

const Card = ({
  className,
  card,
  flipTransition,
  showFrontText,
  autoplay = false,
  onFlip,
  onFlipButtonHover,
  playerReady,
  handlePlaybackRate
}) => {
  const flipped = flipTransition === ENTERING || flipTransition === ENTERED
  const isMandarim = CHINESE_TAGS.includes(card?.cardTags?.[0]?.tag);

  const handleFlip = useCallback(() => {
    if (onFlip) {
      onFlip()
    }
  }, [onFlip])

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.code === "Space") {
        event.preventDefault()
        handleFlip()
      }
    }

    window.addEventListener("keyup", handleKeyUp)
    return () => { window.removeEventListener("keyup", handleKeyUp) }
  }, [handleFlip])

  const handleFlipClick = useCallback((event) => {
    event.preventDefault()
    handleFlip()
  }, [handleFlip]);

  return (
    <CardContainer
      className={className}
      data-flipped={flipped}
      data-flip-transition={flipTransition}
    >
      <FlipContainer data-flipped={flipped}>
        <SideA
          cardId={card.id}
          visible={!flipped}
          autoplay={autoplay}
          showText={showFrontText}
          note={card.notes[0]}
          onFlipButtonHover={onFlipButtonHover}
          onFlip={handleFlipClick}
          playerReady={playerReady}
          handlePlaybackRate={handlePlaybackRate}
        />
        <SideB
          isMandarim={isMandarim}
          visible={flipped}
          autoplay={autoplay}
          noteA={card.notes[0]}
          note={card.notes[1]}
          onFlipButtonHover={onFlipButtonHover}
          onFlip={handleFlipClick}
          playerReady={playerReady}
          handlePlaybackRate={handlePlaybackRate}
        />
      </FlipContainer>
    </CardContainer>
  )
}

export default memo(Card)