import React, { memo, useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import CloseIcon from "../../assets/svgs/CloseIcon"
import { StudyMode } from "../../store/selectors/common"
import useOnMount from "../../hooks/useOnMount"
import { routes } from "../../utils/constants"
import * as actions from "../../store/actions/sessions"
import * as selectors from "../../store/selectors/sessions"
import {
  CloseButton,
  GlobalStyle,
  HackitLogo,
  TopRow,
  BackgroundImage,
  TitleRow,
  StyledHourglass,
  Title,
  ButtonsContainer,
  DeckTitle,
  IdealButton,
  NormalButton,
  MediumButton,
  ShortButton,
  StatusContainer
} from "./styles"
import { DeckType } from "../../constants"
import { useTranslation } from 'react-i18next';
import { Dictionary } from "./../../utils/i18n";

const DeckModePageMobile = ({ sessionTargetType }) => {
  const history = useHistory()
  const { params } = useRouteMatch()
  const sessionTargetId = parseInt(params.id)
  const { t } = useTranslation();

  const dispatch = useDispatch()
  const sessionModeSelector = useMemo(
    () => selectors.sessionModeSelector(sessionTargetType, sessionTargetId),
    [sessionTargetType, sessionTargetId]
  )
  const modeData = useSelector(sessionModeSelector)
  const { loadError } = useSelector(selectors.viewSessionState)

  const [expandedButton, setExpandedButton] = useState(null)

  useOnMount(() => {
    if (!modeData) {
      dispatch(actions.viewSessionReset())
      dispatch(actions.startSessionRequest({ type: sessionTargetType, id: sessionTargetId }))
    }
  })

  const handleClose = useCallback(() => {
    if (history.length > 0) {
      history.goBack()
    } else {
      const baseRoute = sessionTargetType === DeckType.merge ? routes.mergePage : routes.deckPage

      history.replace(`${baseRoute}/${sessionTargetId}`)
    }
  }, [history, sessionTargetType, sessionTargetId])

  const handleExpandButton = useCallback((mode) => {
    setExpandedButton(mode)
  }, [])

  const handleContinueStudy = useCallback((mode) => {
    history.replace(`${routes.studySessionBase}/${sessionTargetType}/${sessionTargetId}/${mode}`)
  }, [history, sessionTargetType, sessionTargetId])

  const renderContent = () => {
    if (modeData) {
      return (
        <>
          <ButtonsContainer>
            <IdealButton
              mode={StudyMode.Ideal}
              expanded={expandedButton === StudyMode.Ideal}
              disabled={!modeData.availableModes.has(StudyMode.Ideal)}
              title={t(Dictionary.studyModeDeck.ideal.title)}
              description={t(Dictionary.studyModeDeck.ideal.subtitle)}
              notice={t(Dictionary.studyModeDeck.ideal.description)}
              onExpand={handleExpandButton}
              onContinue={handleContinueStudy}
            />
            <NormalButton
              mode={StudyMode.Fair}
              expanded={expandedButton === StudyMode.Fair}
              disabled={!modeData.availableModes.has(StudyMode.Fair)}
              title={t(Dictionary.studyModeDeck.fair.title)}
              description={t(Dictionary.studyModeDeck.fair.subtitle)}
              notice={t(Dictionary.studyModeDeck.fair.description)}
              onExpand={handleExpandButton}
              onContinue={handleContinueStudy}
            />
            <MediumButton
              mode={StudyMode.Medium}
              expanded={expandedButton === StudyMode.Medium}
              disabled={!modeData.availableModes.has(StudyMode.Medium)}
              title={t(Dictionary.studyModeDeck.medium.title)}
              description={t(Dictionary.studyModeDeck.medium.subtitle)}
              notice={t(Dictionary.studyModeDeck.medium.description)}onExpand={handleExpandButton}
              onContinue={handleContinueStudy}
            />
            <ShortButton
              mode={StudyMode.Short}
              expanded={expandedButton === StudyMode.Short}
              disabled={!modeData.availableModes.has(StudyMode.Short)}
              title={t(Dictionary.studyModeDeck.short.title)}
              description={t(Dictionary.studyModeDeck.short.subtitle)}
              notice={t(Dictionary.studyModeDeck.short.description)}
              onExpand={handleExpandButton}
              onContinue={handleContinueStudy}
            />
          </ButtonsContainer>
          <DeckTitle>{modeData.name}</DeckTitle>
        </>
      )
    }

    if (loadError) {
      return (
        <StatusContainer>
          Error: {loadError.message}
        </StatusContainer>
      )
    }

    return (
      <StatusContainer>
        Loading...
      </StatusContainer>
    )
  }

  return (
    <>
      <GlobalStyle />
      <BackgroundImage />
      <TopRow>
        <HackitLogo />
        <CloseButton onClick={handleClose}><CloseIcon /></CloseButton>
      </TopRow>
      <TitleRow>
        <StyledHourglass />
        <Title>{t(Dictionary.studyModeDeck.mobileTitle)}</Title>
      </TitleRow>
      {renderContent()}
    </>
  )
}

export default memo(DeckModePageMobile)
