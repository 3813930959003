import styled from "styled-components"
import LoginLogo from "../../../assets/svgs/LoginLogo"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import EntrancePage from "../Common/EntrancePage"
import FormStatus from "../Common/FormStatus"
import { SubmitButton } from "../Common/styles"

export const StyledEntrancePage = styled(EntrancePage)`
  .entrance-page__cover-image {
    margin: -30px -150px -90px -20px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin: -10px -96px -52px -20px;
    }
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Logo = styled(LoginLogo)`
  font-size: 60px;  
  margin-bottom: 16px;
  color: #F24556;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  &[data-succeeded='true'] {
    color: #1BD46F;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 41px; 
    margin-bottom: 11px;
    margin-top: 8px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 37px;
    margin-top: 44px;
    margin-bottom: 14px;
    color: white;
  }
`

export const Notice = styled.div`
  border: 1px solid #E9E9E9;
  border-radius: 8px;
  padding: 15px 17px;
  font-size: 15px;
  line-height: 20px;
  opacity: 0.79;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 10px 11px;
    font-size: 11px;
    line-height: 13px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 12px;
    padding: 12px 15px;
    color: white;
    border: 1px solid rgba(255, 190, 208, 0.37);
  }
`

export const NoticeSpacer = styled.div`
  margin-top: 50px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 34px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 38px;
  }
`

export const StyledFormStatus = styled(FormStatus)`
  &[data-has-status='true'] {
    margin-bottom: 16px;
  }
`

export const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 52px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 36px;
  }
`

export const RegisterContainer = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  color: #959595;
  margin-top: 40px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    margin-top: 28px;
  }

  > a {
    color: #00AEE0;
  }
`

export const MobileRegisterLink = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 15px;
  color: white;
  opacity: 0.72;
`
