import React, { memo, useCallback, useEffect } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import EntrancePage from "../Common/EntrancePage"
import { LoginFormContainer, StyledFormInnerContainer } from "./styles"
import { ContentContainer, PageContainer } from "../Common/styles"
import LoginForm from "./LoginForm"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import useOnChange from "../../../hooks/useOnChange"
import { handleRedirect } from "../../../utils/controls/routing"
import { routes } from "../../../utils/constants"
import MobileAppsBar from "../Common/MobileAppsBar"
import { MOBILE_MAX } from "../../../common/breakpoints"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"
import useOnMount from "../../../hooks/useOnMount";
// import JoinContainer from './JoinContainer';

const initialValues = {
  email: "",
  password: ""
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(4)
})

const LoginPage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    succeeded, error: loginError, pending: pendingLogin
  } = useSelector(authSelectors.loginState)

  useOnMount(() => {
    if (location.state?.logout) {
      dispatch(authActions.userLogout())
    }
  })

  useEffect(() => () => {
    dispatch(authActions.userLoginReset())
  }, [dispatch])

  useOnChange(() => {
    if (succeeded) {
      handleRedirect(history, routes.termsAndConditions, 0)
    }
  }, [succeeded])

  const handleSubmit = useCallback((values) => {
    dispatch(authActions.userLoginRequest({
      email: values.email,
      password: values.password
    }))
  }, [dispatch])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <PageContainer>
        <ContentContainer>
          {isMobile && <MobileAppsBar />}
          <LoginFormContainer>
            <StyledFormInnerContainer>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                <LoginForm pendingLogin={pendingLogin} error={loginError} />
              </Formik>
            </StyledFormInnerContainer>
          </LoginFormContainer>
          {/* {!isMobile && <JoinContainer/>} */}
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(LoginPage)
