import { createSelector } from "reselect"
import { makeSessionId } from "../reducers/sessions"
import { minutesPerMode, secondsPerCard, sessionCardsCount } from "./common"
import { sortBy } from "lodash"


const extractNoteData = (note) => {
  const noteHtml = note.field
  const foundSrc = noteHtml.match(/<source.+src="(.+)"/)

  return {
    text: noteHtml.replaceAll(/<[^>]*>/g, "").trim(),
    soundUrl: foundSrc && foundSrc.length > 1 ? foundSrc[1] : null
  }
}

export const viewSessionState = (state) => state.sessions.viewSession

export const gradeStateSelector = (cardId) => createSelector(
  viewSessionState,
  ({
    currentGradeCardId,
    currentGrade,
    gradeStatus,
    gradeError,
    nextDue
  }) => {
    if (currentGradeCardId === cardId) {
      return {
        currentGrade,
        gradeStatus,
        gradeError,
        nextDue
      }
    }

    return {
      currentGrade: null,
      gradeStatus: null,
      gradeError: null,
      nextDue: null
    }
  }
)

export const sessionDataSelector = (type, id, mode) => createSelector(
  (state) => state.sessions.sessionsById[makeSessionId(type, id)],
  (session) => {
    if (!session) {
      return null
    }

    const cardsCount = sessionCardsCount(mode, session.queue.length)

    const result = {
      deckId: session.deckId,
      currentCard: null,
      name: session.name,
      currentCardIndex: session.currentCardIndex,
      cardsCount,
      queue: session.queue,
      repeatQueue: session.repeatQueue
    }

    if (session.currentCardIndex >= cardsCount) {
      return result
    }

    const item = session.queue[session.currentCardIndex];
    const sortNotes = sortBy(item.notes, item => item.fieldNumber );
    let notes = sortNotes.map((note, index) => {
      if (note.version === 1) {
        if (index === 0) {
          const { text: foreignText, soundUrl } = extractNoteData(note)

          return {
              headers: [
                {
                  content: foreignText.trim ? foreignText.trim() : foreignText,
                  order: 0
                }
              ],
              audios: soundUrl ? [
                {
                  url: soundUrl,
                  order: 0
                }
              ] : null
          }
        }

        const { text: nativeText, soundUrl } = extractNoteData(note)

        return {
          paragraphs: [
            {
              content: nativeText.trim ? nativeText.trim() : nativeText,
              order: 0
            }
          ],
          audios: soundUrl ? [
            {
              url: soundUrl,
              order: 0
            }
          ] : null
        }
      }

      return {
        ...note,
        headers: note.headers.map(header => {
          const {content} = header;
          return {
            ...header,
            content: content.trim ? content.trim() : content,
          }
        })
      }
    });

    result.currentCard = {
      id: item.card.id,
      cardTags: session.tags,
      notes
    }

    return result
  }
)

export const sessionModeSelector = (type, id) => createSelector(
  (state) => state.sessions.sessionsById[makeSessionId(type, id)],
  (session) => {
    if (!session) {
      return null
    }

    const totalMinutes = (session.queue.length * secondsPerCard) / 60
    const availableModes = new Set(
      Object.entries(minutesPerMode)
        .filter(([, minutes]) => totalMinutes >= minutes)
        .map(([mode]) => mode)
      )

    return {
      availableModes,
      name: session.name,
      totalCardsCount: session.queue.length,
      deckId: session.queue[0].card.deckId
     }
  }
)

export const sessionName = (state, type, id) => state.sessions.deckNameById[makeSessionId(type, id)] ?? ""
