import styled from "styled-components"

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 0.33s ease;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const ToastContainer = styled.div`
  margin: 20px 20px;
  border-radius: 10px;
  padding: 20px 16px;
  background-color: #F24556;
  max-height: 140px;
  color: white;

  &[data-type='success'] {
    background-color: #1BD46F;
  }
`

export const Title = styled.div`
  font-family: BlackerDisplayHeavy;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 9px;
`

export const Message = styled.div`
  letter-spacing: 0px;
  opacity: 0.65;
`