import styled from "styled-components"
import PlayIcon from "../../../assets/svgs/PlayIcon"
import PauseIcon from "../../../assets/svgs/PauseIcon"
import { MOBILE_MAX } from "../../../common/breakpoints";

export const Duration = styled.div.attrs({
  className: "audio-player__duration"
})`
  font-size: 14px;
  line-height: 19px;
  height: 19px;
  color: black;
  opacity: 0.61;
  margin-bottom: 3px;
  @media (min-width: ${MOBILE_MAX}px) {
    padding-bottom: 10px;
  }
`

export const WaveformRow = styled.div.attrs({
  className: "audio-player__waveform-row"
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WaveformPlayerRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const Waveform = styled.div.attrs({
  className: "audio-player__waveform"
})`
  flex: 1;
  max-width: 400px;
  height: 50px;
  margin-right: 24px;
  cursor: pointer;
  ${props => props.isError ? 'pointer-events: none;' : ''}
  & > wave {
    overflow: hidden !important;
  }
`

export const PlayButton = styled.div.attrs({
  className: "audio-player__play-button"
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 24px;
  background-color: ${props => props.isError ? '#d3d3d3' : '#00AEE0'};
  border-radius: 50%;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
  ${props => props.isError ? 'pointer-events: none;' : ''}

  :active {
    opacity: 0.7;
  }

  :hover {
    background-color: #03C6FF;
  }
`

export const RateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  cursor:pointer;
  opacity: ${ props => props.show ? 1 : 0};

  width: 44px;
  height: 32px;
  color: rgba(0, 174, 224, 1);
  font-size 16px;
  line-height: 19px;
  border-radius: 22px;
  overflow: hidden;
  transition: opacity 100ms ease-out;
  background: rgba(0, 174, 224, 0.1);
  border: 1px solid rgba(0, 174, 224, 0.15);
  user-select: none;

  @media (max-width: ${MOBILE_MAX}px) {
    height: 22px;
    width: 35px;
    border-radius: 11px;
    font-size: 12px;
    line-height: 14px;
  }
`

export const StyledPlayIcon = styled(PlayIcon).attrs({
  className: "audio-player__play-icon"
})`
  position: relative;
  left: 2px;
  color: white;
`

export const StyledPauseIcon = styled(PauseIcon).attrs({
  className: "audio-player__pause-icon"
})`
  position: relative;
  color: white;
`