// App initially developed in 1366
// All breakpoints screen size - 20 to address inconsistent size
export const breakpoint = {
  mobile: `320px`,
  tablet: `720px`,
  small: `1024px`,
  medium: `1280px`,
  wide: `1440px`,
  big: `1920px`
}
