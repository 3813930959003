import styled from "styled-components"
import BackArrowIcon from "../../../assets/svgs/BackArrowIcon"
import { LAPTOP_MAX } from "../../breakpoints"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 100%;
  border-right: 1px solid #E6E6E6;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 104px;
  }
`

export const BackButton = styled.button.attrs({
  className: "top-back-button_back-button"
})`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #F24556;
  padding-right: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    padding-right: 11px;
  }
`

export const StyledBackIcon = styled(BackArrowIcon)`
  margin-right: 8px;
`
