import decksSlice from "../reducers/decks"

export const {
  setDashboardDeckType,
  setDashboardDeckIndex,
  loadMasterDecksRequest,
  loadMasterDecksSuccess,
  loadMasterDecksFailure,
  masterDecksReset,

  loadUserDecksRequest,
  loadUserDecksSuccess,
  loadUserDecksFailure,
  userDecksReset,

  setUserDecksPage,
  loadDeckWithCardsRequest,
  loadDeckWithCardsSuccess,
  loadDeckWithCardsFailure,

  loadDeckReferralWithCardsRequest,
  loadDeckReferralWithCardsSuccess,
  loadDeckReferralWithCardsFailure,

  loadDeckPageRequest,
  loadDeckPageSuccess,
  loadDeckPageFailure,
  loadMergeWithCardsRequest,
  loadMergeWithCardsSuccess,
  loadMergeWithCardsFailure,
  loadMergePageRequest,
  loadMergePageSuccess,
  loadMergePageFailure,
  viewDeckCardsReset
} = decksSlice.actions