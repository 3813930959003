import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import useSize from "@react-hook/size"
import {
  Container,
  DimensionsContainer,
  ItemBox,
  CardBox,
  CardBoxHeight,
  Content,
  ScrollContainer,
  StatusContainer,
  StatusInnerContainer,
  StatusInnerLeft,
  StatusCounter,
  StyledSwipeIcon,
  ListViewButton,
  SpinnerContainer,
  PlaceholderContainer
} from "./styles"
import { range } from "lodash"
import useOnMount from "../../../hooks/useOnMount"
import useOnChange from "../../../hooks/useOnChange"
import ListItem from "./ListItem"
import DeckCard from "../DeckCard"
import LoadingSpinner from "../../../legacyComponents/Desktop/Animations/LoadingSpinner"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

const Ease = (x) => 1 - Math.pow(1 - x, 3)
const Duration = 300
const SwipeThreshold = 0.8

const DecksCarousel = ({
  deckType,
  decks,
  initialIndex = 0,
  loading = false,
  placeholder = null,
  onDeckClick,
  onOpenListClick,
  onIndexChange
}) => {
  const containerRef = useRef(null)
  const itemBoxRef = useRef(null)
  const capturedTouchId = useRef(null)
  const animationFrameId = useRef(null)
  const {t} = useTranslation();
  const [scrollState, setScrollState] = useState({
    pos: 0,
    transitionPos: 0,
    moveStartPos: null,
    moveOffset: 0,
    moveTime: 0,
    velocity: 0
  })

  useSize(containerRef)

  const itemCount = decks.length
  const maxIndex = Math.max(itemCount - 1, 0)
  const clampIndex = (index) => Math.min(Math.max(index, 0), maxIndex)

  useEffect(() => () => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current)
    }
  }, [])

  useOnMount(() => {
    const itemWidth = Math.floor(itemBoxRef.current?.clientWidth ?? 0)
    const pos = clampIndex(initialIndex) * itemWidth

    setScrollState(prev => ({ ...prev, pos, transitionPos: pos }))
  })

  useOnChange(() => {
    setScrollState(prev => ({ ...prev, pos: 0, transitionPos: 0 }))
  }, [deckType])

  const itemWidth = Math.floor(itemBoxRef.current?.clientWidth ?? 0)
  const containerWidth = Math.floor(containerRef.current?.clientWidth ?? 0)

  const clampPos = (pos) => Math.min(Math.max(pos, 0), maxIndex * itemWidth)
  const posToIndex = (pos) => Math.round(clampPos(pos) / itemWidth)

  const originShift = 0.5 * (containerWidth - itemWidth)
  const currentIndex = posToIndex(scrollState.pos)
  const currentItemSnapPos = currentIndex * itemWidth
  const itemsOffset = currentItemSnapPos - clampPos(scrollState.transitionPos + scrollState.moveOffset)

  useOnChange(() => {
    setScrollState(prev => ({
      ...prev,
      pos: clampPos(prev.pos),
      transitionPos: clampPos(prev.transitionPos)
    }))
  }, [decks])

  useOnChange(() => {
    if (onIndexChange) {
      onIndexChange(currentIndex)
    }
  }, [currentIndex])

  const snapToItem = (itemIndex) => {
    const initialPos = clampPos(scrollState.transitionPos + scrollState.moveOffset)
    const targetPos = clampIndex(itemIndex) * itemWidth
    const animationStartTime = performance.now()

    const resetTransition = () => {
      setScrollState(prev => ({ ...prev, pos: prev.transitionPos, velocity: 0 }))
    }

    if (Math.floor(initialPos) === Math.floor(targetPos)) {
      resetTransition()
      return
    }

    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current)
    }

    const animate = () => {
      animationFrameId.current = requestAnimationFrame(() => {
        const now = performance.now()
        const elapsed = now - animationStartTime
        const scrollDelta = targetPos - initialPos
        const easedTime = Ease(Math.min(1, elapsed / Duration))

        setScrollState(prev => ({ ...prev, transitionPos: initialPos + scrollDelta * easedTime }))

        if (elapsed < Duration) {
          animate()
        } else {
          animationFrameId.current = null
          resetTransition()
        }
      })
    }

    animate()
  }

  const dragBegin = (screenX) => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current)
    }

    setScrollState(prev => ({
      ...prev,
      moveStartPos: screenX,
      pos: prev.transitionPos
    }))
  }

  const dragMove = (screenX) => {
    if (scrollState.moveStartPos !== null) {
      setScrollState(prev => {
        const nextMoveOffset = prev.moveStartPos - screenX
        const instantVelocity =
          prev.moveTime &&
          (nextMoveOffset - prev.moveOffset) / (performance.now() - prev.moveTime)

        return {
          ...prev,
          moveOffset: nextMoveOffset,
          moveTime: performance.now(),
          velocity: prev.velocity ? (0.7 * prev.velocity + 0.3 * instantVelocity) : instantVelocity
        }
      })
    }
  }

  const dragEnd = () => {
    if (scrollState.moveStartPos === null) {
      return
    }

    const velocity = scrollState.velocity
    const indexBefore = posToIndex(scrollState.transitionPos)
    const indexAfter = posToIndex(scrollState.transitionPos + scrollState.moveOffset)

    setScrollState(prev => ({
      ...prev,
      transitionPos: clampPos(prev.transitionPos + prev.moveOffset),
      moveStartPos: null,
      moveOffset: 0
    }))

    if (indexBefore === indexAfter && Math.abs(velocity) > SwipeThreshold) {
      snapToItem(indexAfter + (velocity > 0 ? 1 : -1))
    } else {
      snapToItem(indexAfter)
    }
  }

  const handleMouseDown = (event) => {
    dragBegin(event.screenX)
  }

  const handleMouseMove = (event) => {
    dragMove(event.screenX)
  }

  const handleMouseUp = () => {
    dragEnd()
  }

  const handleTouchStart = (event) => {
    if (capturedTouchId.current !== null) {
      return
    }

    const touch = event.touches[0]

    capturedTouchId.current = touch.identifier
    dragBegin(touch.screenX)
  }

  const handleTouchMove = (event) => {
    for (const touch of event.touches) {
      if (touch.identifier === capturedTouchId.current) {
        dragMove(touch.screenX)
        break
      }
    }
  }

  const handleTouchEndOrCancel = (event) => {
    if (capturedTouchId.current === null) {
      return
    }

    for (const touch of event.touches) {
      if (touch.identifier === capturedTouchId.current) {
        return
      }
    }

    capturedTouchId.current = null
    dragEnd()
  }

  const handleDeckClick = useCallback((deck) => {
    if (scrollState.pos === scrollState.transitionPos && onDeckClick) {
      onDeckClick(deck)
    }
  }, [scrollState, onDeckClick])

  const renderRange = range(
    Math.max(currentIndex - 2, 0),
    Math.min(currentIndex + 3, itemCount)
  )

  return (
    <Container ref={containerRef}>
      <DimensionsContainer>
        <ItemBox ref={itemBoxRef}>
          <CardBox><CardBoxHeight /></CardBox>
        </ItemBox>
      </DimensionsContainer>
      <Content>
        {itemWidth > 0 && (
          <ScrollContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEndOrCancel}
            onTouchCancel={handleTouchEndOrCancel}
          >
            {renderRange.map((index) => (
              <ListItem
                key={decks[index].deckId}
                width={itemWidth}
                originShift={originShift}
                xPos={(index - currentIndex) * itemWidth + itemsOffset}
              >
                <DeckCard deck={decks[index]} onClick={handleDeckClick} />
              </ListItem>
            ))}
          </ScrollContainer>
        )}
        <StatusContainer>
          <StatusInnerContainer>
            <StatusInnerLeft data-visible={itemCount > 0}>
              <StatusCounter>{currentIndex + 1}/{itemCount}</StatusCounter>
              <StyledSwipeIcon />
            </StatusInnerLeft>
            <ListViewButton onClick={onOpenListClick}>{t(Dictionary.dashboard.mobileAllButton)}</ListViewButton>
          </StatusInnerContainer>
        </StatusContainer>
        {itemCount === 0 && loading && (
          <SpinnerContainer>
            <span><LoadingSpinner /></span>
          </SpinnerContainer>
        )}
        {itemCount === 0 && !loading && placeholder && (
          <PlaceholderContainer>
            <span>{placeholder}</span>
          </PlaceholderContainer>
        )}
      </Content>
    </Container>
  )
}

export default memo(DecksCarousel)
