import { keyframes } from "styled-components"

export const quickTiming = `300ms`

export const mediumTiming = `600ms`

export const longTiming = `900ms`

export const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const fadeDissapearOutKeyframes = keyframes`
  0% {
  display: flex;
    opacity: 1;
  }

  99% {
  display: none;
  z-index: inherit;
  }
  100% {
  z-index: -10;
    opacity: 0;
  }
`

export const moveLeftKeyframes = keyframes`
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
`

export const rotate360Keyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const cardAppearKeyframes = keyframes`
0% {
transform: scale(0.99)
}
100%{
transform: scale(1);
}
`

export const slideInKeyframes = keyframes`
0% {
transform: translateX(-100%);
}

100%{
transform: translateX(0);
}
`

export const fadeSlideInKeyframes = keyframes`
0% {
transform: translateX(-100%);
opacity: 0;
}
35% {
opacity: 0;
}
100%{
transform: translateX(0);
opacity: 1;
}
`

export const fadeSlideUpKeyframes = keyframes`
0% {
transform: translateY(100%);
opacity: 0;
}
35% {
opacity: 0;
}
100%{
transform: translateY(0);
opacity: 1;
}
`

export const fadeSlideInFromTopKeyframes = keyframes`
0% {
transform: translateY(-100%);
opacity: 0;
}
35% {
opacity: 0;
}
100%{
transform: translateY(0);
opacity: 1;
}
`

export const growWidthKeyframes = keyframes`
0% {
width: 0%;
}
100% {
width: 100%;
}
`

export const slideInFromRightKeyframes = keyframes`
0% {
opacity: 0;
transform: translateX(100%);
}
5% {
opacity: 0;
}
100%{
opacity: 1;
transform: translateX(0);
}
`

export const slideInUserDecksKeyframes = keyframes`
0% {
opacity: 0;
transform: translateX(-100%);
}

100%{
opacity: 1;
transform: translateX(0);
}
`

export const fadeInOpacityKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const fadeInOutOpacityKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`
