import sessionsSlice from "../reducers/sessions"

export const {
  startSessionRequest,
  startSessionSuccess,
  startSessionFailure,
  gradeCardRequest,
  gradeCardSuccess,
  gradeCardFailure,
  gradeCardReset,
  completeSessionRequest,
  completeSessionSuccess,
  completeSessionFailure,
  viewSessionReset
} = sessionsSlice.actions