import React, { memo, useRef, useState } from "react"
import useTransitionState, { ENTERING, ENTERED } from "../../../hooks/useTransitionState"
import useOnChange from "../../../hooks/useOnChange"
import {
    BackgroundFill,
    Container,
    IconContainer,
    LabelBackground,
    LabelContainer
} from "./styles"

const ModeButton = ({
  className,
  mode,
  title,
  subTitle,
  icon,
  iconBgColor,
  labelBgColor,
  filled = false,
  disabled = false,
  onHover,
  onClick
}) => {
  const labelContainerRef = useRef()
  const [containerHover, setContainerHover] = useState(false)
  const transition = useTransitionState(containerHover, 200, { enter: 100, exit: 0 })
  const hover = transition === ENTERING || transition === ENTERED

  const handleMouseEnter = () => {
    setContainerHover(true)
  }

  const handleMouseLeave = () => {
    setContainerHover(false)
  }

  const handleClick = () => {
    if (onClick && !disabled) {
      onClick(mode)
    }
  }

  useOnChange(() => {
    if (onHover) {
      onHover(mode, hover)
    }
  }, [hover])

  const iconContainerStyle = {}
  const labelContainerStyle = {}
  const backgroundFillStyle = {}

  if (!disabled) {
    iconContainerStyle.backgroundColor = iconBgColor
    backgroundFillStyle.backgroundColor = labelBgColor

    if (hover || filled) {
      iconContainerStyle.borderColor = iconBgColor
      labelContainerStyle.borderColor = "#00000000"
      backgroundFillStyle.width = filled ? "100%" : `${labelContainerRef.current?.clientWidth ?? 0}px`
    } else {
      iconContainerStyle.borderColor = labelBgColor
      labelContainerStyle.borderColor = labelBgColor
    }
  }

  return (
    <Container
      data-disabled={disabled}
      data-hover={hover}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <IconContainer style={iconContainerStyle}>
        {icon}
      </IconContainer>
      <LabelContainer ref={labelContainerRef} style={labelContainerStyle}>
        <LabelBackground>
          <BackgroundFill style={backgroundFillStyle} />
        </LabelBackground>
        <span>
          <strong>{title}:</strong>&nbsp;&nbsp;
          <span>{subTitle}</span>
        </span>
      </LabelContainer>
    </Container>
  )
}

export default memo(ModeButton)
