import { useRef, useEffect } from "react"

export default (callback) => {
  const wasMount = useRef(false)

  useEffect(() => {
    if (!wasMount.current) {
      callback()
    }

    wasMount.current = true
  })
}
