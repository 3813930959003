import React, { memo } from "react"
import CloseIcon from "../../../../assets/svgs/CloseIcon"
import { links } from "../../../../constants"
import useTransitionState, { ENTERING, ENTERED, EXITED } from "../../../../hooks/useTransitionState"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../../utils/i18n";
import { routes } from "../../../../utils/constants"
import {
  Container,
  ContentWrapper,
  HeaderContainer,
  CloseButtonRow,
  CloseButton,
  LogoRow,
  StyledLogo,
  MenuContainer,
  TopItemsContainer,
  MenuItem,
  IconContainer,
  StyledDashboardIcon,
  StyledSupportIcon,
  StylesTermsIcon,
  StyledLogOutIcon,
  LogOutMenuItem
} from "./styles"
import { FBAnalyticsClearUserData } from "./../../../../firebase/logEvents";

const MobileNavigation = ({ show, onClose }) => {
  const transition = useTransitionState(show, 300)
  const {t} = useTranslation();

  const handleItemClick = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleSupportClick = (event) => {
    event.preventDefault()
    window.open(links.support, "_blank")
    handleItemClick()
  }

  const handleTermsClick = (event) => {
    event.preventDefault()
    window.open(links.termsOfUse, "_blank")
    handleItemClick()
  }

  const handleLogoutClick = (event) => {
    FBAnalyticsClearUserData();
    handleItemClick()
  }

  if (transition === EXITED) {
    return null
  }

  return (
    <Container data-visible={transition === ENTERING || transition === ENTERED}>
      <ContentWrapper>
        <HeaderContainer>
          <CloseButtonRow>
            <CloseButton onClick={onClose}><CloseIcon /></CloseButton>
          </CloseButtonRow>
          <LogoRow><StyledLogo /></LogoRow>
        </HeaderContainer>
        <MenuContainer>
          <TopItemsContainer>
            <MenuItem to={routes.dashboard} onClick={handleItemClick}>
              <IconContainer>
                <StyledDashboardIcon />
              </IconContainer>
              <span>{t(Dictionary.menu.dashboard)}</span>
            </MenuItem>
            <MenuItem to="/support" onClick={handleSupportClick}>
              <IconContainer>
                <StyledSupportIcon />
              </IconContainer>
              <span>{t(Dictionary.settings.support)}</span>
            </MenuItem>
            <MenuItem to="/terms-of-use" onClick={handleTermsClick}>
              <IconContainer>
                <StylesTermsIcon />
              </IconContainer>
              <span>{t(Dictionary.settings.terms)}</span>
            </MenuItem>
          </TopItemsContainer>
          <LogOutMenuItem
            to={{
              pathname: routes.login,
              state: { logout: true }
            }}
            onClick={handleLogoutClick}
          >
            <IconContainer>
              <StyledLogOutIcon />
            </IconContainer>
            <span>{t(Dictionary.settings.logout)}</span>
          </LogOutMenuItem>
        </MenuContainer>
      </ContentWrapper>
    </Container>
  )
}

export default memo(MobileNavigation)
