import { createSelector } from "reselect"
import { DeckType } from "../../constants"
import { isLanguage } from "../../utils/checks"
import { CHINESE_TAGS } from './../../utils/helpers/FlagAndLanguage';
import keys from "../../utils/keys"

export const dashboardDeckType = (state) => state.decks.dashboardDeckType
export const dashboardDeckIndex = (state) => state.decks.dashboardDeckIndex

export const masterDecksState = createSelector(
  (state) => state.decks.masterDecks,
  ({ data, dataLoaded, error }) => ({
    dataLoaded,
    error,
    data: data.map(({ id, name, description = null, thumb, decks, tags }) => {
      const result = {
        tags,
        deckId: id,
        title: name,
        avatar: { path: thumb },
        firstVirtualId: decks[0]?.virtualId ?? null,
        cardCount: 0,
        cardsCompleted: 0,
        language: false,
        type: "Type placeholder",
        description,
        category: "Master Decks"
      }

      decks.forEach((deck) => {
        result.cardCount += deck.cards
        result.cardsCompleted += deck.overdue
      })

      if (Array.isArray(tags) && tags.length > 0 && isLanguage(tags[0].tag)) {
        result.language = tags[0].tag
      }

      return result
    })
  })
)

export const masterDecksData = createSelector(
  (state) => state.decks.masterDecks,
  ({ data, loading, error }) => ({
    loading,
    error,
    data: data.map(({ id, name, description = null, thumb, decks, tags }) => {
      const result = {
        deckId: id,
        type: DeckType.merge,
        title: name,
        image: thumb,
        firstVirtualId: decks[0]?.virtualId ?? null,
        totalCount: 0,
        overdueCount: 0,
        language: null,
        description//: "Description placeholder"
      }

      decks.forEach((deck) => {
        result.totalCount += deck.cards
        result.overdueCount += deck.overdue
      })

      if (Array.isArray(tags) && tags.length > 0 && isLanguage(tags[0].tag)) {
        result.language = tags[0].tag
      }

      return result
    })
  })
)

export const userDecksState = (state) => state.decks.userDecks

export const userDecksData = createSelector(
  (state) => state.decks.userDecks,
  ({ data, loading, error }) => ({
    loading,
    error,
    data: data.map(({ deckId, title, description = null, virtualId, cardCount, nonOverdue, tags, avatar }) => {
      const result = {
        deckId,
        type: DeckType.deck,
        virtualId,
        title,
        image: avatar?.path ?? null,
        totalCount: cardCount,
        overdueCount: cardCount - nonOverdue,
        language: null,
        description//: "Description placeholder"
      }

      if (Array.isArray(tags) && tags.length > 0 && isLanguage(tags[0].tag)) {
        result.language = tags[0].tag
      }

      return result
    })

  })
)

export const userDecksPage = (state) => state.decks.userDecksPage

export const deckCardsViewState = (state) => state.decks.viewDeckCards

const noteMapper = (card) => (note, index) => {
  if (note.version === 2) {
    if (index === 0) {
      const audio = note.audios?.[0]?.url

      card.nativePhrase = note.headers?.[0]?.header ?? ""
      card.nativePhrasePronunciation = note.paragraphs?.[0]?.content ?? ""

      if (audio) {
        card.phraseSounds.push({
          url: audio,
          durationSecs: "0",
          hasBeenListened: false
        })
      }

      const image = note.images?.[0]?.url

      if (image) {
       card.imgSrc = image
      }
    } else if (index === 1) {
      card.translatedPhrase = note.paragraphs?.[0]?.content ?? ""
    }
  } else {
    const noteHtml = note.field
    const foundSrc = noteHtml.match(/<source.+src="(.+)"/)

    if (foundSrc && foundSrc.length > 1) {
      card.phraseSounds.push({
        url: foundSrc[1],
        durationSecs: "0",
        hasBeenListened: false
      })
    }

    const phrase = noteHtml.replaceAll(/<[^>]*>/g, "").trim()

    if (index === 0) {
      card.translatedPhrase = phrase
    } else if (index === 1) {
      card.nativePhrase = phrase
    }
  }
}

export const deckWithCardsById = createSelector(
  (state, virtualId) => state.decks.deckWithCardsById[virtualId],
  (state, virtualId) => virtualId,
  deckCardsViewState,
  (deck, virtualId, viewState) => {
    if (!deck) {
      return null
    }

    return {
      deck: {
        ...deck.deck,
        virtualId,
        icons: [],
        tags: deck.tags,
        description: "",
        totalCards: deck.meta.count,
        remainingCards: deck.meta.overdue,
        coverImage: deck.avatar?.path
      },
      pagesCount: viewState.pageSize > 0 ? Math.ceil(deck.meta.count / viewState.pageSize) : 0,
      stats: {
        statsTimespanKey: keys.timespans.general,
        cardsReviewed: 0,
        cardsForgotten: 0,
        newCards: 0,
        wordsLearned: 0,
        timeStudied: 0,
        difficulty: "Fácil",
        easyCardsReviewed: 0,
        normalCardsReviewed: 0,
        hardCardsReviewed: 0
      },
      cards: deck.cards.map((item) => {
        const card = {
          id: item.card.id,
          imgSrc: "",
          isFrontSideActive: true,
          hasCardBeenStudied: true,
          gradingsCountReviews: item.score?.reviews || 0,
          gradingsCountEasy: 3,
          gradingsCountHard: 2,
          gradingsCountNormal: 4,
          nativePhrase: "",
          nativePhrasePronunciation: "",
          translatedPhrase: "",
          phraseSounds: []
        }

        const notes = [...item.notes];
        // if(notes[0]?.version === 2 && notes[1]?.version === 2){
        //   // const isJapanese = deck?.tags[0]?.tag === japanese;

        //   const firstNote = notes[0];
        //   const secondNote = notes[1];
        //   if(secondNote?.headers?.length && !firstNote?.headers?.length &&
        //     secondNote?.audios?.length &&
        //     secondNote?.paragraphs?.length && firstNote?.paragraphs?.length) {
        //       notes.reverse();
        //     // } else if(isJapanese && secondNote?.headers?.length && !secondNote?.paragraphs?.length){
        //     //   notes[1].paragraphs[0] = {...notes[1].headers[0]}
        //     }
        // }
        notes.forEach(noteMapper(card))

        return card
      })
    }
  }
)

export const mergeWithCardsById = createSelector(
  (state, mergeId) => state.decks.mergeWithCardsById[mergeId],
  (state, mergeId) => mergeId,
  deckCardsViewState,
  (merge, mergeId, viewState) => {
    if (!merge) {
      return null
    }

    let remainingCards = 0
    let totalCards = 0

    merge.decks.forEach((deck) => {
      remainingCards += deck.overdue
      totalCards += deck.cards
    })

    return {
      deck: {
        ...merge,
        mergeId,
        title: merge.name,
        icons: [],
        description: "",
        totalCards,
        remainingCards,
        coverImage: merge.thumb
      },
      pagesCount: viewState.pageSize > 0 ? Math.ceil(totalCards / viewState.pageSize) : 0,
      stats: {
        statsTimespanKey: keys.timespans.general,
        cardsReviewed: 0,
        cardsForgotten: 0,
        newCards: 0,
        wordsLearned: 0,
        timeStudied: 0,
        difficulty: "Fácil",
        easyCardsReviewed: 0,
        normalCardsReviewed: 0,
        hardCardsReviewed: 0
      },
      cards: merge.cards.map((item) => {
        const card = {
          id: item.card.id,
          imgSrc: "",
          isFrontSideActive: true,
          hasCardBeenStudied: true,
          gradingsCountReviews: item.score?.reviews || 0,
          gradingsCountEasy: 3,
          gradingsCountNormal: 4,
          gradingsCountHard: 2,
          nativePhrase: "",
          nativePhrasePronunciation: "",
          translatedPhrase: "",
          phraseSounds: []
        }

        const notes = [...item.notes];
        // if(notes[0]?.version === 2 && notes[1]?.version === 2){
        //   // const isJapanese = item.deck?.tags[0]?.tag === japanese;

        //   const firstNote = notes[0];
        //   const secondNote = notes[1];
        //   if(secondNote?.headers?.length && !firstNote?.headers?.length &&
        //     secondNote?.audios?.length &&
        //     secondNote?.paragraphs?.length && firstNote?.paragraphs?.length) {
        //       notes.reverse();
        //     // } else if(isJapanese && secondNote?.headers?.length && !secondNote?.paragraphs?.length){
        //     //   notes[1].paragraphs[0] = {...notes[1].headers[0]}
        //     }
        // }

        notes.forEach(noteMapper(card))

        return card
      })
    }
  }
)

export const virtualToPhysicalDeckId = (state) => state.decks.virtualToPhysicalDeckId
export const virtualToDeckWithCardsById = (state) => state.decks.deckWithCardsById

const noteMapper2 = (card, notes) => (note, index) => {
  if (note.version === 2) {
    if (index === 0) {
      card.foreignText = note.headers?.[0]?.header ?? notes[1].headers?.[0]?.header ?? ""
      card.foreignTextPronunciation = note.paragraphs?.[0]?.content ?? ""
      // card.audio = note.audios?.[0]?.url ?? null
      card.image = note.images?.[0]?.url ?? null
    } else if (index === 1) {
      const reverseCard = !notes[0].headers?.[0]?.header && note.headers?.[0]?.header;
      card.translatedText = reverseCard && notes[0].paragraphs?.[0]?.content ? notes[0].paragraphs?.[0]?.content : note.paragraphs?.[0]?.content ?? notes[0].paragraphs?.[0]?.content ?? ""
    }
  } else {
    const noteHtml = note.field
    const foundSrc = noteHtml.match(/<source.+src="(.+)"/)

    if (foundSrc && foundSrc.length > 1) {
      card.audio = foundSrc[1]
    }

    const phrase = noteHtml.replaceAll(/<[^>]*>/g, "").trim()

    if (index === 0) {
      card.foreignText = phrase
    } else if (index === 1) {
      card.translatedText = phrase
    }
  }
}

export const mergeDetails = createSelector(
  (state, mergeId) => state.decks.mergeWithCardsById[mergeId],
  deckCardsViewState,
  (merge, viewState) => {
    if (!merge) {
      return null
    }

    let overdueCount = 0
    let totalCount = 0

    merge.decks.forEach((deck) => {
      overdueCount += deck.overdue
      totalCount += deck.cards
    })

    return {
      deck: {
        deckId: merge.id,
        title: merge.name,
        description: merge.description, //"Description placeholder",
        totalCount,
        overdueCount,
        image: merge.thumb,
        language: merge.tags?.[0]?.tag ?? null
      },
      pagesCount: viewState.pageSize > 0 ? Math.ceil(totalCount / viewState.pageSize) : 0,
      cards: merge.cards.map((item) => {

        const notes = [...item.notes];

        if(notes[0]?.version === 1 && notes[1]?.version === 1) {
          if(notes[0]?.fieldName === 'back' && notes[1]?.fieldName === 'front') {
            notes.reverse();
          }
        }

        const audioNoteA = notes[0].audios?.[0]?.url;
        const audioNoteB = notes[1].audios?.[0]?.url;

        const tags = notes[0].tags?.length ? notes[0].tags : notes[1].tags?.length ? notes[1].tags : [];

        const card = {
          id: item.card.id,
          image: null,
          audio: audioNoteA ?? audioNoteB ?? null,
          foreignText: "",
          foreignTextPronunciation: "",
          translatedText: "",
          tags,
          gradingsCountReviews: item.score?.reviews || 0,
          gradingsCountEasy: 3,
          gradingsCountHard: 2,
          gradingsCountNormal: 4
        }

        if(notes[0]?.version === 2 && notes[1]?.version === 2){
          // const isJapanese = merge?.tags[0]?.tag === japanese;
          const isMandarim = CHINESE_TAGS.includes(merge?.tags[0]?.tag);

          const firstNote = notes[0];
          const secondNote = notes[1];
          if(!isMandarim && secondNote?.headers?.length && !firstNote?.headers?.length &&
            secondNote?.audios?.length &&
            secondNote?.paragraphs?.length && firstNote?.paragraphs?.length) {
              notes.reverse();
              // console.log("🚀 ~ file: decks.js ~ line 397 ~ cards:merge.cards.map ~ reverse")
            // } else if(isJapanese && secondNote?.headers?.length && !secondNote?.paragraphs?.length){
            //   notes[1].paragraphs[0] = {...notes[1].headers[0]}
            }
        }

        notes.forEach(noteMapper2(card, notes))

        return card
      })
    }
  }
)

export const deckDetails = createSelector(
  (state, virtualId) => state.decks.deckWithCardsById[virtualId],
  (state, virtualId) => virtualId,
  deckCardsViewState,
  (deck, virtualId, viewState) => {
    if (!deck) {
      return null
    }
    const overdueCount = deck.meta.count >= 0 ? (deck.meta.count - (deck.meta.nonOverdue || 0)) : 0;

    return {
      deck: {
        ...deck.deck,
        deckId: virtualId,
        description: deck.deck.description,
        totalCount: deck.meta.count,
        overdueCount,
        image: deck.avatar?.path,
        language: deck.tags?.[0]?.tag ?? null
      },
      pagesCount: viewState.pageSize > 0 ? Math.ceil(deck.meta.count / viewState.pageSize) : 0,
      cards: deck.cards.map((item) => {

        let notes = [...item.notes];

        if(notes[0]?.version === 1 && notes[1]?.version === 1) {
          if(notes[0]?.fieldName === 'back' && notes[1]?.fieldName === 'front') {
            notes.reverse();
          }
        }
        const audioNoteA = notes[0].audios?.[0]?.url;
        const audioNoteB = notes[1].audios?.[0]?.url;

        const tags = notes[0].tags?.length ? notes[0].tags : notes[1].tags?.length ? notes[1].tags : [];

        const card = {
          id: item.card.id,
          image: null,
          audio: audioNoteA ?? audioNoteB ?? null,
          foreignText: "",
          foreignTextPronunciation: "",
          translatedText: "",
          tags,
          gradingsCountReviews: item.score?.reviews || 0,
          gradingsCountEasy: 3,
          gradingsCountHard: 2,
          gradingsCountNormal: 4
        }

        if(notes[0]?.version === 2 && notes[1]?.version === 2){
          // const isJapanese = deck?.tags[0]?.tag === japanese;
          const isMandarim = CHINESE_TAGS.includes(deck?.tags[0]?.tag);

          // reverseCardNotes(notes, isJapanese)
          const firstNote = notes[0];
          const secondNote = notes[1];
          if(!isMandarim && secondNote?.headers?.length && !firstNote?.headers?.length &&
            secondNote?.audios?.length &&
            secondNote?.paragraphs?.length && firstNote?.paragraphs?.length) {
              notes.reverse();
            // } else if(isJapanese && secondNote?.headers?.length && !secondNote?.paragraphs?.length){

            //   console.log("🚀 ~ file: decks.js ~ notes[1].paragraphs[0]", secondNote.paragraphs[0])
            //   console.log("🚀 ~ file: decks.js ~ notes[1].headers[0]", secondNote.headers[0])
            //   notes[1].paragraphs = secondNote.headers;
            }
        }

        notes.forEach(noteMapper2(card, notes))

        return card
      })
    }
  }
)