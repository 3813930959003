import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import AppRoot from "./pages/AppRoot"
import store from "./store"
// import { initialGTM } from './googleTagManage';

// import * as serviceWorker from "./serviceWorker"

// initialGTM()

ReactDOM.render(
  <Provider store={store}>
    <AppRoot />
  </Provider>,
  document.getElementById(`root`)
)

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
