import styled from "styled-components"
import CheckMarkIcon from "../../../../assets/svgs/CheckMarkIcon"
import CrossIcon from "../../../../assets/svgs/CrossIcon"
import selectIcon from "../../../../assets/svgs/SelectIcon.svg"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../../common/breakpoints"

export const Container = styled.div`
  padding-bottom: 14px;
  border-bottom: 1px solid #8B8A8A;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-bottom: 10px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    padding: 0 8px;
    height: 54px;
    border-bottom: 1px solid #D6D6D6;
  }
`

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  height: 24px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    height: 100%;
    font-size: 13px;
    line-height: 15px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 31px;
  color: #F24556;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 21px;
  }
`

export const SelectIcon = styled(selectIcon)``


export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 21px;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 15px;
  }
`

export const ValidIcon = styled(CheckMarkIcon)`
  color: #1BD46F;
  font-size: 21px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
  }
`

export const InvalidIcon = styled(CrossIcon)`
  color: #F24556;
  font-size: 26px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 18px;
  }
`