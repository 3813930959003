import React, { memo, useEffect, useRef, useState } from "react"
import useTransitionState, { ENTERED, ENTERING, EXITED } from "../../../hooks/useTransitionState"
import {
  Container,
  DropdownButton,
  StyledChevron,
  BottomContainer,
  MenuContainer,
  MenuItem
} from "./styles"

const MobileDropdown = ({ className, items, selectedId, onSelect }) => {
  const containerRef = useRef(null)
  const [show, setShow] = useState(false)
  const transition = useTransitionState(show, 200)

  useEffect(() => {
    if (!show) {
      return undefined
    }

    const handleClick = (event) => {
      if (!containerRef.current.contains(event.target)) {
        setShow(false)
      }
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [show])

  const handleClick = () => {
    setShow(prev => !prev)
  }

  const itemClickHandler = (item) => () => {
    setShow(false)

    if (onSelect) {
      onSelect(item)
    }
  }

  return (
    <Container ref={containerRef} className={className}>
      <DropdownButton onClick={handleClick}>
        {items.find((item) => item.id === selectedId)?.label}
        <StyledChevron />
      </DropdownButton>
      <BottomContainer>
        {transition !== EXITED && (
          <MenuContainer data-show={transition === ENTERING || transition === ENTERED}>
            {items.map((item) => (
              <MenuItem key={item.id} onClick={itemClickHandler(item)}>{item.label}</MenuItem>
            ))}
          </MenuContainer>
        )}
      </BottomContainer>
    </Container>
  )
}

export default memo(MobileDropdown)
