import styled from "styled-components"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX, LAPTOP_SMALL_MAX, MOBILE_MAX } from "../../common/breakpoints"
import { navBarTransition } from "../../common/styles/navBar"

export const StatusContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

//120
//84

export const ContentContainer = styled.div`
  padding: 60px 94px;
  overflow-x: auto;
  background-color: #F5F6FA;
  transition: padding-left ${navBarTransition}, padding-right ${navBarTransition};

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 56px 91px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 42px 66px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    padding: 56px 64px;
  }

  &[data-nav-expanded='false'] {
    padding-left: 214px;
    padding-right: 214px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      padding-left: 211px;
      padding-right: 211px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      padding-left: 150px;
      padding-right: 150px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      padding-left: 148px;
      padding-right: 148px;
    }
  }
`

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  min-width: 652px;
`

export const CardsTitleRow = styled.div`
  margin-top: 98px;
  padding-left: 23px;
  margin-bottom: 20px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin-top: 88px;
    padding-left: 22px;
    margin-bottom: 20px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 68px;
    padding-left: 16px;
    margin-bottom: 17px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    margin-top: 66px;
    padding-left: 22px;
    margin-bottom: 20px;
  }
`

export const CardsTitle = styled.span`
  font-family: "IBM Plex Serif";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 23px;
    line-height: 30px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 17px;
    line-height: 22px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 18px;
    line-height: 23px;
  }
`

export const CardsCount = styled.span`
  color: #C2C2C2;
  font-size: 14px;
  margin-left: 8px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 14px;
    margin-left: 5px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 10px;
    margin-left: 6px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 15px;
    margin-left: 9px;
  }
`

export const TabsContainer = styled.div`
  border-bottom: 1px solid #70707042;
  margin-bottom: 47px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin-bottom: 45px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 33px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    margin-bottom: 47px;
  }
`

export const TabButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  color: #00AEE0;
  padding: 0 24px 12px 24px;
  margin-bottom: 2px;
  margin-right: 8px;
  transition: box-shadow 0.2s ease-out;
  text-transform: uppercase;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    padding: 0 22px 12px 22px;
    margin-right: 6px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 17px;
    padding: 0 17px 8px 17px;
    margin-right: 6px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    padding: 0 22px 12px 22px;
    margin-right: 6px;
  }

  &[data-selected='true'] {
    box-shadow: 0px 4px 0px 0px #00AEE0;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -31px -46px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin: -28px -20px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: -21px -20px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    margin: -27px -25px;
  }
`

export const CardCell = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 31px 46px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding: 28px 20px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 21px 20px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 27px 25px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const PageControlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-top: 45px;
  }
`
