import React, { memo } from "react"
import { GradeLabels } from "../constants"
import { useTranslation } from "react-i18next"
import {
  ButtonContainer,
  Content,
  ProgressSvg,
  Spinner,
  ProgressCircle
} from "./styles"

const GradeButton = ({
  index,
  transition,
  grade,
  disabled,
  pending,
  nextDueLabel,
  onClick
}) => {
  const containerStyle = { transitionDelay: `${index * 100}ms` }
  const {t} = useTranslation();

  const renderContent = () => {
    if (pending) {
      return <Spinner />
    }

    if (nextDueLabel) {
      return nextDueLabel
    }
    // return GradeLabels[grade]?.label
    return GradeLabels[grade] ? t(GradeLabels[grade].translationLabel) : null;
  }

  return (
    <ButtonContainer
      data-transition={transition}
      disabled={disabled}
      style={containerStyle}
      onClick={onClick}
    >
      <Content data-show-next-due={!!nextDueLabel} data-pending={pending}>
        {renderContent()}
      </Content>
      {nextDueLabel && (
        <ProgressSvg viewBox="0 0 58 58">
          <ProgressCircle cx="29" cy="29" r="27" strokeWidth="3" />
        </ProgressSvg>
      )}
    </ButtonContainer>
  )
}

export default memo(GradeButton)
