import React, { memo, useRef , useCallback , useMemo } from "react"

import { useMediaQuery } from "react-responsive"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";

import {
  SideContainer,
  SideMainContent,
  SideMainContentContainer,
  SideScroll,
  SideAHeader,
  SideAParagraph,
  TextsContainer,
  SideScrollContent,
  CardHeader,
  FlipButton,
  StyledFlipIcon,
  HeaderRight,
  TagsContainer,
  TagContainer,
  TagLabel,
  StyledAudioPlayer,
  SideImageContainer,
  MobileImage
} from "./styles"
import { getTextSizes } from "./textSizes"
import useTextSizes from "./useTextSizes"

const SideA = ({
  cardId,
  visible,
  autoplay,
  showText = true,
  note,
  onFlipButtonHover,
  onFlip,
  playerReady,
  handlePlaybackRate
}) => {
  const {t} = useTranslation();

  const {
    headers: headersSideA,
    paragraphs: paragraphsSideA,
    audios: sideAAudios,
    images,
    tags
  } = note;
  const hasHeaders = headersSideA && headersSideA.length;
  const hasParagraphs = paragraphsSideA && paragraphsSideA.length;
  const headers = !hasHeaders && hasParagraphs ? paragraphsSideA : headersSideA;
  const paragraphs = !hasHeaders && hasParagraphs ? [] : paragraphsSideA;

  const audios = sideAAudios && sideAAudios[0] && sideAAudios[0]?.url ? sideAAudios : [];
  const hasAudio = audios && audios.length > 0;

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const isLaptop = useMediaQuery({ query: `(max-width: ${LAPTOP_MAX}px)` })
  const isAsian = (paragraphs && paragraphs.length > 0) || (headers[0]?.content || headers[0])?.length < 4
  const testSizes = getTextSizes(isMobile, isLaptop, isAsian, false)
  const scrollRef = useRef()
  const texts = useMemo(() => ({
    header: headers ?? [],
    paragraph: paragraphs ?? []
  }), [headers, paragraphs])
  const { sizes, makeSpanRef } = useTextSizes(scrollRef, testSizes, texts, [showText])

  const onFlipButtonMouseenter = useCallback(() => {
    onFlipButtonHover(true)
  }, [onFlipButtonHover])

  const onFlipButtonMouseleave = useCallback(() => {
    onFlipButtonHover(false)
  }, [onFlipButtonHover])

  const formatText = (text) => showText ? text : Array(text.length + 1).join(`*`)
  const hasImage = !!images && images.length > 0

  return (
    <SideContainer data-back-side={false}>
      {!isMobile && images && images.length > 0 && (
        <SideImageContainer style={{ backgroundImage: `url(${images[0].url})` }} />
      )}
      <SideMainContentContainer>
        <SideMainContent>
          <CardHeader>
            <FlipButton
              onMouseEnter={onFlipButtonMouseenter}
              onMouseLeave={onFlipButtonMouseleave}
              onClick={onFlip}
            >
              <StyledFlipIcon />
              <span>{t(Dictionary.studySession.turnCard)}</span>
            </FlipButton>
            <HeaderRight>
              <strong>A</strong>&nbsp;/&nbsp;B
            </HeaderRight>
          </CardHeader>
          {tags && tags.length > 0 && (
            <TagsContainer>
              {tags.map(({ tag }, index) => (
                <TagContainer key={index}>
                  <TagLabel>{tag}</TagLabel>
                </TagContainer>
              ))}
            </TagsContainer>
          )}
          <SideScroll ref={scrollRef}>
            <SideScrollContent onClick={onFlip}>
              <TextsContainer data-is-asian={isAsian} hasAudio={hasAudio}>
                {isMobile && images && images.length > 0 && (
                  <MobileImage style={{ backgroundImage: `url(${images[0].url})` }} />
                )}
                {headers && headers.map((header, index) => (
                  <SideAHeader
                    key={index}
                    data-hidden-text={!showText}
                    data-has-image={hasImage}
                    data-is-asian={isAsian}
                    style={{ fontSize: sizes.header }}
                  >
                    <span ref={makeSpanRef(`hdr-${index}`)}>
                      {formatText(header.content)}
                    </span>
                  </SideAHeader>
                ))}
                {paragraphs && paragraphs.map((paragraph, index) => (
                  <SideAParagraph
                    key={index}
                    data-hidden-text={!showText}
                    data-has-image={hasImage}
                    data-is-asian={isAsian}
                    style={{ fontSize: sizes.paragraph }}
                  >
                    <span ref={makeSpanRef(`par-${index}`)}>
                      {formatText(paragraph.content)}
                    </span>
                  </SideAParagraph>
                ))}
              </TextsContainer>
            </SideScrollContent>
          </SideScroll>
          {audios && audios.length > 0 && (
            <StyledAudioPlayer
              needPlayerReady
              entityId={cardId}
              url={audios[0].url}
              enabled={visible}
              autoplay={autoplay}
              playerReady={playerReady}
              handlePlaybackRate={handlePlaybackRate}
            />
          )}
        </SideMainContent>
      </SideMainContentContainer>
    </SideContainer>
  )
}

export default memo(SideA)