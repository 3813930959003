import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a, .b {
    fill: currentColor;
  }
  .a { 
    opacity: 0.649;
  }
`

const CollapseIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 19.43 16" {...props}>
    <path className="a" d="M6.742,12,13.4,5.4,12,4,4,12l8,8,1.4-1.4L7.8,13Z" transform="translate(6.03 -4)"/>
    <path className="b" d="M6.742,12,13.4,5.4,12,4,4,12l8,8,1.4-1.4L7.8,13Z" transform="translate(-4 -4)"/>
  </StyledSvg>
)

export default memo(CollapseIcon)
