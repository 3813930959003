import { Log } from "./logger"
import { verbose } from "./verbose"
import { types } from "../assets/types/types"
import { screenWidth } from "../assets/css/screen"

/**
 * * isLanguage
 * checks if element is a language
 * @function isLanguage(@param string)
 */
export const isLanguage = (string) => {
  if (types.languageTags[string] !== undefined) {
    return true
  } else {
    return false
  }
}
// export const isImageAvailable = (src) => {

// }
/**
 * * isMobileWindow
 * checks if window is mobile size
 * TODO: Deprecate and replace with isMobile below
 * @function isMobileWindow(@param window)
 */
export const isMobileWindow = (window) => {
  return window.innerWidth < screenWidth.tablet
}


/**
 * * isObject
 * checks if element is an object
 * @function isObject(@param element)
 */
export const isObject = (element) => {
  // exclude typeof(null) === 'object'

  if (typeof element === "object" && element !== null) {
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isObject(${element})`, `isObject({element: ${element}})`)
    }
    return false
  }
}

/**
 * * isString
 * checks if element is a string
 * @function isString(@param element)
 */
export const isString = (element) => {
  if (typeof element === `string`) {
    return true
  } else if (typeof element === `object`) {
    const elementsObject = element
    for (let objectKeys in elementsObject) {
      element = elementsObject[objectKeys]
      // if any elements passed to isString in object is not string return false
      if (typeof element !== `string`) {
        return false
      }
    }
    // all elements are strings
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isString(${element})`, `isString({element: ${element}})`)
    }
    return false
  }
}

/**
 * * isNonEmptyString
 * checks if string is non empty is an integer
 * @function isNonEmptyString(@param string)
 */
export const isNonEmptyString = (string) => {
  if (isString(string) === true) {
    if (string === "") {
      if (verbose) {
        Log.error(
          `checks.js`,
          `isNonEmptyString(${string})`,
          `isNonEmptyString({string: ${string}})`
        )
      }
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

/**
 * * isBoolean
 * checks if element is a boolean
 * @function isBoolean(@param element)
 */
export const isBoolean = (element) => {
  if (typeof element === `boolean`) {
    return true
  } else if (typeof element === `object`) {
    const elementsObject = element
    for (let objectKeys in elementsObject) {
      element = elementsObject[objectKeys]
      // if any elements passed to isBoolean in object is not boolean return false
      if (typeof element !== `boolean`) {
        return false
      }
    }
    // all elements are boolean
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isBoolean(${element})`, `isBoolean({element: ${element}})`)
    }
    return false
  }
}

/**
 * * isArray
 * checks if element is an array
 * @function isArray(@param element)
 */
export const isArray = (element) => {
  if (Array.isArray(element)) {
    return true
  } else if (typeof element === `object`) {
    const elementsObject = element
    for (let objectKeys in elementsObject) {
      element = elementsObject[objectKeys]
      // if any elements passed to isArray in object is not array return false
      if (Array.isArray(element) === false) {
        return false
      }
    }
    // all elements are arrays
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isArray(${element})`, `isArray({element: ${element}})`)
    }
    return false
  }
}

/**
 * * isArrayEmpty
 * checks if element is an empty array
 * @function isArrayEmpty(@param element)
 */
export const isArrayEmpty = (array) => {
  if (isArray(array)) {
    if (array.length === 0) {
      return true
    } else if (array.length >= 1) {
      return false
    }
  }
}

/**
 * * isNumber
 * checks if element is a number
 * @function isNumber(@param element)
 */
export const isNumber = (element) => {
  if (Number.isFinite(element) === true) {
    return true
  } else if (typeof element === `object`) {
    const elementsObject = element
    for (let objectKeys in elementsObject) {
      element = elementsObject[objectKeys]
      // if any elements passed to isNumber in object is not number return false
      if (Number.isFinite(element) !== true) {
        return false
      }
    }
    // all elements are numbers
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isNumber(${element})`, `isNumber({element: ${element}})`)
    }
    return false
  }
}

/**
 * * isInteger
 * checks if element is an integer
 * @function isInteger(@param element)
 */
export const isInteger = (element) => {
  if (Number.isInteger(element)) {
    return true
  } else if (typeof element === `object`) {
    const elementsObject = element
    for (let objectKeys in elementsObject) {
      element = elementsObject[objectKeys]
      // if any elements passed to isInteger in object is not an integer return false
      if (Number.isInteger(element) !== true) {
        return false
      }
    }
    // all elements are integers
    return true
  } else {
    if (verbose) {
      Log.error(`checks.js`, `isInteger(${element})`, `isInteger({element: ${element}})`)
    }
    return false
  }
}
