import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authToken: null,
  termsAccepted: false,
  login: {
    pending: false,
    error: null,
    succeeded: false
  },
  register: {
    pending: false,
    error: null,
    succeeded: false
  },
  registerPhone: {
    pending: false,
    error: null,
    succeeded: false
  },
  verifyPhone: {
    data: null,
    pending: false,
    error: null,
    succeeded: false
  },
  recoverPassword: {
    pending: false,
    error: null,
    succeeded: false
  },
  user: {
    data: null,
    cached: false,
    loading: false,
    error: null
  },
  loginCredentials: null
}

export default createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoginRequest(state) {
      state.login.pending = true
      state.login.error = null
      state.login.succeeded = false
    },
    userLoginSuccess(state, { payload: { token, user, isUserCached } }) {
      state.authToken = token
      state.user.data = user
      state.user.cached = isUserCached
      state.login.pending = false
      state.login.succeeded = true
    },
    userLoginFailure(state, { payload }) {
      state.login.pending = false
      state.login.error = payload
    },
    userLoginReset(state) {
      state.login = initialState.login
    },
    userRegisterRequest(state) {
      state.register.error = null
      state.register.succeeded = false
      state.register.pending = true
    },
    userRegisterSuccess(state, { payload }) {
      state.register.data = payload
      state.register.succeeded = true
      state.register.pending = false
    },
    userRegisterFailure(state, { payload }) {
      state.register.error = payload
      state.register.pending = false
    },
    userRegisterReset(state) {
      state.register = initialState.register
    },
    userRegisterPhoneRequest(state) {
      state.registerPhone.error = null
      state.registerPhone.succeeded = false
      state.registerPhone.pending = true
    },
    userRegisterPhoneSuccess(state) {
      state.registerPhone.succeeded = true
      state.registerPhone.pending = false
    },
    userRegisterPhoneFailure(state, { payload }) {
      state.registerPhone.error = payload
      state.registerPhone.pending = false
    },
    userRegisterPhoneReset(state) {
      state.registerPhone = initialState.registerPhone
    },
    setLoginCredentials(state, { payload }) {
      state.loginCredentials = payload
    },
    resetLoginCredentials(state) {
      state.loginCredentials = initialState.loginCredentials
    },
    verifyUserPhoneRequest(state) {
      state.verifyPhone.error = null
      state.verifyPhone.succeeded = false
      state.verifyPhone.pending = true
    },
    verifyUserPhoneSuccess(state) {
      state.verifyPhone.succeeded = true
      state.verifyPhone.pending = false
    },
    verifyUserPhoneFailure(state, { payload }) {
      state.verifyPhone.error = payload
      state.verifyPhone.pending = false
    },
    verifyUserPhoneReset(state) {
      state.verifyPhone = initialState.verifyPhone
    },
    verifyUserPhoneSetEncryptedNumber(state, { payload }) {
      state.verifyPhone.data = { encryptedPhone: payload }
    },
    recoverPasswordRequest(state) {
      state.recoverPassword.error = null
      state.recoverPassword.succeeded = false
      state.recoverPassword.pending = true
    },
    recoverPasswordSuccess(state) {
      state.recoverPassword.succeeded = true
      state.recoverPassword.pending = false
    },
    recoverPasswordFailure(state, { payload }) {
      state.recoverPassword.pending = false
      state.recoverPassword.error = payload
    },
    recoverPasswordReset(state) {
      state.recoverPassword = initialState.recoverPassword
    },
    loadUserRequest(state) {
      state.user.loading = true
      state.user.error = null
    },
    loadUserSuccess(state, { payload }) {
      state.user.loading = false
      state.user.data = payload
      state.user.cached = false
    },
    loadUserFailure(state, { payload }) {
      state.user.loading = false
      state.user.error = payload
    },
    setTermsAccepted(state) {
      state.termsAccepted = true
    }
  }
})