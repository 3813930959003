import React, { memo } from "react"

const GermanIcon = (props) => (
  <svg height="28" viewBox="0 0 28 28" {...props}>
    <path d="M26.1066 18.6974H0.995117L9.35531 26.5403L20.5022 26.0758L26.1066 18.6974Z" fill="#F7BD30"/>
    <path d="M26.1066 10.3203H0.995117V18.6902H26.1066V10.3203Z" fill="#FF387D"/>
    <path d="M20.5024 2.38863L8.891 1.45972L0.99707 10.3246H26.1085L20.5024 2.38863Z" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26802 0 14 0C21.732 0 28 6.26802 28 14C28 21.7319 21.732 28 14 28C6.26802 28 0 21.7319 0 14ZM14 1.99052C7.36735 1.99052 1.99052 7.36735 1.99052 14C1.99052 20.6326 7.36736 26.0095 14 26.0095C20.6327 26.0095 26.0095 20.6326 26.0095 14C26.0095 7.36734 20.6327 1.99052 14 1.99052Z" fill="white"/>
  </svg>
)

export default memo(GermanIcon)
