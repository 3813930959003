import React, { memo } from "react"

const NormalIcon = (props) => (
  <svg height="1em" viewBox="0 0 30 35.02" {...props}>
    <path
      d="M26.72,10.65l2.36-2.37a18,18,0,0,0-2.35-2.35L24.37,8.3a15,15,0,1,0,2.35,2.35ZM15,31.67h0A11.68,11.68,0,0,1,3.34,20.38c0-.13,0-.25,0-.38A11.67,11.67,0,1,1,15,31.67Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      d="M15,8.33v12H3.34c0-.13,0-.25,0-.38A11.68,11.68,0,0,1,15,8.33Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <rect x="10" width="10" height="3.33" fill="#fff" />
    <path
      d="M26.67,20A11.67,11.67,0,0,1,15,31.67h0A11.68,11.68,0,0,1,3.34,20.38H15v-12A11.68,11.68,0,0,1,26.67,20Z"
      transform="translate(0 0)"
      fill="#00aee0"
    />
  </svg>
)

export default memo(NormalIcon)
