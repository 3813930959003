import styled from "styled-components"
import CheckMarkIcon from "../../../../assets/svgs/CheckMarkIcon"
import CrossIcon from "../../../../assets/svgs/CrossIcon"
import EyeCrossedIcon from "../../../../assets/svgs/EyeCrossedIcon"
import EyeIcon from "../../../../assets/svgs/EyeIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../../common/breakpoints"

export const Container = styled.div`
  padding-bottom: 14px;
  border-bottom: 1px solid #8B8A8A;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-bottom: 10px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    padding: 0 8px;
    height: 54px;
    border-bottom: 1px solid #D6D6D6;
  }
`

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  height: 24px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    height: 100%;
    font-size: 13px;
    line-height: 15px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 31px;
  color: #F24556;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 21px;
  }
`

export const Input = styled.input`
  flex: 1;
  border: none;
  box-shadow: none;
  caret-color: rgb(167, 113, 119);
  height: 24px;
  margin-right: 8px;
  color: #4B4B4B;

  ::placeholder {
    color: rgba(75, 75, 75, 0.5);
  }

&::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  &[type=number] {
  -moz-appearance: textfield;
  }

  &[data-error='true'] {
    color: #F24556;

    ::placeholder {
      color: rgba(242, 69, 86, 0.5);
    }
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 16px;
  }
`

export const ToggleShowPasswordButton = styled.button`
  padding: 0;

  :not(:last-child) {
    margin-right: 12px;
  }
`

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 21px;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-width: 15px;
  }
`

export const ValidIcon = styled(CheckMarkIcon)`
  color: #1BD46F;
  font-size: 21px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
  }
`

export const InvalidIcon = styled(CrossIcon)`
  color: #F24556;
  font-size: 26px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 18px;
  }
`

export const ShowPasswordIcon = styled(EyeIcon)`
  font-size: 19px;
  color: #00AEE0;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
  }
`

export const HidePasswordIcon = styled(EyeCrossedIcon)`
  font-size: 22px;
  color: #D0D0D0;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
  }
`