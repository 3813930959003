import {
  ENGLISH_TAGS,
  PORTUGUESE_TAGS,
  CHINESE_TAGS,
  english,
  mandarin,
  portuguese} from './helpers/FlagAndLanguage';

export const formatCardsCount = (count) => {
  const stringCount = count.toString()

  return stringCount.length === 1 ? `0${stringCount}` : stringCount
}

export const getLanguage = (language) => {
  const isEnglish = ENGLISH_TAGS.includes(language);
  const isPortuguese = PORTUGUESE_TAGS.includes(language);
  const isChinese = CHINESE_TAGS.includes(language);

  return isEnglish ? english :
        isChinese ? mandarin :
        isPortuguese ? portuguese : language;
}