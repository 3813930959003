import React, { memo } from "react"
import { BackButton, Container, StyledBackIcon } from "./styles"
import { Dictionary } from "../../../utils/i18n"
import { useTranslation } from "react-i18next"

const TopBackButton = ({ className, onClick }) => {
  const {t} = useTranslation()
  return (
  <Container className={className}>
    <BackButton onClick={onClick}>
      <StyledBackIcon />
      <span>{t(Dictionary.common.back)}</span>
    </BackButton>
  </Container>
)}

export default memo(TopBackButton)
