import React, { memo } from "react"

const ControlsIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 20 16"
    {...props}
  >
    <path d="M6.69306 0C6.36658 0 6.05347 0.129694 5.82261 0.360552C5.59176 0.591409 5.46206 0.904518 5.46206 1.231H1.77006C1.44358 1.231 1.13047 1.36069 0.899614 1.59155C0.668757 1.82241 0.539063 2.13552 0.539062 2.462C0.539063 2.78848 0.668757 3.10159 0.899614 3.33245C1.13047 3.56331 1.44358 3.693 1.77006 3.693H5.46206C5.46206 3.85466 5.4939 4.01473 5.55577 4.16408C5.61763 4.31344 5.70831 4.44914 5.82261 4.56345C5.93692 4.67776 6.07263 4.76843 6.22198 4.8303C6.37133 4.89216 6.5314 4.924 6.69306 4.924C6.85472 4.924 7.01479 4.89216 7.16415 4.8303C7.3135 4.76843 7.4492 4.67776 7.56351 4.56345C7.67782 4.44914 7.76849 4.31344 7.83036 4.16408C7.89222 4.01473 7.92406 3.85466 7.92406 3.693V1.231C7.92406 0.904518 7.79437 0.591409 7.56351 0.360552C7.33265 0.129694 7.01954 0 6.69306 0Z" fill="currentColor"/>
    <path d="M17.7702 6.76901H15.3082C15.3129 6.60447 15.2845 6.44065 15.2248 6.28726C15.165 6.13387 15.0752 5.99402 14.9604 5.87598C14.8457 5.75793 14.7085 5.6641 14.5569 5.60002C14.4052 5.53595 14.2423 5.50293 14.0777 5.50293C13.9131 5.50293 13.7501 5.53595 13.5985 5.60002C13.4469 5.6641 13.3097 5.75793 13.1949 5.87598C13.0802 5.99402 12.9903 6.13387 12.9306 6.28726C12.8709 6.44065 12.8425 6.60447 12.8472 6.76901V9.23101C12.8425 9.39555 12.8709 9.55937 12.9306 9.71276C12.9903 9.86615 13.0802 10.006 13.1949 10.1241C13.3097 10.2421 13.4469 10.3359 13.5985 10.4C13.7501 10.4641 13.9131 10.4971 14.0777 10.4971C14.2423 10.4971 14.4052 10.4641 14.5569 10.4C14.7085 10.3359 14.8457 10.2421 14.9604 10.1241C15.0752 10.006 15.165 9.86615 15.2248 9.71276C15.2845 9.55937 15.3129 9.39555 15.3082 9.23101H17.7702C18.0967 9.23101 18.4098 9.10132 18.6406 8.87046C18.8715 8.6396 19.0012 8.3265 19.0012 8.00002C19.0012 7.67353 18.8715 7.36042 18.6406 7.12956C18.4098 6.89871 18.0967 6.76901 17.7702 6.76901Z" fill="currentColor"/>
    <path d="M17.7701 1.23096H9.77007C9.44358 1.23096 9.13048 1.36065 8.89963 1.59151C8.66877 1.82237 8.53906 2.13547 8.53906 2.46196C8.53906 2.78844 8.66877 3.10155 8.89963 3.33241C9.13048 3.56326 9.44358 3.69296 9.77007 3.69296H17.7701C18.0965 3.69296 18.4097 3.56326 18.6405 3.33241C18.8714 3.10155 19.0011 2.78844 19.0011 2.46196C19.0011 2.13547 18.8714 1.82237 18.6405 1.59151C18.4097 1.36065 18.0965 1.23096 17.7701 1.23096Z" fill="currentColor"/>
    <path d="M11.0011 6.76904H1.77006C1.44358 6.76904 1.13047 6.89874 0.899614 7.12959C0.668757 7.36045 0.539063 7.67356 0.539062 8.00005C0.539063 8.32653 0.668757 8.63963 0.899614 8.87048C1.13047 9.10134 1.44358 9.23103 1.77006 9.23103H11.0011C11.3275 9.23103 11.6407 9.10134 11.8715 8.87048C12.1024 8.63963 12.2321 8.32653 12.2321 8.00005C12.2321 7.67356 12.1024 7.36045 11.8715 7.12959C11.6407 6.89874 11.3275 6.76904 11.0011 6.76904Z" fill="currentColor"/>
    <path d="M17.77 12.3081H12.847C12.5205 12.3081 12.2074 12.4378 11.9765 12.6687C11.7457 12.8995 11.616 13.2126 11.616 13.5391C11.616 13.8656 11.7457 14.1787 11.9765 14.4095C12.2074 14.6404 12.5205 14.7701 12.847 14.7701H17.77C18.0965 14.7701 18.4096 14.6404 18.6404 14.4095C18.8713 14.1787 19.001 13.8656 19.001 13.5391C19.001 13.2126 18.8713 12.8995 18.6404 12.6687C18.4096 12.4378 18.0965 12.3081 17.77 12.3081Z" fill="currentColor"/>
    <path d="M9.77006 11.0769C9.44358 11.0769 9.13047 11.2066 8.89961 11.4375C8.66876 11.6683 8.53906 11.9814 8.53906 12.3079H1.77006C1.44358 12.3079 1.13047 12.4376 0.899614 12.6685C0.668757 12.8993 0.539063 13.2124 0.539062 13.5389C0.539063 13.8654 0.668757 14.1785 0.899614 14.4094C1.13047 14.6402 1.44358 14.7699 1.77006 14.7699H8.53906C8.53906 15.0964 8.66876 15.4095 8.89961 15.6404C9.13047 15.8712 9.44358 16.0009 9.77006 16.0009C10.0965 16.0009 10.4097 15.8712 10.6405 15.6404C10.8714 15.4095 11.0011 15.0964 11.0011 14.7699V12.3079C11.0011 11.9814 10.8714 11.6683 10.6405 11.4375C10.4097 11.2066 10.0965 11.0769 9.77006 11.0769Z" fill="currentColor"/>
  </svg>
)

export default memo(ControlsIcon)