import styled from "styled-components"
import SwipeIcon from "./SwipeIcon"

export const CardYShift = 50

export const Container = styled.div`
  position: relative;
`

export const DimensionsContainer = styled.div`
  padding: 0 25px 68px 25px;
`

export const ItemBox = styled.div`
  padding: 0 10px;
  max-width: calc(100vmin - 50px);
`

export const CardBox = styled.div`
  position: relative;
`

export const CardBoxHeight = styled.div`
  padding-bottom: 80%;
`

export const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export const ScrollContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  height: 100%;
  user-select: none;
  touch-action: none;
`

export const ListItemContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 8px 10px 0 10px;
`

export const StatusContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
`

export const StatusInnerContainer = styled.div`
  flex: 0 1 calc(100vmin - 78px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StatusInnerLeft = styled.div`
  display: flex;
  align-items: center;
  transition: none;

  &[data-visible='false'] {
    opacity: 0;
  }
`

export const StatusCounter = styled.div`
  font-weight: bold;
  font-size: 10px;
  color: #FFF6F6;
  min-width: 30px;
  margin-right: 12px;
`

export const StyledSwipeIcon = styled(SwipeIcon)`
  font-size: 28px;
  color: white;
`

export const ListViewButton = styled.button`
  width: 93px;
  height: 30px;
  border-radius: 24px;
  border: 2px solid #FFFFFF;
  color: white;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0px;

  :active {
    opacity: 0.7;
  }
`

const DataStatusContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    transform: translateY(-25px);
  }
`

export const SpinnerContainer = styled(DataStatusContainer)`
  svg {
    stroke: white;
  }
`

export const PlaceholderContainer = styled(DataStatusContainer)`
  color: white;
  opacity: 0.85;
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding: 40px 60px;
`
