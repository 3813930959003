import React, { memo, useMemo } from "react"
import { useFormikContext } from "formik"
import FormStatus from "../Common/FormStatus"
import InputField from "../Common/InputField"
import { EmailIcon, InputGroup, PasswordIcon, StyledForm, SubmitButton, TopFormPart } from "../Common/styles"
import { RegisterButtonContainer, UserIcon } from "./styles"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n"
import useOnChange from "../../../hooks/useOnChange"

const RegisterForm = ({ pending, error, succeeded }) => {
  const formik = useFormikContext()
  const { t } = useTranslation()

  const fieldNames = {
    firstName: t(Dictionary.register.firstName),
    lastName: t(Dictionary.register.lastName),
    email: t(Dictionary.register.email),
    confirmEmail: t(Dictionary.register.confirmEmail),
    password: t(Dictionary.register.password),
    confirmPassword: t(Dictionary.register.confirmPassword)
  }

  const status = useMemo(() => {
    // if (succeeded) {
    //   return {
    //     type: "success",
    //     title: t(Dictionary.common.thankYou),
    //     message: t(Dictionary.register.success)
    //   }
    // }

    if (error) {
      return {
        type: "error",
        title: t(Dictionary.common.oops),
        message: error.message
      }
    }

    return null
  }, [error, t])

  useOnChange(() => {
    if (!pending) {
      formik.setSubmitting(false)
    }
  }, [pending])

  return (
    <StyledForm>
      <TopFormPart>
        <FormStatus fieldNames={fieldNames} status={status} />
        <InputGroup>
          <InputField
            name="firstName"
            type="text"
            placeholder={fieldNames.firstName}
            autoCapitalize="words"
            icon={<UserIcon />}
          />
          <InputField
            name="lastName"
            type="text"
            placeholder={fieldNames.lastName}
            autoCapitalize="words"
            icon={<UserIcon />}
          />
          <InputField
            name="email"
            type="email"
            placeholder={fieldNames.email}
            icon={<EmailIcon />}
          />
          <InputField
            name="confirmEmail"
            type="email"
            placeholder={fieldNames.confirmEmail}
            disablePaste
            icon={<EmailIcon />}
          />
          <InputField
            name="password"
            type="password"
            placeholder={fieldNames.password}
            icon={<PasswordIcon />}
          />
          <InputField
            name="confirmPassword"
            type="password"
            placeholder={fieldNames.confirmPassword}
            disablePaste
            icon={<PasswordIcon />}
          />
        </InputGroup>
        <RegisterButtonContainer>
          <SubmitButton primary type="submit" loading={formik.isSubmitting}>
            {t(Dictionary.common.send)}
          </SubmitButton>
        </RegisterButtonContainer>
      </TopFormPart>
    </StyledForm>
  )
}

export default memo(RegisterForm)
