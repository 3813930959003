import { useEffect, useRef } from "react"

export default (value, reset = false) => {
  const prevValue = useRef(value)

  useEffect(() => {
    prevValue.current = reset ? value : value ?? prevValue.current
  }, [value, reset])

  return reset ? value : value ?? prevValue.current
}
