import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a { fill: #00aee0; stroke: rgba(255,255,255,0.64); }
  .b { fill: #fff; stroke: #fff; }
  .c { stroke: none; }
  .d { fill: none; }
`

const OverdueCardsIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 54 54" {...props}>
    <g transform="translate(0 20)">
      <g transform="translate(0 -20)">
        <g className="a">
          <rect className="c" width="54" height="54" rx="27"/>
          <rect
            className="d" x="0.5" y="0.5" width="53"
            height="53" rx="26.5"
          />
        </g>
      </g>
      <g transform="translate(18.686 -5.016)">
        <path className="b" d="M16.054,21.918H1.689A1.659,1.659,0,0,1,0,20.294V1.622A1.659,1.659,0,0,1,1.689,0H16.054a1.659,1.659,0,0,1,1.691,1.622V20.294A1.66,1.66,0,0,1,16.054,21.918ZM1.019.752a1.386,1.386,0,0,0-.24.87V20.294a1.381,1.381,0,0,0,.24.875H8.637a1.44,1.44,0,0,0,.235-.875V1.622a1.422,1.422,0,0,0-.235-.87Z"/>
      </g>
    </g>
  </StyledSvg>
)

export default memo(OverdueCardsIcon)
