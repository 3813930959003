import styled from "styled-components"
import AudioPlayer from "../../../common/components/AudioPlayer"

export const MinBarHeight = 40

export const Container = styled.div`
  background-color: #F8F8F5;
  box-shadow: 3px 3px 6px #00000040;
  border-radius: 20px;
  margin-bottom: 16px;
`

export const SideAContainer = styled.div`
  padding: 20px 16px 20px 20px;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 11px;
`

export const ForeignText = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #050304;
  margin-top: 2px;
`

export const FlipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #8E8E8E;
  padding: 0;
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin: -14px -12px -14px 0;
`

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TranslatedText = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9B9B9B;
  margin-right: 8px;
`

export const StyledPlayer = styled(AudioPlayer)`
  .audio-player__duration {
    display: none;
  }

  .audio-player__waveform {
    display: none;
  }

  .audio-player__play-button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
`

export const SideBContainer = styled.div`
  color: #383838;
`

export const SideBTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
  margin-bottom: 48px;
`

export const ReviewCount = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 23px;
`

export const MemoryType = styled.div`
  font-size: 10px;
  line-height: 18px;
  margin-top: 4px;
`

export const SideBFlipButton = styled(FlipButton)`
  margin: 6px 4px -14px 0;
`

export const BarsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 113px;
  padding: 0 16px;
`

export const Bar = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #1BD46F;
  border-radius: 10px 10px 0px 0px;

  :not(:last-child) {
    margin-right: 16px;
  }
`

export const BarName = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: white;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const BarValue = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -26px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
`
