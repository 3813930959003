import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"

export const BEFORE_CHANGE = "beforeChange"
export const CHANGING = "changing"
export const CHANGED = "changed"

export default (value, duration = 500, delay = 0) => {
  const currentValue = useRef(value)
  const nextValue = useRef(null)
  const [transitionState, setTransitionState] = useState(CHANGED)

  const firstDuration = typeof duration === "object" ? duration.first : duration
  const changeDuration = typeof duration === "object" ? duration.change : duration
  const lastDuration = typeof duration === "object" ? duration.last : duration
  const firstDelay = typeof delay === "object" ? delay.first : delay
  const changeDelay = typeof delay === "object" ? delay.change : delay
  const lastDelay = typeof delay === "object" ? delay.last : delay

  const switchAfterDelay = useCallback((newState, switchDelay, beforeSwitch = null) => {
    const doSwitch = () => {
      if (beforeSwitch) {
        beforeSwitch()
      }
      setTransitionState(newState)
    }

    if (switchDelay === 0) {
      doSwitch()
      return undefined
    }

    const timeoutId = setTimeout(doSwitch, switchDelay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useLayoutEffect(() => {
    if (transitionState === CHANGED && !Object.is(value, currentValue.current)) {
      let switchDelay = changeDelay

      if (!currentValue.current) {
        switchDelay = firstDelay
      } else if (!value) {
        switchDelay = lastDelay
      }

      return switchAfterDelay(BEFORE_CHANGE, switchDelay, () => {
        nextValue.current = value
      })
    }

    return undefined
  }, [value, firstDelay, changeDelay, lastDelay, transitionState, switchAfterDelay])

  useEffect(() => {
    switch (transitionState) {
      case BEFORE_CHANGE:
        return switchAfterDelay(CHANGING, 20)
      case CHANGING: {
        let switchDuration = changeDuration

        if (!currentValue.current) {
          switchDuration = firstDuration
        } else if (!nextValue.current) {
          switchDuration = lastDuration
        }

        return switchAfterDelay(CHANGED, switchDuration, () => {
          currentValue.current = nextValue.current
          nextValue.current = null
        })
      }
      default:
        return undefined
    }
  }, [transitionState, firstDuration, changeDuration, lastDuration, switchAfterDelay])

  return {
    value: currentValue.current,
    nextValue: nextValue.current,
    changeState: transitionState
  }
}
