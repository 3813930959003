import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const SupportIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 18 18" {...props}>
    <g transform="translate(1 1)">
      <path className="a" d="M19,11a8,8,0,1,1-8-8A8,8,0,0,1,19,11Z" transform="translate(-3 -3)"/>
      <path className="a" d="M13.635,12.1a2.4,2.4,0,0,1,4.664.8c0,1.6-2.4,2.4-2.4,2.4" transform="translate(-7.963 -6.497)"/>
      <path className="a" d="M18,25.5h0" transform="translate(-10 -13.5)"/>
    </g>
  </StyledSvg>
)

export default memo(SupportIcon)
