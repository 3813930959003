import React, { memo } from "react"
import { useRouteMatch } from "react-router-dom"
import {
  PanelContainer,
  BackgroundImage,
  MenuContent,
  TopRow,
  StyledLogo,
  CollapseButton,
  StyledCollapseIcon,
  SectionTitle,
  MenuSection,
  MenuItem,
  StyledDashboardIcon,
  ItemIconContainer,
  StyledSupportIcon,
  StylesTermsIcon,
  StyledLogOutIcon,
  Separator,
  LogOutMenuItem,
  StyledBottomLogo
} from "./styles"
import menuBg from "./images/menu.png"
import { routes } from "../../../utils/constants"
import { links } from "../../../constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "../../../utils/i18n"
import { FBAnalyticsClearUserData } from "./../../../firebase/logEvents";

const Navigation = ({ expanded, toggleExpanded }) => {
  const match = useRouteMatch()
  const {t} = useTranslation()

  const dashboardIsActive =
    match.path.indexOf(routes.mergePage) === 0 ||
    match.path.indexOf(routes.deckPage) === 0 ||
    match.path.indexOf(routes.studySessionStats) === 0

  const handleSupportClick = (event) => {
    event.preventDefault()
    window.open(links.support, "_blank")
  }

  const handleTermsClick = (event) => {
    event.preventDefault()
    window.open(links.termsOfUse, "_blank")
  }

  const handleLogoutClick = (event) => {
    FBAnalyticsClearUserData()
  }

  return (
    <PanelContainer data-expanded={expanded}>
      <BackgroundImage src={menuBg} />
      <MenuContent>
        <TopRow>
          <StyledLogo />
          <CollapseButton onClick={toggleExpanded}>
            <StyledCollapseIcon />
          </CollapseButton>
        </TopRow>
        <SectionTitle>{t(Dictionary.menu.title)}</SectionTitle>
        <MenuSection>
          <MenuItem to={routes.dashboard} className={dashboardIsActive ? "active" : ""}>
            <ItemIconContainer>
              <StyledDashboardIcon />
            </ItemIconContainer>
            <span>{t(Dictionary.menu.dashboard)}</span>
          </MenuItem>
        </MenuSection>
        <Separator />
        <SectionTitle>{t(Dictionary.settings.title)}</SectionTitle>
        <MenuSection>
          <MenuItem to="/support" onClick={handleSupportClick}>
            <ItemIconContainer>
              <StyledSupportIcon />
            </ItemIconContainer>
            <span>{t(Dictionary.settings.support)}</span>
          </MenuItem>
          <MenuItem to="/terms-of-use" onClick={handleTermsClick}>
            <ItemIconContainer>
              <StylesTermsIcon />
            </ItemIconContainer>
            <span>{t(Dictionary.settings.terms)}</span>
          </MenuItem>
          <LogOutMenuItem to={{
            pathname: "/login",
            state: { logout: true }
          }}
          onClick={handleLogoutClick}
          >
            <ItemIconContainer>
              <StyledLogOutIcon />
            </ItemIconContainer>
            <span>{t(Dictionary.settings.logout)}</span>
          </LogOutMenuItem>
        </MenuSection>
      </MenuContent>
      <StyledBottomLogo />
    </PanelContainer>
  )
}

export default memo(Navigation)
