import styled from "styled-components"
import handImage from "../../../../assets/graphics/login/mobile-top-bar-hand.png"
import iosImage from "../../../../assets/graphics/login/ios-app-dl-cta-mobile-2x.png"
import androidImage from "../../../../assets/graphics/login/android-app-dl-cta-mobile-2x.png"

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 138px;
  padding: 22px 87px 0 18px;
`

export const HandImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 87px;
  height: 148px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${handImage});
`

export const Text = styled.div`
  font-size: 11px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.74);
  margin-bottom: 13px;

  strong {
    font-weight: bold;
    font-style: italic;
  }
`

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
`

const LinkImage = styled.div`
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`

export const LinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  height: 31px;
  padding: 0 5px;
  margin-right: 9px;
`

export const IosLinkImage = styled(LinkImage)`
  width: 73px;
  height: 26px;
  background-image: url(${iosImage});
`

export const AndroidLinkImage = styled(LinkImage)`
  width: 78px;
  height: 23px;
  background-image: url(${androidImage});
`