import React, { memo } from "react"
import { Container, FlagWrapper, Circle } from "./styles"
import { flagComponents } from './../../../utils/helpers/FlagAndLanguage';

const Flag = ({ className, language }) => {
  const FlagComponent = flagComponents[language]

  return (
    <Container className={className} data-hidden={!FlagComponent}>
      <FlagWrapper>
        {FlagComponent && <FlagComponent />}
      </FlagWrapper>
      <Circle />
    </Container>
  )
}

export default memo(Flag)
