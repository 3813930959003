import styled, { createGlobalStyle, css } from "styled-components"
import noDecksBg from "../images/no-decks.png"
import noStatsBg from "../images/no-stats-bg.png"

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    overflow: visible;
  }

  body {
    background-color: #00AEE0;
  }
`

export const TopContainer = styled.div`
  background: linear-gradient(180deg, #FF5266 0%, #FF4A6C 100%);
  box-shadow: 0px 3px 6px #74072C2B;
  // border-radius: 0px 0px 20px 20px;
  ${props => props.noDecks ? 'height: calc(100vh - 44px);' : '' }
`

export const TopContent = styled.div`
  padding: 51px 0 50px 0;
`

const TitleFont = css`
  font-family: "BlackerDisplayExtraBold";
  font-weight: bold;
  color: white;
  font-size: 56px;
  line-height: 1;
`

export const Title = styled.div`
  ${TitleFont}
  padding: 0 41px;
  margin-bottom: 8px;
`

export const DropdownRow = styled.div`
  display: flex;
  padding: 0 41px;
  margin-bottom: 4px;

  > :not(:last-child) {
    margin-right: 19px;
  }
`

export const NoDecksContent = styled.div`
  padding: 4px 0 38px 0;
`

export const NoDecksImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export const NoDecksImageContainer = styled.div`
  background: center right / contain url("${noDecksBg}") no-repeat;
  margin: 0 -60px 19px 23px;
  height: 356px;
  box-sizing: border-box;
`

export const NoDecksTitle = styled.div`
  ${TitleFont}
  padding: 64px 0 0 18px;
`

export const NoDecksBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NoDecksNotice = styled.div`
  color: white;
  opacity: 0.65;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  text-align: center;
  padding: 0 25px;
`

export const StatisticsContainer = styled.div`
  padding-top: 70px;
  overflow: hidden;
`

export const StatisticsTitle = styled.div`
  position: relative;
  margin-left: 40px;
  color: white;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  > span {
    position: relative;
  }
`

export const StatisticsShadowTitle = styled.div`
  position: absolute;
  font-family: "BlackerDisplayHeavy";
  color: #1C9BE4;
  font-size: 56px;
  left: 31px;
  top: 2px;
`

export const NoStatsImage = styled.img.attrs({
  src: noStatsBg
})`
  display: block;
  margin: -4px auto -14px auto;
  width: 294px;
  height: 294px;
`

export const NoStatsNotice = styled.div`
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: white;
  margin: 0 36px;
  opacity: 0.65;
  margin-bottom: 36px;
`
