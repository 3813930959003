import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash-es"
import { GradeStatus, Grade } from "../../constants"
// import { sessionCardsCount } from "../selectors/common"

const initialState = {
  sessionsById: {},
  deckNameById: {},
  viewSession: {
    loading: true,
    loadError: null,
    currentGradeCardId: null,
    currentGrade: null,
    gradeStatus: null,
    gradeError: null,
    nextDue: null,
    pendingCompletion: false
  }
}

export const makeSessionId = (type, id) => `${type}-${id}`

export default createSlice({
  name: "sessions",
  initialState,
  reducers: {
    startSessionRequest(state) {
      state.viewSession.loading = true
      state.viewSession.error = null
    },
    startSessionSuccess(state, { payload: { id, type, data } }) {
      const sessionData = { ...data, currentCardIndex: 0, repeatQueue: [] }

      state.sessionsById[makeSessionId(type, id)] = sessionData
      state.viewSession.loading = false
      state.deckNameById[makeSessionId(type, id)] = data.name
    },
    startSessionFailure(state, { payload: error }) {
      state.viewSession.loading = false
      state.viewSession.loadError = error
    },
    gradeCardRequest(state, { payload: { cardId, grade } }) {
      state.viewSession.currentGradeCardId = cardId
      state.viewSession.currentGrade = grade
      state.viewSession.gradeStatus = GradeStatus.Pending
      state.viewSession.gradeError = null
      state.viewSession.nextDue = null
    },
    gradeCardSuccess(state, { payload: { id, type, cardId, mode, nextDue, grade } }) {
      state.viewSession.gradeStatus = GradeStatus.Succeeded
      state.viewSession.nextDue = nextDue

      const session = state.sessionsById[makeSessionId(type, id)]

      if (!session) {
        return
      }

      const cardIndex = session.queue.findIndex((item) => item.card.id === cardId && !item.card.isStudied);

      if (cardIndex !== -1) {
        const studyAgain = cloneDeep(session.queue[cardIndex])
        // const cardsCount = sessionCardsCount(mode, session.queue.length)

        // if ((session.currentCardIndex + 1) >= cardsCount && session.repeatQueue.length > 0) {
        //   session.queue = session.repeatQueue
        //   session.currentCardIndex = 0
        //   session.repeatQueue = []
        // } else {
        // state.studiedCards.push({...studyAgain, due: nextDue})
        if (grade === Grade.Repeat) {
          studyAgain.card.isStudied = false;
          session.queue.push(studyAgain)
        }
        session.queue[cardIndex].card.isStudied = true;
        session.queue[cardIndex].card.due = nextDue;
        session.queue[cardIndex].card.grade = grade;
        session.currentCardIndex = cardIndex + 1;
        // }

      }
    },
    gradeCardFailure(state, { payload: error }) {
      state.viewSession.currentGrade = null
      state.viewSession.gradeStatus = null
      state.viewSession.gradeError = error
    },
    gradeCardReset(state) {
      state.viewSession.currentGradeCardId = null
      state.viewSession.currentGrade = null
      state.viewSession.gradeStatus = null
      state.viewSession.gradeError = null
      state.viewSession.nextDue = null
    },
    completeSessionRequest(state) {
      state.viewSession.pendingCompletion = true
    },
    completeSessionSuccess(state, { payload: { id, type } }) {
      delete state.sessionsById[makeSessionId(type, id)]
      state.viewSession.pendingCompletion = false
    },
    completeSessionFailure(state) {
      state.viewSession.pendingCompletion = false
    },
    viewSessionReset(state) {
      state.viewSession = initialState.viewSession
    }
  }
})