import styled from "styled-components"
import { MOBILE_MAX } from "../../../common/breakpoints"

export const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 35px 16px 35px;
  height: 30px;
  font-size: 14px;
  color: #8B8A8A;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &[data-show='true'] {
    opacity: 1;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    display: none;
  }
`

export const TipRow = styled.div`
  display: flex;
  align-items: center;
`

export const SpaceCharacter = styled.span`
  font-size: 18px;
  font-weight: bold;
  transform: translateY(-12px);
`

export const AsteriskCharacter = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-right: 10px;
`
