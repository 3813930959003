import { breakpoint } from "./breakpoint"
import { pxToInt } from "../../utils/helpers/css"

export const screen = {
  mobileKeyboardClosed: `(min-height: 320px)`,
  mobile: `(min-width: ${breakpoint.mobile})`,
  mobileRetina: `(min-resolution: 192dpi) and (min-width: ${breakpoint.mobile}) and (max-width: ${breakpoint.tablet}),
  (min-resolution: 2dppx) and (min-width: ${breakpoint.mobile}) and (max-width: ${breakpoint.tablet})`,
  tablet: `(min-width: ${breakpoint.tablet})`,
  small: `(min-width: ${breakpoint.small})`,
  medium: `(min-width: ${breakpoint.medium})`,
  wide: `(min-width: ${breakpoint.wide})`,
  wideRetina: `(min-resolution: 192dpi) and (min-width: ${breakpoint.wide}) and (max-width: ${breakpoint.big}),
  (min-resolution: 2dppx) and (min-width: ${breakpoint.wide}) and (max-width: ${breakpoint.big})`,
  big: `(min-width: ${breakpoint.big})`
}

export const screenWidth = {
  mobile: pxToInt(breakpoint.mobile),
  tablet: pxToInt(breakpoint.tablet),
  small: pxToInt(breakpoint.small),
  medium: pxToInt(breakpoint.medium),
  wide: pxToInt(breakpoint.wide),
  big: pxToInt(breakpoint.big)
}
