import React, { memo } from "react"
import { useTranslation } from "react-i18next"
import FormStatus from "./FormStatus"
import { BottomFormPart, SubmitButton, TopFormPart } from "../Common/styles"
import { BgImage, ContentContainer, Description, RegisterButtonContainer } from "./styles"
import { Dictionary } from "../../../utils/i18n"

const VerifyPhoneCompleteForm = ({ onFinish }) => {
  const { t } = useTranslation()

  return (
    <ContentContainer>
      <TopFormPart>
        <FormStatus />
        <Description>{t(Dictionary.register.phoneNumberVerified)}</Description>
        <BgImage />
      </TopFormPart>
      <BottomFormPart>
        <RegisterButtonContainer>
          <SubmitButton primary style={{ zIndex: 10 }} onClick={onFinish}>
            {t(Dictionary.register.finish)}
          </SubmitButton>
        </RegisterButtonContainer>
      </BottomFormPart>
    </ContentContainer>
  )
}

export default memo(VerifyPhoneCompleteForm)
