const debug = process.env && process.env.NODE_ENV && process.env.NODE_ENV === `production`;
const verbose = process.env && process.env.NODE_ENV && process.env.NODE_ENV === `production`;
const levels = {
  info: "info",
  warn: "warn",
  error: "error"
}
/**
 * * printer
 * defines log printer
 * @function printer(@param level, @param className, @param functionName, @param message)
 * @param level: defines level of printer
 * @param className: defines className of printer
 * @param functionName: defines functionName of printer
 * @param message: defines message of printer
 */
const printer = (level, className, functionName, message) => {
  const msg = `${level}:${className}.${functionName}: ${message}`
  if (level === levels.error) {
    console.error(msg)
  } else {
    console.error(msg)
  }
}
/**
 * * printError
 * prints error
 * @function printError(@param className, @param functionName, @param message)
 */
const printError = (className, functionName, message) => {
  if (debug) {
    printer(levels.error, className, functionName, message)
  }
}
/**
 * * printInfo
 * prints info
 * @function printInfo(@param className, @param functionName, @param message)
 */
const printInfo = (className, functionName, message) => {
  if (verbose && debug) {
    printer(levels.info, className, functionName, message)
  }
}
/**
 * * printWarning
 * prints warning
 * @function printWarning(@param className, @param functionName, @param message)
 */
const printWarning = (className, functionName, message) => {
  if (debug) {
    printer(levels.warn, className, functionName, message)
  }
}

export const Log = {
  info: printInfo,
  warn: printWarning,
  error: printError
}
