import React from "react"
import { useLocation, Redirect } from "react-router"
import { useDispatch } from "react-redux"
import qs from "query-string"

import * as referralActions from "../../store/actions/referral"

export default function ReferralRoute(props) {
  const location = useLocation()
  const { pathname, search } = location
  const params = qs.parse(search)
  const dispatch = useDispatch()

  if (search !== "") {
    dispatch(referralActions.routeReferral({ pathname, params}))
    return <Redirect to={pathname}/>
  }

  return props.children
}
