import styled from "styled-components"
import { LAPTOP_MAX } from "../../../common/breakpoints"

const timing = "0.25s ease-out"
const right = "right"

export const Container = styled.div`
  position: absolute;
  box-shadow: 0px 3px 60px #00000064;
  border-radius: 15px;
  overflow: hidden;
  width: 248px;
  color: black;
  background: linear-gradient(159deg, #FF7B62 0%, #FF4B08 100%);
  opacity: 0;
  transition: 
    transform ${timing}, 
    opacity ${timing}, 
    left ${timing}, 
    top ${timing}, 
    bottom ${timing}, 
    ${right} ${timing};

  @media (max-width: ${LAPTOP_MAX}px) {
    box-shadow: 0px 3px 42px #00000064;
    border-radius: 10px;
    width: 176px;
  }

  &[data-show='true'] {
    opacity: 1;
  }

  &&[data-disabled='true'] {
    background: #1A4267;
  }
`

export const InfoContainer = styled.div`
  padding: 32px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 22px;
  }

  ${Container}[data-disabled='true'] & {
    color: #ffffff30;
    padding-bottom: 23px;

    @media (max-width: ${LAPTOP_MAX}px) {
      padding-bottom: 16px;
    }
  }
`

export const InfoStickerTitle = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0px;
  margin-bottom: 10px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 7px;
  }
`

export const InfoStickerDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 14px;
  }
`

export const NonAvailableNotice = styled.div.attrs({
  className: "info-sticker_non-available"
})`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: white;
  padding: 15px 32px 12px 32px;
  background: linear-gradient(117deg, #FF7B62 0%, #FF4B08 100%);

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 10px;
    line-height: 12px;
    padding: 11px 22px 8px 22px;
  }
`
