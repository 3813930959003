export const baseURL = process?.env?.REACT_APP_API_BASE_URL ? process?.env?.REACT_APP_API_BASE_URL : process?.env?.NODE_ENV === `production` ? `https://hackit.app` : `https://dev.hackit.app`

export const defaultHeaders = {
  "Content-Type": "application/json"
}

export const endpoints = {
  decks: `/webapi/v2/decks`,
  auth: `/webapi/v3/auth`,
  users: `/webapi/v3/users`,
  register: (organizationId) => `/register/${organizationId}`,
  userPhone: (email) => `/webapi/v3/auth/${email}/phone`,
  sendVerificationCode: (email, phone) => `/webapi/v3/auth/${email}/phone/${phone}/code`,
  verifyPhone: (email, code) => `/webapi/v3/auth/${email}/phone/${code}`,
  recover: (email) => `/webapi/v3/utils/emails/${email}?action=recover`,
  changePassword: (email) => `/webapi/v3/users/${email}`,
  masterDecks: (userId) => `/webapi/v3/users/${userId}/merges`,
  userDecks: (userId) => `/webapi/v3/users/${userId}/decks`,
  userData: (params) => `/webapi/v3/auth?${params}`,
  deck: (deckId) => `/webapi/v2/decks/${deckId}`,
  deckReferral: (virtualDeckId) => `/webapi/v3/decks/${virtualDeckId}`,
  deckCards: (deckId) => `/webapi/v2/decks/${deckId}/cards`,
  deckMeta: (deckId) => `/webapi/v2/decks/${deckId}/meta`,
  merge: (userId, mergeId) => `/webapi/v3/users/${userId}/merges/${mergeId}`,
  mergeCards: (userId, mergeId) => `/webapi/v3/users/${userId}/merges/${mergeId}/cards`,
  deckSession: (virtualId) => `/webapi/v3/sessions/decks/${virtualId}`,
  deckSessionCard: (virtualId, cardId) => `/webapi/v3/sessions/decks/${virtualId}/cards/${cardId}`,
  mergeSession: (mergeId) => `/webapi/v3/sessions/merges/${mergeId}`,
  mergeSessionCard: (mergeId, cardId) => `/webapi/v3/sessions/merges/${mergeId}/cards/${cardId}`,
  subscribeToDeck: (deckId, unitName, unitThumbUrl, mergeName, mergeThumbUrl, userId) =>
    `/webapi/v3/kajabi/buttons/merge?deckId=${deckId}&unitName=${encodeURIComponent(unitName)}&unitThumbUrl=${encodeURIComponent(unitThumbUrl)}
&mergeName=${encodeURIComponent(mergeName)}&mergeThumbUrl=${encodeURIComponent(mergeThumbUrl)}&userId=${userId}`
}

export const errorsCodes = {
  NoTokenFoundError: 0,
  NetworkingError: 1,
  InternalServerError: 2,
  AuthenticationException: 3,
  TermsAndConditionsNotAcceptedError: 4
}
