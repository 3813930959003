import { Grade } from "../../../constants"
import { Dictionary } from "../../../utils/i18n"

export const GradeLabels = {
  [Grade.Easy]: {
    label: "FÁCIL",
    tip: "Digite 1",
    tipNumber: "1",
    translationLabel: Dictionary.studySession.easy
  },
  [Grade.Good]: {
    label: "BOM",
    tip: "Digite 2",
    tipNumber: "2",
    translationLabel: Dictionary.studySession.good
  },
  [Grade.Difficult]: {
    label: "DIFÍCIL",
    tip: "Digite 3",
    tipNumber: "3",
    translationLabel: Dictionary.studySession.hard
  },
  [Grade.Repeat]: {
    label: "NÃO LEMBRO",
    tip: "Digite 4",
    tipNumber: "4",
    translationLabel: Dictionary.studySession.forgot
  }
}

export const GradesOrder = [Grade.Easy, Grade.Good, Grade.Difficult, Grade.Repeat]

export const GradeForKey = {
  Digit1: Grade.Easy,
  Digit2: Grade.Good,
  Digit3: Grade.Difficult,
  Digit4: Grade.Repeat
}
