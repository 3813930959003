import React, { memo, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { MOBILE_MAX } from "../../../common/breakpoints"
import { GradeStatus } from "../../../constants"
import GradeButton from "./GradeButton"
import { GradeButtonsContainer } from "./styles"
import { GradesOrder, GradeForKey } from './constants';

const GradeButtons = ({
  className,
  transition,
  disabled,
  currentGrade,
  gradeStatus,
  nextDue,
  onGrade
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const gradesOrder = GradesOrder;
  const gradeForKey = GradeForKey;

  useEffect(() => {

    const handleKeyUp = (event) => {
      event.preventDefault();

      if (disabled) {
        return undefined
      }

      const grade = gradeForKey[event.code]
      const isDisabled = (gradeStatus === GradeStatus.Pending && currentGrade !== grade) || gradeStatus === GradeStatus.Succeeded;

      if (grade !== undefined && !isDisabled) {
        onGrade(grade)
      }
    }

    window.addEventListener("keyup", handleKeyUp)
    return () => { window.removeEventListener("keyup", handleKeyUp) }
  // eslint-disable-next-line
  }, [disabled, onGrade]);

  return (
    <GradeButtonsContainer
      className={className}
      data-disabled={disabled}
    >
      {gradesOrder.map((grade, index) => (
        <GradeButton
          key={grade}
          index={index}
          transition={transition}
          isMobile={isMobile}
          grade={grade}
          nextDue={currentGrade === grade ? nextDue : null}
          disabled={
            disabled ||
            (gradeStatus === GradeStatus.Pending && currentGrade !== grade) ||
            gradeStatus === GradeStatus.Succeeded
          }
          pending={currentGrade === grade ? gradeStatus === GradeStatus.Pending : false}
          onClick={onGrade}
        />))}
    </GradeButtonsContainer>
  )
}

export default memo(GradeButtons)
