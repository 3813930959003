import React, { memo, useCallback, useMemo } from "react"
import { useFormikContext } from "formik"
import { useHistory, useLocation } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import InputField from "../Common/InputField"
import {
  EmailIcon,
  PasswordIcon,
  InputGroup,
  ForgotPasswordLink,
  ButtonsGroup,
  ForgotPasswordLinkContainer,
  StyledForm,
  TopFormPart,
  BottomFormPart,
  SubmitButton,
  RegisterButton
} from "../Common/styles"
import useOnChange from "../../../hooks/useOnChange"
import { MOBILE_MAX } from "../../../common/breakpoints"
import { routes } from "../../../utils/constants"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";
import { StyledFormStatus, StyledMobileLogo, 
  // JoinMobile 
} from "./styles"
// import JoinContainer from './JoinContainer';
import qs from "query-string"

const LoginForm = ({ pendingLogin, error }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_MAX}px)` })
  const {t} = useTranslation();
  const formik = useFormikContext()
  const history = useHistory()
  const location = useLocation()

  const fieldNames = {
    email: t(Dictionary.login.email),
    password: t(Dictionary.login.password),
  }

  const status = useMemo(() => error && ({
    type: "error",
    title: t(Dictionary.common.oops),
    message: error.message
  }), [error, t])

  const handleRegister = useCallback(() => {

    const { search } = location;
    const params = qs.parse(search);

    const paramsSearch = params.organizationId ? `?organizationId=${params.organizationId}` : ''
    const paramsFrom = `${routes.login}${paramsSearch}`;

    const paramsRoute = {
      pathname: routes.register,
      search: paramsSearch,
      state: { from: paramsFrom }
    }
    history.push(paramsRoute)
  }, [history, location])

  useOnChange(() => {
    if (!pendingLogin) {
      formik.setSubmitting(false)
    }
  }, [pendingLogin])

  const topGroup = (
    <>
      <StyledFormStatus fieldNames={fieldNames} status={status} />
      <InputGroup>
        <InputField name="email" type="text" placeholder={fieldNames.email} icon={<EmailIcon />} />
        <InputField name="password" type="password" placeholder={fieldNames.password} icon={<PasswordIcon />} />
      </InputGroup>
    </>
  )

  const forgotPasswordLink = (
    <ForgotPasswordLink to={routes.forgot}>
      {t(Dictionary.login.forgotPassword)}
    </ForgotPasswordLink>
  )

  const submitButton = <SubmitButton primary type="submit" loading={formik.isSubmitting}>{t(Dictionary.login.logIn)}</SubmitButton>

  const registerButton = <RegisterButton type="button" onClick={handleRegister}>{t(Dictionary.login.signUp)}</RegisterButton>

  return isMobile ? (
    <StyledForm>
      <TopFormPart>
        {topGroup}
        {submitButton}
        {forgotPasswordLink}
      </TopFormPart>
      {/* <JoinMobile><JoinContainer/></JoinMobile> */}
      <BottomFormPart>
        <StyledMobileLogo />
        {registerButton}
      </BottomFormPart>
    </StyledForm>
  ) : (
    <StyledForm>
      {topGroup}
      <ForgotPasswordLinkContainer>
        {forgotPasswordLink}
      </ForgotPasswordLinkContainer>
      <ButtonsGroup>
        {submitButton}
        {registerButton}
      </ButtonsGroup>
    </StyledForm>
  )
}

export default memo(LoginForm)
