import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a, .d { fill: none; }
  .a, .b { stroke: currentColor; }
  .a { stroke-width: 1.5px; }
  .b { fill: currentColor; }
  .c { stroke: none; }
`

const EnterIcon = (props) => (
  <StyledSvg width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g transform="translate(-43 -20)">
      <g className="a" transform="translate(43 20)">
        <rect className="c" width="22" height="22" rx="5" />
        <rect
          className="d" x="0.75" y="0.75" width="20.5"
          height="20.5" rx="4.25"
        />
      </g>
      <path className="b" d="M8.9,41.743v3.1a.436.436,0,0,1-.427.444H1.633l1.951-2.029-.6-.627L0,45.726l2.986,3.1.6-.627L1.633,46.169H8.469a1.307,1.307,0,0,0,1.28-1.331v-3.1Z" transform="translate(49 -13.243)" />
    </g>
  </StyledSvg>
)

export default memo(EnterIcon)
