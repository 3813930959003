import { useHistory } from "react-router-dom"
import React, { memo, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import EntrancePage from "../Common/EntrancePage"
import { ContentContainer, FormInnerContainer, PageContainer } from "../Common/styles"
import VerifyPhoneCompleteForm from "./VerifyPhoneCompleteForm"
import { routes } from "../../../utils/constants"
import * as authActions from "../../../store/actions/auth"
import * as authSelectors from "../../../store/selectors/auth"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"
import { RegisterFormContainer } from "./styles"

const VerifyPhoneCompletePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { succeeded } = useSelector(authSelectors.loginState)
  const loginCredentials = useSelector(authSelectors.loginCredentials)

  useEffect(() => () => {
    if (succeeded) { dispatch(authActions.resetLoginCredentials()) }
  }, [dispatch, succeeded])

  useEffect(() => {
    if (!succeeded) {
      return undefined
    }

    history.push(routes.termsAndConditions)
  }, [history, succeeded])

  const handleClickFinish = useCallback(() => {
    if (loginCredentials) {
      dispatch(authActions.userLoginRequest({
        email: loginCredentials.email, password: loginCredentials.password, firstLogin: true
      }))
    }
  }, [dispatch, loginCredentials])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <PageContainer>
        <ContentContainer>
          <RegisterFormContainer>
            <FormInnerContainer>
              <VerifyPhoneCompleteForm onFinish={handleClickFinish} />
            </FormInnerContainer>
          </RegisterFormContainer>
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(VerifyPhoneCompletePage)