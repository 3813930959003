import styled from "styled-components"
import PhoneInputIcon from "../../../assets/svgs/PhoneInputIcon"
import UserInputIcon from "../../../assets/svgs/UserInputIcon"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import { FormContainer } from "../Common/styles"

export const RegisterFormContainer = styled(FormContainer)`
`

export const UserIcon = styled(UserInputIcon)`
  font-size: 19px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
  }
`

export const PhoneIcon = styled(PhoneInputIcon)`
  color: #fb4270;
  font-size: 18px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 15px;
  }
`

export const RegisterButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (max-width: ${MOBILE_MAX}px) {
    margin-top: 32px;
  }
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin: 15px 0 40px;

  @media (max-width: ${MOBILE_MAX}px) {
    color: #f8f8f8;
  }
`
