import styled from "styled-components"
import Flag from "./../../../common/components/Flag"
import {
  DESKTOP_SMALL_MAX,
  LAPTOP_MAX,
  // LAPTOP_SMALL_MAX,
  // TABLET_SMALL_MAX
} from "../../../common/breakpoints"
import { transitions } from "../../../assets/animations/transitions"

export const StyledFlag = styled(Flag)`
  width: 60px;
  height: 60px;
  margin-right: 38px;

  .flag__circle {
    box-shadow: none;
  }
`
export const AccordionItem = styled.div`
  border-bottom: 1px solid #D0D0D0;
  ${props => props.isFirst ? 'border-top: 1px solid #D0D0D0;' : '' }
`

export const AccordionHeader = styled.div`
  display: flex;
  height: 188px;
  padding: 0 51px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0,174,224,0);
  transition: ${transitions.quickTransition('background-color') };

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 150px;
    padding: 0 35px;
  }
  ${props => props.activeHover ? `
  cursor: pointer;
  &:hover {
    background-color: rgba(0,174,224,0.1);
  }` : ''}
`

export const AccordionHeaderLang = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 30px;
  }
`

export const AccordionArrow = styled.div`
  display: flex;
  transform: rotateZ(${props => props.isOpen ? -180 : 0}deg);
  transition: ${transitions.quickTransition('transform') };
`

export const AccordionBody = styled.div`
  overflow: hidden;
  transition: ${transitions.quickTransitionAll };

  margin: ${props => props.isOpen ? '-20px -50px 0' : '0px -50px 0'};
  padding: ${props => props.isOpen ? '20px 0' : '0px'};
  // padding: 0 34px;
  // box-sizing: border-box;

  // @media (max-width: ${DESKTOP_SMALL_MAX}px) {
  //   margin: 0 -17px;
  //   // padding: 0 17px;
  // }

  // @media (max-width: ${LAPTOP_MAX}px) {
  //   margin: 0 -17px;
  //   // padding: 0 17px;
  // }
`
export const AccordionCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -30px 0px;
  padding: 0 35px 0px;
  box-sizing: border-box;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin: -30px 0px;
    // padding: 0 35px 0px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin: -21px 0px;
    // padding: 0 35px 0px;
  }
`
