import styled from "styled-components"
import { LAPTOP_MAX } from "../../../../common/breakpoints"
import { CARD_CHANGE_DURATION } from "../../Card/constants"
import bgImage from "./bg-image.png"

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(236deg, #008EE0 0%, #0874B2 100%);
  padding: 0 48px;
  opacity: 0;
  transition: opacity ${CARD_CHANGE_DURATION}ms ease-out;

  &[data-show='true'] {
    opacity: 1;
  }
`

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LeftContainer = styled.div`
  flex: 0 1 758px;
  color: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    flex: 0 1 530px;
  }
`

export const Label1 = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-size: 97px;
  line-height: 1;
  margin-bottom: 15px;

  strong {
    font-weight: 900;
    color: #00C6FF;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 67px;
    margin-bottom: 11px;
  }
`

export const Label2 = styled.div`
  font-weight: medium;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.64;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
    line-height: 26px;
  }
`

export const Spacer = styled.div`
  flex: 0 2 56px;

  @media (max-width: ${LAPTOP_MAX}px) {
    flex: 0 2 39px;
  }
`

export const RightImage = styled.div`
  flex: 0 1 813px;
  height: 800px;
  background: center / contain no-repeat url("${bgImage}");
  margin-right: -42px;

  @media (max-width: ${LAPTOP_MAX}px) {
    flex: 0 1 569px;
    height: 560px;
    margin-right: -29px;
  }
`

export const ProgressBarContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 37px;
  padding-top: 1px;
  background-color: white;
`

export const ProgressBarFill = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 21px;
  height: 100%;
  min-width: 50px;
  width: 60px;
  box-sizing: border-box;
  background: linear-gradient(256deg, #FF5363 0%, #FE4F66 24%, #F93779 100%);
  font-size: 13px;
  font-weight: 600;
  color: white;
`
