import React, { memo } from "react"

const SwipeIcon = (props) => (
  <svg height="1em" viewBox="0 0 32.731 28.414" {...props}>
    <g opacity="0.45">
      <path
        id="Caminho_164"
        data-name="Caminho 164"
        d="M138.257,2.762l-2.1-2.1a.549.549,0,0,0-.776.776l1.114,1.114h-7.258a.549.549,0,1,0,0,1.1h7.358l-1.161,1.161a.549.549,0,1,0,.776.776l2.047-2.047A.549.549,0,0,0,138.257,2.762Z"
        transform="translate(-105.687 -0.5)"
        fill="currentColor"
      />
      <path
        id="Caminho_165"
        data-name="Caminho 165"
        d="M55.516,9.7a4.056,4.056,0,0,0-1.826-.649,3.343,3.343,0,0,0-2.745-1.384,4.066,4.066,0,0,0-.742.07,3.367,3.367,0,0,0-2.587-1.164h-.057l0-3.715A2.817,2.817,0,0,0,46.631.718a2.976,2.976,0,0,0-4,.111,2.808,2.808,0,0,0-.806,2.038l.007,5.362,0,3.4a3.077,3.077,0,0,0-1.54,1.891c-.378,1.284-.077,2.939.921,5.057a24.627,24.627,0,0,0,1.822,3.17l.167,2.225a1.924,1.924,0,0,0,1.911,1.772l7.934-.01a1.922,1.922,0,0,0,1.9-1.666l.308-2.346A12.56,12.56,0,0,0,57.3,12.683,4.265,4.265,0,0,0,55.516,9.7ZM54.18,21.163a.709.709,0,0,0-.108.292l-.323,2.459a.687.687,0,0,1-.7.592l-7.932-.012h0a.687.687,0,0,1-.7-.631l-.177-2.349a.685.685,0,0,0-.122-.341c-.726-1.054-4.78-7.2-1.5-8.575a.7.7,0,0,0,.43-.647c0-1.675-.009-7.234-.011-9.085a1.655,1.655,0,0,1,3.31,0l.005,4.264a.7.7,0,0,0,.815.7,2.911,2.911,0,0,1,.452-.036,2.155,2.155,0,0,1,1.824.937A.692.692,0,0,0,50,9.006a.727.727,0,0,0,.186-.024,2.983,2.983,0,0,1,.755-.1,2.152,2.152,0,0,1,1.9,1.077.612.612,0,0,0,.5.285A2.823,2.823,0,0,1,56.1,12.885,11.329,11.329,0,0,1,54.18,21.163Z"
        transform="translate(-27.298 -5.08) rotate(8)"
        fill="currentColor"
      />
      <path
        id="Caminho_168"
        data-name="Caminho 168"
        d="M138.257,2.762l-2.1-2.1a.549.549,0,0,0-.776.776l1.114,1.114h-7.258a.549.549,0,1,0,0,1.1h7.358l-1.161,1.161a.549.549,0,1,0,.776.776l2.047-2.047A.549.549,0,0,0,138.257,2.762Z"
        transform="translate(138.417 5.747) rotate(180)"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default memo(SwipeIcon)
