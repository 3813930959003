// import useSize from "@react-hook/size"
import React, { memo, useCallback, useRef, useState, useMemo } from "react"
// import LinesEllipsis from "react-lines-ellipsis"
// import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC"
import { useMediaQuery } from "react-responsive"
import { LAPTOP_MAX,DESKTOP_SMALL_MAX,LAPTOP_SMALL_MAX } from "../../../common/breakpoints"
import {
  Container,
  CardContainer,
  HeaderContainer,
  FlipButton,
  CardFlipIcon,
  SideLabel,
  CardContent,
  SideAContainer,
  SideATextsTags,
  SideATexts,
  SideAForeignTextContent,
  SideAForeignText,
  SideATranslatedText,
  PlayerContainer,
  StyledPlayer,
  StyledImage,
  SideBContainer,
  SideBTitle,
  // SideBSubtitle,
  StatsContainer,
  // StatsBar,
  // StatValueCircle,
  // StatBarLabel,
  // MinBarHeightBlock,
  SideATags,
  SideATag
} from "./styles";
import ForeignTextAzian from './ForeignTextAzian';
// import TranslateText from './TranslateText';
import { getTextSizes } from './textSizes';
import useTextSizes from './useTextSizes';
import useOnChange from "./../../../hooks/useOnChange";
import { CN_TAGS, CN_FOREIGN_TEXT } from './../calculationHeightTexts';
import { useTranslation } from "react-i18next";
import { Dictionary } from "../../../utils/i18n";

// const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const playerConfigDesktop = { height: 47 }
const playerConfigLaptop = { height: 43 }

const Card = ({ card, minHeightHeader, updateHeight }) => {
  const [smallTextIsAsian, setSmallTextIsAsian] = useState({clamped: false, visible: false, text:''});
  const isDesktopSmall = useMediaQuery({ query: `(max-width: ${DESKTOP_SMALL_MAX}px)` })
  const isLaptop = useMediaQuery({ query: `(max-width: ${LAPTOP_MAX}px)` })
  const isLaptopSmall = useMediaQuery({ query: `(max-width: ${LAPTOP_SMALL_MAX}px)` })
  const {t} = useTranslation();
  const isMobile = false;

  const isAsian = (card.foreignTextPronunciation && card.foreignTextPronunciation.length > 0) || card.foreignText?.length < 4 ;
  const [isFlipped, setIsFlipped] = useState(false);
  const refTexts = useRef();
  const hasImage = card.image;

  const showText = true;
  const testSizes = getTextSizes({isMobile, isLaptop, isDesktopSmall, isLaptopSmall}, isAsian, hasImage)
  const texts = useMemo(() => ({
    header: card.foreignText ? [card.foreignText] : [],
    paragraph: card.translatedText ? [card.translatedText] : []
  }), [card.foreignText,  card.translatedText]);
  const smallText = isAsian && smallTextIsAsian.clamped;
  const { sizes, makeSpanRef} = useTextSizes(refTexts, testSizes, texts, [showText], smallText);

  const handleFlip = useCallback(() => {
    setIsFlipped(flipped => !flipped)
  }, [])

  useOnChange(() => {
    updateHeight && updateHeight()
  }, [sizes.header?.size, sizes.paragraph?.size])

  // const maxGradings = Math.max(
  //   card.gradingsCountEasy,
  //   card.gradingsCountNormal,
  //   card.gradingsCountHard
  // )

  // const minGradings = Math.min(
  //   card.gradingsCountEasy,
  //   card.gradingsCountNormal,
  //   card.gradingsCountHard
  // )

  // const barStyle = (value) => {
  //   const minBarHeight = minBarHeightBlockRef.current?.clientHeight ?? 0
  //   const percentage = maxGradings > 0 ? (value - minGradings) / (maxGradings - minGradings) : 0

  //   return {
  //     height: `calc((100% - ${minBarHeight}px) * ${percentage} + ${minBarHeight}px)`
  //   }
  // }

  const playerConfig = isLaptop ? playerConfigLaptop : playerConfigDesktop
  const onReflowForeignAsianText = (params) => {
    const {clamped, text} = params;

    if(!smallTextIsAsian.visible) {
      setSmallTextIsAsian({clamped, visible: true, text})
    } else {
      if(text !== smallTextIsAsian.text || clamped !== smallTextIsAsian.clamped) {
        setSmallTextIsAsian((smallTextIsAsian) => {
          return {...smallTextIsAsian, clamped, text}
        })
      }
    }
  }

  return (
    <Container data-has-image={!!card.image}>
      {/* <MinBarHeightBlock ref={minBarHeightBlockRef} /> */}
      <CardContainer>
        <HeaderContainer>
          <FlipButton onClick={handleFlip}>
            <CardFlipIcon />
          </FlipButton>
          <SideLabel>
            <span data-selected={!isFlipped}>A</span>
            <span>&nbsp;/&nbsp;</span>
            <span data-selected={isFlipped}>B</span>
          </SideLabel>
        </HeaderContainer>
        <CardContent>
          <SideAContainer data-visible={!isFlipped}>
            <SideATextsTags>
              {card.tags && card.tags.length ? <SideATags className={CN_TAGS} >
                {card.tags.map(({tag, id}) => <SideATag  key={`tag_${id}`}>{tag}</SideATag>)}
              </SideATags> : <> </>}
              <SideATexts ref={refTexts}>
                <div>
                  <SideAForeignTextContent
                    minHeight={minHeightHeader}
                    >
                    <div className={CN_FOREIGN_TEXT}>
                      {card.foreignText && (isAsian ? <ForeignTextAzian
                        makeSpanRef={makeSpanRef}
                        text={card.foreignText}
                        style={{
                          fontSize: sizes.header?.size,
                          lineHeight: sizes.header?.lineHeight
                        }}
                        onReflowForeignAsianText={onReflowForeignAsianText}
                      /> : (
                        <SideAForeignText style={{
                          fontSize: sizes.header?.size,
                          lineHeight: sizes.header?.lineHeight
                        }}>
                          <span ref={makeSpanRef(`hdr-0`)}>
                            {card.foreignText}
                          </span>
                        </SideAForeignText>
                      ))}
                    </div>
                  </SideAForeignTextContent>
                  {card.translatedText && (
                    <SideATranslatedText
                      style={{
                        fontSize: sizes.paragraph?.size,
                        lineHeight: sizes.paragraph?.lineHeight
                      }}
                    >
                      <div>
                        <span ref={makeSpanRef(`par-0`)}>{card.translatedText}</span>
                      </div>
                    </SideATranslatedText>
                  )}
                </div>
              </SideATexts>
            </SideATextsTags>
            <div>
              {card.audio && <PlayerContainer>
                <StyledPlayer config={playerConfig} url={card.audio} />
              </PlayerContainer>}
              {card.image && <StyledImage src={card.image} />}
            </div>
          </SideAContainer>
          <SideBContainer data-visible={isFlipped}>
            <SideBTitle>{card.gradingsCountReviews || 0} {t(Dictionary.deck.review)}{card.gradingsCountReviews > 1 ? 's' : ''}!</SideBTitle>
            {/* <SideBSubtitle>Short term memory</SideBSubtitle> */}
            <StatsContainer>
              {/* <StatsBar style={barStyle(card.gradingsCountEasy)}>
                <StatValueCircle>{card.gradingsCountEasy}</StatValueCircle>
                <StatBarLabel>Fácil</StatBarLabel>
              </StatsBar>
              <StatsBar style={barStyle(card.gradingsCountNormal)}>
                <StatValueCircle>{card.gradingsCountNormal}</StatValueCircle>
                <StatBarLabel>Bom</StatBarLabel>
              </StatsBar>
              <StatsBar style={barStyle(card.gradingsCountHard)}>
                <StatValueCircle>{card.gradingsCountHard}</StatValueCircle>
                <StatBarLabel>Difícil</StatBarLabel>
              </StatsBar> */}
            </StatsContainer>
          </SideBContainer>
        </CardContent>
      </CardContainer>
    </Container>
  )
}

export default memo(Card)
