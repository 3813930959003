import React, { memo } from "react"
// import useSize from "@react-hook/size"
import {
  SideAForeignText,
  SideAContainerForeignTexts
} from "./styles";
import LinesEllipsis from "react-lines-ellipsis"
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC"

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const ForeignTextAzian = ({text,style, makeSpanRef, onReflowForeignAsianText}) => {

  return (<SideAContainerForeignTexts >
    <SideAForeignText style={style}>
      <span ref={makeSpanRef(`hdr-0`)}>{text}</span>
    </SideAForeignText>
    <SideAForeignText isAsian hiddenText style={style}>
      <ResponsiveEllipsis
        onReflow={onReflowForeignAsianText}
        text={text}
        maxLine="1"
      />
    </SideAForeignText>
  </SideAContainerForeignTexts>)
}

export default memo(ForeignTextAzian);