import React, { memo, useCallback } from "react"
import { formatCardsCount } from "../../../utils/format"
import {
  CardContainer,
  OopsImage,
  StyledFlag,
  TopContainer,
  CountersContainer,
  CounterLabel,
  OverdueIcon,
  TotalIcon,
  Title,
  Description
} from "./styles"

const fallbackNode = <OopsImage />

const DeckCard = ({
  className,
  deck,
  onClick
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(deck)
    }
  }, [deck, onClick])

  return (
    <CardContainer
      className={className}
      src={deck.image}
      fallback={fallbackNode}
      onClick={handleClick}
    >
      <TopContainer>
        <StyledFlag language={deck.language} />
        <CountersContainer>
          <CounterLabel>{formatCardsCount(deck.overdueCount)}</CounterLabel>
          <OverdueIcon />
          <CounterLabel>{formatCardsCount(deck.totalCount)}</CounterLabel>
          <TotalIcon />
        </CountersContainer>
      </TopContainer>
      <div>
        <Title>{deck.title}</Title>
        {deck.description && <Description>{deck.description}</Description>}
      </div>
    </CardContainer>
  )
}

export default memo(DeckCard)
