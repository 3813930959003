import styled, { createGlobalStyle } from "styled-components"
import { MOBILE_MAX } from "../../../common/breakpoints"
import Button from "../../../common/components/Button"
import robotBg from "./robot-bg.png"

export const GlobalStyle = createGlobalStyle`
  body {
    @media (max-width: ${MOBILE_MAX}px) {
      background: linear-gradient(180deg, #FF5363 0%, #F93779 100%) #F93779;
    }
  }
`

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export const BgImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 170px;
  width: 320px;
  background: left bottom / contain no-repeat url("${robotBg}");
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding: 29px 24px 30px 24px;
  color: white;
`

export const BackButtonRow = styled.div`
  margin-bottom: 25px;
`

export const BackButton = styled.button`
  padding: 14px;
  margin: -14px;
  font-size: 16px;
  color: white;
`

export const Title = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-weight: 900;
  font-size: 48px;
  line-height: 54px;
  margin: 0 -3px;
`

export const Description = styled.div`
  font-size: 12px;
  line-height: 18px;
  opacity: 0.65;
  margin: 0 -3px;
`

export const OkButton = styled(Button)`
  width: auto;
`
