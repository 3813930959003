import styled, { css } from "styled-components"
import FlipIcon from "../../../assets/svgs/FlipIcon"
import { DESKTOP_SMALL_MAX, LAPTOP_MAX, LAPTOP_SMALL_MAX, MOBILE_MAX } from "../../../common/breakpoints"
import AudioPlayer from "../../../common/components/AudioPlayer"
import ImageContainer from "../../../common/components/AudioPlayer/ImageContainer"

export const Container = styled.div`
  position: relative;
  height: 512px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 456px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 358px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 466px;
  }
`

export const MinBarHeightBlock = styled.div`
  position: absolute;
  visibility: hidden;
  height: 92px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 84px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 62px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 87px;
  }
`

export const CardContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border: solid 1px #CDE0E2;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    border-radius: 20px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    border-radius: 14px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    border-radius: 22px;
  }
`

const HorizontalPaddings = css`
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 28px;
    padding-right: 28px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    padding-left: 36px;
    padding-right: 36px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${HorizontalPaddings}
  padding-top: 27px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding-top: 25px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-top: 19px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    padding-top: 25px;
  }
`

export const CardFlipIcon = styled(FlipIcon)`
  color: #F24556;
  height: 25px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 22px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 18px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 22px;
  }
`

export const FlipButton = styled.button`
  padding: 8px;
  margin: -8px;
  line-height: 0;
`

export const SideLabel = styled.div`
  font-size: 19px;
  color: #C2C2C2;
  line-height: 1;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 17px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 17px;
  }

  > span[data-selected='true'] {
    color: black;
  }
`

export const CardContent = styled.div`
  position: relative;
  flex: 1;
`

export const SideAContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  transition: opacity 0.2s ease-out;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding-top: 32px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-top: 28px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    padding-top: 32px;
  }

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
`

export const SideATextsTags = styled.div`
  ${HorizontalPaddings}
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

export const SideATexts = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`
export const SideAContainerForeignTexts = styled.div`
  position: relative;
`

export const SideATags = styled.div`
  display: flex;
  padding-bottom: 15px;
`
export const SideATag = styled.div`
  margin-bottom: 12px;
  padding: 5px 10px;
  max-width: 200px;
  border: 1px solid #6B47ED;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #5B5B5B;
  background-color: rgba(107, 71, 237, 0.1);
  white-space: nowrap;
  overflow: hidden;
  border-radius: 6px;
  cursor: default;

  :not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 7px;

    :not(:last-child) {
      margin-right: 7px;
    }
  }
`

export const SideAForeignTextContent = styled.div`
  display: flex;
  min-height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
  align-items: flex-end;
`

export const SideAForeignText = styled.div`
  font-family: "IBM Plex Serif";
  // font-size: ${props => props.isAsian ? (props.smallTextIsAsian ? '24px' : '44px') : '26px'};
  // line-height: ${props => props.isAsian ? (props.smallTextIsAsian ? '30px' : '50px') : '34px'};
  color: #050304;
  opacity: 0.71;
  padding-bottom: 13px;
  ${props => props.hiddenText ? `
    opacity: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  ` : ''};
  font-weight: bolder;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    // font-size: ${props => props.isAsian ? (props.smallTextIsAsian ? '22px' : '34px') : '24px'};
    // line-height: ${props => props.isAsian ?(props.smallTextIsAsian ? '32px' : '40px') : '28px'};
    padding-bottom: 15px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    // font-size:  ${props => props.isAsian ? (props.smallTextIsAsian ? '20px': '30px') : '18px'};
    // line-height: ${props => props.isAsian ? (props.smallTextIsAsian ? '30px': '38px') : '24px'};
    padding-bottom: 9px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    // font-size: ${props => props.isAsian ? (props.smallTextIsAsian ? '18px' : '26px') : '16px'};
    // line-height: ${props => props.isAsian ? (props.smallTextIsAsian ? '25px' : '34px') : '22px'};
    padding-bottom: 16px;
  }
`

export const SideATranslatedText = styled.div`
  flex: 1;
  overflow: hidden;
  font-family: "IBM Plex Serif";
  font-weight: 500;
  // font-size: 24px;
  color: #9B9B9B;
  line-height: initial;
  font-size: initial;
  // line-height: 30px;

  // @media (max-width: ${DESKTOP_SMALL_MAX}px) {
  //   font-size: 20px;
  //   line-height: 28px;
  // }

  // @media (max-width: ${LAPTOP_MAX}px) {
  //   font-size: 18px;
  //   line-height: 25px;
  // }

  // @media (max-width: ${LAPTOP_SMALL_MAX}px) {
  //   font-size: 20px;
  //   line-height: 28px;
  // }

  // ${Container}[data-has-image='true'] & {
  //   font-size: 23px;
  //   line-height: 30px;

  //   @media (max-width: ${DESKTOP_SMALL_MAX}px) {
  //     font-size: 18px;
  //     line-height: 24px;
  //   }

  //   @media (max-width: ${LAPTOP_MAX}px) {
  //     font-size: 16px;
  //     line-height: 21px;
  //   }

  //   @media (max-width: ${LAPTOP_SMALL_MAX}px) {
  //     font-size: 18px;
  //     line-height: 24px;
  //   }
  // }
`

export const PlayerContainer = styled.div`
  ${HorizontalPaddings}
  margin-bottom: 38px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    margin-bottom: 34px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 26px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    margin-bottom: 34px;
  }

  ${Container}[data-has-image='true'] & {
    margin-bottom: 33px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      margin-bottom: 28px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 23px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      margin-bottom: 29px;
    }
  }
`

export const StyledPlayer = styled(AudioPlayer)`
  .audio-player__duration {
    font-size: 15px;
    line-height: 1;
    height: 17px;
    margin-bottom: 3px;
    opacity: 1;
    color: #8B8A8A;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      font-size: 12px;
      height: 14px;
      margin-bottom: 4px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 11px;
      height: 11px;
      margin-bottom: 2px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      font-size: 12px;
      height: 14px;
      margin-bottom: 4px;
    }
  }

  .audio-player__waveform {
    max-width: none;
    margin-right: 20px;
    height: 47px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      margin-right: 17px;
      height: 42px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-right: 14px;
      height: 33px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      margin-right: 17px;
      height: 42px;
    }
  }

  .audio-player__play-button {
    width: 47px;
    height: 47px;
    font-size: 19px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      width: 42px;
      height: 42px;
      font-size: 16.84px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      width: 33px;
      height: 33px;
      font-size: 13px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      width: 43px;
      height: 43px;
      font-size: 17px;
    }
  }
`

export const StyledImage = styled(ImageContainer)`
  height: 127px;
  border-top: 1px solid #70707040;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 114px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 89px
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 116px;
  }

  .image-container__image {
    filter: none;
  }

  .image-container__fallback {
    background-color: white;
  }
`

export const SideBContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  transition: opacity 0.2s ease-out;

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
`

export const SideBTitle = styled.div`
  font-family: "IBM Plex Serif";
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  ${HorizontalPaddings}

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 14px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`

export const SideBSubtitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  opacity: 0.32;
  margin-bottom: 20px;
  ${HorizontalPaddings}

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 33px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 14px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 33px;
  }
`

export const StatsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 300px;
  box-sizing: border-box;
  background-color: #E8FFF0;
  border-top: solid 1px #CDE0E2;
  padding: 59px 40px 26px 40px;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    height: 268px;
    padding: 65px 40px 26px 40px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 210px;
    padding: 41px 28px 18px 28px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    height: 274px;
    padding: 70px 37px 28px 37px;
  }
`

export const StatsBar = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 24px;
  background: linear-gradient(180deg, #1BD46F 0%, #14BC68 100%);
  border-radius: 11px;
  
  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    padding-bottom: 20px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-bottom: 16px;
    border-radius: 8px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    border-radius: 11px;
    padding-bottom: 21px;
  }

  :not(:last-child) {
    margin-right: 28px;

    @media (max-width: ${DESKTOP_SMALL_MAX}px) {
      margin-right: 25px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-right: 20px;
    }

    @media (max-width: ${LAPTOP_SMALL_MAX}px) {
      margin-right: 25px;
    }
  }
`

export const StatValueCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: #1CD473;
  color: white;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 16px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    line-height: 44px;
    width: 44px;
    height: 44px;
    font-size: 24px;
    border-radius: 22px;
  }
`

export const StatBarLabel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  @media (max-width: ${DESKTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    top: -32px;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    top: -26px;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: ${LAPTOP_SMALL_MAX}px) {
    font-size: 15px;
    line-height: 18px;
    top: -32px;
  }
`
