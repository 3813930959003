import React, { memo } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .a {
    fill: none;
    stroke: rgba(112,112,112,0.26); 
  }

  .b { 
    fill: currentColor; 
  }

  .c { 
    stroke: none;
  }

  .d {
    fill: none;
  }
`

const CloseIcon = (props) => (
  <StyledSvg height="1em" viewBox="0 0 46 46" {...props}>
    <g transform="translate(422 280) rotate(180)">
      <g className="a" transform="translate(376 234)">
        <circle className="c" cx="23" cy="23" r="23" />
        <circle className="d" cx="23" cy="23" r="22.5" />
      </g>
      <path className="b" d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z" transform="translate(411 269) rotate(180)"/>
    </g>
  </StyledSvg>
)

export default memo(CloseIcon)
