import { put, all, call, select } from "redux-saga/effects"
import { rest, endpoints } from "../../services/api"
import * as actions from "../actions/sessions"
import { takeLatestCancelable } from "../utils/sagas"
import { currentUserId as currentUserIdSelector } from "../selectors/auth"
import { japanese, CHINESE_TAGS } from './../../utils/helpers/FlagAndLanguage';
import { resolvePhysicalDeckId } from "./decks"
import { FBAnalyticsGradeCard } from "./../../firebase/logEvents";

export function* startSession({ payload: { id, type } }) {
  const userId = yield select(currentUserIdSelector)
  const endpoint = type === "merge" ? endpoints.mergeSession(id) : endpoints.deckSession(id)

  try {
    const deckEndpoint =
      type === "merge" ?
      endpoints.merge(userId, id) :
      endpoints.deck(yield call(resolvePhysicalDeckId, id))

    const [returnedCards, deck] = yield all([call(rest.post, endpoint), call(rest.get, deckEndpoint)])

    let cards = returnedCards.filter((card) => card.notes.length > 1)

    cards.forEach((card) => {

      if (card.notes[0]?.note?.order !== undefined) {
        card.notes.sort((x, y) => x.note.order - y.note.order)
      }

      card.notes.forEach((note) => {
        if (note.version === 2) {
          note.headers.forEach((header) => {
            header.content = header.header
            delete header.header
          })
        }
      })

      if(card.notes[0]?.version === 2 && card.notes[1]?.version === 2){
        const firstNote = card.notes[0];
        const secondNote = card.notes[1];
        const isJapanese = deck?.tags[0]?.tag === japanese;
        const isMandarim = CHINESE_TAGS.includes(deck?.tags[0]?.tag);
        const reserseFirstNote = isJapanese && secondNote?.headers?.length && !secondNote?.paragraphs?.length;

        if(!isMandarim && secondNote?.headers?.length && secondNote?.audios?.length && secondNote?.paragraphs?.length 
          && !firstNote?.headers?.length && firstNote?.paragraphs?.length) {
            card.notes.reverse();
          } else {
            if(reserseFirstNote || (isMandarim && card.notes[1].headers[0] && card.notes[1].paragraphs)){
              card.notes[1].transcription = [...card.notes[1].paragraphs];
              card.notes[1].paragraphs[0] = {...card.notes[1].headers[0]}
            }
          }
      }
    })

    const name = type === "merge" ? deck.name : deck.deck.title;
    const deckId = type === "merge" ? deck.id : deck.deck.id;

    yield put(actions.startSessionSuccess({ id, type, data: { queue: cards, name, tags: deck.tags, deckId } }))
  } catch (error) {
    yield put(actions.startSessionFailure(error))
  }
}

export function* gradeCard({ payload: { id, type, cardId, mode, grade, time, analytic } }) {
  const endpoint = type === "merge" ? endpoints.mergeSessionCard(id, cardId) : endpoints.deckSessionCard(id, cardId)

  try {
    const { nextDue } = yield call(rest.put, endpoint, { grade, time })

    yield put(actions.gradeCardSuccess({ id, type, cardId, mode, grade, nextDue }))

    if(analytic.grade > 0 || analytic.grade === 0){
      FBAnalyticsGradeCard(analytic)
    }
  } catch (error) {
    yield put(actions.gradeCardFailure(error))
  }
}

export function* completeSession({ payload: { id, type } }) {
  const endpoint = type === "merge" ? endpoints.mergeSession(id) : endpoints.deckSession(id)

  try {
    yield call(rest.delete, endpoint)
    yield put(actions.completeSessionSuccess({ id, type }))
  } catch (error) {
    yield put(actions.completeSessionFailure())
  }
}

export default function* () {
  yield all([
    takeLatestCancelable(actions.startSessionRequest, actions.viewSessionReset, startSession),
    takeLatestCancelable(actions.gradeCardRequest, actions.viewSessionReset, gradeCard),
    takeLatestCancelable(actions.completeSessionRequest, actions.viewSessionReset, completeSession)
  ])
}