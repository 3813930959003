import React, { memo, useEffect, useRef, useState } from "react"
import useOnChange from "../../../../hooks/useOnChange"
import useTransitionState, { ENTERED, ENTERING, EXITED } from "../../../../hooks/useTransitionState"
import { Dictionary } from "../../../../utils/i18n"
import { CARD_CHANGE_DELAY, CARD_CHANGE_DURATION } from "../../Card/constants"
import { useTranslation } from "react-i18next"
import {
  CenterContainer,
  Container,
  LeftContainer,
  Label1,
  Label2,
  RightImage,
  ProgressBarContainer,
  ProgressBarFill,
  Spacer
} from "./styles"

const SessionCompleteDesktop = ({
  show
}) => {
  const {t} = useTranslation();
  const intervalId = useRef(0)
  const [progress, setProgress] = useState(0)
  const transition = useTransitionState(show, CARD_CHANGE_DURATION, CARD_CHANGE_DELAY)

  useEffect(() => () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
  }, [])

  useOnChange(() => {
    if (transition === ENTERED && intervalId.current === 0) {
      intervalId.current = setInterval(() => {
        setProgress(prev => Math.min(prev + 0.16, 1))
      }, 650)
    }
  }, [transition])

  if (transition === EXITED) {
    return null
  }

  return (
    <Container data-show={transition === ENTERING || transition === ENTERED}>
      <CenterContainer>
        <LeftContainer>
          <Label1>
            <strong>{t(Dictionary.finishedStudySession.youFinished)}</strong>
            <br />
            <span>{t(Dictionary.finishedStudySession.lodingResults)}</span>
          </Label1>
          <Label2>
            {t(Dictionary.finishedStudySession.lodingResultsSubtext)}
          </Label2>
        </LeftContainer>
        <Spacer />
        <RightImage />
      </CenterContainer>
      <ProgressBarContainer>
        <ProgressBarFill style={{ width: `${progress * 100}%` }}>
          {Math.round(progress * 100)}%
        </ProgressBarFill>
      </ProgressBarContainer>
    </Container>
  )
}

export default memo(SessionCompleteDesktop)
