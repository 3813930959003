export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCOLZ6n03YFhm0XAeSW9DiJ9V7Zd4Fk7QM",
  authDomain: "hackit-4515d.firebaseapp.com",
  databaseURL: "https://hackit-4515d.firebaseio.com",
  projectId: "hackit-4515d",
  storageBucket: "hackit-4515d.appspot.com",
  messagingSenderId: "383606428488",
  appId: "1:383606428488:web:9164fe5c68f3cbb9a9d97d",
  measurementId: "G-VG61SXJ23H"
};

export const firebaseConfigTest = {
  apiKey: "AIzaSyDhVs99eOOaqtkKT6TXjfara0wV-74lFzs",
  authDomain: "my-test-project-54aa1.firebaseapp.com",
  projectId: "my-test-project-54aa1",
  storageBucket: "my-test-project-54aa1.appspot.com",
  messagingSenderId: "153492302221",
  appId: "1:153492302221:web:99e9b59cb3b77c537a35c4",
  measurementId: "G-7SCD252LCY"
};