import { useRef, useEffect, useLayoutEffect } from "react"

/**
 * Calls `callback` function when any value from the `values` array changed
 * @param callback - callback function
 * @param values - array of observed values
 * @param layoutEffect - use 'layoutEffect' hook instead of 'useEffect'
 */
export default (callback, values, layoutEffect = false) => {
  const prevValues = useRef()
  const effectFn = layoutEffect ? useLayoutEffect : useEffect

  effectFn(() => {
    if (prevValues.current) {
      if (values.length !== prevValues.current.length) {
        callback(prevValues.current)
      } else {
        for (let index = 0; index < values.length; index += 1) {
          if (values[index] !== prevValues.current[index]) {
            callback(prevValues.current)
            break
          }
        }
      }
    }
    prevValues.current = values
  })
}
