import React, { memo, useCallback, useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as selectors from "../../store/selectors/decks"
import * as authSelectors from "../../store/selectors/auth"
import * as actions from "../../store/actions/decks"
import DeckCard from "./DeckCard"
import {
  CardsContainer,
  CardCell,
  MasterDecksContainer,
  BlockHeader,
  BlockTitle,
  CardsCount,
  UserDecksContainer,
  // PageControlContainer,
  StatusContainer,
  Spinner,
  StyledPageRoot,
  UserDecks
} from "./styles"
// import PageControl from "../../common/components/PageControl"
import TopBar from "./TopBar"
import Navigation from "../../common/components/Navigation"
import { useHistory } from "react-router-dom"
import { routes } from "../../utils/constants"
import { PageContainer } from "../../common/styles/page"
import NoDecksNotice from "./NoDecksNotice"
import AccordionLang from './AccordionLang';
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../utils/i18n";
import {
  flagComponents,
  languageInfo} from './../../utils/helpers/FlagAndLanguage';
import { getLanguage } from './../../utils/format';

// const PageSize = 6

const DashboardPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const currentUser = useSelector(authSelectors.currentUser)
  const masterDecksData = useSelector(selectors.masterDecksData)
  const userDecksData = useSelector(selectors.userDecksData)
  // const currentPage = useSelector(selectors.userDecksPage)

  const [navMenuExpanded, setNavMenuExpanded] = useState(true);
  const [isActiveLang, setIsActiveLang] = useState(null);
  const [userDecksLang, setUserDecksLang] = useState([]);

  useEffect(() => {
    dispatch(actions.loadMasterDecksRequest())
    dispatch(actions.loadUserDecksRequest())

    return () => {
      dispatch(actions.masterDecksReset())
      dispatch(actions.userDecksReset())
      setUserDecksLang([])
    }
  }, [dispatch]);

  useMemo(() => {

    let tags = {};
    const OTHER = 'other';
    if(userDecksData.data) {

      userDecksData.data.forEach(item => {
        const {language} = item;
        if(language && flagComponents[language]) {

          const key = getLanguage(language);

          if(tags[key]) {
            tags[key].cards.push(item);
          } else {
            tags[key] = {
              ...languageInfo[key],
              name: t(languageInfo[key].keyTranslation),
              language: key,
              cards: [item]
            }
          }

        } else {
          if(tags[OTHER]) {
            tags[OTHER].cards.push(item)
          } else {
            tags[OTHER] = {
              name: t(Dictionary.language.other),
              language: OTHER,
              cards: [item]
            }
          }
        }

      });
    }

    let langOther = null;
    if(tags[OTHER]){
      langOther = {...tags[OTHER]};
      delete tags[OTHER];
    }

    let listLang = Object.values(tags);

    if(langOther?.cards?.length) {
      listLang.push(langOther)
    }
    setUserDecksLang(listLang)
  // eslint-disable-next-line
  },[userDecksData.data])

  const handleToggleExpanded = useCallback(() => {
    setNavMenuExpanded(expanded => !expanded)
  }, [])

  // const handlePageChange = useCallback((page) => {
  //   dispatch(actions.setUserDecksPage(page))
  // }, [dispatch])

  const handleMasterDeckClick = useCallback((deck) => {
    history.push(`${routes.mergePage}/${deck.deckId}`)
  }, [history])

  // const handleUserDeckClick = useCallback((deck) => {
  //   history.push(`${routes.deckPage}/${deck.virtualId}`)
  // }, [history])

  // const userDecksOffset = currentPage * PageSize
  // const userDecksSlice = userDecksData.data.slice(userDecksOffset, userDecksOffset + PageSize)

  const renderMasterDecks = () => {
    if (masterDecksData.data.length && userDecksData.data.length) {
      return (
        <MasterDecksContainer data-nav-expanded={navMenuExpanded}>
          <BlockHeader>
            <BlockTitle>{t(Dictionary.dashboard.masterDecksOption)}</BlockTitle>
            <CardsCount>{masterDecksData.data.length}/{masterDecksData.data.length}</CardsCount>
          </BlockHeader>
          <CardsContainer>
            {masterDecksData.data.map((deck) => (
              <CardCell key={deck.deckId}>
                <DeckCard deck={deck} onClick={handleMasterDeckClick} />
              </CardCell>
            ))}
          </CardsContainer>
        </MasterDecksContainer>
      )
    }

    return null
  }

  const renderUserDecks = () => {
    if (userDecksData.data.length) {
      return (
        <UserDecksContainer data-nav-expanded={navMenuExpanded}>
          <BlockHeader>
            <BlockTitle>{t(Dictionary.dashboard.yourDecksOption)}</BlockTitle>
            <CardsCount>{userDecksData.data.length}</CardsCount>
          </BlockHeader>
          {/* <CardsContainer>
            {userDecksSlice.map((deck) => (
              <CardCell key={deck.deckId}>
                <DeckCard deck={deck} onClick={handleUserDeckClick} />
              </CardCell>
            ))}
          </CardsContainer>
          <PageControlContainer>
            <PageControl
              page={currentPage}
              totalPages={Math.ceil(userDecksData.data.length / PageSize)}
              onChange={handlePageChange}
            />
          </PageControlContainer> */}
          <UserDecks>
            {userDecksLang && userDecksLang.map((lang, index) => <AccordionLang
              key={`lang_${lang.language}`}
              {...lang}
              index={index}
              isActive={isActiveLang}
              handleClick={setIsActiveLang}
            />)}
          </UserDecks>
        </UserDecksContainer>
      )
    }

    const renderStatus = (status) => (
      <MasterDecksContainer data-nav-expanded={navMenuExpanded}>
        <StatusContainer>{status}</StatusContainer>
      </MasterDecksContainer>
    )

    if (userDecksData.error) {
      return renderStatus(<span>Error: {userDecksData.error.message}</span>)
    }

    if (userDecksData.loading) {
      return renderStatus(<Spinner />)
    }

    return <NoDecksNotice />
  }

  const noUserDecks = !userDecksData.data.length && !userDecksData.error && !userDecksData.loading

  return (
    <StyledPageRoot data-no-decks={noUserDecks}>
      <Navigation expanded={navMenuExpanded} toggleExpanded={handleToggleExpanded} />
      <PageContainer data-nav-expanded={navMenuExpanded}>
        <TopBar
          navMenuExpanded={navMenuExpanded}
          firstName={currentUser.firstName}
          lastName={currentUser.lastName}
        />
        {renderMasterDecks()}
        {renderUserDecks()}
      </PageContainer>
    </StyledPageRoot>
  )
}

export default memo(DashboardPage)
