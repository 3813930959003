import styled, { createGlobalStyle } from "styled-components"
import hackitLogo from "../../assets/svgs/memhack-logo-horizontal.svg"
import bg from "./images/bg-bender.png"

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    overflow: visible;
  }

  body {
    background-color: #00AEE0;
  }
`

export const TopContainer = styled.div`
  box-shadow: 0px 3px 30px #0067A264;
  border-radius: 0px 0px 10px 10px;
  height: 249px;
  background: 
    linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), 
    bottom right / 230px 245px url("${bg}") no-repeat,
    linear-gradient(180deg, #008EE0 0%, #006CC9 100%);

  padding: 12px 24px 0 24px;
`

export const TopRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`

export const HackitLogo = styled.img.attrs({
  src: hackitLogo
})`
  width: 81.5px;
  height: 20px;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 14px;
  margin: -15px;
  color: white;
`

export const DeckName = styled.div`
  font-family: "IBM Plex Serif";
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  padding: 0 15px;
  color: #FFFFFF;
`

export const Slogan = styled(DeckName)`
  opacity: 0.65;
`
