import React, { memo } from "react"

const JapaneseIcon = (props) => (
  <svg viewBox="0 0 29 29" {...props}>
    <circle cx="14.5" cy="14.5" r="12.5" fill="#EAEAEA"/>
    <path d="M14.6677 20.7451C17.9974 20.7451 20.6967 17.9794 20.6967 14.5679C20.6967 11.1563 17.9974 8.39062 14.6677 8.39062C11.3379 8.39062 8.63867 11.1563 8.63867 14.5679C8.63867 17.9794 11.3379 20.7451 14.6677 20.7451Z" fill="#D31C24"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.694336 14.4375C0.694336 6.70552 6.96234 0.4375 14.6943 0.4375C22.4263 0.4375 28.6943 6.70552 28.6943 14.4375C28.6943 22.1695 22.4263 28.4375 14.6943 28.4375C6.96234 28.4375 0.694336 22.1695 0.694336 14.4375ZM14.6943 2.4375C8.06691 2.4375 2.69434 7.81008 2.69434 14.4375C2.69434 21.0649 8.06691 26.4375 14.6943 26.4375C21.3217 26.4375 26.6943 21.0649 26.6943 14.4375C26.6943 7.81008 21.3217 2.4375 14.6943 2.4375Z" fill="white"/>
  </svg>

)

export default memo(JapaneseIcon)
