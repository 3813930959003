import React, { memo } from "react"

const EmailInputIcon = (props) => (
  <svg
    height="1em"
    viewBox="0 0 17.656 13.412"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="rgb(255 83 99)" />
        <stop offset="1" stopColor="#f93779" />
      </linearGradient>
    </defs>
    <g id="Email">
      <path
        id="Caminho_89"
        data-name="Caminho 89"
        d="M9.469,11.819a1.1,1.1,0,0,1-1.282,0L.52,6.26A.327.327,0,0,0,0,6.525V15.36a2.218,2.218,0,0,0,2.207,2.223H15.449a2.218,2.218,0,0,0,2.207-2.223V6.525a.327.327,0,0,0-.52-.265Z"
        transform="translate(0 -4.171)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Caminho_90"
        data-name="Caminho 90"
        d="M12.488,4.934,19.342,0H4.87l6.853,4.934A.658.658,0,0,0,12.488,4.934Z"
        transform="translate(-3.278)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
)

export default memo(EmailInputIcon)
