import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 35px;
  height: 35px;  

  &[data-hidden='true'] {
    visibility: hidden;
    height: 0;
  }
`

export const FlagWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Circle = styled.div.attrs({
  className: "flag__circle"
})`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
`
