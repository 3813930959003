import styled from "styled-components"
import LoginLogo from "../../../../assets/svgs/LoginLogo"
import MemhackVerticalLogo from "../../../../assets/svgs/MemhackVerticalLogo"
import { LAPTOP_MAX, MOBILE_MAX } from "../../../../common/breakpoints"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  &[data-show-logo='true'] {
    margin-bottom: 68px;

    &[data-has-status='true'] {
      margin-bottom: 43px;
    }

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 47px;

      &[data-has-status='true'] {
        margin-bottom: 30px;
      }
    }

    @media (max-width: ${MOBILE_MAX}px) {
      margin-bottom: 33px;
    }
  }
`

export const Logo = styled(LoginLogo)`
  font-size: 50px;  
  margin-bottom: 8px;
  color: #F24556;

  ${Container}[data-type='success'] & {
    color: #1BD46F;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 34px;
  }
`

export const LogoWithLabel = styled(MemhackVerticalLogo)`
  font-size: 81px;
  color: #F24556;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 56px;
  }

  @media (max-width: ${MOBILE_MAX}px) {
    font-size: 61px;

    path {
      fill: white;
    }
  }
`

export const StatusText = styled.div`
  background-color: #F24556;
  border-radius: 8px;
  padding: 14px 16px 11px 16px;
  color: white;
  font-size: 15px;
  line-height: 17px;

  b {
    font-family: BlackerDisplayHeavy, Sans-Serif;
    font-size: 20px;
  }

  ${Container}[data-type='success'] & {
    background-color: #1BD46F;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 13px;

    b {
      font-size: 18px;
    }
  }
`
