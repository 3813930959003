import { StudyMode } from './../store/selectors/common';
import { Grade } from './../constants';

export const FBAnalyticsEventsKeys = {
  /// Login events
  login_failed: "login_failed",
  sign_up: "sign_up",

  /// Registration events
  registration_button_click: "registration_button_click",

  /// StudySession events
  deck_study_session_started: "deck_study_session_started",
  deck_study_session_ended: "deck_study_session_ended",
  masterdeck_study_session_started: "masterdeck_study_session_started",
  masterdeck_study_session_ended: "masterdeck_study_session_ended",
  card_graded: "card_graded",

  /// Notifications events
  notifications_enabled: "notifications_enabled",
  notifications_disabled: "notifications_disabled",
  notifications_push_received: "notifications_push_received",
  notifications_push_user_responded: "notifications_push_user_responded",

  /// Sync events
  sync_started: "sync_started",
  sync_ended: "sync_ended",
  sync_cancelled: "sync_cancelled",

  memory_warning: "memory_warning",
}

export const FBAnalyticsParametersKeys = {
  user_id: "id",
  user_email: "email",
  user_first_name: "first_name",
  user_last_name: "last_name",
    
  locale: "locale",
  online: "online",
  aborted: "aborted",
  succeeded: "succeeded",

  decks_count: "decks_count",
  master_decks_count: "master_decks_count",
  total_decks_count: "total_decks_count",

  card_id: "card_id",
  card_language: "card_language",
  card_images_count: "card_images_count",
  grade: "grade",
  difficulty: "difficulty",
  duration: "duration",

  studied_cards_count: "studied_cards_count",
  mode: "mode", //study session duration type

  physical_id: "physical_id",
  virtual_id: "virtual_id",
  deck_title: "deck_title",
  total_cards_count: "total_cards_count",
  overdue_cards_count: "overdue_cards_count",
  chosen_cards_count: "chosen_cards_count",

  estimated_size: "estimated_size",
  sync_duration: "sync_duration",
  sync_succeeded: "sync_succeeded",
}


export const FBAnalyticsSessionDificulty = (val) => {
  const easy = "Fácil";
  const good = "Bom";
  const hard = "Difícil";
  const veryHard = "Muito difícil";
  
  switch (val) {
    case Grade.Easy:
      return easy //Old >3
    case Grade.Good:
      return good //Old >2
    case Grade.Difficult:
      return hard //Old >1
    default:
      return veryHard
  }
}

export const FBAnalyticsCardGrade = (val) => {
  const easy = "Fácil";
  const good = "Bom";
  const hard = "Difícil";
  const dontRemember = "Não lembro";

  switch (val) {
    case 0: 
      return dontRemember
    case 1: 
      return hard
    case 2: 
      return good
    default: 
      return easy
  }
}

export const FBAnalyticsBoolValue = val => {
  const yes = "Sim";
  const no = "Não";

  return val ? yes : no
}

export const FBAnalyticsStudySessionDurationType = val => {
  switch (val) {
    case StudyMode.Ideal: return "ideal"
    case StudyMode.Fair: return "bom"
    case StudyMode.Medium: return "médio"
    default: return "curtinho"
  }
}

export const getDifficultyImplementation = (cards) => {
  if(!cards.length) return FBAnalyticsSessionDificulty(Grade.Repeat)
  
  const totalCards = cards.length;
  const easyGradeCards   = cards.filter(item => item.card.grade === Grade.Easy);
  const goodGradeCards   = cards.filter(item => item.card.grade === Grade.Good);
  const hardGradeCards   = cards.filter(item => item.card.grade === Grade.Difficult);
  const repeatGradeCards = cards.filter(item => item.card.grade === Grade.Repeat);

  const score = (easyGradeCards.length * 4) + (goodGradeCards.length * 3) + (hardGradeCards.length * 2) + repeatGradeCards.length
  const average = score / totalCards;

  const grade = average > 3 ? Grade.Easy : average > 2 ? Grade.Good : average > 1 ? Grade.Difficult : Grade.Repeat;

  return FBAnalyticsSessionDificulty(grade)
}