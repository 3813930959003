import styled, { createGlobalStyle } from "styled-components"
import hackitLogo from "../../assets/svgs/memhack-logo-horizontal.svg"
import bgImage from "./images/bg.png"
import ModeButton from "./ModeButton"
import idealIcon from "./images/ideal-icon.svg"
import normalIcon from "./images/normal-icon.svg"
import mediumIcon from "./images/medium-icon.svg"
import shortIcon from "./images/short-icon.svg"
import hourglass from "./images/hourglass.svg"

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    min-height: 100%;
    overflow: visible;
  }

  body {
    position: relative;
    background: #04294F;
    color: white;
    padding: 0 23px;
  }
`

export const BackgroundImage = styled.img.attrs({
  src: bgImage
})`
  position: absolute;
  top: -24px;
  right: 0;
  width: 134px;
  height: 111px;
`

export const TopRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 39px;
`

export const HackitLogo = styled.img.attrs({
  src: hackitLogo
})`
  width: 81.5px;
  height: 20px;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  font-size: 14px;
  margin: -15px;
  color: white;
`

export const TitleRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
`

export const StyledHourglass = styled.img.attrs({
  src: hourglass
})`
  flex-shrink: 0;
  width: 41px;
  height: 76px;
  margin-right: 16px;
`

export const Title = styled.div`
  font-family: "BlackerDisplayHeavy";
  font-weight: 900;
  font-size: 40px;
  line-height: 1;
`

export const ButtonsContainer = styled.div`
  margin-bottom: 23px;

  > * + * {
    margin-top: 16px;
  }
`

export const DeckTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.7px;
  opacity: 0.65;
  margin-bottom: 16px;
`

export const DeckDescription = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.65;
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 14px;
  color: white;
`

export const IdealButton = styled(ModeButton).attrs({
  icon: idealIcon
})``

export const NormalButton = styled(ModeButton).attrs({
  icon: normalIcon
})`
  .mode-button__top-container {
    background: #FF6638;
    color: black;
  }

  .mode-button__bottom-container {
    color: black;
    background-color: #FF7248;
    border-top-color: #ED572B;
  }
`

export const MediumButton = styled(ModeButton).attrs({
  icon: mediumIcon
})`
  .mode-button__top-container {
    background: #FC9B01;
    color: black;
  }

  .mode-button__bottom-container {
    color: black;
    background-color: #FFA71C;
    border-top-color: #FC9B01;
  }
`

export const ShortButton = styled(ModeButton).attrs({
  icon: shortIcon
})`
  .mode-button__top-container {
    background: #FBC207;
    color: black;
  }

  .mode-button__bottom-container {
    color: black;
    background-color: #FFC919;
    border-top-color: #EDB600;
  }
`
