
const laptopSmall = {
  common: {
    noImage: [
      { header: { size: 16, lineHeight: 21 }, paragraph: { size: 16, lineHeight: 21 } },
      { header: { size: 14, lineHeight: 18 }, paragraph: { size: 14, lineHeight: 18 } },
      { header: { size: 12, lineHeight: 16 }, paragraph: { size: 12, lineHeight: 16 } },
      { header: { size: 10, lineHeight: 14 }, paragraph: { size: 10, lineHeight: 14 } }
    ],
    hasImage: [
      { header: { size: 16, lineHeight: 21 }, paragraph: { size: 16, lineHeight: 21 } },
      { header: { size: 14, lineHeight: 18 }, paragraph: { size: 14, lineHeight: 18 } },
      { header: { size: 12, lineHeight: 16 }, paragraph: { size: 12, lineHeight: 16 } },
      { header: { size: 10, lineHeight: 14 }, paragraph: { size: 10, lineHeight: 14 } }
    ]
  },
  asian: {
    noImage: [{
      header: {
        size: 26, smallSize: 18,
        lineHeight: 34, smallLineHeight: 25
      }, paragraph: { size: 16, lineHeight: 21 }
    },{
        header: {
        size: 24, smallSize: 16,
        lineHeight: 30, smallLineHeight: 21
      }, paragraph: { size: 14, lineHeight: 18 }
    },{ 
        header: {
        size: 22, smallSize: 14,
        lineHeight: 26, smallLineHeight: 18
      }, paragraph: { size: 12, lineHeight: 16 }
    },{
      header: {
        size: 20, smallSize: 12,
        lineHeight: 24, smallLineHeight: 16
      }, paragraph: { size: 10, lineHeight: 14 } }
    ],
    hasImage: [
      { header: { size: 16, lineHeight: 21 }, paragraph: { size: 16, lineHeight: 21 } }
    ]
  }
}

const laptop = {
  common: {
    noImage:[
      { header: { size: 18, lineHeight: 24 }, paragraph: { size: 18, lineHeight: 24 } },
      ...laptopSmall.common.noImage
    ],
    hasImage: [
      { header: { size: 18, lineHeight: 24 }, paragraph: { size: 18, lineHeight: 24 } },
      ...laptopSmall.common.hasImage
    ]
  },
  asian: {
    noImage:[
      { header: {
        size: 30, smallSize: 20,
        lineHeight: 38, smallLineHeight: 30 
      }, paragraph: { size: 18, lineHeight: 24 } },
      ...laptopSmall.asian.noImage
    ],
    hasImage: [
      { header: {
        size: 30, smallSize: 20,
        lineHeight: 38, smallLineHeight: 30
      }, paragraph: { size: 18, lineHeight: 24 } },
      ...laptopSmall.asian.hasImage
    ]
  }
}

const desktopSmall = {
  common:{
    noImage: [
      { header: { size: 24, lineHeight: 28 }, paragraph: { size: 20, lineHeight: 28 } },
      ...laptop.common.noImage
    ],
    hasImage: [
      { header: { size: 24, lineHeight: 28 }, paragraph: { size: 20, lineHeight: 28 } },
      ...laptop.common.hasImage
    ]
  },
  asian: {
    noImage:[
      { header: {
        size: 34, smallSize: 22,
        lineHeight: 40, smallLineHeight: 32
      }, paragraph: { size: 20, lineHeight: 28 } },
      ...laptop.asian.noImage
    ],
    hasImage: [
      { header: {
        size: 34, smallSize: 22,
        lineHeight: 40, smallLineHeight: 32
      }, paragraph: { size: 20, lineHeight: 28 } },
      ...laptop.asian.hasImage
    ]
  }
}

const desktop = {
  common:{
    noImage: [
      { header: { size: 26, lineHeight: 34 }, paragraph: { size: 26, lineHeight: 30 } },
      { header: { size: 26, lineHeight: 34 }, paragraph: { size: 24, lineHeight: 30 } },
      ...desktopSmall.common.noImage
    ],
    hasImage: [
      { header: { size: 28 }, paragraph: { size: 23, lineHeight: 30 } },
      ...desktopSmall.common.hasImage
    ]
  },
  asian: {
    noImage:[
      { header: {
        size: 44, smallSize: 24,
        lineHeight: 50, smallLineHeight: 30 
      }, paragraph: { size: 26, lineHeight: 30 } },
      { header: { size: 28 }, paragraph: { size: 24, lineHeight: 30 } },
      ...desktopSmall.asian.noImage
    ],
    hasImage: [
      { header: { size: 28 }, paragraph: { size: 23, lineHeight: 30 } },
      ...desktopSmall.asian.hasImage
    ]
  }
}

const mobile = {
  common: {
    noImage: [
      { header: { size: 24 }, paragraph: { size: 14, lineHeight: 20} },
      { header: { size: 14 }, paragraph: { size: 12, lineHeight: 16} },
      { header: { size: 14 }, paragraph: { size: 10, lineHeight: 14} },
      { header: { size: 14 }, paragraph: { size: 8,  lineHeight: 12} }
    ],
    hasImage: [
      { header: { size: 24 }, paragraph: { size: 14, lineHeight: 20} },
      { header: { size: 14 }, paragraph: { size: 12, lineHeight: 16} },
      { header: { size: 14 }, paragraph: { size: 10, lineHeight: 14} },
      { header: { size: 14 }, paragraph: { size: 8,  lineHeight: 12} }
    ]
  },
  asian: {
    noImage: [
      { header: { size: 24 }, paragraph: { size: 14, lineHeight: 20} },
      { header: { size: 14 }, paragraph: { size: 12, lineHeight: 16} },
      { header: { size: 14 }, paragraph: { size: 10, lineHeight: 14} },
      { header: { size: 14 }, paragraph: { size: 8,  lineHeight: 12} }
    ],
    hasImage: [
      { header: { size: 24 }, paragraph: { size: 14, lineHeight: 20} },
      { header: { size: 14 }, paragraph: { size: 12, lineHeight: 16} },
      { header: { size: 14 }, paragraph: { size: 10, lineHeight: 14} },
      { header: { size: 14 }, paragraph: { size: 8,  lineHeight: 12} }
    ]
  }
}

const sizes = {
  desktop,
  desktopSmall,
  laptop,
  laptopSmall,
  mobile
}

export const getTextSizes = (activeSize, isAsian, hasImage) => {
  const {
    isMobile,
    isLaptop,
    isDesktopSmall,
    isLaptopSmall
  } = activeSize;
  let platform = "desktop"

  if (isMobile) {
    platform = "mobile"
  } else if (isLaptopSmall) {
    platform = "laptopSmall"
  } else if (isLaptop) {
    platform = "laptop"
  } else if (isDesktopSmall) {
    platform = "desktopSmall"
  }

  const byPlatform = sizes[platform]
  const byLanguage = byPlatform[isAsian ? "asian" : "common"] ?? byPlatform.common

  return byLanguage[hasImage ? 'hasImage' : 'noImage']
}