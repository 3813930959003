export const types = {
  eventCodes: {
    keys: {
      digitOne: `Digit1`,
      digitTwo: `Digit2`,
      digitThree: `Digit3`,
      digitFour: `Digit4`,
      enter: `Enter`,
      space: `Space`,
      keyUp: `keyup`
    }
  },
  languageTags: {
    italian: "italian",
    german: "german",
    english: "english",
    french: "french",
    spanish: "spanish",
    business: "english",
    "pt-BR": "portuguese",
    portuguese: "portuguese",
    gavin: "english",
    englsih: "english",
    mandarim: "mandarim",
    mandarin: "mandarin",
    japanese: "japanese"
  },
  error: {
    NoTokenFoundError: "NoTokenFoundError",
    NetworkingError: "NetworkingError",
    LoadImageError: "LoadImageError",
    InternalServerError: "InternalServerError",
    AuthenticationException: "AuthenticationException",
    ServiceUnavailable: "ServiceUnavailable",
    MalformedJSONException: "MalformedJSONException",
    DataNotFoundException: "DataNotFoundException",
    InvalidInputsException: "InvalidInputsException",
    EmailAlreadyRegisteredException: "EmailAlreadyRegisteredException",
    ConnectionFailedException: "ConnectionFailedException",
    EmailNotFoundException: "EmailNotFoundException",
    TypeCheckException: "TypeCheckException",
    EmailNotConfirmedException: "EmailNotConfirmedException",
    WrongPasswordException: "WrongPasswordException",
    TermsAndConditionsNotAcceptedError: "TermsAndConditionsNotAcceptedError"
  },
  deck: {
    master: `master`,
    all: `all`
  }
}
