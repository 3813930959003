import React, { memo, useCallback, useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { loginText } from "../../../assets/textual/text"
import sidebarCover from "../../../assets/graphics/login/sidebar-cover-2x.png"
import EntrancePage from "../Common/EntrancePage"
import { PageContainer, ContentContainer, FormContainer } from "../Common/styles"
import TermsAndConditionsForm from "./TermsAndConditionsForm"
import { AlertTermsLink, StyledInnerContainer } from "./styles"
import { routes } from "../../../utils/constants"
import BackButton from "../Common/BackButton"
import { setTermsAccepted } from "../../../store/actions/auth"
import ModalAlert from "../../../common/components/ModalAlert"
import * as referralSelectors from "../../../store/selectors/referral"
import { Dictionary } from "../../../utils/i18n"
import { useTranslation } from 'react-i18next';

const initialValues = {
  accepted: false
}

const initialErrors = {
  accepted: "Not accepted"
}

const schema = yup.object().shape({
  accepted: yup.boolean()
})

const TermsAndConditionsPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [showAlert, setShowAlert] = useState(false)
  const referral = useSelector(referralSelectors.referral)


  const handleSubmit = useCallback((values, { setSubmitting }) => {
    if (values.accepted) {
      dispatch(setTermsAccepted())
      setSubmitting(false)
      history.push(referral?.params ? referral.pathname : routes.dashboard)
    } else {
      setShowAlert(true)
      setSubmitting(false)
    }
  }, [history, dispatch, referral])

  const handleCloseAlert = useCallback(() => {
    setShowAlert(false)
  }, [])

  return (
    <EntrancePage slogan={loginText.sidebarHeader} coverImage={sidebarCover}>
      <ModalAlert
        show={showAlert}
        title={t(Dictionary.common.sorry)}
        onClose={handleCloseAlert}
      >
        {t(Dictionary.termsAndConditionsText.sorryText)}{" "}
        <AlertTermsLink href={routes.memhack.termsAndConditions} target="_blank">
          {t(Dictionary.termsAndConditionsText.link)}.
        </AlertTermsLink>
      </ModalAlert>
      <PageContainer>
        <BackButton backUrl={routes.login} />
        <ContentContainer>
          <FormContainer>
            <StyledInnerContainer>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                initialErrors={initialErrors}
                onSubmit={handleSubmit}
              >
                <TermsAndConditionsForm />
              </Formik>
            </StyledInnerContainer>
          </FormContainer>
        </ContentContainer>
      </PageContainer>
    </EntrancePage>
  )
}

export default memo(TermsAndConditionsPage)
