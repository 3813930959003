export const mobileAppIcons = {
  wavyHourglassWidth: `613.4`,
  wavyHourglassHeight: `772.95`,
  wavyHourglassViewBox: `0 0 613.399 772.953`,
  hamburgerWidth: `18.5`,
  hamburgerHeight: `14.5`,
  hamburgerViewBox: `0 0 18.5 14.5`,

  mobileAppNavBarMemhackLogoWidth: `300`,
  mobileAppNavBarMemhackLogoViewBox: `4 0 74 21`,

  mobileTopBarMemhackLogoWidth: `99`,
  mobileTopBarMemhackLogoViewBox: `4 0 74 21`,

  mobileToggleAppNavBarIconHeight: `18.5`,
  mobileToggleAppNavBarIconWidth: `18.5`,
  mobileToggleAppNavBarIconViewBox: `0 0 18.5 14.5`,

  closeMobileNavBarIconWidth: `24`,
  closeMobileNavBarIconHeight: `24`,
  closeMobileNavBarIconViewBox: `0 0 24 24`,
  topbarReturnArrowWidth: `16`,
  topbarReturnArrowHeight: `16`,
  topbarReturnArrowViewBox: `0 0 16 16`
}

export const appIcons = {
  searchFilterTabIconWidth: `15.406`,
  searchFilterTabIconViewBox: `0 0 13.406 13.406`,

  entranceReturnButtonWidth: `16`,
  entranceReturnButtonViewBox: `0 0 16 16`,

  topBarMemhackFoxWidth: `31`,
  topBarMemhackFoxViewBox: `0 0 86.622 47.419`,

  appNavBarMemhackLogoWidth: `126`,
  appNavBarMemhackLogoViewBox: `4 0 74 21`,

  appNavBarCollapsedMemhackFoxWidth: `23.523`,
  appNavBarCollapsedMemhackFoxViewBox: `0 0 23.523 25.802`,

  appNavBarCollapseArrowWidth: `14`,
  appNavBarCollapseArrowViewBox: `0 0 21 21`,

  appNavBarLinkDashboardWidth: `11`,
  appNavBarLinkDashboardViewBox: `-1 0 12.633 15.44`,

  appNavBarLinkDiscoverWidth: `11`,
  appNavBarLinkDiscoverViewBox: `0 0 16 16`,

  appNavBarLinkSupportWidth: `11`,
  appNavBarLinkSupportViewBox: `2 0 13 18`,

  appNavBarLinkTermsAndConditionsWidth: `11`,
  appNavBarLinkTermsAndConditionsViewBox: `0 0 12.842 13.1`,

  appNavBarLinkConfigurationsWidth: `11`,
  appNavBarLinkConfigurationsViewBox: `0 0 17.509 18`,

  appNavBarLinkTakeATourWidth: `11`,
  appNavBarLinkTakeATourViewBox: `0 0 14 16`,

  appNavBarLinkSignoutWidth: `11`,
  appNavBarLinkSignoutViewBox: `0 0 15.75 20`,

  loadingButtonSpinnerWidth: `18`,
  loadingButtonSpinnerHeight: `18`,
  loadingButtonSpinnerViewBox: `0 6 26.593 12.593`,

  closeToastIconWidth: `14`,
  closeToastIconHeight: `18`,
  closeToastIconViewBox: `-4 -4 18 18`,

  memhackLogoWidth: `120`,
  memhackLogoViewBox: `0 0 185 94`,

  fluencyLogoLightWidth: `138.124`,
  fluencyLogoLightHeight: `49.33`,
  fluencyLogoLightViewBox: `0 0 138.124 49.33`,

  memhackFoxWidth: `56.4`,
  memhackFoxHeight: `64.8`,
  memhackFoxViewBox: `0 0 46.7 64`,

  statCardIconWidth: `42`,
  statCardIconHeight: `42`,
  statCardIconViewBox: `0 0 54 54`,

  mobileStatCardIconWidth: `36`,
  mobileStatCardIconHeight: `36`,
  mobileStatCardIconViewBox: `0 0 54 54`,

  returnTabArrowWidth: `13`,
  returnTabArrowViewBox: `0 0 16 18`,

  arrowWidth: `16.836`,
  arrowHeight: `10.511`,
  arrowViewBox: `0 0 16.836 10.511`,

  dropdownButtonArrowWidth: `12`,
  dropdownButtonArrowViewBox: `0 0 18 12`,

  arrowWithHandleWidth: `16`,
  arrowWithHandleHeight: `16`,
  arrowWithHandleViewBox: `0 0 16 16`,

  deckSearchTopBarArrowWidth: `24`,
  deckSearchTopBarArrowHeight: `24`,
  deckSearchTopBarArrowViewBox: `0 0 16 16`,

  calendarCheckId: `monthlyGoalCalendarCheckIcon`,
  calendarCheckWidth: `26.147`,
  calendarCheckHeight: `38`,
  calendarCheckViewBox: `-28 3 59 38`,

  cardsRemainingWidth: `21`,
  cardsRemainingViewBox: `0 0 21 21`,

  cardsOverdueWidth: `16`,
  cardsOverdueViewBox: `0 0 21 21`,

  cardCountWidth: `14`,
  cardCountViewBox: `0 0 16.5 20.166`,

  checkmarkWidth: `15.718`,
  checkmarkHeight: `11.721`,
  checkmarkViewBox: `0 0 15.718 11.721`,

  closeWidth: `14.083`,
  closeHeight: `14.082`,
  closeViewBox: `0 0 14.083 14.082`,

  closeSideBarWidth: `20`,
  closeSideBarHeight: `20`,
  closeSideBarViewBox: `0 0 20 20`,

  configurationsWidth: `24`,
  configurationsHeight: `24`,
  configurationsViewBox: `0 0 20 20`,

  filterButtonWidth: `13`,
  filterButtonViewBox: `0 0 19 19`,

  userDecksCardIconWidth: `21`,
  userDecksCardIconViewBox: `3 2 29 29`,

  flagWidth: `21`,
  flagViewBox: `3 2 29 29`,

  penEditWidth: `14`,
  penEditHeight: `14`,
  penEditViewBox: `0 0 19.309 19.313`,

  lightningWidth: `14.583`,
  lightningHeight: `24.661`,
  lightningViewBox: `0 0 14.583 24.661`,

  deckTypeWidth: `36`,
  deckTypeHeight: `36`,
  deckTypeViewBox: `0 0 36 36`,

  teacherStatusWidth: `36`,
  teacherStatusHeight: `36`,
  teacherStatusViewBox: `0 0 36 36`,

  triangleWidth: `18`,
  triangleHeight: `24`,
  triangleViewBox: `0 0 28 24`,

  plusWidth: `11`,
  plusHeight: `11`,
  plusViewBox: `0 0 15.225 15.225`,

  trashWidth: `12.444`,
  trashHeight: `16`,
  trashViewBox: `0 0 12.444 16`,

  undoWidth: `16`,
  undoHeight: `16`,
  undoViewBox: `0 0 16 16`,

  searchTabIconWidth: `16.549`,
  searchTabIconViewBox: `0 0 19.549 19.532`,

  mobileSearchIconWidth: `21.549`,
  mobileSearchIconHeight: `21.532`,
  mobileSearchIconViewBox: `0 0 19.549 19.532`
}

export const inputIcons = {
  validInputIconWidth: `14.383`,
  validInputIconHeight: `16.946`,
  validInputIconViewBox: `0 0 21.383 7.946`,

  invalidInputIconWidth: `14.383`,
  invalidInputIconHeight: `16.946`,
  invalidInputIconViewBox: `0 0 21.383 7.946`,

  passwordIconWidth: `17`,
  passwordIconHeight: `22.981`,
  passwordIconViewBox: `0 0 17.678 22.981`,

  emailIconWidth: `18`,
  emailIconHeight: `13.412`,
  emailIconViewBox: `0 0 17.656 13.412`,

  toggleInputHiddenIconId: `toggleInputHiddenSvgIcon`,
  toggleInputHiddenIconWidth: `20`,
  toggleInputHiddenIconHeight: `21`,
  toggleInputHiddenIconViewBox: `0 0 28.254 22.128`,

  toggleInputVisibleIconId: `toggleInputVisibleSvgIcon`,
  toggleInputVisibleIconWidth: `20`,
  toggleInputVisibleIconHeight: `21`,
  toggleInputVisibleIconViewBox: `0 0 28.254 22.128`,

  fullNameIconId: `fullNameIconSvg`,
  fullNameIconWidth: `12.573`,
  fullNameIconHeight: `19.474`,
  fullNameIconViewBox: `0 0 12.573 19.474`,

  phoneIconId: `phoneIconSvg`,
  phoneIconWidth: `18.298`,
  phoneIconHeight: `18.337`,
  phoneIconViewBox: `0 0 18.298 18.337`
}

export const loginIcons = {
  memhackSplashLogoWidth: `112`,
  memhackSplashLogoHeight: `112`,
  memhackSplashLogoViewBox: `39 0 112 112`,
  emailWidth: `17.656`,
  emailHeight: `13.412`,
  emailViewBox: `0 0 17.656 13.412`,

  passwordWidth: `15.678`,
  passwordHeight: `22.981`,
  passwordViewBox: `0 0 17.678 22.981`
}

export const registerIcons = {}

export const changePasswordIcons = {}

export const forgotPasswordIcons = {}

export const dashboardIcons = {
  swipeIconWidth: `42.731`,
  swipeIconHeight: `38.414`,
  swipeIconViewBox: `0 0 32.731 28.414`,

  clockIconWidth: `27`,
  clockIconHeight: `27`,
  clockIconViewBox: `0 0 27 27`,

  quickStartTutorialFoxWidth: `41`,
  quickStartTutorialFoxHeight: `49.8`,
  quickStartTutorialFoxViewBox: `0 0 31 39.8`,

  searchFilterTabIconWidth: `13.406`,
  searchFilterTabIconViewBox: `0 0 13.406 13.406`,

  removeFilterButtonWidth: `24`,
  removeFilterButtonHeight: `24`,
  removeFilterButtonViewBox: `0 0 24 24`,

  deckSearchResultIconWidth: `18.5`,
  deckSearchResultIconHeight: `25.166`,
  deckSearchResultIconViewBox: `-5 -5 19.5 30.166`,

  closeSearchTopBarIconWidth: `46`,
  closeSearchTopBarIconHeight: `46`,
  closeSearchTopBarIconViewBox: `0 0 46 46`,

  mobileCloseSearchTopBarIconWidth: `26`,
  mobileCloseSearchTopBarIconHeight: `26`,
  mobileCloseSearchTopBarIconViewBox: `15 15 16 16`
}

export const supportIcons = {
  phoneIconWidth: `18.298`,
  phoneIconHeight: `18.337`,
  phoneIconViewBox: `0 0 18.298 18.337`,

  emailIconWidth: `18`,
  emailIconHeight: `13.412`,
  emailIconViewBox: `0 0 17.656 13.412`
}

export const configurationsIcons = {
  notificationsIconWidth: `16`,
  notificationsIconHeight: `20.012`,
  notificationsIconViewBox: `0 0 16 20.012`
}

export const deckPageIcons = {
  increasingBarsWidth: `14.779`,
  increasingBarsHeight: `12.445`,
  increasingBarsViewBox: `0 0 14.779 12.445`,

  playButtonWidth: `55`,
  playButtonHeight: `55`,
  playButtonViewBox: `0 0 55 55`,
  returnButtonWidth: `21`,
  returnButtonHeight: `21`,
  returnButtonViewBox: `0 0 16 16`
}

export const deckModeIcons = {
  hourglassWidth: `247`,
  hourglassHeight: `594`,
  hourglassViewBox: `0 0 317 594`,

  memhackFoxWidth: `12`,
  memhackFoxHeight: `12`,
  memhackFoxViewBox: `0 0 56.622 60.419`,

  mobileToolTipInfoIconWidth: `54`,
  mobileToolTipInfoIconHeight: `54`,
  mobileToolTipInfoIconViewBox: `0 0 26 26`,

  mobileSwipeIconWidth: `42.731`,
  mobileSwipeIconHeight: `38.414`,
  mobileSwipeIconViewBox: `0 0 32.731 28.414`,

  mobileModeStopwatchHeight: `29`,
  mobileModeStopwatchWidth: `29`,
  mobileModeStopwatchViewBox: `0 0 30 35.07`,

  idealModeStopwatchWidth: `24`,
  idealModeStopwatchHeight: `24`,
  idealModeStopwatchViewBox: `0 0 30 35.07`,

  normalModeStopwatchWidth: `24`,
  normalModeStopwatchHeight: `24`,
  normalModeStopwatchViewBox: `0 0 30 35.02`,

  mediumModeStopwatchWidth: `24`,
  mediumModeStopwatchHeight: `24`,
  mediumModeStopwatchViewBox: `0 0 30.15 35.18`,

  shortModeStopwatchWidth: `24`,
  shortModeStopwatchHeight: `24`,
  shortModeStopwatchViewBox: `0 0 30 35.02`,

  hourglassGlassId: `hourglassGlass`,
  hourglassGlassX: `0px`,
  hourglassGlassY: `0px`,
  hourglassGlassWidth: `225.1px`,
  hourglassGlassHeight: `284.5px`,
  hourglassGlassViewBox: `0 0 225.1 284.5`,
  hourglassGlassEnableBackground: `new 0 0 225.1 284.5`,

  hourglassTopBarWidth: `224.75`,
  hourglassTopBarHeight: `37.323`,
  hourglassTopBarViewBox: `0 0 224.75 37.323`,

  hourglassBottomBarWidth: `224.996`,
  hourglassBottomBarHeight: `45.044`,
  hourglassBottomBarViewBox: `0 0 224.996 45.044`
}

export const studySessionIcons = {
  controlsTabIconWidth: `21`,
  controlsTabIconHeight: `20`,
  controlsTabIconViewBox: `0 0 21 18`,

  cardFlipWidth: `21.098`,
  cardFlipHeight: `14.95`,
  cardFlipViewBox: `0 0 19.098 24.95`,

  cardSoundPlayButtonWidth: `45`,
  cardSoundPlayButtonHeight: `45`,
  cardSoundPlayButtonViewBox: `0 0 55 55`,

  controlsShortcutSpacebarSymbolId: `spaceShortcutSymbol`,
  controlsShortcutSpacebarSymbolWidth: `8.681`,
  controlsShortcutSpacebarSymbolHeight: `4.911`,
  controlsShortcutSpacebarSymbolViewBox: `0 0 11.681 4.911`
}

export const studySessionStatsIcons = {}
