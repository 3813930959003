import { NavLink } from "react-router-dom"
import styled from "styled-components"
import DashboardIcon from "../images/DashboardIcon"
import LogOutIcon from "../images/LogOutIcon"
import SupportIcon from "../images/SupportIcon"
import TermsIcon from "../images/TermsIcon"
import MemhackHorizontalLogo from "../../../../assets/svgs/MemhackHorizontalLogo"

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: scroll;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &[data-visible='true'] {
    opacity: 1
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

export const HeaderContainer = styled.div`
  background: linear-gradient(180deg, #FF5363 0%, #F93779 100%);
  box-shadow: 0px 3px 3px #00000026;
  border-radius: 0px 0px 10px 10px;
  height: 156px;
  box-sizing: border-box;
`

export const CloseButtonRow = styled.div`
  padding: 16px 0 0 20px;
  margin-bottom: 58px;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 44px;
  height: 44px;
  margin: -15px;
  font-size: 14px;
  color: white;
`

export const LogoRow = styled.div`
  padding-left: 23px;
`

export const StyledLogo = styled(MemhackHorizontalLogo)`
  font-size: 37px;
  color: white;
`

export const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TopItemsContainer = styled.div`
  flex: 1;
  padding-top: 9px;
`

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 57px;
  color: #83838C;
  font-size: 14px;
  white-space: nowrap;
  border-bottom: 1px solid #E3E3E380;
`

export const LogOutMenuItem = styled(MenuItem)`
  font-weight: bold;
  border-bottom: none;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 18px;
  margin-right: 12px;
  color: #F93977;
`

export const StyledDashboardIcon = styled(DashboardIcon)`
  font-size: 20px;
`

export const StyledSupportIcon = styled(SupportIcon)`
  font-size: 16px;
`

export const StylesTermsIcon = styled(TermsIcon)`
  font-size: 14px;
`

export const StyledLogOutIcon = styled(LogOutIcon)`
  font-size: 20px;
`
