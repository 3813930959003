import styled from "styled-components"
import { LAPTOP_MAX } from "../../common/breakpoints"
import topBardBg from "./images/top-card-bg.png"

export const ContentContainer = styled.div`
  padding: 60px 100px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 42px 70px;
  }
`

export const TopCardContainer = styled.div`
  color: white;
  height: 412px;
  box-sizing: border-box;
  padding: 82px 77px;
  margin-bottom: 63px;
  background: url('${topBardBg}'), linear-gradient(74deg, #008EE0 0%, #00AEE0 100%);
  background-size: contain, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: right center, center;
  border: 1px solid #D6DAEB48;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 288px;
    padding: 57px 54px;
    margin-bottom: 44px;
  }
`

export const TopCardTitle = styled.div`
  font-family: "IBM Plex Serif";
  font-size: 48px;
  line-height: 54px;
  font-weight: bold;
  letter-spacing: 0px;
  margin-bottom: 20px;
  max-width: 501px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 14px;
    max-width: 351px;
  }
`

export const TopCardSubTitle = styled.div`
  font-size: 16px;
  line-height: 23px;
  font-style: italic;
  letter-spacing: 0px;
  max-width: 501px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
    line-height: 15px;
    max-width: 351px;
  }
`

export const DashboardButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DashboardButton = styled.button`
  width: 380px;
  height: 70px;
  border: 2px solid #00AEE0;
  border-radius: 35px;
  color: #00AEE0;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 266px;
    height: 50px;
    border-radius: 25px;
    font-size: 13px;
  }

  :active {
    opacity: 0.7;
  }
`
