import styled from "styled-components"
import { MOBILE_MAX } from "../../common/breakpoints"

export const StudySessionPageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #FAFCFE 0%, #F4FDFF 100%);

  @media (max-width: ${MOBILE_MAX}px) {
    background: linear-gradient(180deg, #ff5363 0%, #f93779 100%);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
  }
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  @media (max-width: ${MOBILE_MAX}px) {
    color: white;
  }
`
