import React, { memo, useRef } from "react"
import useTransitionState, {
  BEFORE_ENTER,
  BEFORE_EXIT,
  ENTERED,
  EXITED,
  EXITING,
  ENTERING
} from "../../../hooks/useTransitionState"
import useOnChange from "../../../hooks/useOnChange"
import { useTranslation } from "react-i18next"
import { Dictionary } from "./../../../utils/i18n";
import {
  BottomContainer,
  Container,
  Description,
  HourglassIcon,
  Labels,
  Title,
  TopContainer,
  Notice,
  ContinueButton,
  ArrowIcon,
  BottomWrapper
} from "./styles"

const ModeButton = ({
  className,
  mode,
  title,
  description,
  notice,
  icon,
  expanded = false,
  disabled = false,
  onContinue,
  onExpand
}) => {
  const containerRef = useRef()
  const bottomContainerRef = useRef()
  const transition = useTransitionState(expanded && !disabled, 300)
  const {t} = useTranslation()

  useOnChange(() => {
    if (transition === ENTERED) {
      containerRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" })
    }
  }, [transition])

  const handleToggleExpand = () => {
    if (onExpand) {
      onExpand(mode)
    }
  }

  const handleContinueClick = () => {
    if (onContinue) {
      onContinue(mode)
    }
  }

  const bottomWrapperStyle = {}

  if (transition === BEFORE_ENTER || transition === EXITING) {
    bottomWrapperStyle.height = 0
  } else if (transition === ENTERING || transition === BEFORE_EXIT) {
    bottomWrapperStyle.height = `${bottomContainerRef.current?.offsetHeight ?? 0}px`
  }

  return (
    <Container ref={containerRef} className={className} data-disabled={disabled}>
      <TopContainer onClick={handleToggleExpand}>
        <Labels>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Labels>
        <HourglassIcon src={icon} />
      </TopContainer>
      {transition !== EXITED && (
        <BottomWrapper style={bottomWrapperStyle}>
          <BottomContainer
            ref={bottomContainerRef}
            data-in-transition={transition !== EXITED && transition !== ENTERED}
          >
            <Notice>{notice}</Notice>
            <ContinueButton primary outlined onClick={handleContinueClick}>
              <span>
                {t(Dictionary.dashboard.studyNowDeck)}
              </span>
              <ArrowIcon />
            </ContinueButton>
          </BottomContainer>
        </BottomWrapper>
      )}
    </Container>
  )
}

export default memo(ModeButton)
