import React, { memo } from "react"

const OverdueCardsIcon = (props) => (
  <svg height="1em" viewBox="0 0 21 21" {...props}>
    <g transform="translate(1.696 3.19)">
      <g transform="translate(0 -2)">
        <path fill="currentColor" d="M14.615,20.39H1.538A1.526,1.526,0,0,1,0,18.879V1.509A1.526,1.526,0,0,1,1.538,0H14.615a1.526,1.526,0,0,1,1.539,1.509v17.37A1.526,1.526,0,0,1,14.615,20.39ZM.927.7a1.309,1.309,0,0,0-.219.809v17.37a1.3,1.3,0,0,0,.219.814H7.863a1.361,1.361,0,0,0,.214-.814V1.509A1.344,1.344,0,0,0,7.863.7Z" transform="translate(0 -1)" />
      </g>
    </g>
  </svg>
)

export default memo(OverdueCardsIcon)
