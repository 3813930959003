import { NavLink } from "react-router-dom"
import styled from "styled-components"
import CollapseIcon from "./images/CollapseIcon"
import MemhackHorizontalLogo from "../../../assets/svgs/MemhackHorizontalLogo"
import DashboardIcon from "./images/DashboardIcon"
import SupportIcon from "./images/SupportIcon"
import TermsIcon from "./images/TermsIcon"
import LogOutIcon from "./images/LogOutIcon"
import BottomLogo from "./images/BottomLogo"
import { LAPTOP_MAX } from "../../breakpoints"
import {
  navBarTransition,
  navBarWidthDesktop,
  navBarWidthDesktopCollapsed,
  navBarWidthLaptop,
  navBarWidthLaptopCollapsed
} from "../../styles/navBar"

export const PanelContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${navBarWidthDesktopCollapsed}px;
  z-index: 20;
  background: linear-gradient(193deg, #FF5363 0%, #F93779 100%);
  overflow: hidden;
  transition: width ${navBarTransition};

  &[data-expanded='true'] {
    width: ${navBarWidthDesktop}px;

    @media (max-width: ${LAPTOP_MAX}px) {
      width: ${navBarWidthLaptop}px;
    }
  }

  &[data-expanded='false'] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    width: ${navBarWidthLaptopCollapsed}px;
  }
`

export const MenuContent = styled.div`
  position: relative;
  padding: 48px 32px 0 0;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 34px 22px 0 0;
  }

  ${PanelContainer}[data-expanded='false'] & {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 0 0;

    @media (max-width: ${LAPTOP_MAX}px) {
      padding: 22px 0 0 0;
    }
  }
`

export const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: contain;

  ${PanelContainer}[data-expanded='false'] & {
    display: none;
  }

  ${PanelContainer}[data-expanded='true'] & {
    width: ${navBarWidthDesktop}px;

    @media (max-width: ${LAPTOP_MAX}px) {
      width: ${navBarWidthLaptop}px;
    }
  }
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 49px;
  margin-bottom: 74px;

  @media (max-width: ${LAPTOP_MAX}px) {
    padding-left: 34px;
    margin-bottom: 52px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    padding: 0;
    margin-bottom: 96px;

    @media (max-width: ${LAPTOP_MAX}px) {
      margin-bottom: 67px;
    }
  }
`

export const StyledLogo = styled(MemhackHorizontalLogo)`
  color: white;
  font-size: 28px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 20px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    display: none;
  }
`

export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  color: white;
  transition: transform ${navBarTransition};

  ${PanelContainer}[data-expanded='false'] & {
    transform: rotate(180deg);
  }

  @media (max-width: ${LAPTOP_MAX}px) {
    height: 17px;
  }
`

export const StyledCollapseIcon = styled(CollapseIcon)`
  font-size: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
  }
`

export const SectionTitle = styled.div`
  margin-left: 47px;
  color: #FFFFFF;
  opacity: 0.57;
  margin-bottom: 14px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-left: 33px;
    margin-bottom: 10px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    display: none;
  }
`

export const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 47px;

  @media (max-width: ${LAPTOP_MAX}px) {
    margin-bottom: 33px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    margin-bottom: 0;
  }
`

export const MenuItem = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  padding: 0 35px 0 49px;
  height: 61px;
  color: white;
  font-size: 20px;
  border-top-right-radius: 38px;
  border-bottom-right-radius: 38px;
  white-space: nowrap;
  outline: none;
  &:focus { outline: none; }

  @media (max-width: ${LAPTOP_MAX}px) {
    padding: 0 25px 0 34px;
    height: 42px;
    font-size: 14px;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
  }

  &.active {
    background-color: #ED355E;
  }

  &:not(.active):hover {
    text-decoration: underline;
  }

  ${PanelContainer}[data-expanded='false'] & {
    > *:not(:first-child) {
      display: none;
    }

    background-color: transparent;
    padding: 12px;
    height: auto;
    margin-bottom: 42px;

    @media (max-width: ${LAPTOP_MAX}px) {
      padding: 8px;
      margin-bottom: 29px;
    }
  }
`

export const LogOutMenuItem = styled(MenuItem)``

export const ItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 35px;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 25px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    width: auto;
  }
`

export const StyledDashboardIcon = styled(DashboardIcon)`
  font-size: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    font-size: 24px;

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 17px;
    }
  }
`

export const StyledSupportIcon = styled(SupportIcon)`
  font-size: 16px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 11px;
  }
  
  ${PanelContainer}[data-expanded='false'] & {
    font-size: 21px;

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 15px;
    }
  }
`

export const StylesTermsIcon = styled(TermsIcon)`
  font-size: 13px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 10px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    font-size: 17px;

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 12px;
    }
  }
`

export const StyledLogOutIcon = styled(LogOutIcon)`
  font-size: 20px;

  @media (max-width: ${LAPTOP_MAX}px) {
    font-size: 14px;
  }

  ${PanelContainer}[data-expanded='false'] & {
    font-size: 26px;

    @media (max-width: ${LAPTOP_MAX}px) {
      font-size: 18px;
    }
  }
`

export const Separator = styled.div`
  border-bottom: solid 1px #BC0033;
  width: 80px;
  opacity: 0.44;
  margin-bottom: 42px;

  @media (max-width: ${LAPTOP_MAX}px) {
    width: 56px;
    margin-bottom: 29px;
  }

  ${PanelContainer}[data-expanded='true'] & {
    display: none;
  }
`

export const StyledBottomLogo = styled(BottomLogo)`
  min-height: 52px;
  margin-bottom: 83px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${LAPTOP_MAX}px) {
    min-height: 36px;
    margin-bottom: 58px;
  }

  ${PanelContainer}[data-expanded='true'] & {
    display: none;
  }
`